import React, {useContext, useState, useCallback} from "react";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import ReactSlider from 'react-slider';
import {post, get} from "../../../../components/http";

import CustomField from "../../../../components/CustomField";

import {store} from "../../../../store";
import Card from "../../../../components/Card";
import payIco from "../../../../assets/images/pay.svg"

import cardWhite from "../../../../assets/images/addCredit/card-white.svg"
import cardBlue from "../../../../assets/images/addCredit/card-blue.svg"
import btcBlue from "../../../../assets/images/addCredit/btc-blue.svg"
import coupon from "../../../../assets/images/coupon.svg"
import btcWhite from "../../../../assets/images/addCredit/btc-white.svg"
import Button from "../../../../components/Button";

const CreditCardSchema = Yup.object().shape({
  amount: Yup.string().required("Required"),
  moduleid: Yup.number().required("Required"),
});

const amounts = [10, 25, 50, 100, 250, 500, 1000];

const AddCredit = ({t: creditCardTrans}) => {
  const t = (path) => creditCardTrans(`creditCard.${path}`);
  const [loading, setLoading] = useState()
  const [payLink, setPayLink] = useState("")
  const globalState = useContext(store);
  const {addToast} = useToasts();
  const {payModules} = useSelector(({billing}) => billing);
  const {state: {clientDetails} = {}} = globalState;
  const {datecreated} = clientDetails;
  const filteredModules = payModules.filter(
    ({module}) => module !== "Card Payments"
  );

  const createPayment = useCallback(async (value) => {
    setLoading(true)
    const {data} = await post(`/client/payment/add-credit`, {
      amount: value.amount,
      paymentmodule: value.moduleid,
    });
    if (data.error) {
      setLoading(false)
      return addToast(data.error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    setLoading(false)
    return {paymentLink: data.data.payNowLink, invoiceId: data.data.invoice_id}
  }, [])

  return (
    <div className={"AddCredit"}>
      <Formik
        initialValues={{
          amount: 50,
          moduleText: filteredModules.filter(({module}) => module.toLowerCase().includes("card payments"))[0]?.module?.split("-")[0],
          moduleid: filteredModules.filter(({module}) => module.toLowerCase().includes("card payments"))[0]?.id,
        }}
        key={JSON.stringify(datecreated)}
        validationSchema={CreditCardSchema}
        onSubmit={async (value) => {
          if (payLink) {
            // eslint-disable-next-line no-undef
            return window.open(payLink, "_self", "noopener,noreferrer");
          }
          try {
            const {paymentLink} = await createPayment(value)
            // eslint-disable-next-line no-undef
            window.open(paymentLink, "_self", "noopener,noreferrer");
          } catch (error) {
            return addToast(error.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }}
      >
        {({
            errors,
            touched,
            setFieldValue,
            submitCount,
            values,
            initialValues
          }) => (
          <Form>
            <h3 className="font-size-20 text-grey-2 font-weight-regular m-b-15">
              {t("chooseDeposit.title")}
            </h3>
            <h4 className="font-size-20 text-grey-2 font-weight-regular m-b-40 m-b-15">
              {t("chooseDeposit.selectAmount")}
            </h4>
            <div />
            <div className={"slider"}>
              <ReactSlider
                key={values.amount}
                className="horizontal-slider"
                marks
                markClassName="example-mark"
                min={0}
                max={amounts.length - 1}
                defaultValue={amounts.indexOf(Number(values.amount) || initialValues.amount)}
                thumbClassName="example-thumb"
                trackClassName="example-track"
                onChange={value => setFieldValue("amount", amounts[value])}
                renderThumb={(props, state) => <div {...props}>{amounts[state.valueNow]}</div>}
                renderMark={(props) => <span {...{...props, className: `${props.className} mark-${props?.key}`}}>${amounts[props?.key]}</span>}
              />
            </div>
            <div className={"otherAmountContainer flex flex-align-items-center"}>
              <p className={"p-r-30"}>{t("chooseDeposit.otherAmount")}</p>
              <CustomField
                {...{
                  errors,
                  touched,
                  submitCount,
                  className: `flex-grow-1 m-t-15 m-b-10 otherAmount`,
                  name: "amount",
                }}
              >
                <Field
                  name="amount"
                  type="text"
                />
              </CustomField>
            </div>

            <h4 className="font-size-20 text-grey-2 font-weight-regular m-t-40">
              {t("chooseDeposit.selectMethod")}
            </h4>

            <div className={"paymentMethods flex"}>
              <div>
                <button onClick={() => {
                  const [{id: paddlePay, module}] = payModules.filter(
                    ({module}) => module.toLowerCase().includes("card payments")
                  );
                  setFieldValue("moduleText", module)
                  setFieldValue("moduleid", paddlePay)
                }} type={"button"} className={`button flex-grow-1 width-full-width flex flex-align-items-center ${values.moduleText !== "crypto" && "active"}`}>
                  <img className={"m-r-10"} src={values.moduleText !== "crypto" ? cardWhite : cardBlue} alt=""/>
                  {t("chooseDeposit.fiatButton")}
                </button>
                {values.moduleText !== "crypto" ? <Card>
                  <Card.Body>
                    <div>
                      {t("chooseDeposit.tooltip")}
                    </div>
                  </Card.Body>
                </Card> : ""}
              </div>
              <div>
                <button onClick={() => {
                  setFieldValue("moduleText", "crypto")
                  const [{id: cryptoId}] = payModules.filter(
                    ({module}) => module.toLowerCase().includes("crypto.com")
                  );
                  setFieldValue("moduleid", cryptoId)
                }} type={"button"} className={`button flex-grow-1 width-full-width flex flex-align-items-center ${values.moduleText === "crypto" && "active"}`}>
                  <img className={"m-r-10"} src={values.moduleText === "crypto" ? btcWhite : btcBlue} alt=""/>
                  {t("chooseDeposit.cryptoButton")}
                </button>
                {values.moduleText === "crypto" ? <Card>
                  <Card.Body>
                    <div>
                      {t("chooseDeposit.cryptoTooltip")}
                    </div>
                  </Card.Body>
                </Card> : ""}
              </div>
            </div>
            <div className={"flex flex-justify-content-space-between flex-align-items-center m-b-40"}>
              <p className={"font-size-16 font-weight-semibold m-r-30"}>enter your voucher promo code here</p>
              <div className={"flex-grow-1 width-half p-l-20 position-relative"}>
                <CustomField Ico={() => <img src={coupon} className={"ico"} />}>
                  <Field name="coupon" placeholder={"Voucher Code"} type={"text"} />
                </CustomField>
                <button onClick={async () => {
                  const {coupon, ...rest} = values
                  if (!coupon) {
                    return
                  }
                  const {paymentLink, invoiceId} = await createPayment(rest)
                  const {error} = await post(`/client/invoices/${invoiceId}/coupon`, {coupon})
                  if (error) {
                    return addToast(invoiceError.message, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  }
                  const {error: invoiceError, data} =  await get(`/client/invoices/${invoiceId}`)
                  if (invoiceError) {
                    return addToast(invoiceError.message, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  }
                  setFieldValue("amount", data.data.invoicesDetails.grandtotal)
                  setPayLink(paymentLink)
                }} type={"button"} className={"font-weight-semibold button button--blue button--s cursor-pointer vertical-center right-14"}>Apply</button>
              </div>
            </div>
            <div className={"flex flex-justify-content-space-between flex-align-items-center"}>
              <span />
              <Button
                className={`button button--blue flex flex-align-items-center ${!values.amount && "disabled"}`}
                type="submit"
                loading={loading}
                disabled={!values.amount}
              >
                <img className={"m-r-10"} src={payIco} alt=""/>
                {`${t("pay")} ${values.amount ? `$${values.amount}`: ''}`}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddCredit;
