const common = {
  prev: 'Previous',
  next: 'Next',
  copy: 'Copy',
  copied: 'Copied',
  more: 'More',
  edit: 'Edit',
  delete: 'Delete',
  on: 'on',
  off: 'off',
  success: 'Success',
  coomingSoon: 'Cooming soon',
  remove: 'Remove',  
};

export default common;
