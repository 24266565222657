import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEventsRequest } from "../actions";
import Loader from "../../Volumes/components/Loader/Loader";
import Pagination from "../../../components/Pagination";
import prettyDate from "pretty-date";
import OrizontalScroll from "../../../components/OrizontalScroll";
import info from "../../../assets/images/activity/info.svg";
import warning from "../../../assets/images/activity/warning.svg";

const icons = {
  "INFO": info,
  "ERROR": warning
}

const Activity = ({ accountid, t, isInstanceTabElement }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { events, eventsLoading } = useSelector(({ instances }) => instances);
  const ipsPerPage = 10;
  const isMainPage = !accountid;

  useEffect(() => {
    if(accountid && isInstanceTabElement) {
      getEventsRequest({ accountid })(dispatch);
    } else if(!isInstanceTabElement) {
      getEventsRequest()(dispatch);
    }
  }, [dispatch, accountid]);

  const vmEvents = useMemo(() => {
    if (isInstanceTabElement) {
      return events?.filter(event => event.type.split('.')[0] === 'VM');
    }
    return events;
  }, [events]);

  const currentEvents = useMemo(() => {
    const lastEventIndex = currentPage * ipsPerPage;
    const firstEventIndex = lastEventIndex - ipsPerPage;
    return vmEvents.slice(firstEventIndex, lastEventIndex);
  }, [events, vmEvents, currentPage]);

  const pagesCount = useMemo(() => {
    return Math.ceil(vmEvents.length / ipsPerPage)
  }, [events, vmEvents]);

  return eventsLoading ? (
    <div className={"flex flex-justify-content-center width-full-width m-t-20"}><Loader /></div>
  ) : vmEvents.length === 0 ? (
    <div className="flex flex-column width-full-width height-inherit">
      <div className="text-grey-3 font-size-27 width-full-width font-weight-regular">{t("instance.activity.activity")}</div>
      <p className="font-size-16 text-grey-1 flex flex-align-items-center flex-justify-content-center height-full-height">{t("instance.activity.noActivity")}</p>
    </div>
  ) : (
    <div className="activity-tab">
      <OrizontalScroll>
        <OrizontalScroll.Scrollable>
          <div className="text-grey-3 font-size-27 font-weight-regular">{t("instance.activity.activity")}</div>
          <div className="table ssh m-b-30 m-t-30">
            <div
              className={`table__head flex ${!isMainPage ? "table__head--tab" : undefined
                }`}
            >
              <div className="">
                {t("instance.activity.level")}
              </div>
              <div className="text-align-left">
                {t("instance.activity.type")}
              </div>
              <div className="text-align-left">
                {t("instance.activity.description")}
              </div>
              <div className="text-align-left">
                {t("instance.activity.state")}
              </div>
              <div className="text-align-left">
                {t("instance.activity.created")}
              </div>
            </div>
            <div
              key={currentPage}
              className={`table__body activity-tab__list ${!isMainPage ? "activity-tab__list--tab" : undefined
                }`}
            >
              {currentEvents.map(
                ({
                  id,
                  type,
                  level,
                  description,
                  created,
                  state,
                }) => (
                  <div key={id} className="table__row p-t-0 p-b-0">
                    <div className="flex flex-align-items-center activity-tab__list-item">
                      <p className={`text-grey-3 font-size-15 font-weight-semibold ${level}`}>
                        <img src={icons[level]} alt="" />
                        {t(`instance.activity.${level}`)}
                      </p>
                    </div>
                    <div className="flex flex-align-items-center activity-tab__list-item">
                      <p className="text-grey-3 font-size-15 font-weight-semibold">
                        {type}
                      </p>
                    </div>
                    <div className="flex flex-align-items-center activity-tab__list-item">
                      <p className="text-grey-3 font-size-15 font-weight-semibold">
                        {description}
                      </p>
                    </div>
                    <div className="flex flex-align-items-center activity-tab__list-item">
                      <p className={`text-grey-3 font-size-15 font-weight-semibold ${state}`}>
                        {state}
                      </p>
                    </div>
                    <div className="flex flex-align-items-center activity-tab__list-item">
                      <p className="text-grey-3 font-size-15 font-weight-semibold">
                        {prettyDate.format(new Date(created))}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
            {vmEvents.length > 10 ? (
              <div className="m-t-25">
                <Pagination
                  pages={pagesCount}
                  onChange={(page) => {
                    setCurrentPage(page);
                  }}
                />
              </div>
            ) : null}
          </div>
        </OrizontalScroll.Scrollable>
        <OrizontalScroll.Slider className="flex flex-justify-content-center m-b-20" />
      </OrizontalScroll>
    </div>
  );
};

export default Activity;
