import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import {getInstancesByNetworkRequest, getNetworksRequest} from '../../../Dashboard/actions';
import CreateModal from './CreateNetwork/CreateModal';
import Loader from '../../../../pages/Volumes/components/Loader/Loader';
import { useLoopFetching } from '../../../../components/hooks/useLoopFetching';
import checkJobStatus from '../../../../helpers/checkJobStatus';
import NetworksWithLocation from './NetworkWithLocation';
import NetworkDetails from './NetworkDetails';
import NetworkEmpty from './NetworkEmpty';
import { getClientDomainsRequest } from '../../../Profile/actions';
import { getLocationsRequest } from '../../../Create/actions';

const NetworksList = ({ accountid, id, t }) => {
  const dispatch = useDispatch();
  const { networks, offerings, networksLoading, aclList } = useSelector(
    ({ instances }) => instances
  );
  const { locations } = useSelector(({ products }) => products);

  const filtredLocations = useMemo(() => {
    if (networks && networks.length) {
      const zoneIds = networks.map(({ zoneid }) => zoneid);

      return locations.filter(location => zoneIds.includes(location.acs_id));
    }
  }, [networks]);

  const [visibleModal, setVisibleModal] = useState(null);
  const [detailsNetwork, setDetailsNetwork] = useState(null);
  const [instancesbyNetwork, setInstancesbyNetwork] = useState({});
  const { addToast } = useToasts();

  useEffect(() => {
    if (id && networks?.length) {
      const networkById = networks.find(network => network.name === id);
      getInstancesByNetworkRequest(networkById.accountId, networkById.id, setInstancesbyNetwork)(dispatch);
      setDetailsNetwork(networkById);
    }
  }, [id, networks])

  useEffect(() => {
    if (accountid) {
      getNetworksRequest({ accountid })(dispatch);
    } else {
      getNetworksRequest()(dispatch);
      getClientDomainsRequest()(dispatch);
    }
    getLocationsRequest()(dispatch);
  }, [dispatch, accountid]);

  const request = async (data, cb, accId) => {
    try {
      const result = await checkJobStatus(accountid ? accountid : accId, data);
      if (result.status === 'success') {
        cancelFetching();
        cb();
      }
      if (result.status === 'error') {
        cancelFetching();
        addToast(t(result.error_message.errortext), {
          appearance: 'error',
          autoDismiss: true
        });
        setVisibleModal(null);
        // setVisibleKeyPop(null);
      }
    } catch (error) {
      // CATCH FOR CHECK JOB STATUS
      // eslint-disable-next-line no-undef
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      });
      cancelFetching();
      setVisibleModal(null);
    }
  };

  const { startFetching, cancelFetching } = useLoopFetching(request);

  const returnPageContent = () => {
    if (networksLoading) {
      return <div className='text-align-center p-t-30 p-b-30'><Loader /></div>;
    }

    if ((networks.length > 0 && filtredLocations && filtredLocations.length)) {
      return (
        <div>
          <div>
            <div className="flex flex-justify-content-space-between flex-align-items-center m-b-50 create-button">
              <div className="title_container">
                <div className="font-size-27 text-grey-3">{t('networks.title')}</div>
                <div className="font-size-16 text-grey-1">{t('networks.description')}</div>
              </div>
              <button
                className="button button--blue button--md flex create_button"
                onClick={() => setVisibleModal('create')}>
                {t('networks.create')}
              </button>
            </div>
          </div>
          <div className="NetworksWithLocation_container">
            {filtredLocations.map((loc) => (
              <NetworksWithLocation
                setInstancesbyNetwork={setInstancesbyNetwork}
                instancesbyNetwork={instancesbyNetwork}
                setDetailsNetwork={setDetailsNetwork}
                networks={networks}
                startFetching={startFetching}
                location={loc}
                key={loc.id}
                t={t}
              />
            ))}
          </div>
        </div>
      )
    } else {
      return <NetworkEmpty setVisibleModal={setVisibleModal} t={t} />
    }
  }

  return (
    <div className="Networks">
      {!detailsNetwork && returnPageContent()}
      {visibleModal === 'create' && (
        <CreateModal
          onHide={() => {
            setVisibleModal(null);
          }}
          show={visibleModal === 'create'}
          setVisibleModal={setVisibleModal}
          t={t}
          accountid={accountid}
          offerings={offerings}
          aclList={aclList}
          startFetching={startFetching}
          locations={locations}
        />
      )}
      {detailsNetwork &&
        <NetworkDetails
          network={detailsNetwork}
          setDetailsNetwork={setDetailsNetwork}
          t={t}
          instances={instancesbyNetwork[detailsNetwork.id]?.virtualMachines}
          startFetching={startFetching}
        />}
    </div>
  );
};

export default NetworksList;
