import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ConfirmEmail from "./pages/ConfirmEmail";
import ResendConfirmationEmail from "./pages/ResendConfirmationEmail";
import Profile from "./pages/Profile";
import Sidebar from "./components/Layout/Sidebar";
import AuthHeader from "./components/Layout/AuthHeader";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import CreateInstance from "./pages/Create/CreateInstance";
import CreateOrEditLoadBalancer from "./pages/Create/CreateOrEditLoadBalancer";
import CreateVpcNetwork from "./pages/Create/CreateVpcNetwork";
import Billing from "./pages/Billing";
import Network from "./pages/Network";
import Tickets from "./pages/Tickets";
import NewTicket from "./pages/Tickets/NewTicket";
import Dashboard from "./pages/Dashboard";
import Volumes from "./pages/Volumes";
import LoadBalancers from "./pages/LoadBalancers";

const layoutComponents = {
  Sidebar,
  Header,
  Footer,
};

const authLayoutComponents = {
  Header: AuthHeader,
  Footer,
};

const routes = [
  {
    route: "/login",
    name: "Login",
    Component: Login,
    isPrivate: false,
    ...authLayoutComponents,
  },
  {
    route: "/register",
    name: "Register",
    Component: Register,
    isPrivate: false,
    ...authLayoutComponents,
  },
  {
    route: "/forgot-password",
    name: "ForgotPassword",
    Component: ForgotPassword,
    isPrivate: false,
    ...authLayoutComponents,
  },
  {
    route: "/password-reset/",
    name: "ResetPassword",
    Component: ResetPassword,
    isPrivate: false,
    ...authLayoutComponents,
  },
  {
    route: "/confirm-email/:id",
    name: "ResetPassword ConfirmEmail",
    Component: ConfirmEmail,
    isPrivate: false,
    ...authLayoutComponents,
  },
  {
    route: "/resend-confirmation-email/:email",
    name: "ResetPassword ResendConfirmationEmail",
    Component: ResendConfirmationEmail,
    isPrivate: false,
    ...authLayoutComponents,
  },
  {
    route: "/profile/:tab?",
    name: "Profile",
    Component: Profile,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/create-instance/",
    name: "CreateInstance",
    Component: CreateInstance,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/create-load-balancer/",
    name: "CreateLoadBalancer",
    Component: CreateOrEditLoadBalancer,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/load-balancer/:groupid?/edit",
    name: "EditLoadBalancer",
    Component: CreateOrEditLoadBalancer,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/create-vpc-network",
    name: "CreateVpcNetwork",
    Component: CreateVpcNetwork,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/billing/:tab?",
    name: "Billing",
    Component: Billing,
    isPrivate: true,
    ...layoutComponents,
  },
  {
      route: "/vpc-network/:tab?/:id?",
    name: "Network",
    Component: Network,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/volumes",
    name: "Volumes",
    Component: Volumes,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/load-balancers",
    name: "LoadBalancers",
    Component: LoadBalancers,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/tickets/new",
    name: "Tickets",
    Component: NewTicket,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/tickets",
    name: "Tickets",
    Component: Tickets,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/instance/:id/:tab/:subTab?",
    name: "instance",
    Component: Dashboard,
    isPrivate: true,
    ...layoutComponents,
  },
  {
    route: "/",
    name: "dashboard",
    Component: Dashboard,
    isPrivate: true,
    ...layoutComponents,
  },
];

export default routes;
