import React, { useState } from 'react';
import { editFirewallGroupRequest, getFirewallsRequest } from '../../../Dashboard/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import CustomField from '../../../../components/CustomField';
import DeleteModal from './DeleteModal';
import Loader from '../../../../components/Loader/Loader';

const FirewallSettings = ({ t, firewallName, setFirewallName, selectedFirewall }) => {
    const [isModalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const navigate = useHistory();

    const handleGroupEdit = async () => {
        setLoading(true);
        try {
            await editFirewallGroupRequest({ groupId: selectedFirewall.id, name: firewallName })(dispatch);
            addToast(t("firewallSettings.editSuccess"), {
                appearance: "success",
                autoDismiss: true
            });
            setLoading(false);
            await getFirewallsRequest()(dispatch);
            navigate.push('/vpc-network/firewalls');
        } catch (error) {
            setLoading(false);
            return addToast(error.message, {
                appearance: "error",
                autoDismiss: true
            });
        }
    }

    return (
        <div>
            <div className='Name_input_container'>
                <div className='font-size-16 text-grey-1'>{t('firewallSettings.description')}</div>
                <CustomField>
                    <input
                        placeholder={t("firewallSettings.placeholder")}
                        value={firewallName}
                        onChange={(e) => { setFirewallName(e.target.value) }}
                        type="text"
                    />
                </CustomField>
            </div>
            <button
                className='button button--blue full_button'
                onClick={async () => { await handleGroupEdit() }}
            >
                {loading ? <div className='flex flex-justify-content-center'><Loader /></div> : t("firewallSettings.save")}
            </button>
            <div className='Name_input_container m-t-40'>
                <div className='font-size-27 text-grey-3'>{t('firewallSettings.destroy')}</div>
                <div className='font-size-16 text-grey-1'>{t('firewallSettings.destroyDescription')}</div>
            </div>
            <button className='button button--full-red full_button' onClick={() => setModalVisible(true)}>{t("firewallSettings.destroy")}</button>
            {isModalVisible &&
                <DeleteModal
                    onHide={() => setModalVisible(false)}
                    firewall={selectedFirewall}
                    show={isModalVisible}
                    deleteItem='group'
                    isNavigete
                    t={t}
                />}
        </div>
    );
}

export default FirewallSettings;
