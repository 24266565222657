import React from "react";
import Graphs from "./Graphs";
import Destroy from "./Destroy";
import Access from "./Access";
import Backups from "./Backups";
import Snapshot from "./Snapshot";
import Power from "./Power";
import Rebuild from "./Rebuild";
import Recovery from "./Recovery";
import Events from "./Events";
// import Tags from "./Tags";
import Kernel from "./Kernel";
import Volumes from "../../../../Volumes";
import Network from "./Networking";
import Resize from "./Resize";

export default {
  graphs: Graphs,
  access: Access,
  rebuild: Rebuild,
  destroy: Destroy,
  snapshots: Snapshot,
  kernel: Kernel,
  activity: Events,
  power: Power,
  resize: Resize,
  backups: Backups,
  recovery: Recovery,
  // tags: Tags,
  volumes: (props) => <Volumes hideBlockStorage isDashboard {...props} />,
  networking: Network,
};
