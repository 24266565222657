import React, { useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import pretty from 'prettysize';
import { useSelector } from 'react-redux';

import Switch from '../../../../components/Switch';
import minimazeIco from '../../../../assets/images/minimaze-ico.svg';
import instancesIco from '../../../../assets/images/create/instances2.svg';
import settingsIco from '../../../../assets/images/settings.svg';
import loadingIco from '../../../../assets/images/loading.gif';
import Tabs from './Tabs';
import Loader from '../../../../components/Loader/Loader'; //eslint-disable-line

import graphsIco from '../../../../assets/images/dashboard/menu/graphs_icon.svg';
import accessIco from '../../../../assets/images/dashboard/menu/access_icon.svg';
import powerIco from '../../../../assets/images/dashboard/menu/power_icon.svg';
import volumesIco from '../../../../assets/images/dashboard/menu/volumes_icon.svg';
import resizeIco from '../../../../assets/images/dashboard/menu/resize_icon.svg';
import networkingIco from '../../../../assets/images/dashboard/menu/networking_icon.svg';
import backupsIco from '../../../../assets/images/dashboard/menu/backups_icon.svg';
import snapshotsIco from '../../../../assets/images/dashboard/menu/snapshots_icon.svg';
import activityIco from '../../../../assets/images/dashboard/menu/activity_icon.svg';
// import tagsIco from '../../../../assets/images/dashboard/menu/tags_icon.svg';
import destroyIco from '../../../../assets/images/dashboard/menu/destroy_icon.svg';

import { updateInstanceStatus, getIpAddressesRequest } from '../../actions';

import InfoBox from '../../../../components/InfoBox';
import { get } from '../../../../components/http';

const menuItems = [
  { name: 'graphs', icon: graphsIco },
  { name: 'rebuild', icon: backupsIco },
  { name: 'access', icon: accessIco },
  { name: 'power', icon: powerIco },
  { name: 'volumes', icon: volumesIco },
  { name: 'resize', icon: resizeIco },
  { name: 'networking', icon: networkingIco },
  { name: 'backups', icon: backupsIco },
  { name: 'snapshots', icon: snapshotsIco },
  { name: 'activity', icon: activityIco },
  // { name: 'tags', icon: tagsIco },
  { name: 'destroy', icon: destroyIco },
];

const updateStatus = async ({ dispatch, accountid, id: vmId, start }) => {
  try {
    await updateInstanceStatus({ accountid, vmId, start })(dispatch);
    await getIpAddressesRequest()(dispatch);
  } catch (error) {
    throw new Error(error);
  }
};

const Instance = (props) => {
  const {
    memory,
    id,
    accountid,
    t,
    tab,
    instanceId,
    nic,
    dispatch,
    loading,
    state,
    tags,
    domainid,
    cpunumber,
    zonename,
    zoneid,
    templatename,
    displayname,
    iscompatible,
    setInstancesPassword
  } = props;

  const { floatingIpList } = useSelector(({ instances }) => instances);

  useEffect(() => {
    getIpAddressesRequest()(dispatch);
  }, []);

  const extraIp = useMemo(() => {
    const extraIP = floatingIpList?.find(item => item?.virtualmachineid === instanceId)
    return extraIP?.ipaddress;
  }, [floatingIpList, instanceId]);

  const Tab = Tabs[tab] || (() => 'Tab is not developed yet');
  const getConsoleUrl = async () => {
    const {
      data: {
        data: { console_url }
      }
    } = await get(`/accounts/${accountid}/vm/${instanceId}/console`);
    // eslint-disable-next-line no-undef
    window.open(console_url, '_blank', 'noopener,noreferrer, width=300,resizable=yes');
  };

  const ips = useMemo(() => {
    if (props.nic) {
      const { nic } = props;
      const publicNet = nic.find((network) => network.type === "Shared");
      const privateNet = nic.find((network) => network.type === "Isolated");

      const publicIp = publicNet?.ipaddress;
      const privateIp = privateNet?.ipaddress;

      return { publicIp, privateIp };
    }
  }, [props]);

  if (!displayname) {
    return (<div className='flex flex-justify-content-space-around'>
      <Loader />
    </div>)
  }

  return (
    <div key={id} className="Instance">
      <div className="flex flex-justify-content-space-between flex-align-items-center">
        <div className="flex flex-align-items-center">
          <div className="position-relative m-r-15">
            <img src={instancesIco} className="" alt="" width={40} height={32} />
            <span
              className={`status Instance__${state === 'Running' ? 'active' : (state === 'Starting' || state === 'Stopping' ? 'pending' : 'inactive')
                }`}
            />
          </div>
          <div>
            <h3 className="font-size-16 text-grey-3 font-weight-semibold m-b-5 m-t-0">{displayname}</h3>
            <p className="text-grey-8 font-size-12 font-weight-semibold m-t-0 m-b-0">
              {cpunumber} {cpunumber === 1 ? "vCPU" : "vCPUs"} &nbsp; {pretty(memory * 1024 * 1024)} RAM &nbsp; {zonename} &nbsp; {templatename}
            </p>
          </div>
        </div>
        <div className="flex flex-align-items-center no-touch-background">
          {(loading || ['Starting', 'Stopping'].includes(state)) && (
            <div className="loading">
              <img height="70" src={loadingIco} alt="" />
            </div>
          )}
          <InfoBox
            className="InfoBox__size--small text-align-center"
            text={state === 'Running' ? t('instanceDetails.turnOff') : t('instanceDetails.turnOn')}>
            <Switch
              key={state}
              requestOnly
              onChange={(start) => updateStatus({ start, dispatch, accountid, id })}
              checked={state === 'Running'}
              className="Switch--square"
            />
          </InfoBox>
          <Link className="m-l-50" to="/">
            <img src={minimazeIco} alt="" />
          </Link>
        </div>
      </div>
      <div className="line_cuted m-t-25 m-b-0"></div>
      <div className="Instance__details flex flex-justify-content-space-between flex-align-items-center text-grey-1 font-size-16">
        <div>
          <span className="font-weight-semibold">
            IPv4: <span className="font-weight-regular">{ips?.publicIp}</span>
          </span>
        </div>
        <div className="flex flex-align-items-center">
          <span className="font-weight-semibold">IPv6: </span>
          <span className="badge">{t('instanceDetails.soon')}</span>
        </div>
        {ips?.privateIp && (
          <div>
            <span className="font-weight-semibold">
              {t('instanceDetails.privateIp')}: <span className="font-weight-regular">{ips?.privateIp}</span>
            </span>
          </div>
        )}
        <div className="flex flex-align-items-center">
          <span className="font-weight-semibold">{t('instanceDetails.extraIP')}: </span>
          {extraIp ? <a href="javascript:void(0)" className="font-weight-semibold">{extraIp}</a> :
            <Link to="/vpc-network/extra-ips"><a href="javascript:void(0)" className="font-weight-semibold">{t('instanceDetails.extraIPEnable')}</a></Link>}
        </div>
        <div className="flex flex-align-items-center" onClick={getConsoleUrl}>
          <a href="javascript:;">{t('instanceDetails.console')}</a>
          <img className="settings-ico m-l-5" src={settingsIco} alt="" />
        </div>
      </div>
      <div className="line_cuted m-t-0 m-b-25"></div>
      <div className="flex menu_tab_container">
        <div className="menu">
          <ul>
            {menuItems.map(({ name, icon }) => (
              <li key={name} className={`${name === "resize" && iscompatible === 0 && "grey-out"} ${name == tab && 'active'}`}>
                <Link
                  to={`/instance/${instanceId}/${name}`}
                  className={`${name !== tab && 'text-grey-1'} font-size-16`}>
                  <img src={icon} className="m-r-10" style={{ '-webkit-transform': 'translate3d(0,0,0)' }} />
                  {t(`instanceDetails.menu.${name}`)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <Tab
          instantState={state}
          instanceId={instanceId}
          zoneid={zoneid}
          accountid={accountid}
          dispatch={dispatch}
          domainid={domainid}
          extraIp={extraIp}
          tags={tags}
          type={nic && nic[0]?.type}
          nic={nic}
          setInstancesPassword={setInstancesPassword}
          t={t}
        />
      </div>
    </div>
  );
};

export default Instance;
