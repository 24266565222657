import React from "react";
import CustomField from "../../../../../../components/CustomField";
import Modal from "../../../../../../components/Modal";
import { useFormik } from "formik";
import CustomSelect from "../../../../../../components/CustomSelect";
import { useToasts } from "react-toast-notifications";
import { editAclRuleRequest, getAclRulesListRequest } from "../../.././../../Dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../Volumes/components/Loader/Loader";

const availableAction = ["Allow", "Deny"].map((action) => ({
  value: action,
  text: action
}));
const availableTraffic = ["Ingress", "Egress"].map((type) => ({
  value: type,
  text: type
}));
const availableProtocols = ["TCP", "UDP", "ICMP", "ALL"].map((protocol) => ({
  value: protocol,
  text: protocol
}));

const EditAclRule = ({ show, onHide, t, aclid, ruleid, accountid, aclRulesList, startFetching }) => {
  const [editedRule] = aclRulesList.filter((rule) => rule.id === ruleid);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { aclRulesListLoading } = useSelector(({ instances }) => instances);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cidr: editedRule?.cird,
      action: editedRule?.action,
      protocol: editedRule?.protocol,
      startport: editedRule?.startport,
      endport: editedRule?.endport,
      icmptype: editedRule?.icmptype,
      icmpcode: editedRule?.icmpcode,
      traffictype: editedRule?.traffictype,
      reason: editedRule?.reason
    },
    onSubmit: async (values) => {
      if (validateValue().error) {
        return addToast(validateValue().error, {
          appearance: "error",
          autoDismiss: true
        });
      }
      try {
        const sendValues =
          values.protocol === "ALL"
            ? { ...values, startport: null, endport: null, icmpcode: null, icmptype: null }
            : values;
        const {
          data: {
            data: { jobid }
          }
        } = await editAclRuleRequest({ aclid, accountid, values: sendValues, ruleid })(dispatch);
        startFetching(jobid, () => {
          onHide();
          formik.handleReset();
          getAclRulesListRequest({ aclid, accountid })(dispatch);

          addToast(t("editAclRule.success"), {
            appearance: "success",
            autoDismiss: true
          });
        });
      } catch (error) {
        return addToast(error.message, {
          appearance: "error",
          autoDismiss: true
        });
      }
    }
  });

  const validateValue = () => {
    if (
      formik.values.protocol === "ICMP" &&
      (formik.values.icmptype < 1 ||
        formik.values.icmptype > 14 ||
        formik.values.icmpcode > 14 ||
        formik.values.icmpcode < 1)
    ) {
      return {
        error: t("createAclRule.icmpError")
      };
    }
    if (
      (formik.values.protocol === "TCP" || formik.values.protocol === "UDP") &&
      (formik.values.startport < 1 ||
        formik.values.startport > 65535 ||
        formik.values.endport < 1 ||
        formik.values.endport > 65535)
    ) {
      return {
        error: t("createAclRule.portError")
      };
    }
    return { success: "Success" };
  };

  return (
    <div className="create-rule">
      <Modal
        Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("editAclRule.title")}</h2>}
        onHide={() => onHide()}
        show={show}
        size="large"
      >
        <form onSubmit={formik.handleSubmit}>
          <CustomField className="Field--sm m-b-10">
            <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
              {t("createAclRule.cidr")}
            </label>
            <input
              name="cidr"
              type="text"
              onKeyPress={(event) => {
                if (!/^[\d./:/]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={formik.handleChange}
              value={formik.values.cidr}
              defaultValue={editedRule?.cidrlist}
            />
          </CustomField>
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createAclRule.action")}
          </label>
          <CustomSelect
            className="Field--sm m-b-10"
            options={[...availableAction]}
            onChange={(value) => {
              formik.setFieldValue("action", value);
            }}
            defaultValue={editedRule?.action}
          />
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createAclRule.protocol")}
          </label>
          <CustomSelect
            className="Field--sm m-b-10"
            options={[...availableProtocols]}
            onChange={(value) => {
              if (value === "ICMP") {
                formik.setFieldValue("endport", "");
                formik.setFieldValue("startport", "");
              }
              if (value === "ALL") {
                formik.setFieldValue("endport", "");
                formik.setFieldValue("startport", "");
                formik.setFieldValue("icmptype", "");
                formik.setFieldValue("icmpcode", "");
              } else {
                formik.setFieldValue("icmptype", "");
                formik.setFieldValue("icmpcode", "");
              }
              formik.setFieldValue("protocol", value);
            }}
            defaultValue={editedRule?.protocol}
            disabled={editedRule?.protocol === "ALL" || editedRule?.protocol === "ICMP"}
          />
          <CustomField className="Field--sm m-b-10">
            <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
              {t(formik.values.protocol === "ICMP" ? "createAclRule.icmpType" : "createAclRule.startPort")}
            </label>
            <input
              disabled={formik.values.protocol === "ALL"}
              name={formik.values.protocol === "ICMP" ? "icmptype" : "startport"}
              type="text"
              onKeyPress={(event) => {
                if (!/^[\d./]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={formik.handleChange}
              value={formik.values.protocol === "ICMP" ? formik.values.icmptype : formik.values.startport}
              defaultValue={editedRule?.protocol === "ICMP" ? editedRule?.icmptype : editedRule?.startport}
            />
          </CustomField>
          <CustomField className="Field--sm m-b-10">
            <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
              {formik.values.protocol === "ICMP" ? t("createAclRule.icmpCode") : t("createAclRule.endPort")}
            </label>
            <input
              disabled={formik.values.protocol === "ALL"}
              name={formik.values.protocol === "ICMP" ? "icmpcode" : "endport"}
              type="text"
              onKeyPress={(event) => {
                if (!/^[\d./]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={formik.handleChange}
              value={formik.values.protocol === "ICMP" ? formik.values.icmpcode : formik.values.endport}
              defaultValue={editedRule?.protocol === "ICMP" ? editedRule?.icmpcode : editedRule?.endport}
            />
          </CustomField>
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createAclRule.trafficType")}
          </label>
          <CustomSelect
            className="Field--sm m-b-10"
            options={[...availableTraffic]}
            onChange={(value) => formik.setFieldValue("traffictype", value)}
            defaultValue={editedRule?.traffictype}
          />
          <CustomField className="Field--sm m-b-10">
            <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
              {t("createAclRule.description")}
            </label>
            <textarea
              value={formik.values.reason}
              onChange={formik.handleChange}
              name="reason"
              placeholder={t("createAclRule.reasonPlaceholder")}
              defaultValue={editedRule?.reason}
            />
          </CustomField>
          {aclRulesListLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-justify-content-flex-end">
              <button className="button button--grey button--md m-r-15" onClick={() => onHide()}>
                {t("createAclRule.cancel")}
              </button>
              <button className="button button--md" type="submit">
                {t("createAclRule.ok")}
              </button>
            </div>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default EditAclRule;
