export default {
  title: "Load Balancers",
  description: "Creating Load Balancers will give you permission to distribute traffic between multiple Instances and they are a great way to horizontally scale your app.",
  noLoadBalancers: {
    title: "Load Balancers",
    description:
      "Load Balancers let you distribute traffic between multiple Instances and are a great way to horizontally scale your app.",
    button: "Create Load Balancer",
    buttonLoading: "Loading...",
  },
  more: {
    configure: "Configure",
    delete: "Delete",
  },
  region: "REGION",
  instances: "INSTANCES",
  protocols: "PROTOCOLS",
  algoritms: "ALGORITHMS",
  status: "Status",
  ipAddress: "IP",
  protocol: "Protocol",
  algorithm: "Algorithm",
  privPort: "Private Port",
  pubPort: "Public Port",
  healthy: "Healthy",
  name: "NAME",
  size: "Size",
  created: "Created",
  viewInstances: "View Instances",
  viewGraph: "View Graph",
  editSettings: "Edit settings",
  destroy: "Destroy",
  oneInstance: "Instance",
  goBack: " back to load balancers",
  requestsPerSecond: "HTTP Requests per second",
  connections: "Connections",
  responses: "HTTP Responses",
  trafficReceived: "Traffic Received/Sent",
  sessionDuration: "HTTP Total Session Duration",
  averageResponse: "HTTP Average Response Time",
  learn: {
    title: "Basics for Load balancing",
    overview: {
      link: "Load Balancer overview",
      description: "Learn about ShapeHost Load Balancers, or follow ",
      descriptionLink: "our step-by-step guide to creating one.",
    },
    api: {
      link: "API Docs",
      description:
        "Use the ShapeHost API to create and manage Load Balancers programmatically.",
    },
    feedback: {
      link: "Tell us what you think",
      description: "Submit your feedback on Load Balancers.",
    },
  },
  instancesTab: {
    ipAddresses: "IP Addresses",
    downtime: "Downtime",
    queue: "Queue",
    healthCheck: "Health Checks",
    button: "Add Instances",
    healthyIntances: " Healthy Instances",
    viewInstance: "View instance",
    remove: "Remove",
  },
  settings: {
    size: "Size",
    forwardRules: "Forwarding rules",
    algorithm: "Algorithm",
    healthChecks: "Health checks",
    stickySession: "Sticky sessions",
    ssl: "SSL",
    proxyProtocol: "Proxy Protocol",
    backendKeepalive: "Backend Keepalive",
    destroy: "Destroy",
    destroyDescription:
      "Your Load Balancer will be permanently destroyed. Any associated Instances will be disconnected and will stop receiving distributed traffic. Instances will not be destroyed.",
    edit: "Edit",
    resize: "Resize",
    save: "Save",
    cancel: "Cancel",
    onPort: "on port",
    sizeDetails: {
      resizeText:
        "Resizing your Load Balancer will maintain the IP and current configuration, and you will be billed according to the new price-per-hour. More details can be found in the product docs.",
      productDocs: "product docs",
      currentSize: "Current size",
      month: "Month",
      small: "Small",
      medium: "Medium",
      large: "Large",
      smallDescription:
        "best for: Static websites and blogs with low to moderate traffic.",
      mediumDescription:
        "best for: Transaction-driven sites or services with moderate traffic.",
      largeDescription:
        "best for: Busy sites, endpoints where high performance is a must.",
    },
    destroyModal: {
      title: "Destroy load balancer",
      description:
        "Your Balancer will be permanently destroyed. Any associated Instances will be disconnected and will stop receiving distributed traffic. Instances will not be destroyed. You will lose the provisioned IP address, which might impact any DNS records pointing to it. This will not affect any associated Instances.",
      confirmDescription:
        "Confirm you want to permanently destroy this load balancer by entering its name below.",
      confirm: "Confirm",
      cancel: "Cancel",
      placeholder: "Enter the name of this Load Balancer to delete it",
    },
  },
  tabs: {
    instances: "Instances",
    graphs: "Graphs",
    settings: "Settings",
  },
  assignModal: {
    title: "Assing VM to Load balancer rule",
    update: "Update",
    cancel: "Cancel",
    list: {
      name: "Name",
      state: "State",
      displayName: "Display Name",
      account: "Account",
      zone: "Zone",
      select: "Select",
    },
  },
  removeModal: {
    title: "Remove VM from Load Balancer rule",
    list: {
      name: "Name",
      ipAddress: "IP Address",
      remove: "Remove",
    },
  },
  editModal: {
    name: "Name",
    namePlaceholder: "Enter new name for rule",
    protocol: "Select Protocol",
    algorithm: "Select algorithm",
    title: "Edit Load Balancer rule",
    update: "Update",
    cancel: "Cancel",
  },
  destroyModal: {
    title: "Destroy load balancer",
    removeWarning1: "Your Load Balancer will be",
    removeWarningRed: "permanently destroyed.",
    removeWarning2: "Any associated Instances will be disconnected and will stop receiving distributed traffic while the Instances will not be destroyed.",
    removeWarning3: "You will lose the provisioned IP address, which might impact any DNS records pointing to it. This will not affect any associated Instances.",
    destroy: "Destroy",
    deleteSuccess: "Successfully Deleted"
  },
}
