import React from "react";
import CustomField from "../../../../components/CustomField";

const ConcludeAndCreate = ({ t, setNameLb, nameLb, isCreate }) => {
  return (
    <div className="ConcludeAndCreate m-b-30">
      <h3 className="font-size-27 text-grey-2 font-weight-regular m-b-20 ">
        {t("title")}
      </h3>
      <p className="text-grey-1 font-size-16 hidden-xs m-t-25 m-b-25">
        {isCreate ? t("descriptionCreate") : t("descriptionConfig")}
      </p>
      <CustomField>
        <input
          placeholder={t("placeholder")}
          value={nameLb}
          type="text"
          onChange={(e) => setNameLb(e.target.value)}
        />
      </CustomField>
    </div>
  );
};

export default ConcludeAndCreate;
