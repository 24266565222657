import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../../../components/Card";
import DestroyModal from "./DestroyModal";

const Destroy = ({ accountid, instanceId, dispatch }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation("dashboard");
  return (
    <div className="flex-grow-1">
      <Card className="m-b-40">
        <Card.Body className="width-full-width flex-direction-column p-l-40 p-r-40">
          <h3 className="font-size-27 text-grey-3 font-weight-regular m-b-10">
            {t("instance.destroy.title")}
          </h3>
          <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-25">
            <span className="font-weight-semibold text-grey-3">{t("instance.destroy.descriptionBold")}</span> {t("instance.destroy.description")}
          </p>
          <button
            onClick={() => setShow(true)}
            className="button button--md button--full-red max-width-250 m-b-20"
          >
            {t("instance.destroy.button")}
          </button>
        </Card.Body>
      </Card>
      <DestroyModal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        dispatch={dispatch}
        accountid={accountid}
        instanceId={instanceId}
      />
    </div>
  );
};

export default Destroy;
