import React, { useEffect } from "react";
import { getLBGroupsRequest } from "../Dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NoLoadBalancersImg from "../../assets/images/loadBalancers/empty-lb-icon.svg";
import LoadBalancersList from "../Network/components/LoadBalancers/components/LoadBalancersList";
import Loader from "../Volumes/components/Loader/Loader";

const NoLoadBalancers = ({ t }) => (
  <div className="Volumes flex flex-column flex-align-items-center max-width-full-width m-b-30 content-center">
    <img src={NoLoadBalancersImg} width="190" height="160" alt="" />
    <div>
      <p className="text-grey-1 font-size-15 m-t-50 m-b-30 max-width-450 text-align-center">{t("noLoadBalancers.description")} </p>
      <div className="flex flex-justify-content-space-around">
        <Link to="/create-load-balancer">
          <button
            className="button button--blue button--lg flex font-weight-semibold font-size-16"
          >
            {`+ ${t("noLoadBalancers.button")}`}
          </button>
        </Link>
      </div>
    </div>
  </div>
);

const LoadBalancers = () => {
  const { t } = useTranslation("loadBalancers");
  const dispatch = useDispatch();
  const { lbGroups, lbGroupsLoading } = useSelector(({ instances }) => instances);

  useEffect(() => {
    if (!lbGroups.length) {
      getLBGroupsRequest()(dispatch);
    }
  }, [dispatch]);

  return (
    <div className="LoadBalancers">
      {lbGroupsLoading ? (
        <div className="width-full-width flex flex-justify-content-space-around m-t-180">
          <Loader />
        </div>
      ) : lbGroups.length ? (
        <LoadBalancersList
          loadBalancers={lbGroups}
          t={t}
        />
      ) : (
        <NoLoadBalancers t={t} />
      )}
    </div>
  );
}

export default LoadBalancers;
