import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import instancesIco from "../../../../assets/images/create/instances.svg";
import volumesIco from "../../../../assets/images/create/hard-disk.svg";
import firewallsIco from "../../../../assets/images/create/cloudFirewalls.svg";
import floatingIpsIco from "../../../../assets/images/create/ip.svg";
import loadBalancersIco from "../../../../assets/images/create/database.svg";
import line from "../../../../assets/images/small-line.svg";
import {store} from "../../../../store";

const items = firstname => [
  { text: "instances", link: firstname ? "/create-instance" : "/profile", image: instancesIco },
  {
    text: "volumes",
    link: "/volumes",
    image: volumesIco,
    className: "m-b-20",
  },
  {
    text: "firewalls",
    image: firewallsIco,
    link: "/vpc-network/firewalls",
  },
  { text: "floatingIps", link: "/vpc-network/extra-ips", image: floatingIpsIco },
  {
    text: "loadBalancers",
    link: "/create-load-balancer",
    image: loadBalancersIco,
  },
];

const Create = () => {
  const { t } = useTranslation("header");
  const globalState = useContext(store);
  const { state: { clientDetails } = {} } = globalState;
  return (
    <div className="CreateDropdown width-full-width">
      <ul>
        {items(clientDetails?.firstname).map(({ text, link, image, className }) => (
          <li key={text} className={className}>
            <Link to={link} className="m-r-10">
              <img src={image} width="30" alt="" />
              <div>
                <span>{t(`createNav.${text}`)}</span>
                <span className="description">
                  {t(`createNav.${text}Description`)}
                </span>
              </div>
            </Link>
            {text === "volumes" ? <img className="line" src={line}/> : ''}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Create;
