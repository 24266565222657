import React, {useEffect, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import InfoBox from '../../../../components/InfoBox';

import instancesIco from '../../../../assets/images/create/instances2.svg';
import instancesIcoInactive from '../../../../assets/images/create/instances2-inactive.svg';
import spanshotInactiveIco from '../../../../assets/images/chooseImage/snapshot-inactive-icon.svg';
import spanshotIco from '../../../../assets/images/chooseImage/snapshot-icon.svg';
import showMoreIco from '../../../../assets/images/show-more-ico.svg';
import ipLocation from '../../../../assets/images/ip-location.svg';

import InstanceMenu from './components/InstanceMenu';
import InstanceDetails from './components/InstanceDetails';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {icons} from "../../../Create/CreateInstance/components/ChooseLocations";
import {osicons} from "../../../Create/CreateInstance/components/ChooseImage/Tabs/Distributions";
import pretty from "prettysize";
import Loader from "../../../../components/Loader/Loader";

const tableHead = ["IP", "OS", "DATACENTER", "OPTIONS"]

export const locationsNaming = {
  "EMEA-DE-01":"FRA1",
  "EMEA-UK-01":"LON1",
  "EMEA-FIN-01":"HEL1",
  "EMEA-NL-01":"AMS1",
  "APAC-IN-01":"MUB1",
  "APAC-HK-01":"HKG1",
  "APAC-JP-01":"TOK1",
  "APAC-SG-01":"SGP1",
  "AMS-NYC-01":"NYC1",
  "AMER-BR-01":"SAO1",
  "AMS-FLO-01":"FLO1",
  "AMS-CA-01":"TOR1",
}


const Resources = ({instances = [], t}) => {
  const [visibleMenu, setVisibleMenu] = useState(null);
  const [showDetails, setShowDetails] = useState(null);
  const [isCopied, setIsCopied] = useState(null);
  const {t: commonT} = useTranslation('common');

  const onCopy = (ip) => {
    setIsCopied(ip)
    setTimeout(() => setIsCopied(null), 2000) // eslint-disable-line
  }

  useEffect(() => {
    const hideInstancesMenu = (e) => {
      if (
        e.target.className &&
        e.target.className.includes &&
        !e.target.className.includes('Card__body') &&
        !e.target.className.includes('menu-item') &&
        !e.target.className.includes('openMenu')
      ) {
        setVisibleMenu(null);
      }
    };
    window.addEventListener('click', hideInstancesMenu); // eslint-disable-line
    return () => {
      window.removeEventListener('click', hideInstancesMenu); // eslint-disable-line
    };
  }, []);
  return (
    <div className='ResourcesTab'>
      <div className="flex flex-justify-content-space-between heading">
      <span className='text-grey-3 font-weight-semibold font-size-12 display-inline-block instance'>
        <span>{t('resources.instances')} ({instances && instances.length})</span>
      </span>
      {tableHead.map(value =>
        <span className={value.toLowerCase()} key={value}>
          <span>{value}</span>
        </span>
      )}
      </div>
      <ul>
        {instances.map(
          (
            {
              displayname,
              templatedisplaytext: server,
              state,
              nic,
              tags,
              backups,
              cpunumber,
              ipv4,
              ipv6,
              vpc,
              zonename: location,
              zoneid,
              memory,
              price,
              storage,
              id,
              iscompatible,
              ipGeolocation,
            },
            index
          ) => {
            // const [firstTag, ...otherTags] = tags.map(({value}) => value);
            const {ipaddress: ip, publicip} = nic[0];
            const osName =
              server.split('-')[0].split(' ').length > 1 ? server.split(' ')[0] : server.split('-')[0];
            const osIco = osicons[osName]
            const [serviceId] = tags?.filter(({key}) => key === "service_id")
            const {value: accountid} = serviceId || {}

            return (
              <>
                <li
                  key={id}
                  className={`flex flex-justify-content-space-between flex-align-items-center
                  ${(state === 'Running' && 'Instance-el__active') ||
                  (['Starting', 'Stopping'].includes(state)
                    ? 'Instance-el__pending'
                    : 'Instance-el__inactive'
                  )} 
                  ${showDetails === index ? 'active' : ''}`}
                  onClick={() => setShowDetails(index === showDetails ? null : index)} //eslint-disable-line
                >
                  <div className='flex flex-align-items-center instance'>
                    <div className="instanceIco__status m-r-10">
                      {['Starting', 'Stopping'].includes(state) ? <Loader /> :
                        <>
                        <InfoBox
                          ico={state === 'Running' ? instancesIco : instancesIcoInactive}
                          text={server}
                        />

                        <span
                          className={
                            `status ${(state === 'Running' && 'Instance__active') ||
                            (['Starting', 'Stopping'].includes(state)
                              ? 'Instance__pending'
                              : 'Instance__inactive')}`
                          }
                        />
                      </>}
                    </div>
                    <div className="name">
                      <a
                        className='font-weight-semibold font-size-16 m-r-15'
                        href='javascript:;'
                      >
                        <Link to={`/instance/${id}/graphs`} className='instanceName'>
                          {displayname}
                        </Link>
                      </a>
                      <div className="font-size-12 text-grey-8">
                        <span className="m-r-10">{cpunumber} vCPUs</span>
                        <span>{pretty(memory * 1024 * 1024)} RAM</span>
                        {storage ? <span>{pretty(storage * 1024 * 1024)} Disk</span> : ''}
                      </div>
                    </div>
                  </div>
                  <div
                    className='ip text-grey-1 flex-grow-1 font-size-16 min-width-100 copy-wrapper position-relative max-width-100'>
                    {ipGeolocation && <img className='ip-location' src={ipLocation} alt=''/>}
                    {vpc ? publicip : ip}
                    <a href='javascript:;' onClick={event => {
                      event.preventDefault();
                      event.stopPropagation()
                    }}>
                      <CopyToClipboard text={ip} onCopy={onCopy}>
                        <span
                          className='copy text-blue-1 m-l-10 position-relative'
                          style={{zIndex: '1', cursor: 'pointer'}}
                        >
                          {isCopied === ip ? commonT('copied') : commonT('copy')}
                        </span>
                      </CopyToClipboard>
                    </a>
                  </div>
                  {location ? <div className="os">
                    <InfoBox
                      ico={osIco}
                      text={server}
                    />
                  </div> : ''}
                  {location ? <div className="datacenter">
                    <img src={icons[location.split('-')[1]]}/>
                    {locationsNaming[location]}
                  </div> : ''}
                  {/*<div className='tags max-width-100 flex-grow-1'>*/}
                  {/*  {firstTag && <span className='first-tag'>{firstTag}</span>}*/}
                  {/*  {otherTags && !!otherTags.length && (*/}
                  {/*    <InfoBox*/}
                  {/*      className='InfoBox__tags InfoBox__color--white'*/}
                  {/*      text={otherTags.map((tag) => (*/}
                  {/*        <span key={tag} className='tag'>*/}
                  {/*                                      {tag}*/}
                  {/*                                  </span>*/}
                  {/*      ))}*/}
                  {/*    >*/}
                  {/*      <span className='text-blue-1'>(+{otherTags.length})</span>*/}
                  {/*    </InfoBox>*/}
                  {/*  )}*/}
                  {/*</div>*/}
                  <div className='flex flex-align-items-center options'>
                    <div className="backups invisible">
                      <InfoBox
                        ico={backups && backups.length ? spanshotIco : spanshotInactiveIco}
                        text={t('resources.nobackup')}
                      />
                    </div>
                    <div className='position-relative m-l-20'>
                      <InstanceMenu
                        visibleMenu={visibleMenu === index}
                        t={t}
                        id={id}
                        displayname={displayname}
                        accountid={accountid}
                      />
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisibleMenu(visibleMenu === index ? null : index);
                        }}
                        src={showMoreIco}
                        className='openMenu cursor-pointer'
                        alt=''
                      />
                    </div>
                  </div>
                </li>
                {showDetails === index && (
                  <InstanceDetails
                    {...{
                      server,
                      state,
                      osIco,
                      ip,
                      cpu: cpunumber,
                      ipv4,
                      accountid,
                      ipv6,
                      vpc,
                      location,
                      zoneid,
                      memory,
                      displayname,
                      price,
                      storage,
                      t,
                      commonT,
                      iscompatible,
                      nic,
                      id
                    }}
                  />
                )}
              </>
            );
          }
        )}
      </ul>
    </div>
  );
};

export default Resources;
