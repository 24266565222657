import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import Card from "../../../../../components/Card";
import Loader from "../../../../../pages/Volumes/components/Loader/Loader";
import alertIco from "../../../../../assets/images/red-alert.svg";
import { updateInstanceStatus } from "../../../actions";
import { useLoopFetching } from "../../../../../components/hooks/useLoopFetching";
import checkJobStatus from "../../../../../helpers/checkJobStatus";

const Power = ({ t: dashboardTrans, dispatch, accountid, instanceId: vmId, instantState }) => {
  const { addToast } = useToasts();
  const t = (path) => dashboardTrans(`instance.power.${path}`);
  const [loading, setLoading] = useState(false);
  const [forcedLoading, setForcedLoading] = useState(false);

  const request = async (data, cb) => {
    try {
      const result = await checkJobStatus(accountid, data);
      if (result.status === "success") {
        cancelFetching();
        cb();
      }
      if (result.status === "error") {
        cancelFetching();
        setLoading(false);
        addToast(t(result.error_message.errortext), {
          appearance: "error",
          autoDismiss: true
        });
      }
    } catch (error) {
      setLoading(false);
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
      cancelFetching();
    }
  };

  const { startFetching, cancelFetching } = useLoopFetching(request);

  const handleStatusChange = async (isForced) => {
    isForced ? setForcedLoading(true) : setLoading(true);
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await updateInstanceStatus({
        accountid,
        vmId,
        start: instantState === "Stopped" ? true : false,
        isForced
      })(dispatch);
      startFetching(jobid, () => {
        isForced? setForcedLoading(false) : setLoading(false);
      });
    } catch (error) {
      isForced ? setForcedLoading(false) : setLoading(false);
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  }

  return (
    <div className="flex-grow-1">
      <Card className="m-b-40">
        <Card.Body className="width-full-width flex-direction-column p-l-40 p-r-40">
          <h3 className="font-size-27 text-grey-3 font-weight-regular m-b-10">{t("title")}</h3>
          <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-25">
            {t("description")}
            <span>{t("powerOff")}</span>
          </p>
          <div>
            <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6">{t("instanceTurnedOff.title")}</p>
            <ul className="p-l-17">
              <li className="text-grey-1 font-size-16 m-t-0 line-height-1-6">{t("instanceTurnedOff.item1")}</li>
              <li className="text-grey-1 font-size-16 m-t-0 line-height-1-6">{t("instanceTurnedOff.item2")}</li>
            </ul>
          </div>
          <div className="flex flex-align-items-center">
            <button
              disabled={loading || forcedLoading}
              onClick={async () => {
                await handleStatusChange()
              }}
              className="button button--md button--blue m-t-20 max-width-250 m-b-20 min-width-250 flex flex-justify-content-space-around font-size-15 font-weight-semibold"
            >
              {t(instantState === "Stopped" || instantState === "Stopping" ? "buttonOff" : "button")}
              {loading && <Loader />}
            </button>
            <p className="flex flex-align-items-center text-grey-1 font-size-14 m-l-20 ">
              <img className="m-r-12" src={alertIco} alt="" />
              {t("detail1")}
              <span className="font-weight-semibold text-grey-3 m-l-5 m-r-5">{t("detail1Bold")}</span>
              {t("detail2")}
              <span className="font-weight-semibold text-grey-3 m-l-5 m-r-5">{t("detail2Bold")}</span>
              {t("detail3")}
            </p>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body className="width-full-width flex-direction-column p-l-40 p-r-40">
          <h3 className="font-size-27 text-grey-3 font-weight-regular m-b-10">
            {t("powerCycle.title")}
          </h3>
          <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-15">
            {t("powerCycle.description")}{" "}
          </p>
          <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-25">
            {t("powerCycle.proceed")}
          </p>
          <button onClick={async () => {
            await handleStatusChange(true)
          }} disabled={instantState !== "Running" || forcedLoading || loading} className="button button--md button--blue max-width-250 m-b-20 font-size-15 font-weight-semibold flex flex-justify-content-space-around">
            {t("powerCycle.button")}
            {forcedLoading && <Loader />}
          </button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Power;
