import { get } from "../../components/http";
import { createSlice } from "@reduxjs/toolkit";

// Slice
const slice = createSlice({
  name: "billing",
  initialState: {
    payModules: [],
    payModulesLoading: false,
    usage: [],
    usageLoading: false,
  },
  reducers: {
    getPayModules: (state, action) => {
      state.payModules = action.payload;
    },
    setPayModulesLoading: (state, action) => {
      state.payModulesLoading = action.payload;
    },
    getUsage: (state, action) => {
      state.usage = action.payload;
    },
    setUsageLoading: (state, action) => {
      state.usageLoading = action.payload;
    },
  },
});

export default slice.reducer;

// Actions
const {
  getPayModules,
  setPayModulesLoading,
  getUsage: getUsageAction,
  setUsageLoading,
} = slice.actions;

//get paymentmodules
export const getPayModulesRequest = () => async (dispatch) => {
  dispatch(setPayModulesLoading(true));
  try {
    const {
      data: { data },
    } = await get(`/client/payment/modules`);
    dispatch(getPayModules(data));
  } catch (error) {
    dispatch(setPayModulesLoading(false));
  }
  dispatch(setPayModulesLoading(false));
};

export const getUsageRequest = () => async (dispatch) => {
  dispatch(setUsageLoading(true));
  const {
    data: {
      data: { accounts },
    },
  } = await get(`/accounts?status=active`);
  const {
    data: {
      data: { usage },
    },
  } = await get(`/client/accounts/${accounts[0].id}/metered-billing/usage`);
  dispatch(getUsageAction(Object.values(usage)));
  dispatch(setUsageLoading(false));
};
