import React, {useState} from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {Link, useHistory, useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import { post } from "../../components/http";

import mailIco from "../../assets/images/mail.svg";
import forgotPasswordIll1 from "../../assets/images/forgotPassword-ill1.svg";
import forgotPasswordIll2 from "../../assets/images/forgotPassword-ill2.svg";
import forgotPasswordIll3 from "../../assets/images/forgotPassword-ill3.svg";

import CustomField from "../../components/CustomField";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";

const SiginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const forgotPassword = async ({ values, addToast, history }) => {
  let forgotResponse;
  try {
    forgotResponse = await post("/password/forgot", values);
  } catch (error) {
    return addToast(error.message, {
      appearance: "error",
      autoDismiss: true,
    });
  }
  const { data: { error } = {} } = forgotResponse;
  if (error) {
    return addToast(error.message, {
      appearance: "error",
      autoDismiss: true,
    });
  }
  history.push("/login");
  return addToast("Please check your email for the reset link", {
    appearance: "success",
    autoDismiss: true,
  });
};

const ForgotPassword = () => {
  const { t } = useTranslation("forgotPassword");
  const [captchaToken, setCaptchaToken] = useState('');
  const history = useHistory();
  const { search } = useLocation();
  const { addToast } = useToasts();
  return (
    <div className="ForgotPasswordPage">
      <div className="card">
        <div className="ill">
          <img className="ill__1" src={forgotPasswordIll1} alt="" />
          <img className="ill__2" src={forgotPasswordIll2} alt="" />
          <img className="ill__3" src={forgotPasswordIll3} alt="" />
        </div>
        <h2>{t("title")}</h2>
        <p className="text-grey-1 text-align-center m-b-30 font-weight-light">
          {t("description")}
        </p>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={SiginSchema}
          onSubmit={(values) => {
            forgotPassword({ values: {...values, recaptcha: captchaToken }, history, addToast });
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <CustomField
                {...{
                  errors,
                  touched,
                  name: "email",
                  Ico: () => <img src={mailIco} className="ico" alt="" />,
                }}
              >
                <Field
                  name="email"
                  type="email"
                  placeholder={t("emailPlaceholder")}
                />
              </CustomField>

              <button className="button width-full-width m-b-15" type="submit">
                {t("submitButton")}
              </button>
            </Form>
          )}
        </Formik>
        {process.env.REACT_APP_ENV === "production" &&  // eslint-disable-line
          <GoogleReCaptcha
            onVerify={setCaptchaToken}/>
        }
      </div>
      <p className="text-align-center text-grey-1 font-size-16 m-t-20 font-weight-light">
        <Link to={`/login${search}`} className="font-weight-semibold lighter">
          {t("loginLink")}
        </Link>
      </p>
    </div>
  );
};

export default ForgotPassword;
