import React, {useContext} from "react";
import { useTranslation } from "react-i18next";

import {Link, useHistory} from "react-router-dom";

import profileIco from "../../../assets/images/sidebar/profile.svg";
import billingIco from "../../../assets/images/sidebar/billing.svg";
import networkIco from "../../../assets/images/sidebar/network.svg";
import supportIco from "../../../assets/images/sidebar/support.svg";
import accountIco from "../../../assets/images/sidebar/account.svg";
import volumesIco from "../../../assets/images/sidebar/harddisk.svg";
import claimIco from "../../../assets/images/sidebar/claim-coupon.svg";
import balancersIco from "../../../assets/images/sidebar/load-balancers-icon.svg";
import logo from "../../../assets/images/sidebar/logo.svg";
import {store} from "../../../store";

const navItems = [
  {
    name: "dashboard",
    icon: profileIco,
    category: 'SERVICES',
    link: "/",
  },
  {
    name: "claimbonus",
    icon: claimIco,
    category: 'PROFILE',
    action: () => {
      localStorage.removeItem("closeClaimBonus"); //eslint-disable-line
    },
    link: "/dashboard",
  },
  {
    name: "billing",
    icon: billingIco,
    category: 'PROFILE',
    link: "/billing",
  },
  {
    name: "volumes",
    icon: volumesIco,
    category: 'SERVICES',
    link: "/volumes",
  },
  {
    name: "loadBalancers",
    icon: balancersIco,
    link: "/load-balancers",
    category: 'SERVICES',
  },
  {
    name: "network",
    icon: networkIco,
    link: "/vpc-network",
    category: 'SERVICES',
  },
  {
    name: "support",
    icon: supportIco,
    category: 'PROFILE',
    link: "/tickets",
  },
  {
    name: "account",
    icon: accountIco,
    category: 'PROFILE',
    link: "/profile",
  },
];

export const Nav = ({
  t,
  removeItems,
}) => {
  const history = useHistory();
  const globalState = useContext(store);
  const { state: { clientDetails: { user: { bonus_claimed, refferrer_id } = {} } = {} } = {} } = globalState;
  const groupByCategory = navItems.reduce((acc, data) => {
    acc[data.category] = acc[data.category] ? [...acc[data.category], data] : [data];
    return acc
  }, {})
  return (
    <nav>
      <div>
      {Object.keys(groupByCategory).map((category) => (
        <ul key={category}>
          <h3 className="category">{category}</h3>
          {groupByCategory[category].map(
            ({name, icon, link, action}) =>
              !(removeItems || []).includes(name) && (
                <li key={name}>
                  {!action ?
                    <Link to={link}>
                      <img src={icon} alt=""/>
                      <div>
                        <span>{t(`nav.${name}`)}</span>
                        <span className="description">
                        {t(`nav.${name}Description`)}
                      </span>
                      </div>
                    </Link> :
                    <>
                    {name !== 'claimbonus' || (bonus_claimed === 0 && refferrer_id!=null) && <a onClick={() => {
                      action()
                      link && history.push(link)
                    }} className={'cursor-pointer'}>
                      <img src={icon} alt=""/>
                      <div>
                        <span>{t(`nav.${name}`)}</span>
                        <span className="description">
                        {t(`nav.${name}Description`)}
                      </span>
                      </div>
                    </a>}
                    </>
                  }
                </li>
              )
          )}
        </ul>
      ))}
      </div>
    </nav>
  )
};

const Sidebar = ({ sidebarExtended, setSidebarExtended }) => {
  const { t } = useTranslation("sidebar");
  return (
    <aside
      className={`Sidebar ${
        sidebarExtended ? "Sidebar__extended" : "Sidebar__compact"
      }`}
    >
      <div className="Sidebar__content">
        <Link to="/" className="logo">
          <img src={logo} alt="" />
        </Link>
        <Nav
          {...{ t, setSidebarExtended, sidebarExtended }}
          removeItems={["profile"]}
        />
      </div>
    </aside>
  );
};

export default Sidebar;
