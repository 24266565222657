import React from "react"
import Loader from "../Loader/Loader";

const Button = ({children, loading, className, ...props}) => {
  return  (
    <button disabled={loading} className={`${className} ${loading ? 'button--loading' : ''}`} {...props}>
      {loading && <Loader size={"extra-small"} />}
      <span className={loading && 'invisible'}>{children}</span>
    </button>
  )
}

export default Button
