import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNicsListRequest } from "../../../Dashboard/actions";
import AddModal from "./AddModal";
import AssignModal from "./AssignModal";
import DeleteModal from "./DeleteModal";
import { useLoopFetching } from "../../../../components/hooks/useLoopFetching";
import checkJobStatus from "../../../../helpers/checkJobStatus";
import { useToasts } from "react-toast-notifications";

const Nics = ({ accountid, id, t }) => {
  const dispatch = useDispatch();
  const { nicsList } = useSelector(({ instances }) => instances);
  const [activeSecondary, setActiveSecondary] = useState(null);
  useEffect(() => {
    if (accountid) getNicsListRequest({ accountid, virtualmachineId: id })(dispatch);
  }, [accountid]);
  const [visibleModal, setVisibleModal] = useState(null);
  const [nicToDelete, setNicToDelete] = useState(null);
  const [isSecondaryToDelete, setIsSecondaryToDelete] = useState(false);
  const { addToast } = useToasts();

  const request = async (data, cb) => {
    try {
      const result = await checkJobStatus(accountid, data);
      if (result.status === "success") {
        cancelFetching();
        cb();
      }
      if (result.status === "error") {
        cancelFetching();
        addToast(t(result.error_message.errortext), {
          appearance: "error",
          autoDismiss: true
        });
        setVisibleModal(null);
      }
    } catch (error) {
      // CATCH FOR CHECK JOB STATUS
      // eslint-disable-next-line no-undef
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
      cancelFetching();
      setVisibleModal(null);
    }
  };

  const { startFetching, cancelFetching } = useLoopFetching(request);

  return (
    <div className="Nics">
      <div className="flex">
        <button className="button button--sm p-r-40 p-l-40" onClick={() => setVisibleModal("assign")}>
          {t("nics.button")}
        </button>
        <button className="button button--sm p-r-40 p-l-40 m-l-10" onClick={() => setVisibleModal("add")}>
          {t("nics.addButton")}
        </button>
      </div>
      <div className="Nics__list m-t-30">
        {nicsList && !!nicsList.length && (
          <div className="list-table ssh m-b-30">
            <div className="list-table__heading">
              <div className="text-blue-1 flex flex-align-items-center">{t("nics.network")}</div>
              <div className="text-align-left flex flex-align-items-center">{t("nics.mac")}</div>
              <div className="text-align-left flex flex-align-items-center">{t("nics.ip")}</div>
              <div className="text-align-left flex flex-align-items-center">{t("nics.netmask")}</div>
              <div className="text-align-left flex flex-align-items-center max-width-100">{t("nics.gateway")}</div>
              <div className="max-width-70 min-width-70 delete-column" />
            </div>
            <div className="list-table__body">
              {nicsList.map(
                ({ networkname, macaddress, ipaddress, netmask, gateway, id, isdefault, secondaryip }, idx) => (
                  <div className="list-table__row-wrapper" key={id}>
                    <div className="list-table__row">
                      <div className="flex flex-align-items-center font-weight-semibold position-relative list-table__item">
                        <div
                          onClick={() => {
                            setActiveSecondary(activeSecondary === idx ? null : idx);
                          }}
                          className={`list-table__btn-toggler ${
                            !secondaryip ? "list-table__btn-toggler--disabled" : undefined
                          } ${activeSecondary === idx ? "list-table__btn-toggler--open" : undefined}`}
                        />
                        {networkname}
                      </div>
                      <div className="flex flex-align-items-center font-weight-semibold list-table__item  ">
                        {macaddress}
                      </div>
                      <div className="flex flex-align-items-center font-weight-semibold list-table__item ">
                        {ipaddress}
                      </div>
                      <div className="flex flex-align-items-center font-weight-semibold list-table__item ">
                        {netmask}
                      </div>
                      <div className="flex flex-align-items-center font-weight-semibold list-table__item max-width-100 ">
                        {gateway}
                      </div>
                      <button
                        onClick={() => {
                          setNicToDelete(id);
                          setVisibleModal("delete");
                        }}
                        className={`button max-width-70 button--red button--sm ${
                          isdefault ? "button--hide" : undefined
                        }`}
                      >
                        {t("nics.delete")}
                      </button>
                    </div>
                    {secondaryip && (
                      <div
                        className={`Nics__secondary ${activeSecondary === idx ? "Nics__secondary--open" : undefined}`}
                      >
                        <p className="Nics__secondary-title font-size-16">{t("nics.secondaryIps")}</p>
                        <div className="list-table">
                          <div className="list-table__heading">
                            <div className="text-blue-1 flex flex-align-items-center">{t("nics.ip")}</div>
                          </div>
                          <div className="list-table__body">
                            {secondaryip.map(({ ipaddress, id }) => (
                              <div className="list-table__row" key={ipaddress}>
                                <div className="flex flex-align-items-center font-weight-semibold list-table__item  ">
                                  {ipaddress}
                                </div>
                                <button
                                  className="button button--red button--sm max-width-70"
                                  onClick={() => {
                                    setVisibleModal("delete");
                                    setNicToDelete(id);
                                    setIsSecondaryToDelete(true);
                                  }}
                                >
                                  {t("nics.delete")}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
      <AssignModal
        show={visibleModal === "assign"}
        onHide={() => {
          setVisibleModal(null);
        }}
        accountid={accountid}
        id={id}
        title={t("nics.firstTitle")}
        t={t}
        startFetching={startFetching}
      />
      <DeleteModal
        show={visibleModal === "delete"}
        onHide={() => {
          setVisibleModal(null);
          setIsSecondaryToDelete(false);
        }}
        accountid={accountid}
        id={id}
        t={t}
        nicToDelete={nicToDelete}
        isSecondaryToDelete={isSecondaryToDelete}
        startFetching={startFetching}
      />
      <AddModal
        show={visibleModal === "add"}
        onHide={() => {
          setVisibleModal(null);
          setIsSecondaryToDelete(false);
        }}
        accountid={accountid}
        id={id}
        t={t}
        startFetching={startFetching}
      />
    </div>
  );
};

export default Nics;
