import React from "react";
import { useTranslation } from "react-i18next";

import Search from "./Search";
import Nav from "./Nav";
import logo from "../../../assets/images/sidebar/logo-light.svg";
import { Link } from "react-router-dom";

const Header = () => {
  const { t } = useTranslation("header");

  return (
    <header className="container Header">
      <Link to="/" className="logo visible-xs">
        <img src={logo} alt="" />
      </Link>
      <Search />
      <Nav t={t} />
    </header>
  );
};

export default Header;
