import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import instancesIco from "../../../../../assets/images/create/instances.svg";

const InstanceNameAndState = ({
  status,
  id,
  name,
  location,
  withLink = false,
}) => (
  <div className="flex flex-align-items-center min-width-320">
    <div className="Instance__title-ico no-border p-l-0">
      <img className="m-r-10" src={instancesIco} alt="" />
      <span
        className={`Instance__status Instance__status--${
          status === "Healthy" ? "active" : "inactive"
        }`}
      ></span>
    </div>
    <div>
      <h4 className="font-weight-semibold m-b-0 m-t-0">
        {withLink ? (
          <Link to={`/network/load-balancers/${id}/instances`}>{name}</Link>
        ) : (
          name
        )}
      </h4>
      <span className="font-size-12">{`${location}`}</span>
    </div>
  </div>
);

const Instances = ({
  t,
  instances = [
    {
      id: "1",
      name: "Instance-Ubuntu-s-1vCPU-1GB-sfo2-01",
      location: "NYC3",
      downTime: 6515,
      status: "Healthy",
      ips: ["65.255.32.217", "65.255.32.217"],
      healthCheck: "100%",
      queue: 0,
    },
  ],
}) => {
  const { t: commonT } = useTranslation("common");
  const [visibleKeyPop, setVisibleKeyPop] = useState(null);
  return (
    <div className="Create">
      <div className="flex flex-justify-content-space-between flex-align-items-center font-size-16 m-t-0 m-b-40">
        <span className="text-grey-1">
          <strong>
            {instances.filter(({ status }) => status === "Healthy").length} /{" "}
            {instances.length}{" "}
          </strong>
          {t("instancesTab.healthyIntances")}
        </span>
        <Link to="/create-load-balancer">
          <button className="button button--blue button--md" href="">
            {t("instancesTab.button")}
          </button>
        </Link>
      </div>
      <div className="table font-size-16 m-b-40">
        <div className="table__head flex">
          <div className="min-width-320">{t("name")}</div>
          <div>{t("status")}</div>
          <div>{t("instancesTab.ipAddresses")}</div>
          <div>{t("instancesTab.downtime")}</div>
          <div>{t("instancesTab.queue")}</div>
          <div>{t("instancesTab.healthCheck")}</div>
          <div className="text-align-center text-blue-1 flex flex-align-items-center max-width-70 more position-relative"></div>
        </div>
        <div className="table__body ">
          {instances.map(
            (
              { id, name, location, downTime, status, ips, healthCheck, queue },
              i
            ) => (
              <div
                key={name}
                className="flex flex-align-items-center width-full-width text-grey-1"
              >
                <div className="m-t-0 m-b-0">
                  <InstanceNameAndState
                    {...{ t, status, id, name, location }}
                  />
                </div>
                <div className="m-t-0 m-b-0">
                  <div>{status}</div>
                </div>
                <div className="m-t-0 m-b-0">
                  {ips.map((ip) => (
                    <div key="ip">{ip}</div>
                  ))}
                </div>
                <div className="m-t-0 m-b-0">
                  <div>{downTime}</div>
                </div>
                <div className="m-t-0 m-b-0">
                  <div>{queue}</div>
                </div>
                <div className="m-t-0 m-b-0">{healthCheck}</div>
                <div className="text-align-center text-blue-1 flex flex-align-items-center flex-justify-content-flex-end max-width-70 more position-relative m-t-0 m-b-0">
                  <a
                    className="lighter font-weight-regular"
                    href="javascript:;"
                    onClick={() =>
                      visibleKeyPop !== i
                        ? setVisibleKeyPop(i)
                        : setVisibleKeyPop(null)
                    }
                  >
                    {commonT("more")}{" "}
                    <span
                      className={`arrow fomt-weight-semibold ${
                        visibleKeyPop === i ? "arrow--up" : "arrow--down"
                      } arrow--blue m-l-5`}
                    />
                  </a>
                  {visibleKeyPop !== null && visibleKeyPop === i && (
                    <div className="keyPop popup">
                      <div
                        className="text-grey-1"
                        onClick={() => setVisibleKeyPop(null)}
                      >
                        {t("instancesTab.viewInstance")}
                      </div>
                      <div
                        className="deleteKey text-red-1"
                        onClick={() => setVisibleKeyPop(null)}
                      >
                        {t("instancesTab.remove")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Instances;
