export default {
  button: 'Create',
  usage: 'CREDIT',
  placeholder: 'Search by resource or public IP',
  placeholderMobile: 'Search',
  placeholderCommand: '(ctrl+B)',
  nav: {
    profile: 'My Profile',
    profileDescription: 'Edit personal information',
    signout: 'Sign Out',
    signoutDescription: 'Sign out or switch account',
  },
  createNav: {
    instances: 'Instances',
    instancesDescription: 'Create cloud servers',
    clusters: 'Clusters',
    clustersDescription: 'Create Kubernetes clusters',
    databases: 'Databases',
    databasesDescription: 'Create databases clusters',
    volumes: 'Volumes',
    volumesDescription: 'Add storage to Servers',
    domanies: 'Domains/DNS',
    domaniesDescription: 'Route your existing domains',
    firewalls: 'Cloud Firewalls',
    firewallsDescription: 'Increase Server security',
    floatingIps: 'Extra IPs',
    floatingIpsDescription: 'Reserve Server IP addresses',
    loadBalancers: 'Load Balancers',
    loadBalancersDescription: 'Create Load Balancer rules',
    alertPolicies: 'Alert policies',
    alertPoliciesDescription: 'Monitor your Servers',
    spaces: 'Spaces',
    spacesDescription: 'Store and serve static assets',
  },
  notifications: {
    title: 'Latest Tickets',
    button: 'View All',
    asRead: 'mark as read',
  },
};
