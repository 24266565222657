import React, { useEffect } from "react";
import { getUsageRequest } from "../../billingActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Volumes/components/Loader/Loader";
import OrizontalScroll from "../../../../components/OrizontalScroll";
import instancesIco from '../../../../assets/images/create/instances2.svg';
import UsageHeading from "../UsageHeading";

const Usage = ({ t: usageTrans }) => {
  const dispatch = useDispatch();
  const t = (path) => usageTrans(`usage.${path}`);
  const { usage, usageLoading } = useSelector(({ billing }) => billing);
  useEffect(() => {
    getUsageRequest()(dispatch);
  }, []);
  const totalUsage = usage.reduce((acc, data) => acc + Number(data.charge), 0);
  return (
    <div className="usage">
      <UsageHeading usage={totalUsage} />
      {usageLoading ? (
        <div className={"flex flex-justify-content-center"}><Loader /></div>
      ) :
        <>
          {usage.length ? (
            <OrizontalScroll>
              <OrizontalScroll.Scrollable>
                <div className="usage__table">
                  <div className="usage__table-head">
                    <span className="usage__table-title">{t("name")}</span>
                    <span className="usage__table-title">{t("usage")}</span>
                    <span className="usage__table-title">{t("bandwidth")}</span>
                    <span className="usage__table-title">{t("charge")}</span>
                  </div>
                  <>
                    {usage.map((usageInfo) => <div key={usageInfo.id} className="usage__table-content m-b-10">
                    <span className="usage__table-title">
                      <img src={instancesIco} alt=""/>
                      {usageInfo.name}
                    </span>
                      <span className="usage__table-title">{usageInfo.usage} {usageInfo.unit_name}</span>
                      <span className="usage__table-title">{usageInfo.bandwidth}</span>
                      <span className="usage__table-title">$ {usageInfo.charge}</span>
                    </div>)}
                  </>
                </div>
              </OrizontalScroll.Scrollable>
              <OrizontalScroll.Slider className="flex flex-justify-content-center m-b-20 m-t-20"/>
            </OrizontalScroll>) : <p className={`text-grey-1 text-align-center p-t-30`}>{t('empty')}</p>
          }
      </>
      }
    </div>
  );
};

export default Usage;
