import Size from "./Size";
import ForwardRules from "./ForwardRules";
import Algorithm from "./Algorithm";
import HealthChecks from "./HealthChecks";
import StickySession from "./StickySession";
import Ssl from "./Ssl";
import ProxyProtocol from "./ProxyProtocol";
import BackendKeepalive from "./BackendKeepalive";
import Destroy from "./Destroy";

export default {
  size: Size,
  forwardRules: ForwardRules,
  algorithm: Algorithm,
  healthChecks: HealthChecks,
  stickySession: StickySession,
  ssl: Ssl,
  proxyProtocol: ProxyProtocol,
  backendKeepalive: BackendKeepalive,
  destroy: Destroy,
};
