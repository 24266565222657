import React, { useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from 'react-toast-notifications';
import InfoBox from "../../../../components/InfoBox";
import BackArrow from "../../../../assets/images/tickets/back-arrow.svg";
import TrashIco from "../../../../assets/images/network/trash_icon_white.svg";
import EditIco from "../../../../assets/images/network/edit_icon_white.svg";
import copyIco from '../../../../assets/images/network/copy_icon.svg';
import loadBalancerIco from '../../../../assets/images/network/load_balancer_icon.svg';
import instancesIco from '../../../../assets/images/create/instances2.svg';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import pretty from "prettysize";

const NetworkDetails = ({ network, setDetailsNetwork, t, instances, startFetching }) => {
	const { addToast } = useToasts();
	const [visibleModal, setVisibleModal] = useState(null);

	return (
		<div className="Network_details">
			<div className="Header_container">
				<div className="Title_container">
					<div
						onClick={() => { setDetailsNetwork(null) }}
						className="font-size-27 text-blue-1 cursor-pointer">
						<img src={BackArrow} className="m-r-15" />
						{network.name}
					</div>
					<div className="font-size-16 text-grey-1">{network.city} / {network.instancesCount} {t('networks.details.resources')} / {network.cidr}</div>
				</div>
				<div className="Buttons_container">
					<button className="button button--blue" onClick={() => { setVisibleModal('edit') }}>
						<img src={EditIco} className="m-r-8" />
						{t('networks.details.edit')}
					</button>
					{instances?.length > 0 && <InfoBox className="Details_delete_button" text={t('networks.details.deleteDisabled')}>
						<button className="button button--full-red button_disabled">
							<img src={TrashIco} className="m-r-8" />
							{t('networks.details.delete')}
						</button>
					</InfoBox>}
					{instances?.length === 0 && <button className="button button--full-red" onClick={() => { setVisibleModal('delete') }}>
						<img src={TrashIco} className="m-r-8" />
						{t('networks.details.delete')}
					</button>}
				</div>
			</div>
			<div className="Info_section">
				<div className="font-size-27 text-grey-3"> {t('networks.details.info')}</div>
				<div className="Info_container">
					<div className="Info_column">
						<div><span>{t('networks.details.name')}</span> - {network.name} </div>
						<div><span>{t('networks.details.gateway')}</span> - {network.gateway}</div>
						<div><span>{t('networks.details.netmask')}</span> - {network.netmask}</div>
					</div>
					<div className="Info_column">
						<div><span>{t('networks.details.cidr')}</span> - {network.cidr}</div>
						<div><span>{t('networks.details.dns1')}</span> - {network.dns1}</div>
						<div><span>{t('networks.details.dns2')}</span> - {network.dns2}</div>
					</div>
					<div className="Info_column">
						<div><span>{t('networks.details.zone')}</span> - {network.zonename}</div>
						<div><span>{t('networks.details.type')}</span> - {network.type}</div>
						<div><span>{t('networks.details.state')}</span> - {network.state}</div>
					</div>
				</div>
			</div>
			<div className="Resources_section">
				<div className="font-size-27 text-grey-3"> {t('networks.details.resources')}</div>
				{(instances && instances.length) ? <div className="List_container">
					<div className="List_header">
						<div>{t('networks.details.instance')} ({network.instancesCount})</div>
						<div>{t('networks.details.publicIp')}</div>
						<div>{t('networks.details.privateIp')}</div>
					</div>
					<div className="List_body">
						{instances.map(instance =>
						(<div key={instance.id} className="List_item">
							<div>
								<div className="Instance position-relative m-r-14">
									<img src={instancesIco} />
									<span className={`status ${(instance.state === 'Running' && 'Instance__active') ||
										(['Starting', 'Stopping'].includes(instance.state)
											? 'Instance__pending'
											: 'Instance__inactive')}`
									} />
								</div>
								<div className="flex flex-column">
									<div className="font-size-16 font-weight-semibold text-blue-1">{instance.displayname}</div>
									<div className="font-size-12 font-weight-semibold text-grey-8">{instance.cpunumber} {t('networks.details.cpu')} / {pretty(instance.memory * 1024 * 1024)} {t('networks.details.ram')}</div>
								</div>
							</div>
							<div className="font-size-16 text-grey-3">
								{instance.nic[0].ipaddress}
								<CopyToClipboard
									text={instance.nic[0].ipaddress}
									onCopy={() => {
										addToast(t("networks.successCopy"), {
											appearance: "success",
											autoDismiss: true,
											autoDismissTimeout: 2000,
										});
									}}>
									<img src={copyIco} />
								</CopyToClipboard>
							</div>
							<div className="font-size-16 text-grey-3">
								{instance.nic[1].ipaddress}
								<CopyToClipboard
									text={instance.nic[1].ipaddress}
									onCopy={() => {
										addToast(t("networks.successCopy"), {
											appearance: "success",
											autoDismiss: true,
											autoDismissTimeout: 2000,
										});
									}}>
									<img src={copyIco} />
								</CopyToClipboard>
							</div>
						</div>)
						)}
					</div>
				</div> : null}
				{/* Check all hardcoded values below */}
				<div className="List_container">
					<div className="List_header">
						<div>{t('networks.details.loadBalancer')} (1)</div>
						<div>{t('networks.details.publicIp')}</div>
						<div>{t('networks.details.privateIp')}</div>
					</div>
					<div className="List_body">
						<div className="List_item">
							<div>
								<div className="Instance position-relative m-r-14">
									<img src={loadBalancerIco} />
								</div>
								<div className="flex flex-column">
									<div className="font-size-16 font-weight-semibold text-blue-1">Staging Load Balancer</div>
								</div>
							</div>
							<div className="font-size-16 text-grey-3">
								39.453.112.7
								<CopyToClipboard
									text="39.453.112.7"
									onCopy={() => {
										addToast(t("networks.successCopy"), {
											appearance: "success",
											autoDismiss: true,
											autoDismissTimeout: 2000,
										});
									}}>
									<img src={copyIco} />
								</CopyToClipboard>
							</div>
							<div className="font-size-16 text-grey-3">
								39.453.112.7
								<CopyToClipboard
									text="39.453.112.7"
									onCopy={() => {
										addToast(t("networks.successCopy"), {
											appearance: "success",
											autoDismiss: true,
											autoDismissTimeout: 2000,
										});
									}}>
									<img src={copyIco} />
								</CopyToClipboard>
							</div>
						</div>
					</div>
				</div>
			</div>
			<EditModal
				onHide={() => {
					setVisibleModal(null);
				}}
				show={visibleModal === 'edit'}
				startFetching={startFetching}
				setDetailsNetwork={setDetailsNetwork}
				network={network}
				t={t}
			/>
			<DeleteModal
				show={visibleModal === 'delete'}
				networkDetails={network}
				setDetailsNetwork={setDetailsNetwork}
				setVisibleModal={setVisibleModal}
				t={t}
				onHide={() => {
					setVisibleModal(null);
				}}
				startFetching={startFetching}
			/>
		</div>
	)
}

export default NetworkDetails;
