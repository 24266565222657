import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {store} from '../../../store';
import {createInstanceAction} from './actions';
import {useToasts} from 'react-toast-notifications';
import {deployInstanceRequest, getLocationsRequest, getOrderStatusRequest, setCreateInstanceLoading} from '../actions';

import checkJobStatus from '../../../helpers/checkJobStatus';
import {useLoopFetching} from '../../../components/hooks/useLoopFetching';
import Loader from '../../../components/Loader/Loader';

import ChoosePlan from './components/ChoosePlan';
import ChooseImage from './components/ChooseImage';
import VpcNetwork from './components/VpcNetwork/VpcNetwork';
import Authentication from './components/Authentication';
import AdditionalOptions from './components/AdditionalOptions';
import ConcludeAndCreate from './components/ConcludeAndCreate';
import BackupWork from './components/BackupWork';
import {post} from '../../../components/http';
import warning from "../../../assets/images/activity/warning.svg";
import Button from "../../../components/Button";
import LinearWithValueLabel from "./components/LoaderBox";
import Checkbox from "../../../components/Checkbox";

export const createTags = ({accountId, vmId, serviceType}) => {
  const defaultTagsQuery = `?tags[0][key]=service_id&tags[0][value]=${accountId}&tags[1][key]=service_type&tags[1][value]=${serviceType}`;

  const fullUrl =
    `/accounts/${accountId}/resource-tags-vm` +
    defaultTagsQuery +
    `&vmid=${vmId}&resourcetype=UserVm`;
  post(fullUrl);
};

const CreateInstance = () => {
  const history = useHistory();
  const timeoutRef = useRef();
  const {addToast} = useToasts();
  const {t} = useTranslation('createInstance');
  const globalState = useContext(store);
  const {dispatch: contextDispatch, state} = globalState;
  const dispatch = useDispatch();
  const [activeProgress, setActiveProgress] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [blockedPort, setBlockedPort] = useState(false);

  const {
    pricingPlan,
    shapehostNetworks: primaryNetworks,
    selectedRegion: region,
    createInstanceLoading,
    fetchLocationsLoading,
    networkType,
    selectedProject,
    selectedPlan
  } = useSelector(({products}) => products);
  const {
    sshKeys, ssh
  } = state?.createInstance;

  let extraParams = {};

  if (state?.createInstance?.isWindows11) {
    extraParams = {
      bootmode: "Secure",
      boottype: "UEFI",
    }
  }

  //   const tags = useSelector(({ products }) => products.tags);
  const accountId = state?.clientDetails?.id;
  const [creatingStatus, setCreatingStatus] = useState('createInstance');
  const [networkdIdForDeploy] = useMemo(
    () => primaryNetworks.filter(({
                                    type,
                                    zone,
                                    active,
                                    primary
                                  }) => active && primary && type === "Shared" && zone.acs_id === region?.acs_id),
    [region?.acs_id, primaryNetworks]
  );
  const instanceName = `${state?.createInstance?.osName ? `${state?.createInstance?.osName}-` : ''}${pricingPlan?.name ? `${pricingPlan?.name}-` : ''}${pricingPlan?.cpus ? `${pricingPlan?.cpus}vcpus-` : ''}${pricingPlan?.cpuSize ? `${pricingPlan?.cpuSize?.replace(" ", "").toLowerCase()}-` : ''}${region?.name ? `${region?.name}-` : ''}`;


  const request = async (data, _, accId) => {
    try {
      const result = await checkJobStatus(accId, data);
      if (result.status === 'success') {
        createTags({accountId: accId, vmId: result.result.virtualmachine.id, serviceType: pricingPlan.name});
        cancelFetching();
        history.push(`/instance/${result.result.virtualmachine.id}/access/${result.result.virtualmachine.password}`);
        setCreatingStatus('createInstance');
        setCreateInstanceLoading(false)(dispatch);
      }
      if (result.status === 'error') {
        cancelFetching();
        addToast(t(result.error_message.errortext), {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      });
      cancelFetching();
    }
  };

  const {cancelFetching} = useLoopFetching(request);

  const buttonText = {
    deploying: t('deploying'),
    createInstance: t('button'),
    creditRequired: t('creditRequired')
  };

  const translationsByComponent = (component) => (text) => t(`${component}.${text}`);

  useEffect(() => {
    if (state?.clientDetails?.firstname === "") {
      history.push('/profile')
    }
  }, [state?.clientDetails?.firstname])

  useEffect(() => {
    const onMount = async () => {
      await getLocationsRequest()(dispatch);
    };
    onMount();
  }, []); //eslint-disable-line

  useEffect(() => {
    return () => {
      // eslint-disable-next-line no-undef
      clearTimeout(timeoutRef.current);
      cancelFetching();
    };
  }, []);

  const checkOrderStatus = async (orderId) => {
    // eslint-disable-next-line
    timeoutRef.current = setTimeout(async () => {
      const orderStatusResult = await getOrderStatusRequest({id: orderId})(dispatch);
      if (orderStatusResult?.data?.data[0]?.status === "PROCESSING") {
        return history.push("/?deploy-success");
      }

      // eslint-disable-next-line
      setTimeout(async () => {
        await checkOrderStatus(orderId);
      }, 15000);
    }, 15000);
  };

  const handleCreateInstance = async (name) => {
    if (!name) {
      return addToast(t('createNameError'), {
        appearance: 'error',
        autoDismiss: true
      });
    } else {
      let result
      try {
        setCreatingStatus('deploying');
        let deployBody = {
          product_id: pricingPlan?.id,
          cloud_name: `cloud${accountId}`,
          payment_module_id: 58, // default payment module
          selectedPlan,
          defaultNetwork: networkdIdForDeploy.acs_id,
          zoneid: region.acs_id,
          pricingPlan: pricingPlan,
          templateid: state.createInstance?.templateid,
          name: name.replaceAll(' ', '-'),
          keypairs: sshKeys?.join(),
          userdata: state.createInstance.userData,
          appUserData: state.createInstance?.oneClick?.appsUserData,
          projectName: selectedProject.nameToSend,
          advancedNetworkId: state?.createInstance?.advancedNetwork?.id,
          networkType,
          host: region?.host,
          country: region.country
        };

        deployBody = {...deployBody, ...ssh, ...extraParams}

        result = await deployInstanceRequest(deployBody)(dispatch);
        const instancesOnWait = [...(JSON.parse(localStorage.getItem("instancesOnWait")) || []), { //eslint-disable-line
          name: name.replaceAll(' ', '-'),
          orderId: result?.data?.data?.order_id
        }];
        localStorage.setItem("instancesOnWait", JSON.stringify(instancesOnWait)) //eslint-disable-line
        await checkOrderStatus(result?.data?.data?.order_id, name)
      } catch (error) {
        history.push('/tickets');
        return addToast(error.message, {
          appearance: 'error',
          autoDismiss: true
        });
      }

    }
  };

  useEffect(() => {
    window.scrollTo(0, 0) //eslint-disable-line
  }, [fetchLocationsLoading])

  if (fetchLocationsLoading) {
    return (
      <div className="container flex flex-justify-content-center p-t-80">
        <Loader/>
      </div>
    )
  }

  return (
    <div className="CreateInstance">
      <div className="container">
        <h2 className="title m-t-30">{t('title')}</h2>
        <ChoosePlan createInstanceAction={createInstanceAction} dispatch={contextDispatch} t={translationsByComponent('choosePlan')}/>
        <ChooseImage
          dispatch={contextDispatch}
          state={state}
          createInstanceAction={createInstanceAction}
          t={translationsByComponent('chooseImage')}
        />
        <VpcNetwork dispatch={contextDispatch} state={state} createInstanceAction={createInstanceAction}
                    t={translationsByComponent('vpcNetwork')}/>
        {!state.createInstance.isWindowsOS && (
          <Authentication
            dispatch={contextDispatch}
            state={state}
            createInstanceAction={createInstanceAction}
            t={translationsByComponent('authentication')}
          />
        )}
        <AdditionalOptions
          dispatch={contextDispatch}
          state={state}
          createInstanceAction={createInstanceAction}
          t={translationsByComponent('additionalOptions')}
        />
        <ConcludeAndCreate
          dispatch={contextDispatch}
          state={state}
          createInstanceAction={createInstanceAction}
          instanceName={instanceName}
          t={translationsByComponent('conclude')}
        />

        <BackupWork
          dispatch={contextDispatch}
          state={state}
          createInstanceAction={createInstanceAction}
          disabled
          t={translationsByComponent('backupWork')}
        />
        {/*<Coupon t={translationsByComponent('coupon')} />*/}
        {/*{`${(pricingPlan?.price || 0) * (state?.createInstance?.distribution || [])?.length *15}, ${Number(state?.clientDetails?.credit)}, ${(pricingPlan?.price || 0) * (state?.createInstance?.distribution || [])?.length > Number(state?.clientDetails?.credit)}`}*/}
        <div className={'m-t-50 m-b-50'}>
        <label className="font-weight-light font-size-16 text-grey-1 flex m-b-10">
          <Checkbox className={'m-r-10'} onChange={(e) => setTermsAccepted(e.target.checked)} />
          I have read and agree to Shapehost&apos;s <a href="https://shape.host/terms" className={'m-l-5'} target={"_blank"} rel="noreferrer" >Terms and Conditions.</a>
        </label>
        <label className="font-weight-light font-size-16 text-grey-1 flex m-b-50">
          <Checkbox className={'m-r-10'} onChange={(e) => setBlockedPort(e.target.checked)} />
            I understand and agree that the use of port 25 is not permitted on this platform.
        </label>
        {!activeProgress ?
          <Button
            className="button button--green width-full-width flex"
            loading={createInstanceLoading ||
              creatingStatus === 'deploying' ||
              creatingStatus === 'creditRequired'}
            disabled={
              !termsAccepted ||
              !blockedPort ||
              !pricingPlan ||
              !state.createInstance.templateid ||
              !(state?.createInstance?.distribution || [])?.length ||
              (pricingPlan?.price || 0) * (state?.createInstance?.distribution || [])?.length > Number(state?.clientDetails?.credit)
            }
            onClick={async () => {
              setActiveProgress(true)
              const requests = state?.createInstance?.distribution.map(name => handleCreateInstance(name))
              await Promise.all(requests)
            }}>
            {buttonText[creatingStatus]}
          </Button> :
          <LinearWithValueLabel {...{active: activeProgress, setActive: setActiveProgress}} />
          }
          {(pricingPlan?.price || 0) * (state?.createInstance?.distribution || [])?.length > Number(state?.clientDetails?.credit) &&
            <p className={'text-grey-1 font-size-16 font-weight-light m-b-25 flex flex-align-items-center'}>
              <img className={'m-r-10'} src={warning} alt=""/>
              Not enough funds to complete, please top up
            </p>}
        </div>
      </div>
    </div>
  );
};

export default CreateInstance;
