export default {
  title: 'Create VPC Network',
  description:
    'A Virtual Private Cloud (VPC) Network ensures secure traffic between any resources like Instances, Kubernetes clusters, database clusters, and load balancers. Any resources you create within a VPC can communicate securely over their private IP Addresses.',
  chooseLocation: {
    title: 'Choose a datacenter region',
    description: 'VPC networks can only contain resources that are in the same datacenter region.',
    learnMore: 'Learn more',
    firstLocation:
      'This is your first VPC network in NYC3. It will be set as the default for that datacenter, and cannot be changed.',
  },
  configurePrivateIpRange: {
    title: 'Configure the Private IP Range',
    description:
      'Resources created in this VPC will be assigned a private IP for secure communication within the specified range.',
    generateIp: 'Generate an IP range for me',
    generateIpDescription: 'We shall auto create an IP range for you',
    llBeGenerated: 'An address range for this VPC network will be generated on creation.',
    recommended: 'RECOMMENDED',
    ownIp: 'Configure my own IP range',
    ownIpDescription: 'All you need to specify is a subnet IP prefix and size',
    addressRangeLabel: 'Address Range Prefix*',
    addressRangePlaceholder: 'Private IP range prefix',
    networkSize: 'NETWORK SIZE',
    moreAbout: 'More about available addresses',
  },
  chooseName: {
    title: 'Choose a name and description',
    description: 'You can edit this default name and add a meaningful description to you.',
    placeholder: 'Name',
    descriptionPlaceholder: 'Description: Enter a description...',
  },
  button: 'Create VPC Network',
};
