export default {
  title: "Create Load Balancer",
  titleConfigure: "Load Balancers",
  description:
    "Load balancers distribute traffic between Instances within the same datacenter. Create a load balancer, then add Instances by name or by tag.",
  descriptionConfigure: "Load balancers distribute traffic between Instances within the same datacenter. Create a load balancer, then add Instances by name or by tag.",
  locationTitle: "Choose a datacenter region",
  locationTitleConfig: "Datacenter region",
  locationDescription: "Choose the same datacenter as the Instances you plan to load balance.",
  addIP: "Add Extra IP",
  selectExtraIp: "Select an available IP address",
  choosePlan: {
    locations: {
      noLocations: "No available locations for this region",
      allLocations: "All locations",
      title: "Choose Server's Location",
      titleLoadBalancers: "Choose a datacenter region",
      titleLoadBalancersConfigure: "Datacenter region ",
      descriptionLoadBalancers: "Choose the same datacenter as the Instances you plan to load balance.",
      countries: {
        "DE": "Germany",
        "UK": "United Kingdom",
        "RU": "Russia",
        "FI": "Finlanda",
        "CA": "Canada",
        "BR": "Brasil",
        "US": "USA",
        "FR": "France",
        "IN": "India",
        "SG": "Singapore",
        "JP": "Japan",
        "NL": "Netherlands",
        "HK": "Hong Kong"
      }
    },
  },
  chooseIP: {
    title: "Choose load balancer IP",
    region: "Region",
    loadBalancerIp: "Choose load balancer IP address",
  },
  vpcNetwork: {
    title: "VPC Network",
    description:
      "All resources created in this datacenter will be members of the same VPC network. They can communicate securely over their Private IP addresses.",
    whatMean: "What does this mean",
    modal:
      "Private networking is now automatically enabled. You can create new networks or just use the default.",
    learnMore: "Learn more",
  },
  addInstances: {
    title: "Add Instances",
    description:
      "You can add Instances or tags now, or after the Load Balancer is created. All Instances must be in the same VPC network as the Load Balancer.",
    placeholder: "Search for an instance or tag",
  },
  concludeAndCreate: {
    title: "Name",
    descriptionCreate: "Create a load balancer name",
    descriptionConfig: "Edit a load balancer name",
    placeholder: "Enter the name of your load balancer here",
  },
  forwardRules: {
    title: "Forwarding rules",
    description:
      "Set how traffic will be routed from the Load Balancer to your Instances. At least one rule is required to be set.",
    name: "NAME",
    algorithm: "ALGORITHM",
    protocol: "PROTOCOL",
    pubPort: "PUBLIC PORT",
    privPort: "PRIVATE PORT",
    addVm: "Add VM",
    createRule: "+ Add new rule",
    delete: "Delete",
    placeholder: "Search for an available instance",
    rulename: "Rule Name"
  },
  AdvancedSettings: {
    title: "Advanced settings",
    description:
      "These are the most commonly used settings and are all selected by default. You can change them at any time by clicking this button.",
    editButton: "Edit Advanced Settings",
    hideButton: "Hide Advanced Settings",
    algoritm: "Algoritm",
    styckySession: "Stycky Session",
    healthChecks: "Sealth Checks",
    ssl: "SSL",
    proxyProtocol: "Proxy Protocol",
    backendKeepalive: "Backend Keepalive",
    changeAdvanced: {
      roundRobin: "Round Robin",
      leastConnections: "Least Connections",
      none: "None",
      cookie: "Cookie",
      healthDescription:
        "Set how often the Load Balancer checks if Instances are responding. It will automatically stop sending traffic to unresponsive Instances.",
      protocol: "PROTOCOL",
      port: "PORT",
      path: "PATH",
      checkInterval: "CHECK INTERVAL(in sec)",
      responseTime: "RESPONSE TIME(in sec)",
      unhealtyThreshold: "UNHEALTHY THRESHOLD",
      healtyThreshold: "HEALTHY THRESHOLD",
      ssl: "SSL",
      sslLabel: "Redirect HTTP to HTTPS ",
      proxyLabel: "Enable Proxy Protocol ",
      learnMore: "Learn more",
      heepAliveLabel: "Enable Backend Keepalive",
    },
  },
  DestroyModal: {
    title: "Destroy load balancer",
  },
  button: "Create Load Balancer",
  buttonSave: "Save",
};
