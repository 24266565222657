import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import EditProfileForm from "./EditProfileForm";

import { store } from "../../../../store";

const Profile = () => {
  const globalState = useContext(store);
  const { state: { clientDetails } = {}, dispatch } = globalState;
  const { datecreated } = clientDetails || {};
  const { t: profileTranslations } = useTranslation("profile");
  const { t: billingTranslations } = useTranslation("billing");
  const billingProfileTrans = (path) => billingTranslations(`billingProfile.${path}`);
  const t = (path) => profileTranslations(`profile.${path}`);
  return (
    <div className="ProfilePage">
      <p className="date text-grey-1 text-align-right m-b-35">
        {t("profileDetails.memberSince")}{" "}
        <span className="display-inline-block">{datecreated}</span>
      </p>

      <EditProfileForm
        key={JSON.stringify(datecreated)}
        {...{ ...clientDetails, dispatch }}
        t={t}
        billingProfileTrans={billingProfileTrans}
      />

    </div>
  );
};

export default Profile;
