import React, { useState, useMemo, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useToasts } from "react-toast-notifications";
import { useDispatch } from 'react-redux';
import {
    getFirewallsRequest,
    getIpAddressesRequest,
    getLBGroupsRequest,
    createFirewallGroupRequest,
    deleteFirewallGroupRequest,
    createFirewallRuleRequest,
    assignFirewallRuleToGroupRequest,
    deleteFirewallRuleRequest,
} from '../../../Dashboard/actions';
import FirewallSettings from './FirewallSettings';
import FirewallInstances from './FirewallInstances';
import FirewallRulesList from './FirewallRulesList';
import ArrowIco from '../../../../assets/images/network/arrow-left.svg';
import Tabs from '../../../../components/Tabs';
import Loader from '../../../../components/Loader/Loader';

const CreateEmptyRule = () => {
    return {
        type: 'new',
        id: uuid(),
        startport: 1,
        endport: 65535,
        protocol: 'tcp',
        icmptype: null,
        icmpcode: null,
        cidrlist: '',
    }
}

export const createRule = async (rule, groupId, extraIp, dispatch) => {
    const ruleId = await createFirewallRuleRequest({
        accountId: extraIp.accountId,
        ipaddressid: extraIp.id,
        protocol: rule.protocol,
        cidrlist: rule.cidrlist,
        startport: rule.protocol === "icmp" ? "" : rule.startport,
        endport: rule.protocol === "icmp" ? "" : rule.endport,
        icmpcode: rule.protocol === "icmp" ? rule.icmpcode : "",
        icmptype: rule.protocol === "icmp" ? rule.icmptype : "",
    })(dispatch)

    await assignFirewallRuleToGroupRequest({
        accountId: extraIp.accountId,
        groupId: groupId,
        ruleId: ruleId
    })(dispatch)
}

const CreateOrEditFirewall = () => {
    const [selected, setSelected] = useState('rules');
    const [firewallName, setFirewallName] = useState();
    const [usedInstances, setUsedInstances] = useState([]);
    const [rules, setRules] = useState([CreateEmptyRule()]);
    const { firewalls, firewallsLoading, instances, floatingIpList, lbGroups } = useSelector(({ instances }) => instances);
    const { t: networkT } = useTranslation('network');
    const t = (path) => networkT(`firewalls.${path}`);
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const navigate = useHistory();

    const { id: groupId } = useParams();

    useEffect(() => {
        if (!firewalls.length && groupId) {
            getFirewallsRequest()(dispatch);
        }
        if (!floatingIpList.length) {
            getIpAddressesRequest()(dispatch);
        }
        if (!lbGroups.length) {
            getLBGroupsRequest()(dispatch);
        }
    }, [dispatch])

    const usedEsxtraIps = useMemo(() => {
        if (usedInstances && usedInstances.length) {
            const usedInstancesName = usedInstances.map(instance => { return instance.name });
            const ips = floatingIpList.filter(extraIp => usedInstancesName.includes(extraIp.virtualmachinename))

            return ips;
        }
    }, [usedInstances]);

    const handleRuleDelete = (choosenRule) => {
        const newRules = rules.filter((rule) => rule.id !== choosenRule.id);

        setRules([...newRules]);
    }

    const handleAddRule = () => {
        setRules([...rules, CreateEmptyRule()]);
    }

    const handleRuleEdit = (editedRule) => {
        const newRules = [];
        rules.forEach((rule) => {
            if (rule.id === editedRule.id) {
                newRules.push({ ...editedRule })
            } else {
                newRules.push(rule);
            }
        })

        setRules(newRules);
    }

    const selectedFirewall = useMemo(() => {
        if (groupId && firewalls.length) {
            const firewall = firewalls.find(firewall => { return firewall.id.toString() === groupId });

            const instanceIds = [];
            const usedInstances = [];
            const ipList = firewall?.rules?.map(rule => rule.ipaddressid);

            floatingIpList?.forEach(extraIp => {
                if (ipList?.includes(extraIp.id)) {
                    instanceIds.push(extraIp.virtualmachineid);
                }
            });

            instances?.forEach(instance => {
                if (instanceIds.includes(instance.id)) {
                    usedInstances.push(instance);
                }
            });

            const firewallCopy = { ...firewall, instances: usedInstances }

            setFirewallName(firewallCopy?.name);
            setRules(firewallCopy?.rules);

            return firewallCopy;
        }
    }, [firewalls]);

    const isButtonDisabled = useMemo(() => {
        if (!firewallName || !usedInstances?.length) {
            return true;
        }

        for (const rule of rules) {
            if (!rule.cidrlist) {
                return true;
            }

            if ((rule.protocol !== 'icmp') && (!rule.startport || !rule.endport)) {
                return true;
            }

            if ((rule.protocol === 'icmp') && (!rule.icmptype || !rule.icmpcode)) {
                return true;
            }
        }

        return false;
    }, [rules, firewallName, usedInstances]);

    useEffect(() => {
        if(groupId) {
            const instanceIds = [];
            const usedInstances = [];
            const ipList = selectedFirewall?.rules?.map(rule => rule.ipaddressid);

            floatingIpList?.forEach(extraIp => {
                if (ipList?.includes(extraIp.id)) {
                    instanceIds.push(extraIp.virtualmachineid);
                }
            });

            instances?.forEach(instance => {
                if (instanceIds.includes(instance.id)) {
                    usedInstances.push(instance);
                }
            });

            setUsedInstances(usedInstances);
        }
    }, [firewalls, instances]);

    const handleCreate = async (setLoading) => {
        setLoading(true)
        let groupId;
        try {
            groupId = await createFirewallGroupRequest({ name: firewallName })(dispatch);

            for (const extraIp of usedEsxtraIps) {

                for (const rule of rules) {
                    await createRule(rule, groupId, extraIp, dispatch);
                }
            }

            navigate.push('/vpc-network/firewalls');
            getFirewallsRequest()(dispatch);
            addToast("Created", {
                appearance: "success",
                autoDismiss: true
            });
            setLoading(false);
        } catch (error) {
            if (groupId) {
                await deleteFirewallGroupRequest({ groupId: groupId })(dispatch);
            }
            setLoading(false);
            return addToast(error.message, {
                appearance: "error",
                autoDismiss: true
            });
        }
    }

    const handleEdit = async (setLoading) => {
        setLoading(true);
        try {
            const deletedRulesIds = [];
            const initialRules = selectedFirewall.rules;
            const initialRulesIds = initialRules.map(rule => rule.id);
            const ruleIds = rules.map(rule => rule.id);
            initialRulesIds.forEach(id => {
                if (!ruleIds.includes(id)) {
                    deletedRulesIds.push(id);
                }
            })

            if (deletedRulesIds.length) {
                for (const ruleId of deletedRulesIds) {
                    const choosenRule = initialRules.find(rule => rule.id === ruleId);
                    const choosenIp = floatingIpList.find(extraIp => extraIp.id === choosenRule.ipaddressid);

                    await deleteFirewallRuleRequest({ accountId: choosenIp.accountId, ruleId: ruleId })(dispatch);
                }
            }

            for (const extraIp of usedEsxtraIps) {
                for (const rule of rules) {
                    if (rule.type === 'new') {
                        await createRule(rule, selectedFirewall.id, extraIp, dispatch);
                    }

                    if (rule.type === 'edit') {
                        await deleteFirewallRuleRequest({ accountId: extraIp.accountId, ruleId: rule.id })(dispatch);

                        await createRule(rule, selectedFirewall.id, extraIp, dispatch);
                    }
                }
            }
            getFirewallsRequest()(dispatch);
            addToast("Edited", {
                appearance: "success",
                autoDismiss: true
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            return addToast(error.message, {
                appearance: "error",
                autoDismiss: true
            });
        }
    }

    return (<div>
        {firewallsLoading ? <div className='text-align-center p-t-30 p-b-30'><Loader /></div>
            : <div>
                <div className='Title_container'>
                    <div className='Title_content'>
                        {selectedFirewall ?
                            <Link to="/vpc-network/firewalls">
                                <div className='font-size-27 text-blue-1 cursor-pointer'>
                                    <img src={ArrowIco} className='m-r-15' />
                                    {selectedFirewall.name}
                                </div>
                            </Link>
                            : <div className='font-size-27 font-weight-light text-grey-3'>{t('firewallRules.title')}</div>}
                        <div className='font-size-16 text-grey-1 line-height-1-6 Title-desc'>{t('firewallRules.titleDescription')}</div>
                    </div>
                    {selected === 'rules' && <button onClick={() => { handleAddRule() }} className='button button--blue'>{t('firewallRules.addRule')}</button>}
                </div>
                <Tabs>
                    <Tabs.Header>
                        <ul>
                            <li>
                                <Link
                                    className={(selected === 'rules') && 'active'}
                                    onClick={() => setSelected('rules')} >
                                    {t('firewallDetails.rules')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={(selected === 'instances') && 'active'}
                                    onClick={() => setSelected('instances')}>
                                    {t('firewallDetails.instances')}
                                </Link>
                            </li>
                            {selectedFirewall ? <li>
                                <Link
                                    className={(selected === 'settings') && 'active'}
                                    onClick={() => setSelected('settings')}>
                                    {t('firewallDetails.settings')}
                                </Link>
                            </li> : null}
                        </ul>
                    </Tabs.Header>
                    <Tabs.Content>
                        {selected === 'rules' &&
                            <FirewallRulesList
                                rules={rules}
                                t={t}
                                handleRuleDelete={handleRuleDelete}
                                handleRuleEdit={handleRuleEdit}
                                selectedFirewall={selectedFirewall}
                                handleCreate={handleCreate}
                                setFirewallName={setFirewallName}
                                firewallName={firewallName}
                                isButtonDisabled={isButtonDisabled}
                                handleEdit={handleEdit}
                            />}
                        {selected === 'instances' &&
                            <FirewallInstances
                                rules={rules}
                                usedInstacesForRules={usedInstances}
                                setUsedInstances={setUsedInstances}
                                groupId={groupId}
                                t={t}
                            />}
                        {selected === 'settings' &&
                            <FirewallSettings
                                firewallName={firewallName}
                                setFirewallName={setFirewallName}
                                selectedFirewall={selectedFirewall}
                                t={t}
                            />}
                    </Tabs.Content>
                </Tabs>
            </div>}
    </div>)
}

export default CreateOrEditFirewall;
