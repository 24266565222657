export default {
  addKey: {
    title: 'Add public SSH key',
    description:
      'Copy your public SSH key and paste it in the space below. For instructions on how, follow the steps on the right.',
    placeholders: {
      content: 'SSH key content',
      name: 'Name',
    },
    button: 'Add SSH key',
    domain:"Domain Select",
    copy:'Copy',
  },
  details: {
    title: 'SSH keys',
    description:
      'To create or add SSH keys on Linux, MacOS & Windows follow these instructions. Windows users without OpenSSH can very easily  ',
    link: 'install and use PuTTY',
    instead: 'instead.',
    copy: 'copy',
    keySet: {
      title: 'Create a new key set, if needed',
      openTerminal: 'Open a terminal and run the command:',
      keygen: 'ssh-keygen',
      saveAndName: 'Then you will be prompted to save and name the new key.',
      genereting:
        'Generating public/private rsa key pair. Enter file in which to save the key (/Users/USER/.ssh/id_rsa):',
      confirmPassphase:
        'After you will be asked to create and confirm a passphrase for the key (this is highly recommended):',
      enterPassphase: 'Enter passphrase (empty for no passphrase): Enter same passphrase again:',
      generated:
        'This step will generate two files, by default called id_rsa and id_rsa.pub. Next, add this public key.',
      generatedFiles:
        'This step will generate two files, by default called id_rsa and id_rsa.pub. Next, add this public key.',
    },
    publicKey: {
      title: 'Adding the public key',
      description:
        'Copy then paste the contents of the .pub.pub file, typically id_rsa.pub, into the SSH key content field on the left.',
      placeholder: 'cat ~/.ssh/id_rsa.pub',
      forMoreDetails: 'For more detailed guidance, see',
      detailsLink: 'How to Add SSH Keys to Instances',
    },
  },
};
