export default {
  title: "Network",
  tabs: {
    networks: "Networks",
    extraIPs: "Extra IPs",
    loadBalancers: "Load Balancers",
    vpc: "VPC",
    firewalls: "Firewalls",
    ptr: "PTR records",
    publicIps: "Public IPs",
    nics: "NICs",
  },
  networks: {
    create: "+ Create VPC",
    successCopy: "Copied",
    title: "VPC Networks",
    description: "Resources assigned to the same VPC network can communicate securely with each other via private IP. Communication with resources outside the VPC must use a public network IP.",
    list: {
      name: "NAME",
      cidr: "CIDR",
      instances: "INSTANCES",
      state: "STATE",
    },
    countries: {
      "DE": "Germany",
      "UK": "United Kingdom",
      "RU": "Russia",
      "FI": "Finland",
      "CA": "Canada",
      "BR": "Brazil",
      "US": "USA",
      "FR": "France",
      "IN": "India",
      "SG": "Singapore",
      "JP": "Japan",
      "NL": "Netherlands",
      "HK": "Hong Kong",
    },
    detailsFull: "Details",
    fullDetails: {
      title: "Network Info",
      cidr: "CIDR",
      gateway: "Gateway",
      netmask: "Netmask",
      zonename: "Zone",
      state: "State",
      networkDomain: "Network Domain",
      dns: "DNS",
      type: "Type",
    },
    emptyDescription: "A Virtual Private Cloud is a dedicated environment that is hosted by a public cloud platform allowing multiple isolated networks to communicate on a highly secure infrastructure.",
    edit: "Edit",
    delete: "Delete",
    noNetworks: "This services does not have a private network included.",
    deleteModal: {
      title: "Delete VPC",
      confirm: "Destroy",
      cancel: "Cancel",
      success: "Successfully deleted",
      yourVPC: "Your VPC",
      warningRed: " permanently destroyed.",
      warning1: "Any associated Instances will be disconnected and will stop receiving distributed traffic while the Instances will not be destroyed.",
      warning2: "You will lose the provisioned IP address, which might impact any DNS records pointing to it. This will not affect any associated Instances."
    },
    details: {
      resources: "Resources",
      edit: "Edit",
      delete: "Delete",
      info: "Network Info",
      name: "Name",
      gateway: "Gateway",
      netmask: "Netmask",
      cidr: "CIDR",
      dns1: "DNS1",
      dns2: "DNS2",
      zone: "Zone",
      type: "Type",
      state: "State",
      instance: "INSTANCE",
      loadBalancer: "LOAD BALANCER",
      publicIp: "PUBLIC IP",
      privateIp: "PRIVATE IP",
      cpu: "vCPUs",
      ram: "RAM",
      deleteDisabled: "You cannot delete a VPC with assigned instances.",
    },
    editModal: {
      title: "Edit Network",
      cidr: "CIDR",
      displaynetwork: "Display Network",
      displaytext: "Display Text",
      guestvmcidr: "Guest VM CIDR",
      name: "NAME",
      description: "DESCRPTION",
      networkdomain: "Network Domain",
      networkoffering: "Network Offerring",
      cancel: "Cancel",
      confirm: "Apply changes",
      success: "Network successfully changed",
      error: "Somethig went wrong",
      placeholderName: "The new name for the network",
      placeholderCidr: "Example: 10.0.0.0/16",
      placeholderDomain: "Example, mynetwork.domain",
      placeholderDescription: "Something",
    },
    createModal: {
      name: "NAME",
      title: "Create network",
      placeholderName: "The new name for the network",
      selectDomain: "Select project",
      networkDomain: "Network Domain",
      networkDomainPlaceholder: "Example:mynetwork.domain",
      networkoffering: "Network Offerring",
      vpc: "VPC",
      description: "DESCRIPTION",
      placeholderDescription: "Enter network’s description",
      region: "REGION",
      placeholderRegion: "Select region",
      gateway: "Gateway",
      gatewayPlaceholder:
        "The gateway of the tier in super cidr range and not overlapping tier",
      netmask: "Netmask",
      netmaskPlaceholder:
        "Netmask of the tier. For example, with VPC CIDR of 10.0.0.0.16",
      cancelButton: "Cancel",
      confirmButton: "Create Network",
      success: "Succeffully created a network",
      error: "Somethig went wrong",
      createErrorVPC: "VPC is required for this type of network",
      noDomains: "There are no available domains",
    },
  },
  extraIp: {
    title: "Assign an Extra IP",
    descriptionPart1:
      "An Extra IP is a static IP address that points to one of your Instances. It allows you to redirect network traffic to any of your Instances in the same datacenter.",
    descriptionPart2:
      "For instance, if your primary Instance goes offline, you can point your Extra IP to a backup Instance. Extra IPs are tied to the datacenters and each costs ",
    descriptionBold: "$2.5/month/IP.",
    link: "click here",
    list: {
      title: "Extra IPs",
      ip: "IP",
      instance: "INSTANCE",
      cpu: "vCPUs",
      ram: "RAM",
      sourceNat: "Source NAT",
      staticNat: "Floating IP",
      network: "Network",
      portForwarding: "Port Forwarding",
      releaseIp: "Release IP",
      createBalancer: "Create rule balancer",
      disableNat: "Disable Floating IP",
      enableNat: "Enable Floating IP",
      withVpc: "With VPC",
      placeholder: "Assign to a instance",
      assigning: "Assigning IP...",
      unassigning: "Unassigning IP...",
    },
    emptyExtraIP: {
      description:
        "An Extra IP is a static IP address that points to one of your Instances. It allows you to redirect your network traffic to any of your Instances.",
      learnMore: "Learn more",
      button: "+ Assign Extra IP",
    },
    locations: {
      countries: {
        "DE": "Germany",
        "UK": "United Kingdom",
        "RU": "Russia",
        "FI": "Finland",
        "CA": "Canada",
        "BR": "Brazil",
        "US": "USA",
        "FR": "France",
        "IN": "India",
        "SG": "Singapore",
        "JP": "Japan",
        "NL": "Netherlands",
        "HK": "Hong Kong"
      }
    },
    assignModal: {
      selectRegion: "Select Region",
      selectInstance: "Select Instance",
      cancel: "Cancel Operation",
      assign: "+ Assign Extra IP",
      selectPlaceholder: "Search for Instance",
    },
    deleteModal: {
      title: "Delete Extra IP",
      yourLB: "Your Load Balancer will be",
      warningRed: " permanently destroyed.",
      warning1: "Any associated Instances will be disconnected and will stop receiving distributed traffic while the Instances will not be destroyed.",
      warning2: "You will lose the provisioned IP address, which might impact any DNS records pointing to it. This will not affect any associated instances.",
      cancel: "Cancel",
      destroy: "Destroy",
    },
    reassignKey: "Reassign",
    unassignKey: "Unassign",
    deleteKey: "Delete",
  },
  nics: {
    button: "Assign Private IP",
    addButton: "Add network to VM",
    network: "Network",
    netmask: "Netmask",
    gateway: "Gateway",
    mac: "MAC",
    secondaryIps: " Secondary IPs",
    ip: "IP",
    type: "Type",
    delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    firstTitle: "Assign secondary IP to:",
    secondTitle: "Assign public IP",
    deleteModal: {
      title: "Delete a secondary IP address from a NIC",
      delete: "Delete",
      cancel: "Cancel",
      success: "Successfully deleted",
    },
    assignModal: {
      success: "Successfully assigned",
    },
    addModal: {
      success: "Successfully added",
      title: "Adds VM to specified network by creating a NIC",
      noNetworks: "There are no available networks",
    },
  },
  loadBalancer: {
    noLoadBalancers: {
      title: "You don’t have any Load Balancers",
      description:
        "Load Balancers let you distribute traffic between multiple Instances and are a great way to horizontally scale your app. ",
      button: "Create Load Balancer",
    },
    more: {
      configure: "Configure",
      addVm: "Add VMs",
      removeVm: "Remove VMs",
      edit: "Edit",
      editTags: "Edit tags",
      delete: "Delete",
    },
    status: "Status",
    ipAddress: "IP",
    protocol: "Protocol",
    algorithm: "Algorithm",
    privPort: "Private Port",
    pubPort: "Public Port",
    healthy: "Healthy",
    name: "Name",
    size: "Size",
    created: "Created",
    viewInstances: "View Instances",
    viewGraph: "View Graph",
    editSettings: "Edit settings",
    destroy: "Destroy",
    instances: "Instances",
    oneInstance: "Instance",
    goBack: " back to load balancers",
    requestsPerSecond: "HTTP Requests per second",
    connections: "Connections",
    responses: "HTTP Responses",
    trafficReceived: "Traffic Received/Sent",
    sessionDuration: "HTTP Total Session Duration",
    averageResponse: "HTTP Average Response Time",
    learn: {
      title: "Basics for Load balancing",
      overview: {
        link: "Load Balancer overview",
        description: "Learn about ShapeHost Load Balancers, or follow ",
        descriptionLink: "our step-by-step guide to creating one.",
      },
      api: {
        link: "API Docs",
        description:
          "Use the ShapeHost API to create and manage Load Balancers programmatically.",
      },
      feedback: {
        link: "Tell us what you think",
        description: "Submit your feedback on Load Balancers.",
      },
    },
    instancesTab: {
      ipAddresses: "IP Addresses",
      downtime: "Downtime",
      queue: "Queue",
      healthCheck: "Health Checks",
      button: "Add Instances",
      healthyIntances: " Healthy Instances",
      viewInstance: "View instance",
      remove: "Remove",
    },
    settings: {
      size: "Size",
      forwardRules: "Forwarding rules",
      algorithm: "Algorithm",
      healthChecks: "Health checks",
      stickySession: "Sticky sessions",
      ssl: "SSL",
      proxyProtocol: "Proxy Protocol",
      backendKeepalive: "Backend Keepalive",
      destroy: "Destroy",
      destroyDescription:
        "Your Load Balancer will be permanently destroyed. Any associated Instances will be disconnected and will stop receiving distributed traffic. Instances will not be destroyed.",
      edit: "Edit",
      resize: "Resize",
      save: "Save",
      cancel: "Cancel",
      onPort: "on port",
      sizeDetails: {
        resizeText:
          "Resizing your Load Balancer will maintain the IP and current configuration, and you will be billed according to the new price-per-hour. More details can be found in the product docs.",
        productDocs: "product docs",
        currentSize: "Current size",
        month: "Month",
        small: "Small",
        medium: "Medium",
        large: "Large",
        smallDescription:
          "best for: Static websites and blogs with low to moderate traffic.",
        mediumDescription:
          "best for: Transaction-driven sites or services with moderate traffic.",
        largeDescription:
          "best for: Busy sites, endpoints where high performance is a must.",
      },
      destroyModal: {
        title: "Destroy load balancer",
        description:
          "Your Balancer will be permanently destroyed. Any associated Instances will be disconnected and will stop receiving distributed traffic. Instances will not be destroyed. You will lose the provisioned IP address, which might impact any DNS records pointing to it. This will not affect any associated Instances.",
        confirmDescription:
          "Confirm you want to permanently destroy this load balancer by entering its name below.",
        confirm: "Confirm",
        cancel: "Cancel",
        placeholder: "Enter the name of this Load Balancer to delete it",
      },
    },
    tabs: {
      instances: "Instances",
      graphs: "Graphs",
      settings: "Settings",
    },
    assignModal: {
      title: "Assing VM to Load balancer rule",
      update: "Update",
      cancel: "Cancel",
      list: {
        name: "Name",
        state: "State",
        displayName: "Display Name",
        account: "Account",
        zone: "Zone",
        select: "Select",
      },
    },
    removeModal: {
      title: "Remove VM from Load Balancer rule",
      list: {
        name: "Name",
        ipAddress: "IP Address",
        remove: "Remove",
      },
    },
    editModal: {
      name: "Name",
      namePlaceholder: "Enter new name for rule",
      protocol: "Select Protocol",
      algorithm: "Select algorithm",
      title: "Edit Load Balancer rule",
      update: "Update",
      cancel: "Cancel",
    },
  },
  firewalls: {
    emptyFirewalls: {
      description:
        "Firewalls allow you to easily secure your infrastructure by explicitly defining which type of traffic is allowed to reach it.",
      button: "+ Create Firewall",
    },
    firewallsList: {
      title: "Firewall Rules",
      description: "Firewalls allow you to easily secure your infrastructure by explicitly defining which type of traffic is allowed to reach it. Use tags to organize your infrastructure and apply Firewall rules to multiple resources.",
      button: "+ Create Firewall",
      name: "NAME",
      instances: "INSTANCES",
      rules: "RULES",
      created: "CREATED",
      configureKey: "Configure",
      deleteKey: "Destroy",
    },
    firewallDetails: {
      rules: "Rules",
      instances: "Instances",
      settings: "Settings",
    },
    firewallRules: {
      title: "Create Firewall",
      titleDescription: "In this section you can set the Firewall rules for incoming traffic. Only the specified ports will accept inbound connections. All other traffic will be dropped.",
      addRule: "+ Create New Rule",
      name: "Name",
      description: "Choose a Firewall name",
      placeholder: "Firewall Name",
      save: "Save",
      saveRules: "Save Firewall Rules",
      protocol: "PROTOCOL",
      portRange: "PORT RANGE",
      source: "SOURCE",
      deleteKey: "Delete",
      editKey: "Edit",
      selectPlaceholder: "Search for Instances, Load Balancers, or enter an IP",
      type: "ICMP TYPE",
      code: "ICMP CODE",
    },
    firewallInstances: {
      instance: "INSTANCE",
      publicIp: "PUBLIC IP",
      os: "OS",
      region: "REGION",
      more: "More",
      unassign: "Unassign",
      placeholder: "Assign instances to your firewall rules",
      assignSuccess: "Successfully Assigned",
      unassignSuccess: "Successfully Unassigned",
    },
    list: {
      ram: "RAM",
      cpu: "vCPUs",
      unassign: "Unassigning",
      assign: "Assigning"
    },
    firewallSettings: {
      name: "Name",
      description: "Edit Firewall name",
      placeholder: "Firewall Name",
      save: "Save",
      destroy: "Destroy",
      destroyDescription: "This is irreversible. Your Firewall will be destroyed, and any Droplets will be dissociated from them.",
      editSuccess: "Successfully Edited",
    },
    deleteModal: {
      titleGroup: "Delete Firewall",
      titleRule: "Delete Firewall Rule",
      warning1: "This is irreversible. Your Firewall will be destroyed, and any Instances will be dissociated from them.",
      warning2: " Once this happens, the Instances will allow any type of inbound and outbound traffic, unless you’ve configured a software firewall in them.",
      cancel: "Cancel",
      delete: "Delete",
      deleteSuccess: "Successfully Deleted",
    },
    ip: "IP",
    addRule: "Add Rule",
    sourceCidr: "Source CIDR",
    destinationCidr: "Destination CIDR",
    inboundTitle: "Inbound Rules",
    outboundTitle: "Outbound Rules",
    noInbound: "There are no Inbound rules yet",
    noOutbound: "There are no Outbound rules yet",
    protocol: "Prototocol",
    startPort: "Start Port/ICMP",
    endPort: "End Port/ICMP",
    icmpType: "ICMP Type",
    icmpCode: "ICMP Code",
    state: "State",
    viewInstances: "View Instances",
    viewRules: "View Rules",
    delete: "Delete",
    tags: "Add tags",
  },
  ptrRecords: {
    title: "PTR records",
    description:
      "To update your own PTR records please update your Instances's hostname through the control panel.",
    ip: "IP Address",
    name: "PTR Record",
    noPtrRecords: {
      title: "PTR records",
      description: "Currently you have no PTR records.",
      createOnRename:
        "We'll create a PTR record for a server when you rename the host Instance to the fully qualified domain name of a domain you are managing on your account.",
    },
  },
  vpc: {
    title: "VPC Networks",
    description:
      "Resources assigned to the same VPC network can communicate securely with each other via private IP. Communication with resources outside the VPC must use a public network IP.",
    whyUse: "How should I use VPC networks?",
    button: "Create VPC Network",
    aclButton: "Create ACL List",
    aclTitle: "ACL List",
    aclName: "Name",
    aclDescription: "Description",
    aclDelete: "Delete",
    aclDeleteTitle: "Delete ACL list",
    cancel: 'Cancel',
    edit: "Edit",
    delete: "Delete",
    restart: "Restart",
    noVpc: {
      description: "No vpc records yet",
    },
    learnAbout: {
      title: "Learn more about VPC Networks",
      productDocs: {
        title: "PRODUCT DOCS",
        link: "VPC Networks overview",
        description:
          "Information on creating and managing Virtual Private Cloud (VPC) Network.",
      },
      tutorials: {
        title: "TUTORIALS",
        link: "Gateways and more",
        description:
          "Learn to configure instances as public gateways, and other tasks for VPC Networks",
      },
      api: {
        title: "API",
        link: "VPC Network API Docs",
        description:
          "Use the API to automate and configure private communication",
      },
    },
    list: {
      name: "Name",
      state: "State",
      cidr: "CIDR",
      zone: "Zone",
    },
    createModal: {
      title: "Create VPC",
      cidr: "CIDR",
      cidrPlaceholder: "Example 10.110.1.1/24",
      name: "Name",
      vpcOffering: "VPC Offering",
      region: "Region",
      networkDomain: "Network Domain",
      confirm: "Create VPC",
      cancel: "Cancel",
      success: "VPC was successfully created",
      domain: "Select project",
    },
    deleteModal: {
      title: "Delete VPC",
      confirm: "Delete",
      cancel: "Cancel",
      success: "VPC was successfully deleted",
    },
    editModal: {
      title: "Edit VPC",
      name: "Name",
      placeholderName: "The new name for the VPC",
      confirm: "Apply changes",
      cancel: "Cancel",
      success: "VPC was successfully changed",
    },
    restartModal: {
      title: "Restart VPC",
      description: "Please confirm that you want to restart the VPC.",
      remark:
        "Remark: making a non-redundant VPC redundant will force a clean up.The networks will not be available for a couple of minutes.",
      clean: "Clean up",
      cleanTooltip: "If cleanup old network elements.",
      redundant: "Make redundant",
      redundantTooltip: "Turn a single VPC into a redundant one.",
      cancel: "Cancel",
      restart: "Restart",
      success: "VPC was successfully restarted",
    },
    aclList: {
      title: "ACL list rules",
      number: "Rule number",
      cidr: "CIDR List",
      action: "Action",
      protocol: "Protocol",
      startPort: "Start Port",
      endPort: "End Port",
      trafficType: "Traffic Type",
      edit: "Edit",
      tags: "Add tags",
      delete: "Delete",
      addRule: "+ Add Rule",
    },
    editAclRule: {
      title: "Edit ACL Rule",
      success: "ACL rule was successfully changed"
    },
    deleteAclRule: {
      title: "Delete ACL Rule",
      success: "ACL Rule was deleted",
    },
    createAclList: {
      title: "Create ACl List",
      success: "ACL list was created",
      name: "Name",
      vpc: "Select VPC",
      desc: "Description",
      createBtn: "Create ACL list"
    },
    createAclRule: {
      title: "Add ACL rule",
      cidr: "CIDR List:",
      action: "Action:",
      protocol: "Protocol:",
      startPort: "Start Port:",
      endPort: "End Port:",
      icmpCode: "ICMP code",
      icmpType: "ICMP type",
      allProtocolError: "Ports should be null for ALL protocol",
      trafficType: "Traffic Type:",
      description: "Description:",
      ok: "OK",
      cancel: "Cancel",
      portError: "Ivalid Port Value. Valid values is 1-65535",
      icmpError: "Invalid ICMP/Type or ICMP/Code value. Valid values is 1-14",
      reasonPlaceholder: "Enter the reason behind an ACL rule.",
      success: "ACL rule was successfully added",
    },
  },
  publicIps: {
    button: "Assign Public IP",
    ip: "IP",
    type: "Type",
    delete: "Delete",
  },
};
