import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, Link } from 'react-router-dom';
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  getLocationsRequest,
  createLbGroupRequest,
  createLbRuleRequest,
  assignRuleToGroupRequest,
  assignInstancetoLbRuleRequest,
  unassignInstanceFromLbRuleRequest,
  deleteLbGroupRequest,
  updateLbGroupRequest,
  updateLbRuleRequest,
  deleteLbRuleRequest,
} from "../actions";
import { getIpAddressesRequest, getLBGroupsRequest } from "../../Dashboard/actions";
import { v4 } from "uuid";
import Loader from "../../Volumes/components/Loader/Loader";
import ForwardRules from "./components/ForwardRules";
import ConcludeAndCreate from "./components/ConcludeAndCreate";
import Locations from "../CreateInstance/components/ChooseLocations";
import SelectFloatingIp from "../../../components/SelectFloatingIp";
import BackIco from "../../../assets/images/arrow-left-blue.svg";
import IconIP from "../../../assets/images/IP-Icon.svg"

const CreateOrEditLoadBalancer = () => {
  const { addToast } = useToasts();
  const { t } = useTranslation("createLoadBalancer");
  const navigate = useHistory();
  const translationsByComponent = (component) => (text) => t(`${component}.${text}`);
  const dispatch = useDispatch();
  const [selectedIp, setSelectedIp] = useState(null);
  const [nameLb, setNameLb] = useState(null);
  const [loading, setLoading] = useState(false);
  const { floatingIpList, instances, lbGroups, lbGroupsLoading } = useSelector(({ instances }) => instances); //eslint-disable-line
  const { selectedRegion } = useSelector(({ products }) => products);

  const [rules, setRules] = useState([
    {
      name: '',
      protocol: "tcp",
      privPort: null,
      pubPort: null,
      algorithm: "roundrobin",
      id: v4(),
      instances: [],
      type: "new",
    }
  ]);

  const { groupid } = useParams();

  useEffect(() => {
    const onMount = async () => {
      await getProducts({ params: {} })(dispatch);
      await getLocationsRequest()(dispatch);
      if (floatingIpList.length === 0) getIpAddressesRequest()(dispatch);
      if (lbGroups.length === 0) getLBGroupsRequest()(dispatch);
    };
    onMount();
  }, []); //eslint-disable-line

  const loadBalancerGroup = useMemo(() => {
    if (lbGroups.length && groupid) {
      const lbGroup = lbGroups.find((group) => { return group.id.toString() === groupid });
      setRules(lbGroup?.rules);
      setNameLb(lbGroup?.name);
      return lbGroup;
    }
    return null;
  }, [lbGroups, groupid]);

  const isButtonDisabled = useMemo(() => {
    if (loadBalancerGroup) {
      if (!nameLb) {
        return true;
      }
    } else {
      if (!selectedIp || !nameLb) {
        return true;
      }
    }

    return rules.some(rule => !rule.name || !rule.publicport || !rule.privateport)

  }, [selectedIp, rules, nameLb]);

  useEffect(() => {
    if (!groupid) {
      const rulesCopy = rules;
      rulesCopy.forEach(rule => {
        rule.instances = [];
        rule.name = '';
        rule.protocol = "tcp";
        rule.privateport = '';
        rule.publicport = '';
        rule.algorithm = "roundrobin";
      })
      setSelectedIp(null);
      setRules([...rulesCopy]);
      setNameLb('');
    }
  }, [selectedRegion]);


  const extraIps = useMemo(() => {
    if (floatingIpList.length && selectedRegion) {
      const usedExtraIpIds = lbGroups.map(group => group.publicipid);
      const filteredByStaticNat = floatingIpList.filter(extraIp => extraIp.isstaticnat === false && !usedExtraIpIds.includes(extraIp.id));

      return filteredByStaticNat.filter(({ zonename }) => zonename === selectedRegion.name);
    }
  }, [floatingIpList, selectedRegion]);

  const handleAddRules = () => {
    setRules((prev) => [
      ...prev,
      {
        name: null,
        protocol: "tcp",
        privateport: null,
        publicport: null,
        algorithm: "roundrobin",
        id: v4(),
        instances: [],
        type: "new",
      }
    ]);
  };

  const handleRuleEdit = (editedRule) => {
    const newRules = [];
    rules.forEach((rule) => {
      if (rule.id === editedRule.id) {
        newRules.push({ ...editedRule })
      } else {
        newRules.push(rule);
      }
    })

    setRules(newRules);
  }

  const handleDeleteRule = (ruleId) => {
    setRules((prev) => prev.filter(({ id }) => ruleId !== id));
  };
  const handleChangeRules = (e, id) => {
    if (e === "udp" || e === "tcp-proxy" || e === "tcp") {
      setRules((prev) => prev.map((rule) => (rule.id === id ? { ...rule, protocol: e } : rule)));
    } else if (e === "leastconn" || e === "roundrobin" || e === "source") {
      setRules((prev) => prev.map((rule) => (rule.id === id ? { ...rule, algorithm: e } : rule)));
    } else {
      const { name, value } = e.target;
      setRules((prev) => prev.map((rule) => (rule.id === id ? { ...rule, [name]: value } : rule)));
    }
  };
  const handleChangeInstances = (ruleId, instances) => {
    setRules((prevRules) => {
      const rulesCopy = [...prevRules];
      const choosenRuleIndex = rulesCopy.findIndex(({ id }) => (id === ruleId));
      const choosenRule = { ...rulesCopy[choosenRuleIndex], instances, type: rulesCopy[choosenRuleIndex].type || 'edit' };
      rulesCopy.splice(choosenRuleIndex, 1, choosenRule);
      return rulesCopy
    })
  }

  const createRuleWithInstances = async (rule, publicipid, networkid, zoneid, accountId, groupId) => {
    const { name, algorithm, protocol, publicport, privateport } = rule;

    const result = await createLbRuleRequest({
      name: name,
      algorithm: algorithm,
      protocol: protocol,
      description: name,
      publicport: publicport,
      privateport: privateport,
      publicipid: publicipid,
      networkid: networkid,
      zoneid: zoneid,
      accountId: accountId,
    })(dispatch)

    await assignRuleToGroupRequest({
      accountid: accountId,
      rule_id: result.data.data.id,
      description: nameLb,
      groupid: groupId,
    })(dispatch)

    if (rule.instances && rule.instances.length) {
      const instanceIds = rule.instances.map(instance => instance.id);

      await assignInstancetoLbRuleRequest({
        accountId: accountId,
        ruleId: result.data.data.id,
        instanceIds: instanceIds
      })(dispatch)
    }
  }

  const handleCreate = async () => {
    if (validatePorts().length) {
      return addToast(validatePorts().join(), {
        appearance: "error",
        autoDismiss: true
      });
    }
    setLoading(true);
    const { accountId, networkid, id, zoneid } = selectedIp;
    let groupid;
    try {
      const groupId = await createLbGroupRequest({
        accountid: accountId,
        networkid: networkid,
        description: nameLb,
        name: nameLb,
      })(dispatch);
      groupid = groupId;

      for (const rule of rules) {
        await createRuleWithInstances(rule, id, networkid, zoneid, accountId, groupId);
      }
      navigate.push('/load-balancers');
      getLBGroupsRequest()(dispatch);
      addToast("Created", {
        appearance: "success",
        autoDismiss: true
      });
      setLoading(false);
    } catch (error) {
      if (groupid) {
        await deleteLbGroupRequest({ accountid: accountId, groupid: groupid })(dispatch);
      }
      setLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  }

  const handleEdit = async (loadBalancerGroup) => {
    if (validatePorts().length) {
      return addToast(validatePorts().join(), {
        appearance: "error",
        autoDismiss: true
      });
    }
    const { id, networkid, accountid, publicipid, zoneid } = loadBalancerGroup;
    setLoading(true);
    try {
      const deletedRulesIds = [];
      const initialRules = loadBalancerGroup.rules;
      const initialRulesIds = initialRules.map(rule => rule.id);
      const ruleIds = rules.map(rule => rule.id);
      initialRulesIds.forEach(id => {
        if (!ruleIds.includes(id)) {
          deletedRulesIds.push(id);
        }
      })

      for (const rule of initialRules) {
        if (deletedRulesIds.includes(rule.id)) {
          await deleteLbRuleRequest({ accountId: accountid, id: rule.intermediateId })(dispatch);
        }
      }

      for (const rule of rules) {
        if (rule.type === 'edit') {
          const deleteInstancesIds = [];
          const newInstancesIds = [];
          const initialInstanses = initialRules.find(initialRule => initialRule.id === rule.id).instances;
          const initialInstansesIds = initialInstanses.map(instance => instance.id);
          const instanceIds = rule.instances.map(instance => instance.id);
          if (initialInstansesIds.length) {
            initialInstansesIds.forEach(id => {
              if (!instanceIds.includes(id)) {
                deleteInstancesIds.push(id);
              }
            })
          }

          if (instanceIds.length) {
            instanceIds.forEach(id => {
              if (!initialInstansesIds.includes(id)) {
                newInstancesIds.push(id);
              }
            })
          }

          if (newInstancesIds.length) {
            await assignInstancetoLbRuleRequest({
              accountId: accountid,
              ruleId: rule.id,
              instanceIds: newInstancesIds
            })(dispatch);
          }

          if (deleteInstancesIds.length) {
            //change this once the API will be fixed, to send all Instance Ids by one string
            for (const instanceId of deleteInstancesIds) {
              await unassignInstanceFromLbRuleRequest({
                accountId: accountid,
                ruleId: rule.id,
                instanceIds: [instanceId]
              })(dispatch);
            }
          }

          await updateLbRuleRequest({
            accountId: accountid,
            algorithm: rule.algorithm,
            protocol: rule.protocol,
            id: rule.id,
            name: rule.name,
          })(dispatch);
        }

        if (rule.type === 'new') {
          await createRuleWithInstances(rule, publicipid, networkid, zoneid, accountid, id);
        }
      }
      await updateLbGroupRequest({
        id: id,
        accountid: accountid,
        name: nameLb,
        networkid: networkid,
      })(dispatch);
      await getLBGroupsRequest()(dispatch);
      addToast("Updated", {
        appearance: "success",
        autoDismiss: true
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  }

  const validatePorts = () => { //eslint-disable-line
    const errors = [];
    rules.forEach(({ privateport, publicport }, i) => {
      if (publicport < 1 || publicport > 65535) {
        errors.push(`Ivalid public port value for ${i + 1} rule`);
      }
      if (privateport < 1 || privateport > 65535) {
        errors.push(`Ivalid private port value for ${i + 1} rule`);
      }
    });
    return errors;
  };

  const getPageHeader = () => {
    return !loadBalancerGroup ? (<div>
      <h2 className="title m-t-30">{t("title")}</h2>
      <p className="text-grey-1 font-size-16 m-t-25">{t("description")}</p>
    </div>) :
      (<div >
        <Link to="/load-balancers" className="flex flex-align-items-center m-t-25 cursor-pointer"><img src={BackIco} className="m-r-15" /><div className="text-blue-1 font-size-36 font-weight-light">{t("titleConfigure")}</div></Link>
        <p className="text-grey-1 font-size-16 m-t-25">{t("descriptionConfigure")}</p>
      </div>)
  }

  const getLocationHeader = () => {
    return (<div>
      <div className="font-size-27 text-grey-3 m-t-40 ">{loadBalancerGroup ? t("locationTitleConfig") : t("locationTitle")}</div>
      <div className="text-grey-1 font-size-16 m-t-25 m-b-25">{t("locationDescription")}</div>
    </div>)
  }

  const isLoadBalancer = useMemo(() => {
    const zoneIds = instances?.map(instance => instance.zoneid);
    const defaultZoneId = loadBalancerGroup ? loadBalancerGroup.zoneid : undefined
    const type = loadBalancerGroup ? 'edit' : 'create';

    return { type: type, disabledLocationIds: zoneIds, defaultZoneId: defaultZoneId };
  }, [loadBalancerGroup, instances]);

  return (
    <div className="Create CreateLoadBalancer">
      {lbGroupsLoading ?
        <div className="width-full-width flex flex-justify-content-space-around m-t-180">
          <Loader />
        </div> :
        <div className="container">
          {getPageHeader()}
          {getLocationHeader()}
          <Locations t={translationsByComponent("choosePlan")} isLoadBalancer={isLoadBalancer} />
          <>
            <label className="text-grey-2 text-align-left font-size-27 font-weight-regular display-block m-b-25 m-t-40">
              {t("chooseIP.loadBalancerIp")}
            </label>
            {loadBalancerGroup ?
              <div className="Disabled_IP"><img src={IconIP} />{loadBalancerGroup.publicip}</div> :
              <SelectFloatingIp ipList={extraIps} t={t} onChange={(selected) => setSelectedIp(selected)} selectedIp={selectedIp} />
            }
          </>
          <ForwardRules
            t={translationsByComponent("forwardRules")}
            rules={rules}
            handleAddRules={handleAddRules}
            handleChangeRules={handleChangeRules}
            handleDeleteRule={handleDeleteRule}
            handleRuleEdit={handleRuleEdit}
            handleChangeInstances={handleChangeInstances}
            loadBalancer={loadBalancerGroup}
          />
          <ConcludeAndCreate t={translationsByComponent("concludeAndCreate")} setNameLb={setNameLb} nameLb={nameLb} isCreate={loadBalancerGroup ? false : true} />
          <button
            className="button button--blue m-t-30 m-b-50 width-full-width flex"
            onClick={async () => loadBalancerGroup ? await handleEdit(loadBalancerGroup) : await handleCreate()}
            disabled={isButtonDisabled}
          >
            {loading ? <Loader /> : loadBalancerGroup ? t("buttonSave") : t("button")}
          </button>
        </div>}
    </div>
  );
};

export default CreateOrEditLoadBalancer;
