import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SelectWithIcons from "../SelectWithIcons";
import IconIP from "../../assets/images/IP-Icon.svg"
import ConfigIco from "../../assets/images/config-icon.svg";

const ExtraSection = () => {
  const { t } = useTranslation("createLoadBalancer");

  return (<div className="p-l-20 Extra_section">
    <Link className="flex flex-align-items-center" to="/vpc-network/extra-ips">
      <img src={ConfigIco} />
      <span className="font-size-16 text-blue-1 font-weight-semibold m-l-10">{t('addIP')}</span>
    </Link>
  </div>)
}

const SelectFloatingIp = ({ ipList, onChange, t }) => {
  const [selectedIp, setSelectedIp] = useState({text: t('selectExtraIp')});
  const { selectedRegion } = useSelector(({ products }) => products);

  useEffect(() => {
      setSelectedIp({text: t('selectExtraIp')});
  }, [selectedRegion]);

  const options = ipList &&
    ipList.map(({ id, ipaddress, ...props }) => ({
      value: id,
      text: ipaddress,
      icon: IconIP,
      ...props,
    }))

  const handleIpSelect = (option) => {
    setSelectedIp(option);
    const [selectedIp] = ipList.filter(({ id }) => id === option.value);
    onChange && onChange(selectedIp);
  }

  return (
    <div className="m-b-40">
      <SelectWithIcons
        options={options}
        ExtraSection={ExtraSection}
        onChange={handleIpSelect}
        defaultOption={selectedIp}
        className={options?.length ? "Select_extraIP" : "Select_extraIP_noOptions"}
      />
    </div>
  );
};

export default SelectFloatingIp;
