import React from "react";
import { Link } from "react-router-dom";
import noBalanceImg from "../../../../../assets/images/network/no-load-balance.svg";

const NoLoadBalancers = ({ t }) => (
  <div className="LoadBalancers flex card border-grey-1 max-width-full-width">
    <img className="m-r-30" src={noBalanceImg} alt="" />
    <div>
      <h2 className="font-weight-regular text-grey-3 font-size-27">
        {t("noLoadBalancers.title")}
      </h2>
      <p className="text-grey-1 font-size-16">
        {t("noLoadBalancers.description")}{" "}
      </p>
      <Link to="/create-load-balancer">
        <button className="button button--blue button--md m-t-20" href="">
          {t("noLoadBalancers.button")}
        </button>
      </Link>
    </div>
  </div>
);

export default NoLoadBalancers;
