import React from 'react';
import Slider from 'react-slick';

import { useSelector } from 'react-redux';

import Card from '../../../../../components/Card';
import engFlag from '../../../../../assets/images/chooseLocations/eng-flag.svg';
import genFlag from '../../../../../assets/images/chooseLocations/ger-flag.svg';
import fraFlag from '../../../../../assets/images/chooseLocations/fra-flag.svg';
import nthFlag from '../../../../../assets/images/chooseLocations/nth-flag.svg';
import spaFlag from '../../../../../assets/images/chooseLocations/spa-flag.svg';
import porFlag from '../../../../../assets/images/chooseLocations/por-flag.svg';
import denFlag from '../../../../../assets/images/chooseLocations/den-flag.svg';

const icons = [
    {
        country: 'United States',
        icon: engFlag
    },
    {
        country: 'United States',
        icon: engFlag
    },
    {
        country: 'United States',
        icon: engFlag
    },
    {
        country: 'United States',
        icon: engFlag
    },
    {
        country: 'Germany',
        icon: genFlag
    },
    {
        country: 'France',
        icon: fraFlag
    },
    {
        country: 'Netherlands',
        icon: nthFlag
    },
    {
        country: 'Spain',
        icon: spaFlag
    },
    {
        country: 'Portugal',
        icon: porFlag
    },
    {
        city: 'Oslo',
        country: 'Denmark',
        icon: denFlag
    }
];

const Locations = ({
    t,
    filter = 'allLocations',
    createInstanceAction,
    dispatch,
    state: { createInstance: { location = {} } = {} } = {}
}) => {
    const { locations } = useSelector((state) => state.products);
    const filtredLocations = locations
        .filter(({ continent }) => filter === continent || filter === 'allLocations')
        .map((location) => {
            const [iconByCountry] = icons.filter(({ country }) => country === location.country);
            const { icon } = iconByCountry;
            return {
                ...location,
                icon
            };
        });
    return (
        <div className='Locations'>
            <div className='flex row hidden-xs hidden-xs-flex'>
                {filtredLocations.length ? (
                    filtredLocations.map(({ city, country, continent, icon }) => (
                        <Card
                            key={`${city}${country}`}
                            className='Card--title-bottom Card--full-border width-full-width p-l-15 p-r-15 m-b-25'
                        >
                            <Card.Body
                                onClick={() =>
                                    createInstanceAction({
                                        location: { city, country, continent, icon }
                                    })(dispatch)
                                }
                                className={`flex-align-items-center text-grey-4 ${city === location.city && 'active'}`}
                            >
                                <img className='m-r-10 m-l-15' src={icon} alt='' />
                                <div>
                                    <div className='text-grey-2'>{city}</div>
                                    <div className='text-grey-1 font-size-12'>{country}</div>
                                </div>
                            </Card.Body>
                        </Card>
                    ))
                ) : (
                    <p className='text-grey-1 font-size-16 font-weight-light p-l-15'>{t('locations.noLocations')}</p>
                )}
            </div>
            <div className='row visible-xs m-b-40'>
                <Slider
                    {...{
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    dots: true,
                                    centerPadding: 50,
                                    centerMode: true,
                                    arrows: false
                                }
                            }
                        ]
                    }}
                >
                    {filtredLocations.length ? (
                        filtredLocations.map(({ city, country, continent, icon }) => (
                            <Card
                                key={`${city}${country}`}
                                className='Card--title-bottom Card--full-border width-full-width p-l-10 p-b-10 p-r-10'
                            >
                                <Card.Body
                                    onClick={() =>
                                        createInstanceAction({
                                            location: { city, country, continent, icon }
                                        })(dispatch)
                                    }
                                    className={`flex-align-items-center text-grey-4 ${
                                        city === location.city && 'active'
                                    }`}
                                >
                                    <img className='m-r-10 m-l-15' src={icon} alt='' />
                                    <div>
                                        <div className='text-grey-2'>{city}</div>
                                        <div className='text-grey-1 font-size-12'>{country}</div>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))
                    ) : (
                        <p className='text-grey-1 font-size-16 font-weight-light p-l-15'>
                            {t('locations.noLocations')}
                        </p>
                    )}
                </Slider>
            </div>
        </div>
    );
};

export default Locations;
