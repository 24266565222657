import React from 'react';

import Checkbox from '../../../../components/Checkbox';
import InfoBox from '../../../../components/InfoBox';

const AdditionalOptions = ({
  t,
  dispatch,
  createInstanceAction,
  state: { createInstance: { additionalOptions, isWindowsOS } = {} } = {}
}) => {
  const handleActiveUserData = (e) => {
    if (additionalOptions === 'userConf') {
      createInstanceAction({ additionalOptions: '' })(dispatch);
    } else {
      createInstanceAction({ additionalOptions: e.target.value })(dispatch);
    }
  };

  const handleChange = (e) => {
    createInstanceAction({ userData: e.target.value })(dispatch);
  };

  return (
    <div className="AdditionalOptions m-t-30">
      <h3 className="font-size-20 text-grey-2 font-weight-regular m-b-25 flex flex-align-items-center">
        <span className="m-r-10">{t('title')}</span>
        <InfoBox text={t('infoBox')} />
      </h3>
      <div className="flex">
        {!isWindowsOS && (
          <label
            className={`flex  m-r-20 ${
              additionalOptions === 'userConf' ? 'text-blue-1' : 'text-grey-2'
            }`}>
            <Checkbox
              checked={additionalOptions === 'userConf'}
              onChange={handleActiveUserData}
              className="Checkbox--full-box m-r-10"
              // type='radio' // TODO: this is temp fix for ability to toggle checkbox
              value="userConf"
              name="additionalOptions"
            />
            {t('userConf')}
          </label>
        )}
        <label
          className={`flex disabled  m-r-20 ${
            additionalOptions === 'ipv6' ? 'text-blue-1' : 'text-grey-2'
          }`}>
          <Checkbox
            disabled
            checked={additionalOptions === 'ipv6'}
            onChange={(e) => createInstanceAction({ additionalOptions: e.target.value })(dispatch)}
            className="Checkbox--full-box m-r-10"
            type="radio"
            value="ipv6"
            name="additionalOptions"
          />
          {t('ipv6')}
          <span className={"m-l-10 badge"}>COOMING SOON</span>
        </label>

      </div>
      {additionalOptions === 'userConf' && (
        <div className="textareaWrapper">
          <textarea
            onChange={handleChange}
            className="textarea"
            placeholder={t('textareaPlaceholder')}></textarea>
        </div>
      )}
    </div>
  );
};

export default AdditionalOptions;
