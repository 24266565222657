import { useRef, useEffect } from "react";

export const useLoopFetching = (request, timeout = 2000) => {
  const timeoutId = useRef();

  useEffect(() => {
    return () => {
      // eslint-disable-next-line no-undef
      clearTimeout(timeoutId.current);
    };
  }, []);
  // cb - callback on success status from job api
  // can paste account id, is some cases we dont have account in main page, but we need it for job api usage
  const startFetching = async (data, cb, accountid) => {
    // eslint-disable-next-line no-undef
    timeoutId.current = setInterval(async () => {
      await request(data, cb, accountid);
    }, timeout);
  };
  const cancelFetching = () => {
    // eslint-disable-next-line no-undef
    clearTimeout(timeoutId.current);
  };
  return {
    startFetching,
    cancelFetching
  };
};
