import React, { useEffect } from 'react';
import Slider from 'react-slick';
import pretty from 'prettysize';
import Card from '../../../../components/Card';
import { useSelector, useDispatch } from 'react-redux';

import standardIco from '../../../../assets/images/choosePlan/standard.svg';
import cpuIco from '../../../../assets/images/choosePlan/cpu.svg';
import memoryIco from '../../../../assets/images/choosePlan/memory.svg';
import settingsIco from '../../../../assets/images/choosePlan/settings.svg';

import ChooseLocations from '../components/ChooseLocations';
import {getProducts, setPricingPlan, setSelectedPlan} from '../../actions';

const plans = ['standard', 'cpuOptimized', 'memoryOptimized']

const icons = {
  standard: standardIco,
  cpuOptimized: cpuIco,
  memoryOptimized: memoryIco
}

const planNaming = {
  standard: "s-",
  cpuOptimized: "c-",
  memoryOptimized: "m-"
}

const ChoosePlan = ({ t,dispatch, createInstanceAction }) => {
  const reduxDispatch = useDispatch();
  const { selectedPlan, pricingPlan, selectedRegion, products } = useSelector(
    ({ products }) => products
  );
  useEffect(() => {
    const onMount = async () => {
      await getProducts({ params: { name: planNaming[selectedPlan], zoneid: selectedRegion?.id } })(reduxDispatch);
    };
    onMount();
  }, [selectedRegion, selectedPlan]);

  const pricingPlans =
    products &&
    products.map(
      ({ cpu, memory, price_per_hour, price_per_month, storage, id, name, hb_id, bandwidth }) => {
        return {
          price: price_per_month,
          perHour: price_per_hour,
          disk: storage,
          cpus: cpu,
          cpuSize: pretty(memory * 1024 * 1024),
          id,
          name,
          hb_id,
          memory,
          bandwidth
        };
      }
    );

  useEffect(() => {
    if (pricingPlans?.length) {
      setPricingPlan(pricingPlans[1] ? pricingPlans[1] : pricingPlans[0] )(reduxDispatch);
    }
  }, [products])

  return (
    <div className="ChoosePlan">
      <ChooseLocations t={t} />
      <h3 className="font-size-20 text-grey-2 font-weight-regular m-b-25 m-t-10 flex flex-justify-content-space-between">
        {t('title')}
        <a
          href="https://shape.host/pricing"
          target={"_blank"}
          rel="noreferrer"
          className="text-blue-1 m-l-10 font-size-16 font-weight-semibold flex flex-align-items-center">
          {t('help')} <img className="m-l-5 settingsIco" src={settingsIco} alt="" />
        </a>
      </h3>

      <div className="ChoosePlan__planType row m-b-30 flex">
        {plans.map((plan) => <Card key={plan} className="flex-grow-1 m-l-15 m-r-15">
          <Card.Body
            onClick={() => {
              setSelectedPlan(plan)(reduxDispatch);
              createInstanceAction({ templateid: "", isWindowsOS: false, isWindows11: false, osName: "" })(dispatch);
            }}
            className={
              `flex-align-items-center text-grey-2
              ${
                selectedPlan === plan && 'active'
              }
              `
            }
          >
            <img className="m-r-10 m-l-15" src={icons[plan]} alt=""/>
            <div>
              <div className={"font-weight-semibold font-size-22"}>{t(`${plan}.title`)}</div>
              <div className={"text-blue-1 font-size-18"}>{t(`${plan}.description`)}</div>
            </div>
          </Card.Body>
        </Card>)}
      </div>
      <Card className={`m-b-30 standard-description standard-description--${selectedPlan}`}>
        <Card.Body>
          <p className=" font-size-16 font-weight-light text-grey-1 m-b-0 m-t-0">{t(`text.${selectedPlan}`)}</p>
        </Card.Body>
      </Card>
      <div className="row pricing m-b-30">
        <Slider
          className="ChoosePlan__slider"
          {...{
            dots: true,
            speed: 500,
            slidesToShow: pricingPlans ? (pricingPlans.length > 5 ? 5 : pricingPlans.length) : 0,
            slidesToScroll: 5,
            centerMode: false,
            responsive: [
              {
                breakpoint: 1366,
                settings: {
                  slidesToShow: pricingPlans ? (pricingPlans.length > 4 ? 4 : pricingPlans.length) : 0,
                  slidesToScroll: 4,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: pricingPlans ? (pricingPlans.length > 4 ? 4 : pricingPlans.length) : 0,
                  slidesToScroll: 4,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerPadding: 50,
                  centerMode: true,
                  arrows: false
                }
              }
            ]
          }}>
          {pricingPlans &&
            pricingPlans.map(
              ({ price, perHour, cpus, cpuSize, disk, bandwidth, id, name, memory, hb_id }) => (
                <div key={id} className="p-l-15 p-r-15 p-b-5">
                  <div className="pricing-box">
                    <Card>
                      <Card.Body
                        onClick={() => {
                          setPricingPlan({
                            id,
                            price,
                            perHour,
                            cpus,
                            cpuSize,
                            disk,
                            bandwidth,
                            memory,
                            name,
                            hb_id
                          })(reduxDispatch);
                          createInstanceAction({ templateid: "", isWindowsOS: false, isWindows11: false, osName: "" })(dispatch);
                        }}
                        className={` ${
                          pricingPlan?.id === id && 'active'
                        }`}>
                        <div>
                          <div className="pricing-box__price-per-month">
                            <span className="currency">$</span>
                            <span className="price">{price}</span>
                            <span className="month">per mo</span>
                          </div>
                          <div className="pricing-box__price-per-hour font-size-15 m-t-5">${perHour}/hour</div>
                          <div className={"pricing-box__details"}>
                            <div className="text-grey-1 font-size-14">
                              <strong>{cpus} Cores</strong> CPU
                            </div>
                            <div className="text-grey-1 font-size-14">
                              <strong>{cpuSize}</strong> Memory
                            </div>
                            <div className="text-grey-1 font-size-14">
                              <strong>{disk} GB</strong> SSD disk space
                            </div>
                            <div className="text-grey-1 font-size-14">
                              <strong>{bandwidth} GB</strong> bandwidth
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              )
            )}
        </Slider>
      </div>
    </div>
  );
};

export default ChoosePlan;
