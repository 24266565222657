import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSort } from "./useSort";
import Pagination from "../../components/Pagination";
import BillingBlue from "../../assets/images/tickets/billing_blue.svg";
import BillingGray from "../../assets/images/tickets/billing_gray.svg";
import TechBlue from "../../assets/images/tickets/tech_blue.svg";
import TechGray from "../../assets/images/tickets/tech_gray.svg";
import EmptyTicketIco from "../../assets/images/tickets/empty_ticket_icon.svg"


import OrizontalScroll from "../../components/OrizontalScroll";
import Replay from "./Replay";
import { getTicketDetailsRequest, getTicketsRequest } from "./actions";

const TicketsEmpty = ({t}) => {

  return <div className="Empty_tickets">
    <img src={EmptyTicketIco} />
    <p className="text-grey-1">{t("empty")}</p>
    <Link
      to="/tickets/new"
      className="button button--blue p-l-50 p-r-50 flex button-new"
    >
      + {t("button")}
    </Link>
  </div>
}

const Tickets = () => {
  const dispatch = useDispatch();
  const { tickets, ticketPaginationData } = useSelector(({ support }) => support);
  const { t } = useTranslation("tickets");
  const [openMessage, setOpenMessage] = useState(null);
  useEffect(() => {
    getTicketsRequest()(dispatch);
  }, []);
  const { items, requestSort, sortConfig } = useSort(tickets);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name
      ? `${name}--${sortConfig.direction}`
      : undefined;
  };

  const handleTicketRequest = (idx, ticket_number) => {
    if (openMessage === idx) {
      setOpenMessage(null);
    } else {
      setOpenMessage(idx);
      getTicketDetailsRequest({ ticket_number })(dispatch);
    }
  };

  const handleTicketStatus = (status) => {
    if (status === "Answered") return "opened";
    if (status === "Open") {
      return "closed";
    }
    return "pending";
  }

  const handleDepartmentLogo = (status, department) => {
    if (department === "Technical") {
      if (status !== "Open") {
        return TechBlue
      }
      return TechGray
    }

    if (department === "Billing") {
      if (status !== "Open") {
        return BillingBlue
      }
      return BillingGray
    }
  }

  const handleDate = (date) => {
    return date.split(' ')[0].replace('-', '.').replace('-', '.');
  }

  return (
    <div className="Tickets">
      {tickets.length ? (<div className="container">
        <h2 className="title m-b-20">{t("title")}</h2>
        <div className="flex flex-align-items-center flex-justify-content-space-between m-b-40">
          <p className="font-size-16 text-grey-1 max-width-650 line-height-1-4">
            {t("description")}
          </p>
          <Link
            to="/tickets/new"
            className="button button--blue p-l-50 p-r-50 flex button-new"
          >
            + {t("button")}
          </Link>
        </div>
        <div>
          <>
            <OrizontalScroll>
              <OrizontalScroll.Scrollable>
                <div className="list-table m-b-30">
                  <div className="list-table__heading">
                    <div className="sender sender-header">{t("table.ticket")}</div>
                    <div
                      className={`text-align-left status status-line ${getClassNamesFor(
                        "status"
                      )}`}
                      onClick={() => requestSort("status")}
                    >
                      {t("table.status")}
                    </div>
                    <div className="text-align-left subject">
                      {t("table.subject")}
                    </div>
                    <div className="text-align-left departament">
                      {t("table.departament")}
                    </div>
                    <div
                      className={`text-align-left date ${getClassNamesFor(
                        "date"
                      )}`}
                      onClick={() => requestSort("date")}
                    >
                      {t("table.created")}
                    </div>
                    <div
                      className={`text-align-left lastreply lastreply-header ${getClassNamesFor(
                        "lastreply"
                      )}`}
                      onClick={() => requestSort("lastreply")}
                    >
                      {t("table.updated")}
                    </div>
                  </div>
                  <div className="list-table__body">
                    <div className="Tickets__content">
                      {items.map(
                        (
                          {
                            date,
                            lastreply_date,
                            ticket_number,
                            status,
                            deptname,
                            tsubject,
                            id,
                          },
                          index
                        ) => (
                          <>
                            <div
                              key={id}
                              onClick={() =>
                                handleTicketRequest(index, ticket_number)
                              }
                              className={`flex cursor-pointer ${status === "Answered" && "new"
                                }`}
                            >
                              <div className={`flex flex-align-items-center flex-justify-content-flex-start sender ${status !== "Open" && "text-blue-1"
                                }`}>
                                {"#" + ticket_number}
                              </div>
                              <div className={`flex flex-align-items-center status-line`}>
                                <div
                                  className={`status ${handleTicketStatus(status)}`}
                                ></div>
                                <div className={`status-text ${handleTicketStatus(status)}`}>{status}</div>
                              </div>
                              <div
                                className={`flex flex-align-items-center font-weight-semibold subject ${status !== "Open" && "text-blue-1"
                                  }`}
                              >
                                {tsubject}
                              </div>
                              <div
                                className={`flex flex-align-items-center font-weight-semibold departament ${status !== "Open" && "text-blue-1"
                                  }`}
                              >
                                <img src={handleDepartmentLogo(status, deptname)} />
                                {deptname}
                              </div>
                              <div className="flex flex-justify-content-space-between date flex-align-items-center">
                                <div>{handleDate(date)}</div>
                              </div>
                              <div className="flex flex-justify-content-space-between lastreply flex-align-items-center">
                                <div>{handleDate(lastreply_date)}</div>
                              </div>
                            </div>
                            {openMessage === index && (
                              <Replay t={t} ticketNumber={ticket_number} setOpenMessage={setOpenMessage} status={status} />
                            )}
                          </>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </OrizontalScroll.Scrollable>
              <OrizontalScroll.Slider className="flex flex-justify-content-center m-b-20" />
            </OrizontalScroll>
            {ticketPaginationData.totalpages && <Pagination
              pages={ticketPaginationData.totalpages}
              onChange={async (page) => await getTicketsRequest(page - 1)(dispatch)} />}
          </>

        </div>
      </div>) : (<TicketsEmpty t={t} />)}
    </div>
  );
};

export default Tickets;
