import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ToastProvider} from 'react-toast-notifications';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {Provider} from 'react-redux';

import Layout from './components/Layout';
import PrivateComponent from './components/PrivateComponent';
import routes from './routes';

import {StateProvider} from './store';
import {SelectContextProvider} from './components/CustomSelect/store';

import store from './reduxStore';

function App() {
  return (
      <StateProvider>
        <Provider store={store}>
          <SelectContextProvider>
            <ToastProvider autoDismiss autoDismissTimeout={6000}>
              <GoogleReCaptchaProvider // eslint-disable-line
                reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY} // eslint-disable-line
              >
                <div className="App">
                  <Router>
                    <Switch>
                      {routes.map(({Component, route, name, isPrivate, Sidebar, Header, Footer}) => (
                        <Route key={name} path={route}>
                          {(props) => <Layout {...{Sidebar, Header, Footer, name}}>
                            {isPrivate ? (
                              <PrivateComponent>
                                <Component {...props}/>
                              </PrivateComponent>
                            ) : (
                              <Component {...props}/>
                            )}
                          </Layout>}
                        </Route>
                      ))}
                    </Switch>
                  </Router>
                </div>
              </GoogleReCaptchaProvider>
            </ToastProvider>
          </SelectContextProvider>
        </Provider>
      </StateProvider>
  );
}

export default App;
