import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { getClientDetails } from "./actions";
import { store } from "../../store";

const PrivateComponent = ({ children }) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  let history = useHistory();
  const isLoggedIn = !!localStorage.getItem("atkn"); // eslint-disable-line
  useEffect(() => {
    const onMount = async () => {
      try {
        await getClientDetails()(dispatch);
      } catch (error) {
        history.push("/login");
      }
    };
    onMount();
  }, [dispatch]);
  if (!isLoggedIn) {
    history.push("/login");
    return "";
  }

  return <div className="PrivateComponent">{children}</div>;
};

export default PrivateComponent;
