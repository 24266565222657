import React, { useState, useReducer } from "react";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../../../components/Modal";
import CustomField from "../../../../../components/CustomField";
import SelectVpc from "../../../../../components/SelectVpc";
import { createAclListRequest, getVpcRequest } from "../../../../Dashboard/actions";
import Button from "../../../../../components/Button";

const CreateAclList = ({ show, onHide, t, accountid, startFetching }) => {
  const initialValues = {
    name: null,
    description: null
  };
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { vpcList, aclRulesListLoading } = useSelector(({ instances }) => instances);
  const [selectedVpc, setSelectedVpc] = useState(null);
  const [vpcFields, setVpcFields] = useReducer(
    (prevValues, newValues) => ({ ...prevValues, ...newValues }),
    initialValues
  );
  const { name, description } = vpcFields;

  const handleVpcFieldsChange = (event) => {
    const { name, value } = event.target;
    setVpcFields({ [name]: value });
  };
  const handleClearVpcFields = () => {
    setVpcFields({
      name: null,
      description: null
    });
  };

  const handleCreateAclList = async () => {
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await createAclListRequest({
        name,
        description,
        vpcid: selectedVpc.id,
        accountid: accountid ? accountid : selectedVpc.accountId
      })(dispatch);
      startFetching(
        jobid,
        () => {
          addToast("ACL list created", {
            appearance: "success",
            autoDismiss: true
          });
          onHide();
          accountid ? getVpcRequest({ accountid })(dispatch) : getVpcRequest()(dispatch);
          handleClearVpcFields();
        },
        accountid || selectedVpc.accountId
      );
    } catch (error) {
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };

  return (
    <Modal
      size="large"
      Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("createAclList.title")}</h2>}
      onHide={onHide}
      show={show}
    >
      <div className="modal-inner">
        <CustomField className="Field--sm m-b-10">
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createAclList.name")}
          </label>
          <input name="name" type="text" onChange={handleVpcFieldsChange} value={name} />
        </CustomField>
        <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
          {t("createAclList.vpc")}
        </label>
        <SelectVpc onChange={setSelectedVpc} vpcList={vpcList} />
        <CustomField className="Field--sm m-b-10">
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createAclList.desc")}
          </label>
          <textarea name="description" placeholder="Description..." onChange={handleVpcFieldsChange} />
        </CustomField>
      </div>

      <div className="flex row m-t-10">
        <button onClick={() => onHide()} className="button button--grey width-half flex-grow-1 m-l-15 m-r-15">
          {t("createModal.cancel")}
        </button>
        <Button
          loading={aclRulesListLoading}
          disabled={!name || !selectedVpc}
          onClick={handleCreateAclList}
          className="button width-half flex-grow-1 m-l-15 m-r-15"
        >
          {t("createAclList.createBtn")}
        </Button>
      </div>
    </Modal>
  );
};

export default CreateAclList;
