import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Switch = ({ checked, className, onChange, requestOnly }) => {
  const { t } = useTranslation("common");
  const [state, setState] = useState(checked);
  return (
    <div
      className={`Switch ${state && "Switch--active"} ${className}`}
      onClick={() => {
        onChange(!state);
        !requestOnly && setState(!state);
      }}
    >
      <span className="text">{state ? t("on") : t("off")}</span>
      <span className="circle" />
    </div>
  );
};

export default Switch;
