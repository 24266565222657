import React from "react";
import CustomField from "../../../../../../components/CustomField";
import Modal from "../../../../../../components/Modal";
import { useFormik } from "formik";
import CustomSelect from "../../../../../../components/CustomSelect";
import { useToasts } from "react-toast-notifications";
import { addAclRuleRequest, getAclRulesListRequest } from "../../.././../../Dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../Volumes/components/Loader/Loader";

const availableAction = ["Allow", "Deny"].map((action) => ({
  value: action,
  text: action
}));
const availableTraffic = ["Ingress", "Egress"].map((type) => ({
  value: type,
  text: type
}));
const availableProtocols = ["TCP", "UDP", "ICMP", "ALL"].map((protocol) => ({
  value: protocol,
  text: protocol
}));

const CreateRuleModal = ({ show, onHide, t, aclid, accountid, startFetching }) => {
  const { aclRulesListLoading } = useSelector(({ instances }) => instances);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      cidr: null,
      action: "Allow",
      protocol: "TCP",
      startport: null,
      endport: null,
      icmptype: null,
      icmpcode: null,
      traffictype: "Ingress",
      reason: null
    },
    onSubmit: async (values) => {
      if (validateValue().error) {
        return addToast(validateValue().error, {
          appearance: "error",
          autoDismiss: true
        });
      }
      try {
        const sendValues =
          values.protocol === "ALL"
            ? { ...values, startport: null, endport: null, icmpcode: null, icmptype: null }
            : values;
        const {
          data: {
            data: { jobid }
          }
        } = await addAclRuleRequest({ aclid, accountid, values: sendValues })(dispatch);
        startFetching(
          jobid,
          () => {
            onHide();
            formik.handleReset();
            getAclRulesListRequest({ aclid, accountid })(dispatch);
            addToast("ACL rule was successfully added", {
              appearance: "success",
              autoDismiss: true
            });
          },
          accountid
        );
      } catch (error) {
        return addToast(error.message, {
          appearance: "error",
          autoDismiss: true
        });
      }
    }
  });

  const validateValue = () => {
    if (
      formik.values.protocol === "ICMP" &&
      (formik.values.icmptype < 1 ||
        formik.values.icmptype > 14 ||
        formik.values.icmpcode > 14 ||
        formik.values.icmptype < 1)
    ) {
      return {
        error: t("createAclRule.icmpError")
      };
    }
    if (
      (formik.values.protocol === "TCP" || formik.values.protocol === "UDP") &&
      (formik.values.startport < 1 ||
        formik.values.startport > 65535 ||
        formik.values.endport < 1 ||
        formik.values.endport > 65535)
    ) {
      return {
        error: t("createAclRule.portError")
      };
    }
    return { success: "Success" };
  };

  return (
    <div className="create-rule">
      <Modal
        Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("createAclRule.title")}</h2>}
        onHide={() => onHide()}
        show={show}
        size="large"
      >
        <form onSubmit={formik.handleSubmit}>
          <CustomField className="Field--sm m-b-10">
            <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
              {t("createAclRule.cidr")}
            </label>
            <input
              name="cidr"
              type="text"
              onKeyPress={(event) => {
                if (!/^[\d./:/]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={formik.handleChange}
              value={formik.values.cidr}
            />
          </CustomField>
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createAclRule.action")}
          </label>
          <CustomSelect
            className="Field--sm m-b-10"
            options={[...availableAction]}
            onChange={(value) => {
              formik.setFieldValue("action", value);
            }}
          />
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createAclRule.protocol")}
          </label>
          <CustomSelect
            className="Field--sm m-b-10"
            options={[...availableProtocols]}
            onChange={(value) => {
              if (value === "ICMP" || value === "ALL") {
                formik.setFieldValue("endport", "");
                formik.setFieldValue("startport", "");
              } else {
                formik.setFieldValue("icmptype", "");
                formik.setFieldValue("icmpcode", "");
              }
              formik.setFieldValue("protocol", value);
            }}
          />
          <CustomField className="Field--sm m-b-10">
            <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
              {formik.values.protocol === "ICMP" ? t("createAclRule.icmpType") : t("createAclRule.startPort")}
            </label>
            <input
              name={formik.values.protocol === "ICMP" ? "icmptype" : "startport"}
              type="text"
              onKeyPress={(event) => {
                if (!/^[\d./]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={formik.handleChange}
              value={formik.values.protocol === "ICMP" ? formik.values.icmptype : formik.values.startport}
              disabled={formik.values.protocol === "ALL"}
            />
          </CustomField>
          <CustomField className="Field--sm m-b-10">
            <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
              {formik.values.protocol === "ICMP" ? t("createAclRule.icmpCode") : t("createAclRule.endPort")}
            </label>
            <input
              name={formik.values.protocol === "ICMP" ? "icmpcode" : "endport"}
              type="text"
              onKeyPress={(event) => {
                if (!/^[\d./]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={formik.handleChange}
              value={formik.values.protocol === "ICMP" ? formik.values.icmpcode : formik.values.endport}
              disabled={formik.values.protocol === "ALL"}
            />
          </CustomField>
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createAclRule.trafficType")}
          </label>
          <CustomSelect
            className="Field--sm m-b-10"
            options={[...availableTraffic]}
            onChange={(value) => formik.setFieldValue("traffictype", value)}
          />
          <CustomField className="Field--sm m-b-10">
            <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
              {t("createAclRule.description")}
            </label>
            <textarea
              value={formik.values.reason}
              onChange={formik.handleChange}
              name="description"
              placeholder={t("createAclRule.reasonPlaceholder")}
            />
          </CustomField>
          {aclRulesListLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-justify-content-flex-end">
              <button className="button button--grey button--md m-r-15" onClick={() => onHide()}>
                {t("createAclRule.cancel")}
              </button>
              <button className="button button--md" type="submit">
                {t("createAclRule.ok")}
              </button>
            </div>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default CreateRuleModal;
