import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/Modal';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { deleteNetwork, getNetworksRequest } from '../../../Dashboard/actions';
import Loader from '../../../Volumes/components/Loader/Loader';

const DeleteModal = ({
  networkDetails,
  onHide,
  accountid,
  setVisibleModal,
  show,
  t,
  startFetching,
  setDetailsNetwork
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const handleDeleteNetwork = async () => {
    setLoading(true);
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await deleteNetwork({
        accountid: accountid ? accountid : networkDetails.accountId,
        id: networkDetails.id
      })(dispatch);

      startFetching(
        jobid,
        () => {
          addToast(t('networks.deleteModal.success'), {
            appearance: 'success',
            autoDismiss: true
          });
          setLoading(false);
          setVisibleModal(null);
          setDetailsNetwork(null);
          onHide();
          getNetworksRequest({
            accountid: accountid
          })(dispatch);
        },
        accountid ? accountid : networkDetails.accountId
      );
    } catch (error) {
      setLoading(false);
      return addToast(t(error.message), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  return (
    <div>
      <div>
        <Modal
          Title={() => (
            <span className="font-size-38 text-grey-3 font-weight-light">
              {t("networks.deleteModal.title")}
            </span>
          )}
          onHide={onHide}
          show={show}>
          <div className="font-size-16 text-grey-1 ">
            {t("networks.deleteModal.yourVPC")}
            <span className="text-red-2 font-weight-semibold">{t("networks.deleteModal.warningRed")}</span>
            <div>{t("networks.deleteModal.warning1")}</div>
            <div className="m-t-20 m-b-40">{t("networks.deleteModal.warning2")}</div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="flex row">
              <button
                onClick={() => setVisibleModal(null)}
                className="button button--grey width-half flex-grow-1 m-l-15 m-r-15">
                {t('networks.deleteModal.cancel')}
              </button>
              <button
                onClick={handleDeleteNetwork}
                className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15">
                {t('networks.deleteModal.confirm')}
              </button>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default DeleteModal;
