export default {
  title: 'Reset Password',
  emailPlaceholder: 'Email address',
  submitButton: 'Reset Your Password',
  password: 'Password',
  rePassword: 'Re-type Password',
  loginLink: 'Back to login page',
  success: 'Your password has been reset successfully',
  confirmEmail: {
    title: 'Confirm your email address',
    success: 'Your email has been confirmed successfully',
  },
  resend: {
    title: 'Resend confirmation email',
    success: 'Confirmation email has been sent successfully',
  }
};
