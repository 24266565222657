import React from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import Modal from "../../../components/Modal";
import { detachVolume, getVolumesRequest } from "../actions";
import Button from "../../../components/Button";

const DetachModal = ({ t, show, onHide, volumeDetails, setVisibleModal, startFetching, loading }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  return (
    <div>
      <Modal
        Title={() => <h2 className="font-size-27  text-grey-3 m-t-0 m-b-0">{t("detachModal.title")}</h2>}
        onHide={onHide}
        show={show}
        size="small"
      >
        <p className="font-size-16 text-grey-1 line-height-1-6 m-t--10 m-b-30 ">
          <span className="m-b-20">{t("detachModal.detaching")}</span>
          <br />
          <br />
          <span>{t("detachModal.description1")}</span>
          <span className="font-weight-bold">{t("detachModal.volume")}</span>
          {t("detachModal.description2")}
        </p>
        <div className="flex row ">
          <button
            onClick={() => setVisibleModal(null)}
            className="button button--grey width-half flex-grow-1 m-l-15 m-r-15"
          >
            {t("detachModal.cancel")}
          </button>
          <Button
            loading={loading}
            onClick={async () => {
              try {
                const {
                  data: {
                    data: { jobid }
                  }
                } = await detachVolume(volumeDetails)(dispatch);
                startFetching(jobid, () => {
                  setVisibleModal(null);
                  onHide();
                  addToast(t("detachModal.success"), {
                    appearance: "success",
                    autoDismiss: true
                  });
                  getVolumesRequest()(dispatch);
                });
              } catch (error) {
                return addToast(error.message, {
                  appearance: "error",
                  autoDismiss: true
                });
              }
            }}
            className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15"
          >
            {t("detachModal.confirm")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DetachModal;
