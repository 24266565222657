import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";
import {getNetworksRequest} from "../../../../Dashboard/actions";
import Loader from "../../../../../components/Loader/Loader";

const AdvancedNetworks = ({selectedRegion, open, advancedNetwork, onClick, setOpen}) => {
  const reduxDispatch = useDispatch();
  const {networks, networksLoading} = useSelector((state) => state.instances);

  useEffect(() => {
    const onMount = async () => {
      await getNetworksRequest()(reduxDispatch);
    }
    if (!networks.length) {
      onMount()
    }
    onClick(null)
  }, [selectedRegion])

  const transformedNetworks =
    networks &&
    selectedRegion &&
    networks
      .filter((network) => network.zoneid === selectedRegion.acs_id)
      .map((network) => ({
        ...network,
        text: network.name,
        value: network.id
      }));

  useEffect(() => {
    if (advancedNetwork === undefined && transformedNetworks.length) {
      onClick(transformedNetworks[0])
    }
  }, [advancedNetwork, transformedNetworks])

  return (
    <>
      {open &&
        <div className={"AdvancedNetworks"}>
          {networksLoading ?
            <div className={`flex flex-justify-content-center p-t-10 p-b-10`}>
              <Loader size={"small"}/>
            </div>
            :
            <ul>
              {!transformedNetworks?.length && <li onClick={() => {
                onClick(null);
                setOpen(false);
              }}>Create network</li>}
              {transformedNetworks?.map(({displaytext, cidr, value, ...rest}) =>
                <li className={advancedNetwork?.value === value && 'active'} onClick={() => {
                  onClick({
                    displaytext,
                    cidr,
                    value, ...rest
                  });
                  setOpen(false);
                }} key={value}>
                  <span className={`name`}>{displaytext}</span>
                  <span className={`ip`}>{cidr}</span>
                </li>)}
            </ul>}
        </div>
      }
    </>
  )
}

export default AdvancedNetworks
