import React from "react";
import Modal from "../../../../../components/Modal";
import { deleteAclListRequest, getVpcRequest } from "../../../../Dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../../Volumes/components/Loader/Loader";

const DeleteAclList = ({ onHide, show, aclListId, accountid, t, startFetching, underInstance }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { aclRulesListLoading } = useSelector(({ instances }) => instances);

  const handleDeleteAclList = async () => {
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await deleteAclListRequest({
        accountid,
        listid: aclListId
      })(dispatch);
      startFetching(
        jobid,
        () => {
          onHide();
          addToast("Deleted", {
            appearance: "success",
            autoDismiss: true
          });
          underInstance ? getVpcRequest({ accountid })(dispatch) : getVpcRequest()(dispatch);
        },
        accountid
      );
    } catch (error) {
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  return (
    <Modal
      size="large"
      Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("aclDeleteTitle")}</h2>}
      onHide={onHide}
      show={show}
    >
      {aclRulesListLoading ? (
        <Loader />
      ) : (
        <div className="flex row">
          <button className="button button--grey width-half flex-grow-1 m-l-15 m-r-15" onClick={() => onHide()}>
            {t("cancel")}
          </button>
          <button
            className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15"
            onClick={handleDeleteAclList}
          >
            {t("aclDelete")}
          </button>
        </div>
      )}
    </Modal>
  );
};

export default DeleteAclList;
