import React from "react";

const defaultStatusOrder = ["Client-Reply", "In-Progress", "Open", "Answered", "Closed"];

export const useSort = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items].sort((a,b) => {
      if (defaultStatusOrder.indexOf(a.status) === defaultStatusOrder.indexOf(b.status)) {
        return new Date(b.lastreply_date) - new Date(a.lastreply_date);
      }
      if (defaultStatusOrder.indexOf(a.status) > defaultStatusOrder.indexOf(b.status)) {
        return 1
      } else {
        return -1
      }
    });
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
