import React, {useEffect, useState} from 'react';
import Tabs from '../../../../../components/Tabs';
import InfoBox from '../../../../../components/InfoBox';
import tabsComponent from './Tabs';
import {useLocation} from "react-router-dom";

const tabs = (t) => [
    {
        name: 'distributions',
        text: t('tabs.distributions')
    },
    {
        name: 'apps',
        text: t('tabs.oneClick')
    },
];

const ChooseImage = ({ t, ...props }) => {
    const [activeTab, setActiveTab] = useState('distributions');
    const location = useLocation();
    const query = new URLSearchParams(location.search); //eslint-disable-line
    const tab = query.get("imageTab")
    useEffect(() => {
        if (tab) {
            setActiveTab(tab)
        }
    }, [tab])

    const TabComponnet = tabsComponent[activeTab];
    return (
        <div className='ChooseImage'>
            <h3 className='font-size-20 text-grey-2 font-weight-regular m-b-25 flex flex-align-items-center'>
                <span className='m-r-10'>{t('title')}</span>
                <InfoBox text={t('infoBox')} />
            </h3>

            <Tabs>
                <Tabs.Header>
                    <ul>
                        {tabs(t).map(({ name, text }) => (
                            <li key={name}>
                                <a
                                    href='javascript:;'
                                    onClick={() => setActiveTab(name)}
                                    className={`${activeTab === name && 'active'}`}
                                >
                                    {text}
                                </a>
                            </li>
                        ))}
                    </ul>
                </Tabs.Header>
                <Tabs.Content>
                    <TabComponnet t={t} {...props} />
                </Tabs.Content>
            </Tabs>
        </div>
    );
};

export default ChooseImage;
