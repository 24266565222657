import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DownloadKey from "./DownloadKey";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

const Created = ({ t, setSuccessCreate, onHide, setName }) => {
  const { addToast } = useToasts();
  const { createResponse } = useSelector(({ profile }) => profile);
  useEffect(() => {
    return () => {
      setSuccessCreate(false);
      setName(null);
    };
  }, []);
  return (
    <>
      <p className="m-b-20 font-size-16">{t("createKey.created")}</p>
      <div className="flex flex-justify-content-flex-end">
        <CopyToClipboard
          text={createResponse?.privatekey}
          onCopy={() => {
            addToast(t("createKey.successCopy"), {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
            });
          }}
        >
          <button className="button button--blue m-r-10">
            {t("createKey.copy")}
          </button>
        </CopyToClipboard>
        <DownloadKey privatekey={createResponse?.privatekey} t={t} />
        <button className="button button--grey m-r-10" onClick={onHide}>
          {t("createKey.close")}
        </button>
      </div>
    </>
  );
};

export default Created;
