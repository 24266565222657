import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { getSnapshotsRequest, getVolumesRequest } from "../actions";
import CustomField from "../../../components/CustomField";

import Modal from "../../../components/Modal";
import { createVolumeSnapshot } from "../actions";

const SnapshotModalModal = ({ t, show, onHide, volumeDetails, setVisibleModal, startFetching }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [name, setName] = useState();

  const handleCreateSnapshot = async () => {
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await createVolumeSnapshot({ ...volumeDetails, name })(dispatch);
      startFetching(jobid, () => {
        getVolumesRequest()(dispatch);
        getSnapshotsRequest()(dispatch);
        setVisibleModal(null);
        onHide();
        addToast(t("snapshotModal.success"), {
          appearance: "success",
          autoDismiss: true
        });
        setVisibleModal(null);
      });
    } catch (error) {
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };

  return (
    <div>
      <Modal
        Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("snapshotModal.title")}</h2>}
        onHide={onHide}
        show={show}
      >
        <p className="font-size-16 text-grey-1 line-height-1-6 m-t--10 m-b-30">
          {t("snapshotModal.description")}
          <span className="text-blue-1 display-block">{t("snapshotModal.price")}</span>
        </p>
        <CustomField>
          <input
            className="m-b-30"
            onChange={(e) => setName(e.target.value)}
            placeholder={t("snapshotModal.placeholder")}
            type="text"
          />
        </CustomField>
        <div className="flex row">
          <button
            disabled={!name}
            onClick={handleCreateSnapshot}
            className="button button--blue flex-grow-1 m-l-15 m-r-15"
          >
            {t("snapshotModal.button")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SnapshotModalModal;
