import checkJobStatus from "./checkJobStatus";
import { v4 as uuidv4 } from 'uuid';
import {getOrderStatus} from "../pages/Create/actions";

let result = {};

const findProp = (obj, prop) => obj && Object.entries(obj).reduce((a, [key, val]) => {
  if (a) return a;
  if (key === prop) return val;
  if (typeof val === 'object') return findProp(val, prop);
}, null);

const checkStatus = async (accId, jobid, resultId, order) => {
  try {
    result[resultId] = await (order ? getOrderStatus(jobid) : checkJobStatus(accId, jobid));

    if (result[resultId]?.status === "success" || result[resultId]?.data?.data[0]?.status === "SUCCESS") {
      return {...result[resultId]?.result}
    }
    if (result[resultId]?.data?.data[0]?.status?.toLowerCase().includes("error")) {
      return new Error(result[resultId]?.data?.data[0]?.status)
    }
  } catch (error) {
    throw new Error(error)
  }
  setTimeout(() => checkStatus(accId, jobid, resultId, order), 6000) //eslint-disable-line
}

const reqWithJobId = async (request, accId, jobId, order) => {
  const resultId = uuidv4();
  let jobid;
  let accountId;
  let data;

  if (jobId) {
    jobid = jobId
  } else {
    data = await request;
    jobid = findProp(data, order ? 'order_id' : 'jobid')
    accountId = findProp(data, 'accountId')
  }
  if (!jobid) {
    return data
  }
  const waitForResult = new Promise((resolve, reject) => {
    const checkResultStatus = () => {
      if (result[resultId]?.status === "success" || result[resultId]?.data?.data[0]?.status === "SUCCESS") {
        return resolve({...data, ...result[resultId]?.result})
      }
      if (result[resultId]?.data?.data[0]?.status?.toLowerCase().includes("error")) {
        return reject(result[resultId])
      }
      setTimeout(checkResultStatus, 2000) //eslint-disable-line
    }
    checkResultStatus()
  })
  try {
    await Promise.all([waitForResult, checkStatus(accId || accountId, jobid, resultId, order)])
    return result[resultId]
  } catch (e) {
    return {error: e}
  }
}

export default reqWithJobId
