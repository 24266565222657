import Firewalls from './Firewalls/Firewalls';
import ExtraIps from './ExtraIps/ExtraIps';
import LoadBalancers from './LoadBalancers';
import PtrRecords from './PtrRecords';
import Vpc from './Vpc';
import Networks from './NetworksList/NetworksList';
import PublicIps from './PublicIps/PublicIps';
import Nics from './Nics/Nics';
import CreateOrEditFirewall from './Firewalls/CreateOrEditFirewall';

export default {
  'extra-ips': ExtraIps,
  'load-balancers': LoadBalancers,
  'ptr-records': PtrRecords,
  vpc: Vpc,
  firewalls: Firewalls,
  'vpc-networks': Networks,
  'public-ips': PublicIps,
  nics: Nics,
  'firewalls-create': CreateOrEditFirewall,
  'firewalls-edit': CreateOrEditFirewall,
};
