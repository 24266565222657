import React from "react";

import Card from "../../../../../components/Card";
import SnapshotsIco from "../../../../../assets/images/dashboard/snapshots_icon_soon.svg";

const Snapshot = ({ t: dashboardTrans }) => {
  const t = (path) => dashboardTrans(`instance.snapshots.${path}`);

  return (
    <div className="flex-grow-1">
      <Card className="height-full-height">
        <Card.Body className="flex flex-column height-full-height flex-align-items-center flex-justify-content-center">
          <img src={SnapshotsIco} width={113} height={113} className="m-b-50" />
          <p className="font-size-16 text-grey-1 width-half text-align-center line-height-1-6">{t("comingSoonDescription")}</p>
          <button className="button button--blueDisabled m-t-20 p-l-40 p-r-40">
            {t("comingSoon")}
          </button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Snapshot;
