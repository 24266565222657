import React, { useState, useEffect, useMemo } from 'react';
import instancesIco from '../../../../assets/images/create/instances2.svg';
import pretty from "prettysize";
import DeleteModal from './DeleteModal';
import SelectInstance from './SelectInstance';
import Loader from '../../../Volumes/components/Loader/Loader';
import TrashIco from '../../../../assets/images/tickets/trash_icon.svg'
import UnassignIco from '../../../../assets/images/network/unassign_icon.svg'
import IpLocationIco from '../../../../assets/images/ip-location.svg';
import useComponentVisible from '../../../../helpers/useComponentVisible';

const ExtraIpListItem = ({
    extraIp, i, expanded, setExpanded, instances, t, commonT, assignedInstanceIds, handleEnableStaticNat, handleDisableStaticNat, handleExtraIpDelete }) => {
    const {
        ipaddress,
        virtualmachineid
    } = extraIp;
    const [openModal, setOpenModal] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedInstance, setSelectedInstance] = useState(null);
    const [assigning, setAssigning] = useState(false);
    const [unassigning, setUnassigning] = useState(false);
    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(null, 'more');


    const instance = instances?.find(instance => instance.id === virtualmachineid);

    const filteredVMs = useMemo(() => {
        let filteredByNetwork = instances?.filter(instance => instance?.nic[1]?.networkid === extraIp?.associatednetworkid);

        if (assignedInstanceIds.length) {
            filteredByNetwork = filteredByNetwork?.filter(instance => !assignedInstanceIds.includes(instance.id))
        }
        if (searchText) {
            return filteredByNetwork.filter(instance => instance.name.toLowerCase().includes(searchText.toLowerCase()));
        }
        return filteredByNetwork;
    }, [instances, searchText]);

    useEffect(() => {
        if (!extraIp.virtualmachineid && selectedInstance) {
            const accountId = selectedInstance.accountid;
            const ipaddressid = extraIp?.id;
            const virtualmachineid = selectedInstance?.id
            const networkid = selectedInstance.nic[1].networkid;
            const vmguestip = selectedInstance.nic[1].ipaddress;

            handleEnableStaticNat(accountId, ipaddressid, virtualmachineid, networkid, vmguestip, setAssigning);
        }
    }, [selectedInstance]);

    const handleOptionsSelect = (value) => {
        setExpanded(value)
    }

    return (
        <div className="List_item">
            <div className="flex flex-align-items-center position-relative">
                <img src={IpLocationIco} alt="" />
                {ipaddress}
            </div>
            {virtualmachineid && <div className="flex">
                <div className="Instance position-relative m-r-14">
                    <img src={instancesIco} />
                    <span className={`status ${(instance?.state === 'Running' && 'Instance__active') ||
                        (['Starting', 'Stopping'].includes(instance?.state)
                            ? 'Instance__pending'
                            : 'Instance__inactive')}`
                    } />
                </div>
                <div className="Instance_info_container">
                    <div className="font-size-15 font-weight-semibold text-grey-3">{instance?.displayname}</div>
                    <div className="font-size-12 font-weight-semibold text-grey-8 Instance_info">
                        <span>{instance?.cpunumber} {t('list.cpu')}</span>
                        <span>{pretty(instance?.memory * 1024 * 1024)} {t('list.ram')}</span>
                        <span>{instance?.zonename}</span>
                        <span>{instance?.templatedisplaytext}</span>
                    </div>
                </div>
            </div>}
            {!virtualmachineid &&
                <SelectInstance
                    SearchClassName="Search_container"
                    triangleClassname="triangle_position"
                    handleOptionsSelect={handleOptionsSelect}
                    setSelectedInstance={setSelectedInstance}
                    setSearchText={setSearchText}
                    placeholder={t("list.placeholder")}
                    instances={filteredVMs}
                    expanded={expanded}
                    t={t}
                    i={i}
                />}
            {(unassigning || assigning) ? null : <div className="text-align-center text-blue-1 flex flex-align-items-center more position-relative">
                <a
                    className="lighter font-weight-semibold font-size-16"
                    href="javascript:;"
                    id='more'
                    onClick={() => {
                        isComponentVisible !== i ? setIsComponentVisible(i) : setIsComponentVisible(null);
                    }}>
                    {commonT('more')}
                    <span
                        className={`arrow fomt-weight-semibold ${isComponentVisible === i ? 'arrow--up' : 'arrow--down'
                            } arrow--blue m-l-5`}
                    />
                </a>
                {isComponentVisible !== null && isComponentVisible === i && (
                    <div ref={ref} className="keyPop popup font-size-14 font-weight-semibold text-grey-1 List_popup popup_menu">
                        {virtualmachineid && <div
                            onClick={() => { handleDisableStaticNat(instance.accountid, extraIp.id, setUnassigning) }}
                        >
                            <img src={UnassignIco} className="m-r-8" />
                            {t('unassignKey')}
                        </div>}
                        {virtualmachineid && <div className="line_cuted m-t-10 m-b-10"></div>}
                        <div
                            onClick={() => {
                                setOpenModal(true);
                                setIsComponentVisible(null);
                            }}>
                            <img src={TrashIco} className="m-r-8" />
                            {t('deleteKey')}
                        </div>
                    </div>
                )}
            </div>}
            {unassigning ? <div className="font-size-16 font-weight-semibold text-grey-8">{t('list.unassigning')} <Loader className="Loader_grey" /></div> : null}
            {assigning ? <div className="font-size-16 font-weight-semibold text-grey-8">{t('list.assigning')} <Loader className="Loader_grey" /></div> : null}
            {openModal && <DeleteModal
                extraIp={extraIp}
                show={openModal}
                setOpenModal={setOpenModal}
                handleExtraIpDelete={handleExtraIpDelete}
                onHide={() => {
                    setOpenModal(false);
                    setIsComponentVisible(null);
                }}
            />}
        </div>
    )
}

export default ExtraIpListItem;
