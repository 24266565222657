import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userIco from "../../../../assets/images/header/user-ico.svg";
import informationIco from "../../../../assets/images/header/info-ico.svg";
import logoutIco from "../../../../assets/images/header/logout-ico.svg";
import { post } from "../../../http";
import { useToasts } from "react-toast-notifications";
import line from "../../../../assets/images/small-line.svg";

const logout = async ({ history, addToast }) => {
  try {
    await post("logout");
    localStorage.removeItem("atkn"); //eslint-disable-line
    localStorage.removeItem("last_logged"); //eslint-disable-line
    history.push("/login");
  } catch (error) {
    return addToast("Something went wrong", {
      appearance: "error",
      autoDismiss: true,
    });
  }
};

const ProfileDropdown = ({
  clientDetails: { firstname, lastname, email } = {},
}) => {
  const { t } = useTranslation("header");
  const history = useHistory();
  const { addToast } = useToasts();

  return (
    <div className="ProfileDropdown">
      <ul className="profile">
        <li>
          <Link to="/profile">
            <img src={userIco} alt="" />
            <div>
              <span>
                {(firstname || lastname) && `${firstname} ${lastname}`}
              </span>
              <span className="description">{email}</span>
            </div>
          </Link>
        </li>
      </ul>
      <img className="line" src={line}/>
      <ul>
        <li>
          <Link to="/profile" className="m-r-10">
            <img width="30" src={informationIco} alt="" />
            <div>
              <span>{t("nav.profile")}</span>
              <span className="description">{t("nav.profileDescription")}</span>
            </div>
          </Link>
        </li>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              logout({ addToast, history });
            }}
            className="m-r-10"
          >
            <img width="30" src={logoutIco} alt="" />
            <div>
              <span>{t("nav.signout")}</span>
              <span className="description">{t("nav.signoutDescription")}</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;
