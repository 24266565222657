import React, { useState } from 'react';
import Modal from '../../../../components/Modal';
import { useTranslation } from 'react-i18next';
import Loader from '../../../Volumes/components/Loader/Loader';

const DeleteModal = ({
    onHide,
    setOpenModal,
    show,
    handleExtraIpDelete,
    extraIp
}) => {
    const [loading, setLoading] = useState(false);
    const { t: networkT } = useTranslation('network');
    const t = (path) => networkT(`extraIp.deleteModal.${path}`);

    return (
        <div>
            <div>
                <Modal
                    Title={() => (
                        <span className="font-size-38 text-grey-3 font-weight-light">
                            {t("title")}
                        </span>
                    )}
                    onHide={onHide}
                    show={show}>
                    <div className="font-size-16 text-grey-1 ">
                        {t("yourLB")}
                        <span className="text-red-2 font-weight-semibold">{t("warningRed")}</span>
                        <div>{t("warning1")}</div>
                        <div className="m-t-20 m-b-40">{t("warning2")}</div>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="flex row">
                            <button
                                onClick={() => setOpenModal(false)}
                                className="button button--grey width-half flex-grow-1 m-l-15 m-r-15">
                                {t('cancel')}
                            </button>
                            <button
                                onClick={() => {handleExtraIpDelete(extraIp, setLoading)}}
                                className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15">
                                {t('destroy')}
                            </button>
                        </div>
                    )}
                </Modal>
            </div>
        </div>
    );
};

export default DeleteModal;
