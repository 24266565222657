import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Modal from "../Modal";
import CustomField from "../CustomField";
import SelectDomain from "../../components/SelectDomain/";
import Loader from "../../pages/Volumes/components/Loader/Loader";
import { getCreateKeyRequest } from "../../pages/Profile/actions";
import Created from "./Created";

const CreateKeyModal = ({ show, onHide, t }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { domains, createLoading } = useSelector(({ profile }) => profile);
  const [name, setName] = useState(null);
  const [domain, setDomain] = useState(null);
  const [successCreate, setSuccessCreate] = useState(false);
  return (
    <div className="SshModal">
      <Modal show={show} onHide={onHide}>
        <div className="flex row m-t--30 flex-mobile-column">
          <div className="SshModal__add-key m-l-25 m-r-25 flex-grow-1">
            <h2 className="title m-t--10">{t("createKey.title")}</h2>
            {createLoading ? (
              <Loader />
            ) : successCreate ? (
              <Created
                t={t}
                setSuccessCreate={setSuccessCreate}
                onHide={onHide}
                setName={setName}
              />
            ) : (
              <>
                <p className="m-b-20">{t("createKey.description")}</p>
                <label>Name</label>
                <CustomField className="m-b-30">
                  <input
                    name=""
                    placeholder={t("createKey.name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </CustomField>
                <label>{t("createKey.domain")}</label>
                <SelectDomain domainList={domains} onChange={setDomain} large />
                <div className="flex flex-align-items-center ">
                  <button className="button button--grey width-half  m-r-15">
                    {t("createKey.cancel")}
                  </button>
                  <button
                    disabled={!name || !domain}
                    className="button button--blue width-half  m-l-15"
                    onClick={async () => {
                      try {
                        await getCreateKeyRequest({
                          accountid: domain.accountid,
                          name,
                          domains,
                        })(dispatch);
                        setSuccessCreate(true);
                        return addToast(t("createKey.success"), {
                          appearance: "success",
                          autoDismiss: true,
                        });
                      } catch (error) {
                        return addToast(t(error.message), {
                          appearance: "error",
                          autoDismiss: true,
                        });
                      }
                    }}
                  >
                    {t("createKey.ok")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateKeyModal;
