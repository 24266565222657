import React, { useState } from "react";
import { editVpc } from "../../../Dashboard/actions";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../components/Modal";
import CustomField from "../../../../components/CustomField";
import Loader from "../../../Volumes/components/Loader/Loader";

const EditVpc = ({ show, onHide, t, accountid, vpcid, underInstance }) => {
  const dispatch = useDispatch();
  const { vpcLoading } = useSelector(({ instances }) => instances);
  const { addToast } = useToasts();
  const [name, setName] = useState(null);
  return (
    <Modal
      size="large"
      Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("editModal.title")}</h2>}
      onHide={onHide}
      show={show}
    >
      <div className="modal-inner">
        <CustomField className="Field--sm m-b-10">
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("editModal.name")}
          </label>
          <input
            name="name"
            type="text"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            placeholder={t("editModal.placeholderName")}
          />
        </CustomField>
      </div>
      <div className="flex row m-t-10">
        <button onClick={() => onHide()} className="button button--grey width-half flex-grow-1 m-l-15 m-r-15">
          {t("editModal.cancel")}
        </button>
        <button
          onClick={async () => {
            try {
              await editVpc({
                accountid,
                id: vpcid,
                name,
                underInstance
              })(dispatch);
              onHide();
              setName(null);
              return addToast(t("editModal.success"), {
                appearance: "success",
                autoDismiss: true
              });
            } catch (error) {
              return addToast(error.message, {
                appearance: "error",
                autoDismiss: true
              });
            }
          }}
          className="button width-half flex-grow-1 m-l-15 m-r-15 flex"
        >
          {vpcLoading ? <Loader /> : t("editModal.confirm")}
        </button>
      </div>
    </Modal>
  );
};

export default EditVpc;
