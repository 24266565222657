import React, { useEffect, useState } from "react";

function materializeEffect(event) {
  const circle = document.createElement("div"); //eslint-disable-line
  const x = event.layerX;
  const y = event.layerY;
  circle.classList.add("circle");
  circle.style.left = `${x}px`;
  circle.style.top = `${y}px`;
  this.appendChild(circle);
  //eslint-disable-next-line
  setTimeout(() => {
    try {
      this.removeChild(circle);
    } catch (error) {
      //eslint-disable-next-line
      console.log(error);
    }
  }, 500);
}

const Layout = ({ children, Sidebar, Header, Footer, name }) => {
  const [sidebarExtended, setSidebarExtended] = useState(true);

  useEffect(() => {
    //eslint-disable-next-line
    document.addEventListener("click", (e) => {
      if (e.target.tagName === "BUTTON") {
        materializeEffect.call(e.target, e);
      }
    });
  }, []);
  return (
    <div className={`Layout ${name} ${sidebarExtended && "Layout--extended"}`}>
      {Sidebar && <Sidebar {...{ sidebarExtended, setSidebarExtended }} />}
      <div className="Layout__right">
        <div className={`Header__container`}>
          {Header && <Header />}
        </div>
        <main className="Layout__main">
          <div className="Layout__content">{children}</div>
        </main>
        {Footer && <Footer />}
      </div>
    </div>
  );
};

export default Layout;
