import React from "react";
import { useTranslation } from "react-i18next";
import SelectInstance from "./SelectInstance"
import OrizontalScroll from "../../../../components/OrizontalScroll";
import { availableAlgorithm, availableRules } from "../constants";
import DeleteIco from "../../../../assets/images/tickets/trash_icon.svg";
import ConfigIco from "../../../../assets/images/config-icon.svg";
import SelectWithIcons from "../../../../components/SelectWithIcons";
import useComponentVisible from "../../../../helpers/useComponentVisible";

const ForwardRules = ({
  t,
  hideTitle = false,
  rules,
  handleAddRules,
  handleDeleteRule,
  handleChangeRules,
  handleChangeInstances,
  handleRuleEdit,
  loadBalancer,
}) => {
  const { t: commonT } = useTranslation("common");
  const { isComponentVisible, setIsComponentVisible, ref } = useComponentVisible(null, 'more');

  return (
    <OrizontalScroll>
      <OrizontalScroll.Scrollable>
        <div className="ForwardRules">
          {!hideTitle && (
            <div className="flex flex-align-items-center flex-justify-content-space-between width-full-width ">
              <div>
                <h3 className="font-size-27 text-grey-2 font-weight-regular m-b-20 m-t-40 ">
                  {t("title")}
                </h3>
                <p className="text-grey-1 font-size-16 hidden-xs m-t-25 m-b-25">
                  {t("description")}
                </p>
              </div>
              <button
                className="button button--md add_rule_button"
                onClick={handleAddRules}
              >
                {t("createRule")}
              </button>
            </div>
          )}
          <div className="rules">
            <div className="rules_header flex">
              <span>{t("name")}</span>
              <span>{t("protocol")}</span>
              <span>{t("algorithm")}</span>
              <span>{t("pubPort")}</span>
              <span>{t("privPort")}</span>
            </div>
            <div className="rules__body">
              {rules.map(({ name, privateport, publicport, id, instances, protocol, algorithm, type }, i) => (
                <div className="List_item" key={id}>
                  {type && ((type === "new" || type === "edit")) ? <div className="List_item_inputs">
                    <div className="Main_content">
                      <div>
                        <input
                          className="Name_input"
                          name="name"
                          placeholder={t('rulename')}
                          value={name}
                          onChange={(e) => handleChangeRules(e, id)}
                        />
                      </div>
                      <div>
                        <SelectWithIcons
                          className="Value_select"
                          options={availableRules}
                          defaultOption={availableRules.find(proto => proto.value === protocol)}
                          onChange={(option) => { handleChangeRules(option.value, id); }}
                        />
                      </div>
                      <div>
                        <SelectWithIcons
                          className="Value_select"
                          options={availableAlgorithm}
                          defaultOption={availableAlgorithm.find(algorihthm => algorihthm.value === algorithm)}
                          onChange={(option) => { handleChangeRules(option.value, id); }}
                        />
                      </div>
                      {type === 'edit' ? <div className="Port_item">{publicport}</div> :
                        <div>
                          <input
                            className="Port_input"
                            onKeyPress={(event) => {
                              if (!/^[\d./]+$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            name="publicport"
                            value={publicport}
                            onChange={(e) => handleChangeRules(e, id)}
                          />
                        </div>}
                      {type === 'edit' ? <div className="Port_item">{privateport}</div> : 
                      <div>
                        <input
                          className="Port_input"
                          onKeyPress={(event) => {
                            if (!/^[\d./]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          name="privateport"
                          value={privateport}
                          onChange={(e) => handleChangeRules(e, id)}
                        />
                      </div>}
                    </div>
                    {rules.length > 1 ? <div className="text-align-center text-blue-1 flex flex-align-items-center max-width-70 more position-relative">
                      <a
                        className="lighter font-weight-regular"
                        href="javascript:;"
                        id='more'
                        onClick={() =>
                          isComponentVisible !== i ? setIsComponentVisible(i) : setIsComponentVisible(null)
                        }>
                        {commonT('more')}
                        <span
                          className={`arrow fomt-weight-semibold ${isComponentVisible === i ? 'arrow--up' : 'arrow--down'
                            } arrow--blue m-l-5`}
                        />
                      </a>
                      {isComponentVisible !== null && isComponentVisible === i && (
                        <div ref={ref} className="keyPop popup m-t-10 popup_menu">
                          {rules.length > 1 &&
                            <div className="text-grey-1 cursor-pointer" onClick={() => {
                              handleDeleteRule(id);
                              setIsComponentVisible(null);
                            }}>
                              <img src={DeleteIco} />
                              {commonT("delete")}
                            </div>}
                        </div>
                      )}
                    </div> : null}
                  </div> :
                    <div className="List_item_show">
                      <div className="Main_content">
                        <div>{name}</div>
                        <div>{availableRules.find(proto => proto.value === protocol)?.text}</div>
                        <div>{availableAlgorithm.find(algorihthm => algorihthm.value === algorithm)?.text}</div>
                        <div>{publicport}</div>
                        <div>{privateport}</div>
                      </div>
                      <div className="text-align-center text-blue-1 flex flex-align-items-center max-width-70 more position-relative">
                        <a
                          className="lighter font-weight-regular"
                          href="javascript:;"
                          id='more'
                          onClick={() =>
                            isComponentVisible !== i ? setIsComponentVisible(i) : setIsComponentVisible(null)
                          }>
                          {commonT('more')}
                          <span
                            className={`arrow fomt-weight-semibold ${isComponentVisible === i ? 'arrow--up' : 'arrow--down'
                              } arrow--blue m-l-5`}
                          />
                        </a>
                        {isComponentVisible !== null && isComponentVisible === i && (
                          <div ref={ref} className="keyPop popup m-t-10 popup_menu">
                            <div className="text-grey-1 cursor-pointer" onClick={() => {
                              handleRuleEdit({ name, privateport, publicport, id, instances, protocol, algorithm, type: 'edit' })
                              setIsComponentVisible(null);
                            }}>
                              <img src={ConfigIco} />
                              {commonT("edit")}
                            </div>
                            {rules.length > 1 && <><div className="line_cuted m-t-10 m-b-10"></div>
                              <div className="text-grey-1 cursor-pointer" onClick={() => {
                                handleDeleteRule(id);
                                setIsComponentVisible(null);
                              }}>
                                <img src={DeleteIco} />
                                {commonT("delete")}
                              </div></>}
                          </div>
                        )}
                      </div>
                    </div>}
                  <div className="InstanceSelectCont">
                    <SelectInstance
                      className="InstanceSelect"
                      handleChangeInstances={handleChangeInstances}
                      defaultInstances={instances}
                      loadBalancer={loadBalancer}
                      ruleId={id}
                      t={commonT}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OrizontalScroll.Scrollable>
      <OrizontalScroll.Slider className="flex flex-justify-content-center m-b-20 m-t-20" />
    </OrizontalScroll>
  );
};

export default ForwardRules;
