import React from "react";
import arrowDownIco from "../../assets/images/arrow-down.svg";
import useComponentVisible from "../../helpers/useComponentVisible";

const SelectWithIcons = ({ options, defaultOption, onChange, isChartFilter, className, ExtraSection }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false, 'options');

    const handleSelectChange = (option) => {
        onChange(option);
        setIsComponentVisible(!isComponentVisible);
    }

    return (
        <div id="options" className={className? className + " Select_Container" : "Select_Container"}>
            <div className="Selected" onClick={() => { setIsComponentVisible(!isComponentVisible) }}>
                <span className="flex flex-align-items-center">
                    {defaultOption?.icon && <img className="m-r-6" src={defaultOption.icon} />}
                    {isChartFilter && "Last "}
                    {defaultOption?.text}
                </span>
                <img src={arrowDownIco} />
            </div>
            {isComponentVisible && options ? <div ref={ref} id="options" className="Options_Container position-absolute">
                {options && options.map(option => (
                    <div className={`Option ${option?.disabled ? 'grey-out' : ''}`} key={option.text} onClick={() => handleSelectChange(option)}>
                        {option.icon && <img className="m-r-6" src={option.icon} />}
                        {option.text}
                    </div>
                ))}
                {options.length && ExtraSection ? <div className="line_cuted m-t-5 m-b-10"></div> : null}
                {ExtraSection ? <ExtraSection /> : null}
            </div> : null}
        </div>
    )
}

export default SelectWithIcons;
