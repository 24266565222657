import React from 'react';

const Header = ({ children, className }) => (
  <div className={`Tabs__header ${className}`}>{children}</div>
);
const Content = ({ children }) => <div className="Tabs__content">{children}</div>;

const Tabs = ({ children, className, ...props }) => {
  return (
    <div className={`Tabs ${className}`} {...props}>
      {children}
    </div>
  );
};

Tabs.Header = Header;
Tabs.Content = Content;

export default Tabs;
