import React, { useState, useRef, useEffect } from "react";

const DownloadKey = ({ privatekey, t }) => {
  const linkRef = useRef();
  const [state, setState] = useState({
    fileType: "key",
    fileDownloadUrl: null,
    data: privatekey,
  });
  const fileNames = {
    key: "privateKey.key",
  };

  const download = (event) => {
    event.preventDefault();
    // Prepare the file
    let output;

    if (state.fileType === "key") {
      // Prepare data:
      output = state.data;
    }
    // Download it
    // eslint-disable-next-line no-undef
    const blob = new Blob([output]);
    // eslint-disable-next-line no-undef
    const fileDownloadUrl = URL.createObjectURL(blob);
    setState((prev) => ({ ...prev, fileDownloadUrl: fileDownloadUrl }));
  };

  useEffect(() => {
    if (state.fileDownloadUrl) {
      linkRef.current.click();
      setState((prev) => ({ ...prev, fileDownloadUrl: "" }));
    }
  }, [state.fileDownloadUrl]);

  return (
    <div>
      <button className="button button--blue m-r-10" onClick={download}>
        {t("createKey.download")}
      </button>
      <a
        className="hidden"
        download={fileNames[state.fileType]}
        href={state.fileDownloadUrl}
        ref={linkRef}
      ></a>
    </div>
  );
};

export default DownloadKey;
