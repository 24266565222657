import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getNetworksRequest} from "../../../actions";
import {Link} from "react-router-dom";
import Card from "../../../../../components/Card";
import LinkIco from "../../../../../assets/images/dashboard/link_icon.svg";
import FirewallIco from "../../../../../assets/images/dashboard/firewall_icon.svg";
import ArrowsIco from "../../../../../assets/images/dashboard/arrows_icon.svg";
import InstanceIco from "../../../../../assets/images/dashboard/instance_icon_dashboard.svg";
import {VpcModal} from "../../Resources/components/InstanceDetails";

const Network = ({t: dashboardTrans, id, zoneid, nic, extraIp}) => {
  const dispatch = useDispatch();
  const [firewalls, setFirewalls] = useState(false);
  const [advancedNetwork, setAdvancedNetwork] = useState(null);
  const [basicNetwork, setBasicNetwork] = useState(null);
  const [vpcRange, setVpcRange] = useState(null);
  const [vpcModal, setVpcModal] = useState(null);
  const t = (path) => dashboardTrans(`instance.network.${path}`);
  const {networks} = useSelector(({instances}) => instances);

  useEffect(() => {
    getNetworksRequest()(dispatch);
  }, []);

  useEffect(() => {
    if (nic) {
      setAdvancedNetwork(nic.find(net => net.type === "Isolated"))
      setBasicNetwork(nic.find(net => net.type === "Shared"))
    }

    if (advancedNetwork && networks) {
      const {cidr} = networks?.find(net => net?.id === advancedNetwork?.networkid) || {};

      setVpcRange(cidr);
    }
  }, [nic])

  return (
    <div className="flex-grow-1 Networking">
      <Card className="height-full-height">
        <Card.Body className="flex flex-column">
          <div className="p-t-25 p-l-30 p-r-30">
            <div>
              <div className="font-size-27 text-grey-3">{t('public')}</div>
              <p className="font-size-16 text-grey-1 m-b-30">{t('publicDesc')}</p>
              <div className="flex text-grey-1 font-size-16 distance">
                <div className="distance">
                  <div className="font-weight-semibold">{t('IPv4Public')}</div>
                  <div className="m-t-10">{basicNetwork?.ipaddress}</div>
                </div>
                <div className="distance">
                  <div className="font-weight-semibold">{t('publicGateway')}</div>
                  <div className="m-t-10">{basicNetwork?.gateway}</div>
                </div>
                <div className="distance">
                  <div className="font-weight-semibold">{t('subnetMask')}</div>
                  <div className="m-t-10">{basicNetwork?.netmask}</div>
                </div>
              </div>
              <div className="font-weight-semibold font-size-16 text-grey-1 m-t-30 m-b-30">
                <div className="m-b-10">{t('extraIP')}</div>
                {extraIp ? <a href="javascript:void(0)" className="m-t-10 cursor-pointer text-blue-1">{extraIp}</a> :
                  <Link to="/vpc-network/extra-ips"><a href="javascript:void(0)"
                                                       className="m-t-10 cursor-pointer text-blue-1">{t('enable')}</a></Link>}
              </div>
            </div>
            <div className="line_cuted m-t-0 m-b-0"></div>
            <div className="p-t-30 p-b-30">
              <div className="font-size-27 text-grey-3">{t('private')}</div>
              <p
                className="font-size-16 text-grey-1 m-b-30 flex">{advancedNetwork ? t('privateDesc') : t('noPrivateDesc')} {!advancedNetwork ?
                <div className="position-relative m-l-5">
                  <div className="position-relative vpc-container">
                    <span onClick={() => setVpcModal(!vpcModal)} className="text-blue-1 cursor-pointer position-relative">
                      {t('here')}
                    </span>
                    {vpcModal && <VpcModal instanceId={id} networks={networks.filter(({zoneid: netZoneId}) => {
                      return netZoneId === zoneid
                    })}/>}
                  </div>
                </div> : null}</p>
              {advancedNetwork && <div className="flex text-grey-1 font-size-16 distance">
                <div className="distance">
                  <div className="font-weight-semibold">{t('IPv4Private')}</div>
                  <div className="m-t-10">{advancedNetwork.ipaddress}</div>
                </div>
                <div className="distance">
                  <div className="font-weight-semibold">{t('VPCNetwork')}</div>
                  <Link to={`/vpc-network/vpc-networks/${advancedNetwork.networkname}`} className="m-t-10 cursor-pointer text-blue-1">{advancedNetwork.networkname} <img src={LinkIco}/>
                  </Link>
                </div>
                <div className="distance">
                  <div className="font-weight-semibold">{t('IPRange')}</div>
                  <div className="m-t-10">{vpcRange}</div>
                </div>
              </div>}
            </div>
            {advancedNetwork && <div>
              <div className="line_cuted m-t-0 m-b-0"></div>
              <div className="p-t-30 p-b-30">
                <div className="font-size-27 text-grey-3">{t('firewalls')}</div>
                <p
                  className="font-size-16 text-grey-1 m-b-30">{firewalls ? t('firewallsDesc') : t('noFirewallsDesc')}</p>
                {!firewalls ?
                  <Link to="/vpc-network/firewalls">
                    <button className="button button--blue p-l-40 p-r-40" onClick={() => {
                      setFirewalls(true)
                    }}>+ {t('button')}</button>
                  </Link>
                  : <div>
                    <div>
                      <div className="font-weight-semibold text-grey-1 font-size-16 flex flex-align-items-center">
                        <img className="m-r-10" src={FirewallIco}/>
                        {t('firewallProd')}
                      </div>
                      <div className="list_container">
                        <div className="flex font-size-12 font-weight-semibold text-grey-3 m-t-20 m-b-10 p-l-20">
                          <div className="list_item">PROTOCOL</div>
                          <div className="list_item">PORT RANGE</div>
                          <div>SOURCE</div>
                        </div>
                        <div
                          className="flex flex-align-items-center width-full-width border-grey-1 p-t-10 p-b-10 p-l-20 font-size-16 text-grey-1">
                          <div className="list_item">TCP</div>
                          <div className="list_item">65000-65000</div>
                          <div className="source_items_container">
                            <div className="source_item">All IPv4</div>
                            <div className="source_item"><img src={InstanceIco}/> Staging Instance</div>
                            <div className="source_item"><img src={ArrowsIco}/> 39.453.112.7</div>
                          </div>
                        </div>
                        <div
                          className="flex flex-align-items-center width-full-width border-grey-1 p-t-10 p-b-10 p-l-20 font-size-16 text-grey-1">
                          <div className="list_item">ICMP</div>
                          <div className="list_item">22</div>
                          <div className="source_items_container">
                            <div className="source_item">All IPv4</div>
                            <div className="source_item"><img src={ArrowsIco}/> 39.453.112.7</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m-t-30">
                      <div className="font-weight-semibold text-grey-1 font-size-16 flex flex-align-items-center">
                        <img className="m-r-10" src={FirewallIco}/>
                        {t('firewallStag')}
                      </div>
                      <div className="list_container">
                        <div className="flex font-size-12 font-weight-semibold text-grey-3 m-t-20 m-b-10 p-l-20">
                          <div className="list_item">PROTOCOL</div>
                          <div className="list_item">PORT RANGE</div>
                          <div>SOURCE</div>
                        </div>
                        <div
                          className="flex flex-align-items-center width-full-width border-grey-1 p-t-10 p-b-10 p-l-20 font-size-16 text-grey-1">
                          <div className="list_item">ICMP</div>
                          <div className="list_item">22</div>
                          <div className="source_items_container">
                            <div className="source_item">All IPv4</div>
                            <div className="source_item"><img src={ArrowsIco}/> 39.453.112.7</div>
                          </div>
                        </div>
                        <div
                          className="flex flex-align-items-center width-full-width border-grey-1 p-t-10 p-b-10 p-l-20 font-size-16 text-grey-1">
                          <div className="list_item">TCP</div>
                          <div className="list_item">65000-65000</div>
                          <div className="source_items_container">
                            <div className="source_item">All IPv4</div>
                            <div className="source_item"><img src={InstanceIco}/> Staging Instance</div>
                            <div className="source_item"><img src={ArrowsIco}/> 39.453.112.7</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </div>
            </div>}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Network;
