import React from "react";

const Title = ({ children, className, ...rest }) => (
  <div {...rest} className={`Card__title ${className}`}>{children}</div>
);
const Body = ({ children, disabled, className, ...rest }) => (
  <div {...rest} className={`${disabled ? 'Card__body Card__body_disabled' : 'Card__body'} ${className}`}>{children}</div>
);

const Card = ({ children, className, ...rest }) => {
  return <div {...rest} className={`Card ${className}`}>{children}</div>;
};

Card.Title = Title;
Card.Body = Body;

export default Card;
