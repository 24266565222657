import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import Card from "../../../components/Card";
import Modal from "../../../components/Modal";
import { increaseVolumeSize } from "../actions";

const volumeSizes = [
  {
    price: 10,
    priceOnHour: 0.022,
    diskSize: 100,
  },
  {
    price: 50,
    priceOnHour: 0.099,
    diskSize: 500,
  },
  {
    price: 100,
    priceOnHour: 0.199,
    diskSize: 1000,
  },
];

const IncreaseModal = ({ t, show, onHide, volumeDetails, setVisibleModal }) => {
  const [selectedSize, setSelectedSize] = useState(100);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  return (
    <div className="IncreaseModal">
      <Modal
        Title={() => (
          <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">
            {t("increaseModal.title")}
          </h2>
        )}
        size="middle"
        onHide={onHide}
        show={show}
      >
        <p className="font-size-16 text-grey-1 line-height-1-6 m-t--20 m-b-50">
          {t("increaseModal.description")}
        </p>
        <div className="row flex">
          {volumeSizes.map(({ diskSize, price, priceOnHour }) => (
            <div key={diskSize} className="pricing-box p-l-15 p-r-15">
              <Card
                onClick={() => setSelectedSize(diskSize)}
                className="Card--title-bottom"
              >
                <Card.Body
                  className={`flex-justify-content-center ${
                    selectedSize === diskSize && "active"
                  }`}
                >
                  <div>
                    <div className="pricing-box__price-per-month">
                      <span className="currency">$</span>
                      <span className="price">{price}</span>
                      <span className="month">mo</span>
                    </div>
                    <div className="pricing-box__price-per-hour">
                      ${priceOnHour}/{t("increaseModal.hour")}
                    </div>
                  </div>
                </Card.Body>
                <Card.Title>
                  <div className="text-grey-1 font-size-12 text-align-center flex-grow-1">
                    {diskSize} GB
                  </div>
                </Card.Title>
              </Card>
            </div>
          ))}
        </div>
        <div className="flex m-t-40">
          <button
            onClick={async () => {
              try {
                await increaseVolumeSize({
                  size: selectedSize,
                  ...volumeDetails,
                })(dispatch);
                setVisibleModal(null);
                return addToast(t("increaseModal.success"), {
                  appearance: "success",
                  autoDismiss: true,
                });
              } catch (error) {
                return addToast(t("increaseModal.error"), {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            }}
            className="button button--blue flex-grow-1 "
          >
            {t("increaseModal.button")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default IncreaseModal;
