import React from "react";
import ReactDOM from "react-dom";
import "@pathofdev/react-tag-input/build/index.css";
import App from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/style/index.scss";
import "./translations";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root") //eslint-disable-line
);
