import React, { useState } from 'react';
import {deleteFirewallGroupRequest, deleteFirewallRuleRequest, getFirewallsRequest} from '../../../Dashboard/actions';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useToasts } from "react-toast-notifications";
import Loader from '../../../Volumes/components/Loader/Loader';
import Modal from '../../../../components/Modal';

const DeleteModal = ({ t, onHide, show, deleteItem, firewall, isNavigete, handleDelete }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const navigate = useHistory();

    const handleGroupDelete = async () => {
        try {
            setLoading(true);
            try {
                await Promise.all(firewall?.rules?.map(({id: ruleId}) => deleteFirewallRuleRequest({ruleId, accountId: firewall?.instances[0].accountid})(dispatch)));
            } catch (e) {
                return
            }
            await deleteFirewallGroupRequest({ groupId: firewall.id })(dispatch);
            onHide()
            addToast(t("deleteModal.deleteSuccess"), {
                appearance: "success",
                autoDismiss: true
            });
            setLoading(false);
            await getFirewallsRequest()(dispatch);
            if (isNavigete) {
                navigate.push('/vpc-network/firewalls');
            }
        } catch (error) {
            setLoading(false);
            return addToast(error.message, {
                appearance: "error",
                autoDismiss: true
            });
        }
    }

    return (
        <div>
            <div>
                <Modal
                    Title={() => (
                        <span className="font-size-38 text-grey-3 font-weight-light">
                            {deleteItem === 'group' ? t("deleteModal.titleGroup") : t("deleteModal.titleRule")}
                        </span>
                    )}
                    onHide={onHide}
                    show={show}>
                    {deleteItem === 'group' ? <div className="font-size-16 text-grey-1 ">
                        <div>{t("deleteModal.warning1")}</div>
                        <div className="m-t-20 m-b-40">{t("deleteModal.warning2")}</div>
                    </div> : null}
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="flex row">
                            <button
                                onClick={() => onHide(false)}
                                className="button button--grey width-half flex-grow-1 m-l-15 m-r-15">
                                {t('deleteModal.cancel')}
                            </button>
                            <button
                                onClick={async () => { handleDelete ? handleDelete() : await handleGroupDelete(); }}
                                className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15">
                                {t('deleteModal.delete')}
                            </button>
                        </div>
                    )}
                </Modal>
            </div>
        </div>
    );
}

export default DeleteModal
