import { get } from "../components/http";

const checkJobStatus = async (accountid, jobid) => {
  let status;
  // TODO MAYBE REMOVE TRY CATCH
  try {
    status = await get(`/accounts/${accountid}/jobs/${jobid}`);
  } catch (error) {
    throw new Error(error);
  }
  return status.data.data;
};

export default checkJobStatus;
