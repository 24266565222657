export default {
  title: "Create New Instance",
  choosePlan: {
    title: "Choose a plan",
    help: "Help me choose a plan",
    standard: {
      title: "Standard",
      description: "Starter Performance Solution",
    },
    cpuOptimized: {
      title: "CPU-Optimized",
      description: "High Performance Solution",
    },
    memoryOptimized: {
      title: "Memory-Optimized",
      description: "High Performance Solution",
    },
    text: {
      standard: "The Standard Instance plan is preferred by businesses that need a balanced solution of CPU, RAM, and NVMe SSD resources.",
      cpuOptimized: "The CPU-Optimized plan is for applications that require high processing power and more CPU resources than RAM or NVMe SSD.",
      memoryOptimized: "The Memory-Optimized plan is for memory-focused use cases that demand more RAM resources than CPU or NVMe SSD.",
    },
    pricing: {
      showAll: "Show All Plans",
      description: "Each Instance adds more data transfer to your account.",
    },
    locations: {
      noLocations: "No available locations for this region",
      allLocations: "All locations",
      title: "Chose Server's Location",
      countries: {
        "DE": "Germany",
        "UK": "United Kingdom",
        "RU": "Russia",
        "FI": "Finland",
        "CA": "Canada",
        "BR": "Brazil",
        "US": "USA",
        "FR": "France",
        "IN": "India",
        "SG": "Singapore",
        "JP": "Japan",
        "NL": "Netherlands",
        "HK": "Hong Kong"
      }
    },
  },
  chooseImage: {
    title: "Choose an image",
    infoBox:
      "Standard virtual machines with a mix of memory and compute resources. Best for small projects that can handle variable levels.",
    tabs: {
      distributions: "Distributions",
      oneClick: "Apps",
      snapshot: "Snapshot",
      backups: "Backups",
      upload: "Upload ISO",
    },
    distributions: {
      ubuntu: "Ubuntu",
      freeBsd: "FreeBSD",
      fedora: "Fedora",
      debian: "Debian",
      centos: "Centos",
      windows: "Windows",
      selectVersion: "Select version",
    },
    loadAll: "Load all One-Click Aps list",
    minimizeAll: "Minimize all One-Click Aps list",
    oneClick: {
      placeholder: "Search keyword",
    },
  },
  chooseLocation: {
    title: "Chose Server's Location",
    filters: {
      allLocations: "All locations",
      americas: "Americas",
      europe: "Europe",
      australia: "Australia",
      asia: "Asia",
      oceania: "Oceania",
    },
    locations: {
      noLocations: "No locations avaiable",
    },
  },
  vpcNetwork: {
    title: "Network Type",
    description:
      "This resource can communicate over Private IP address only with other resources in the same VPC network.",
    learnMore: "Learn more",
    onPrivateIp: "This Droplet will have no Private IP",
    modal:
      "VPC networks provide a more secure connection between resources because the network is inaccessible from the public internet and other VPC networks.",
    networkSelect:"Choose the VPC network",
    locationSelect: "Select location first",
    vpcName:"VPC Name",
    cidr:"CIDR",
    cidrPlaceholder: "Example, 10.0.0.0/16",
    networkDomain:"Network Domain",
    acl:"ACL",
    gateway:"Gateway",
    netmask:"Netmask",
    basic: "Basic",
    advanced: "Advanced",
    basicDescription: "A public IP address will be assigned to instances deployed with this network type, and with no private network.",
    advancedDescription: "A VPC network equipped with a private network, firewall, and load balancer features"
  },
  rootPassword: {
    title: "Create Root Password",
    placeholder: "Type your password",
    modal:
      "Instead of using and emailing you a one-time password, in this field you can create a root password to access your instances.",
  },
  authentication: {
    title: "Authentification",
    sshKey: "SSH keys",
    sshDescription: "A more new and secure authentication method",
    passwordAuthentication: "Password",
    passwordAuthenticationDescription: "Receive a root password to access the instance (less secure)",
    password: {
      placeholder: "Type your root password here ...",
      securePassword: "Please store your password securely",
      laterMail: "You will not be sent later an email containing your chosen password.",
      passwordReqTitle: "Password requirements",
      passwordReqDescription: "Your password must be in length minimum eight characters, and should also include at least one lowercase letter, one uppercase letter and one number."
    },
    ssh: {
      title: "Choose your SSH keys",
      error: "Select at least on key",
      button: "Add SSH Key",
      selectAll: "Select All",
    },
  },
  additionalOptions: {
    title: "Select additional options",
    ipv6: "IPv6 Networking",
    userConf: "User Data Configuration",
    enableMonitoring: "Enable Monitoring",
    textareaPlaceholder: "Enter user data here",
  },
  conclude: {
    title: "Conclude and create",
    description:
      "You can select to create one or more instances with this same ",
    configuration: "configuration",
    configurationEmptyState:
      "You can select to create one or more instances with the same configuration",
    instance: "instance",
    instanceNameing:
      "Name your instances from down here to later easily remember them by",
    howMany: "How many Instances?",
    chooseName: "Choose a hostname",
    deployMultiple: "Deploy multiple instances with the same configuration.",
    placeholder: "Enter the name of your instance here",
  },
  addTags: {
    title: "Add tags",
    description:
      "Use tags to organize and relate resources. Tags may contain letters, numbers, colons, dashes, and underscores.",
    placeholder: "Type tags here",
    noVpc: "Tags are available only for vpc network",
  },
  selectProject: {
    title: "Select project",
    description: "Assign Instances to a project or create a new one",
  },
  selectDomain: {
    title: "Select domain",
    description: "Some description here",
  },
  backupWork: {
    title: "Backup your work",
    enable: "Enable BackUp",
    recommended: "RECOMMENDED",
    systemLevel: "System-Level backup",
    occuresDescription: "occurs once a week, and are retained for 4 weeks.",
    perInstance: "(per Instance)",
  },
  coupon: {
    enterPromo: {
      title: "Enter Promo Coupon",
      placeholder: "Enter Promo Code Here",
      button: "Validate Code",
    },
  },
  button: "Create instance",
  creditRequired: "Waiting for payment",
  deploying: "Deploy in progress",
  createNameError: "Name of instance should be provided",
  common: {
    addNew: "Add new",
  },
};
