import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Modal from "../../../../components/Modal";
import CustomField from "../../../../components/CustomField";
import { editNetwork, getNetworksRequest } from "../../../Dashboard/actions";
import Loader from '../../../../pages/Volumes/components/Loader/Loader';

const EditModal = ({
  show,
  onHide,
  t,
  network,
  startFetching,
  setDetailsNetwork
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [cidr, setCidr] = useState(network.cidr);
  const [name, setName] = useState(network.name);
  const [description, setDescription] = useState(network.displaytext);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditNetwork = async () => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { jobid }
        }
      } = await editNetwork({
        accountid: network.accountId,
        id: network.id,
        changecidr: cidr,
        displaytext: description,
        name: name
      })(dispatch);
      startFetching(jobid, () => {
        onHide();
        getNetworksRequest()(dispatch);
        addToast(t("networks.editModal.success"), {
          appearance: "success",
          autoDismiss: true
        });
        setDetailsNetwork(null);
        setIsLoading(false);
      }, network.accountId);
    } catch (error) {
      setIsLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };

  return (
    <Modal
      size="small"
      Title={() => (
        <span className="font-size-38 text-grey-3 font-weight-light">
          {t("networks.editModal.title")}
        </span>
      )}
      onHide={onHide}
      show={show}
    >
      <div className="Create_network_modal">
        <div className="modal_section">
          <span className="font-size-13 font-weight-semibold text-grey-3">{t("networks.editModal.name")}</span>
          <CustomField>
            <input
              name="name"
              type="text"
              defaultValue={name}
              onChange={(e) => { setName(e.target.value) }}
              placeholder={t("networks.editModal.placeholderName")}
            />
          </CustomField>
        </div>
        <div className="modal_section">
          <span className="font-size-13 font-weight-semibold text-grey-3">{t("networks.editModal.description")}</span>
          <CustomField>
            <input
              name="description"
              type="text"
              defaultValue={description}
              onChange={(e) => { setDescription(e.target.value) }}
              placeholder={t("networks.editModal.placeholderDescription")}
            />
          </CustomField>
        </div>
        <div className="modal_section">
          <span className="font-size-13 font-weight-semibold text-grey-3">{t("networks.editModal.cidr")}</span>
          <CustomField>
            <input
              name="cidr"
              type="text"
              defaultValue={cidr}
              onChange={(e) => { setCidr(e.target.value) }}
              placeholder={t("networks.editModal.placeholderCidr")}
            />
          </CustomField>
        </div>
        {isLoading ? (<Loader />) : (<div className="flex flex-justify-content-space-between m-t-30">
          <button className="modal_button cancel_button" onClick={() => onHide()}>
            {t("networks.editModal.cancel")}
          </button>
          <button
            disabled={!(name && description && cidr)}
            className={`modal_button confirm_button ${!(name && description && cidr) && "button--blueDisabled"}`}
            onClick={handleEditNetwork}>
            {t("networks.editModal.confirm")}
          </button>
        </div>)}
      </div>
    </Modal>
  );
};

export default EditModal;
