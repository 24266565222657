import React, {useContext, useEffect, useState} from 'react';

import Pagination from '../../../components/Pagination';
import OrizontalScroll from '../../../components/OrizontalScroll';

import emptyIll from '../../../assets/images/empty-invoices.svg';
import downloadIco from '../../../assets/images/download-ico.svg';

import { store } from '../../../store';

import { getInvoicesRequest, getInvoicesPdfRequest } from '../actions';
import { get } from '../../../components/http';
import AccountBalance from "./AccountBalance";
import Modal from "../../../components/Modal";
import AddCredit from "./AddCredit/AddCredit";
import Loader from "../../../components/Loader/Loader";

const BillingProfile = ({ t }) => {
  const billingProfileTrans = (path) => t(`billingProfile.${path}`);
  const [show, setShow] = useState();
  const globalState = useContext(store);
  const {
    dispatch,
    state: {
      invoices: { paginated: { totalpages } = {}, invoices } = {},
      invoicesPdf = {},
      clientDetails = {}
    } = {},
  } = globalState;
  useEffect(() => {
    async function onMount() {
      await getInvoicesRequest()(dispatch);
      await getInvoicesPdfRequest()(dispatch);
    }
    onMount();
  }, [dispatch]);
  useEffect(() => {
    async function onMount() {
      if (invoices?.length) {
        const invoice_ids = invoices.map(({ id }) => Number(id));
        await getInvoicesPdfRequest({ invoice_ids })(dispatch);
      }
    }
    onMount();
  }, [dispatch, invoices]);
  if (!invoices) {
    return <div className={"flex flex-justify-content-center"}><Loader /></div>;
  }

  const onPayClick = (id) => async () => {
    const { data } = await get(`/client/invoices/${id}/pay`);
    // eslint-disable-next-line no-undef
    window.open(data.data.payNowLink, '_self', 'noopener,noreferrer');
  };
  return (
    <div className="Invoices">
      <AccountBalance credit={clientDetails?.credit} action={() => setShow(true)} />
      <Modal show={show} onHide={() => setShow(false)}>
        <div>
          <AddCredit t={t} />
        </div>
      </Modal>
      {invoices.length ? (
        <>
          <OrizontalScroll>
            <OrizontalScroll.Scrollable>
              <div className="list-table m-b-30">
                <div className="list-table__heading">
                  <div>
                    <span className="font-weight-semibold">
                      {billingProfileTrans('history.tableHeads.id')}
                    </span>
                  </div>
                  <div className="text-align-center">
                    <span className="font-weight-semibold">
                      {billingProfileTrans('history.tableHeads.amount')}
                    </span>
                  </div>
                  <div className="text-align-left">
                    <span className="font-weight-semibold">
                      {billingProfileTrans('history.tableHeads.method')}
                    </span>
                  </div>
                  <div className="text-align-center">
                    <span className="font-weight-semibold">
                      {billingProfileTrans('history.tableHeads.status')}
                    </span>
                  </div>
                  <div>
                    <span className="font-weight-semibold">
                      {billingProfileTrans('history.tableHeads.date')}
                    </span>
                  </div>
                  <div>
                    <span className="font-weight-semibold">
                      {billingProfileTrans('history.tableHeads.dueDate')}
                    </span>
                  </div>
                  <div className="text-align-right"></div>
                </div>
                <div className="list-table__body">
                  {invoices.map(
                    ({
                      date,
                      duedate,
                      module: description,
                      grandtotal: amount,
                      status,
                      id,
                    }) => (
                      <div key={id} className="list-table__row">
                        <div>#{id}</div>
                        <div className="text-align-center">${amount}</div>
                        <div
                          className={`text-align-left ${
                            status === 'Unpaid' && 'text-blue-1 font-weight-semibold'
                          }`}>
                          {description || "Credit"}
                        </div>
                        <div className={`text-align-center position-relative ${status}`}>
                          {status}
                        </div>
                        <div>{date}</div>
                        <div>{duedate}</div>
                        <div className="text-align-right font-weight-regular">
                          {status === 'Paid' ? (
                            <>
                              <a
                                className="font-weight-medium"
                                href={`data:application/pdf;base64,${invoicesPdf[id]}`}
                                download="invoice.pdf">
                                <img className={"m-r-15"} src={downloadIco} alt="" />
                              </a>
                            </>
                          ) : ''}
                          {status === 'Unpaid' ? (
                            <div className="text-align-center">
                              <button
                                className="font-weight-semibold button button--blue button--s cursor-pointer"
                                onClick={onPayClick(id)}>
                                Pay now
                              </button>
                            </div>
                          ) : ''}
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </OrizontalScroll.Scrollable>
            <OrizontalScroll.Slider className="flex flex-justify-content-center m-b-50" />
          </OrizontalScroll>
          {totalpages && (
            <Pagination
              onChange={async (page) => await getInvoicesRequest(page - 1)(dispatch)}
              pages={totalpages}
            />
          )}
        </>
      ) : (
        <div className="BillingProfile__empty flex">
          <img className="m-r-50" src={emptyIll} alt="" />
          <div>
            <h3 className="text-grey-3 font-size-27 m-b-0 m-t-0 font-weight-regular">
              {billingProfileTrans('empty.title')}
            </h3>
            <p className="text-grey-1 m-t-5 font-size-16">
              {billingProfileTrans('empty.description')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingProfile;
