export default {
  title: "Support Tickets",
  newTicketTitle: "Create Ticket",
  description:
    "Do you have a question or problem? The support ticket system allows us to respond to your inquiries. Submit a ticket and we will get back to you.",
  tabs: {
    all: "All Tickets",
    opened: "Opened",
    answered: "Answered",
  },
  button: "New Ticket",
  table: {
    sender: "Sender",
    status: "Status",
    departament: "Departament",
    subject: "Subject",
    updated: "Last Updated at",
    created: "Created at",
    updatedBy: "Last Update by",
    ticket: "Ticket",
  },
  status: {
    opened: "Opened",
    closed: "Closed",
    pending: "Waiting for answer",
  },
  empty:
    "Do you have question or problem? The support ticket system allows us to responde to your inquiries. Submit a ticket and we will get back to you.",
  asRead: "mark as read",
  replay: {
    title: "Your Reply:",
    closeTicket: "Close Ticket",
    closeDescription:
      "Click this button only if your issue has been resolved, it is no longer applicable or you don't need it anymore!",
    submit: "Submit Reply",
    allowed: "Allowed File Extension:",
    success: "Successfully replied",
    successClosed: "Successfully closed ticket",
  },
  newTicket: {
    description:
      "Please provide ticket details below. When reporting a problem make sure to provide as much relevant information as possible.",
    departament: "Departament:",
    departamentDescription:
      "Current average response time for this department is approximately 6 hours.",
    subject: "Subject:",
    relatedServices: "Related Services:",
    yourMessage: "Your Message:",
    submit: "Submit Ticket",
    goBack: "Go Back(Cancel)",
  },
};
