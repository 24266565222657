import React, { useEffect, useRef, useState } from 'react';
import searchIco from '../../../assets/images/network/loupe_icon.svg';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { getInstancesRequest } from "../../../pages/Dashboard/actions";
import { osicons } from "../../../pages/Create/CreateInstance/components/ChooseImage/Tabs/Distributions";
import InfoBox from "../../InfoBox";
import pretty from "prettysize";
import ipLocation from "../../../assets/images/ip-location.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { icons } from "../../../pages/Create/CreateInstance/components/ChooseLocations";
import { locationsNaming } from "../../../pages/Dashboard/components/Resources";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const generateServerName = (displayname, search) => {
  const index = displayname?.toLowerCase().indexOf(search?.toLowerCase());
  const value = displayname.slice(index, search?.length + index)
  const nameArray = displayname.split(value)

  const html = nameArray.join(`<span class="text-blue-1">${value}</span>`)
  return html
}

const Results = ({ instances, loading, search, setShowResults, onChange, short }) => {
  const { t: commonT } = useTranslation('common');
  const [isCopied, setIsCopied] = useState(null);
  const history = useHistory();
  const onCopy = (ip) => {
    setIsCopied(ip)
    setTimeout(() => setIsCopied(null), 2000) // eslint-disable-line
  }
  return (
    <div className={"Search__results Dashboard"}>
      <div className={"ResourcesTab"}>
        {!search && !instances?.length ?
          <p>You currently don’t have any instance created</p> :
          (loading ?
            <Loader />
            :
            <ul>
              {instances.length ? instances.map(
                (
                  instanceDetails
                ) => {
                  const {
                    displayname,
                    templatedisplaytext: server,
                    state,
                    nic,
                    cpunumber,
                    vpc,
                    zonename: location,
                    memory,
                    storage,
                    id,
                    ipGeolocation,
                  } = instanceDetails;
                  const { ipaddress: ip, publicip } = nic[0];
                  const osName =
                    server.split('-')[0].split(' ').length > 1 ? server.split(' ')[0] : server.split('-')[0];
                  const osIco = osicons[osName]

                  return (
                    <div key={id} className={"Search__instance"}>
                      <li
                        onClick={onChange ? (() => {
                          onChange(instanceDetails)
                          setShowResults(false)
                        }) : (() => history.push(`/instance/${id}/graphs`))}
                        className={`flex flex-justify-content-space-between flex-align-items-center
                  ${(state === 'Running' && 'Instance-el__active') ||
                          (['Starting', 'Stopping'].includes(state)
                            ? 'Instance-el__pending'
                            : 'Instance-el__inactive'
                          )} 
                  `}
                      >
                        <div className='flex flex-align-items-center instance'>
                          <div className="instanceIco__status m-r-10">
                            <span
                              className={
                                `status ${(state === 'Running' && 'Instance__active') ||
                                (['Starting', 'Stopping'].includes(state)
                                  ? 'Instance__pending'
                                  : 'Instance__inactive')}`
                              }
                            />
                          </div>
                          <div className="name">
                            <a
                              className='font-weight-semibold font-size-16 m-r-20'
                              href='javascript:;'
                              onClick={() => setShowResults(false)}
                            >
                              <>
                                {search ? <span className={'text-grey-8'} dangerouslySetInnerHTML={{ __html: generateServerName(displayname, search) }} /> : displayname}
                              </>
                            </a>
                            <div className="font-weight-semibold font-size-12 text-grey-8">
                              <span className="m-r-5">{cpunumber} vCPUs</span>/
                              <span className="m-l-5 m-r-5">{pretty(memory * 1024 * 1024)} RAM</span>
                              {storage ? <span className="">{pretty(storage * 1024 * 1024)} Disk</span> : ''}
                            </div>
                          </div>
                        </div>
                        {!short && <div
                          className='ip text-grey-1 flex-grow-1 font-size-16 min-width-100 copy-wrapper position-relative max-width-100'>
                          {ipGeolocation && <img className='ip-location' src={ipLocation} alt='' />}
                          <span dangerouslySetInnerHTML={{ __html: generateServerName(vpc ? publicip : ip, search) }} />
                          <a href='javascript:;' onClick={event => {
                            event.preventDefault();
                            event.stopPropagation()
                          }}>
                            <CopyToClipboard text={vpc ? publicip : ip} onCopy={onCopy}>
                              <span
                                className='copy text-blue-1 m-l-10 position-relative'
                                style={{ zIndex: '1', cursor: 'pointer' }}
                              >
                                {isCopied === ip ? commonT('copied') : commonT('copy')}
                              </span>
                            </CopyToClipboard>
                          </a>
                        </div>}
                        {location && !short ? <div className="os">
                          <InfoBox
                            ico={osIco}
                            text={server}
                          />
                        </div> : ''}
                        {location ? <div className="datacenter">
                          <img src={icons[location.split('-')[1]]} />
                          {locationsNaming[location]}
                        </div> : ''}
                      </li>
                      <div className={"line"} />
                    </div>
                  );
                }
              ) : <p className={"text-grey-1"}>No resources found</p>}
            </ul>
          )}
      </div>
    </div>
  )
}

let outsideIsVisible;
let outsideFocused;

const Search = ({ hideOverley, onChange, short, selectedInstance, fullWidth, extraFilter, placeholder }) => {
  const { t } = useTranslation("header");
  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState(null);
  const [focused, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const { instances } = useSelector((state) => state.instances) || {};
  const dispatch = useDispatch();
  useEffect(() => {
    async function onMount() {
      setLoading(true)
      await getInstancesRequest()(dispatch);
    }
    if (instances === null) {
      onMount();
    }
  }, []); //eslint-disable-line
  useEffect(() => {
    if (instances) {
      setLoading(false)
    }
  }, [instances])

  let filteredInstances = instances

  if (extraFilter) {
    filteredInstances = filteredInstances?.filter(extraFilter)
  }

  if (searchValue) {
    filteredInstances = filteredInstances?.filter(({ displayname, nic }) => {
      const { ipaddress: ip } = nic[0]
      return (ip?.toLowerCase().includes(searchValue.toLowerCase()) || displayname.toLowerCase().includes(searchValue.toLowerCase()))
    })
  }

  if (!short) {
    outsideIsVisible = showResults;
  }

  useEffect(() => {
    const listenForCommands = (event) => {
      if (event.metaKey && event.code === "KeyB" && !short) {
        setShowResults(!outsideIsVisible)
        if (outsideFocused) {
          return inputRef.current.blur()
        }
        return inputRef.current.focus()
      }
    }
    document.addEventListener('keydown', listenForCommands); //eslint-disable-line
  }, [])

  useEffect(() => {
    const hideInstancesMenu = (e) => {
      if (
        e.target.className &&
        e.target.className.includes &&
        !e.target.className.includes('Card__body') &&
        !e.target.className.includes('openMenu')
      ) {
        setShowResults(null);
      }
    };
    window.addEventListener('click', hideInstancesMenu); // eslint-disable-line
    return () => {
      window.removeEventListener('click', hideInstancesMenu); // eslint-disable-line
    };
  }, []);

  return (
    <div className={`Search ${fullWidth && 'Search--full-width'}`}>
      {showResults && !hideOverley && <div onClick={() => setShowResults(false)} className={"Search__overley"} />}
      <form action="" autoComplete="new-password">
        <input
          ref={inputRef}
          className={selectedInstance && !focused && "color-transparent"}
          onChange={(e) => setSearchValue(e.target.value)}
          onFocus={() => {
            outsideFocused = true;
            setFocus(true)
            setShowResults(true)
          }}
          onBlur={() => {
            outsideFocused = false;
            setFocus(false)
          }}
          type="text"
          autoComplete="off"
          name="search"
        />
      </form>
      <div className="placeholder">
        <img src={searchIco} alt="" />
        <>
          {selectedInstance && !focused ?
            <div>
              {selectedInstance?.name}
            </div> :
            (
              <>
                {!searchValue && !focused &&
                  <>
                    <div className="hidden-xs">
                      {placeholder ? placeholder : <>{t('placeholder')} <span>{t('placeholderCommand')}</span></>}
                    </div>
                    <div className="visible-xs">{t('placeholderMobile')}</div>
                  </>}
              </>
            )}
        </>

      </div>
      {showResults && <Results instances={filteredInstances?.slice(0, 5)} t={t} loading={loading} search={searchValue} setShowResults={setShowResults} onChange={onChange} short={short} />}
    </div>
  );
};

export default Search;
