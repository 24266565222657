import React, { useRef } from "react";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";

import CustomField from "../../../../components/CustomField";
import { put } from "../../../../components/http";
import { getClientDetails } from "../../../../components/PrivateComponent/actions";

import checkedIco from "../../../../assets/images/checked.svg";
import phoneIco from "../../../../assets/images/creditCard/phone-ico.svg";
import addressIco from "../../../../assets/images/creditCard/address-ico.svg";
import nickIco from "../../../../assets/images/creditCard/nickname-ico.svg";
import cityIco from "../../../../assets/images/creditCard/city-ico.svg";
import geographyIco from "../../../../assets/images/creditCard/geography-ico.svg";
import postalIco from "../../../../assets/images/creditCard/postal-ico.svg";
import nameIco from "../../../../assets/images/creditCard/name-ico.svg";
import regionIco from "../../../../assets/images/creditCard/region-ico.svg";
import saveIco from "../../../../assets/images/save-ico.svg";
import {countries} from "countries-list";

const phoneRegExp =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; //eslint-disable-line

const EditProfile = Yup.object().shape({
  firstname: Yup.string().required("First name field is required"),
  lastname: Yup.string().required("Last name field is required"),
  phonenumber: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Phone number field is required"),
  company: Yup.string(),
  address1: Yup.string().required("Address field is required"),
  address2: Yup.string().required("Address field is required"),
  city: Yup.string().required("City field is required"),
  state: Yup.string().required("State field is required"),
  postcode: Yup.string().required("Post code field is required"),
});

const EditProfileForm = ({
  t,
  billingProfileTrans,
  firstname,
  lastname,
  phonenumber,
  companyname,
  address1,
  address2,
  city,
  postcode,
  state,
  countryname,
  country,
  dispatch,
}) => {
  const { addToast } = useToasts();
  const form = useRef();
  const billingCountries = Object.keys(countries).map((countryKey) => ({
    text: countries[countryKey].name,
    value: countryKey,
  }));
  billingCountries.sort((a, b) => {
    const nameA = a.text.toUpperCase(); // ignore upper and lowercase
    const nameB = b.text.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  return (
    <div className="">
      {!firstname && <p className={"text-red-2"}>We&apos;ve noticed that your profile isn&apos;t fully completed yet. Taking a few moments to fill in the missing details can enhance your experience with us. Plus, a complete profile helps us serve you better!</p>}
      <Formik
        initialValues={{
          firstname,
          lastname,
          phonenumber,
          companyname,
          address1,
          address2,
          city,
          postcode,
          state,
          countryname,
          country,
        }}
        validationSchema={EditProfile}
        onSubmit={async (values) => {
          let updateResult;
          try {
            updateResult = await put("/client/details",
              values,
            );
          } catch (error) {
            updateResult = error;
            return addToast(error.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          const { data: { error } = {} } = updateResult;
          if (error) {
            return addToast(error.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
          await getClientDetails()(dispatch);
          return addToast("Your account was succesfully updated", {
            appearance: "success",
            autoDismiss: true,
          });
        }}
      >
        {({ errors, touched, initialValues, handleChange, submitCount }) => {
          return (
            <Form ref={form}>
              <div className="ProfilePage__info-box flex flex-justify-content-space-between flex-mobile-column ">
                <div className="flex width-full-width">
                  <div className="EditProfileForm">
                    <div className={'form'}>
                      <div className="m-b-10 m-t-10">
                        <CustomField
                          {...{
                            errors,
                            required: true,
                            touched,
                            name: "firstname",
                            Ico: () => <img src={nickIco} className="ico" alt="" />,
                          }}
                        >
                          <input
                            name="firstname"
                            maxLength="50"
                            type="text"
                            placeholder={`${t("modals.editProfile.firstname")}`}
                            defaultValue={initialValues.firstname}
                            onChange={handleChange}
                          />

                          {initialValues.firstname && (
                            <img className="checked-ico" src={checkedIco} alt="" />
                          )}
                        </CustomField>
                      </div>
                      <div className="m-b-10 m-t-10">
                        <CustomField
                          {...{
                            errors,
                            required: true,
                            touched,
                            name: "lastname",
                            Ico: () => <img src={nickIco} className="ico" alt="" />,
                          }}
                        >
                          <input
                            name="lastname"
                            maxLength="50"
                            type="text"
                            placeholder={`${t("modals.editProfile.lastname")}`}
                            defaultValue={initialValues.lastname}
                            onChange={handleChange}
                          />

                          {initialValues.lastname && (
                            <img className="checked-ico" src={checkedIco} alt="" />
                          )}
                        </CustomField>
                      </div>
                      <div className="m-b-10 m-t-10">
                        <CustomField
                          {...{
                            errors,
                            required: true,
                            touched,
                            name: "address1",
                            Ico: () => <img src={addressIco} className="ico" alt="" />,
                          }}
                        >
                          <input
                            name="address1"
                            maxLength="50"
                            type="text"
                            placeholder={`${t("modals.editProfile.address")}`}
                            defaultValue={initialValues.address1}
                            onChange={handleChange}
                          />
                          {initialValues.address1 && (
                            <img className="checked-ico" src={checkedIco} alt="" />
                          )}
                        </CustomField>
                      </div>
                      <div className="m-b-10 m-t-10">
                        <CustomField
                          {...{
                            errors,
                            touched,
                            name: "phonenumber",
                            required: true,
                            Ico: () => (
                              <img width={14} src={phoneIco} className="ico phone-ico m-l-5" alt="" />
                            ),
                          }}
                        >
                          <input
                            name="phonenumber"
                            maxLength="50"
                            type="text"
                            placeholder={`${t("modals.editProfile.phoneNumber")}`}
                            defaultValue={initialValues.phonenumber}
                            onChange={handleChange}
                          />
                          {initialValues.phonenumber && (
                            <img className="checked-ico" src={checkedIco} alt="" />
                          )}
                        </CustomField>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'Billing'}>
                <h3 className="font-size-27 text-grey-2 font-weight-regular m-t-40 m-b-10">
                  {billingProfileTrans("title")}
                </h3>
                <div className="flex row flex-mobile-column">
                  <CustomField
                    {...{
                      errors,
                      touched,
                      submitCount,
                      required: true,
                      className: "flex-grow-1 m-l-15 m-r-15 m-t-15 m-b-10",
                      name: "address2",
                      Ico: () => <img src={addressIco} className="ico" alt="" />,
                    }}
                  >
                    <input
                      name="address2"
                      maxLength="50"
                      type="text"
                      placeholder={`${billingProfileTrans("billingAddress2")}`}
                      defaultValue={initialValues.address2}
                      onChange={handleChange}
                    />
                  </CustomField>
                  <CustomField
                    {...{
                      errors,
                      touched,
                      submitCount,
                      required: true,
                      className: "flex-grow-1 m-l-15 m-r-15 m-b-10 m-t-15",
                      name: "city",
                      Ico: () => <img src={cityIco} className="ico" alt="" />,
                    }}
                  >
                    <input
                      name="city"
                      maxLength="50"
                      type="text"
                      placeholder={`${billingProfileTrans("billingCity")}`}
                      defaultValue={initialValues.city}
                      onChange={handleChange}
                    />
                  </CustomField>
                </div>
                <div className="flex row flex-mobile-column">
                  <CustomField
                    {...{
                      errors,
                      touched,
                      submitCount,
                      required: true,
                      className: "flex-grow-1 m-l-15 m-r-15 m-t-15 m-b-10",
                      name: "state",
                      Ico: () => <img src={geographyIco} className="ico" alt="" />,
                    }}
                  >
                    <input
                      name="state"
                      maxLength="50"
                      type="text"
                      placeholder={`${billingProfileTrans("billingRegion")}`}
                      defaultValue={initialValues.state}
                      onChange={handleChange}
                    />
                  </CustomField>
                  <CustomField
                    {...{
                      errors,
                      touched,
                      submitCount,
                      required: true,
                      className: "flex-grow-1 m-l-15 m-r-15 m-t-15 m-b-10",
                      name: "postcode",
                      Ico: () => <img src={postalIco} className="ico" alt="" />,
                    }}
                  >
                    <input
                      name="postcode"
                      maxLength="50"
                      type="text"
                      placeholder={`${billingProfileTrans("billingPostalCode")}`}
                      defaultValue={initialValues.postcode}
                      onChange={handleChange}
                    />
                  </CustomField>
                  <CustomField
                    {...{
                      errors,
                      touched,
                      submitCount,
                      className: "flex-grow-1 m-l-15 m-r-15 m-b-10 m-t-15",
                      name: "companyname",
                      Ico: () => <img src={nameIco} className="ico" alt="" />,
                    }}
                  >
                    <input
                      name="companyname"
                      maxLength="50"
                      type="text"
                      placeholder={billingProfileTrans("companyName")}
                      defaultValue={initialValues.companyname}
                      onChange={handleChange}
                    />
                  </CustomField>
                </div>

                <div className="flex row flex-mobile-column">
                  <CustomField
                    {...{
                      errors,
                      touched,
                      submitCount,
                      className:
                        "flex-grow-1 m-l-15 m-r-15 m-t-15 m-b-10 billingCountry width-full-width flex",
                      Ico: () => <img src={regionIco} className="ico" alt="" />,
                    }}
                  >
                    <Field
                      name="country"
                      component="select"
                      placeholder={"Select country"}
                      onChange={handleChange}
                      defaultValue={country || billingCountries[0].value}
                    >
                      {billingCountries.map(({ text, value }) => (
                        <option key={value} value={value}>
                          {text}
                        </option>
                      ))}
                    </Field>
                  </CustomField>
                </div>
                <div className="flex row flex-mobile-column">
                  <div className="flex-grow-1 width-full-width m-l-15 m-r-15 m-b-10 m-t-15"/>
                  <div className="flex-grow-1 width-full-width m-l-15 m-r-15 m-b-10 m-t-15"/>
                </div>
                <div className="button-container flex row flex-mobile-column m-t-40">
                  <div className="flex-grow-1 width-full-width m-l-15 m-r-15 m-b-10 m-t-15"/>
                  <div className="flex-grow-1 width-full-width m-l-15 m-r-15 m-b-10 m-t-15"/>
                  <div className="flex-grow-1 width-full-width m-l-15 m-r-15 m-b-10 m-t-15">
                    <button
                      type="submit"
                      className="button button--blue width-full-width flex flex-align-items-center"
                    >
                      <img src={saveIco} className="ico m-r-10" alt="" />
                      {billingProfileTrans("button")}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditProfileForm;
