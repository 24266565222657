import React from 'react';

const InfoBox = ({ text, ico, className, classNameBox, children, sm }) => {
  return (
    <div className={`InfoBox ${classNameBox}`}>
      {children ||
        (ico ? (
          <img className="InfoBox__ico-image" src={ico} alt="" />
        ) : (
          <span className={`InfoBox__ico ${sm ? 'InfoBox__ico--small' : ''}`}>i</span>
        ))}
      <div className={"InfoBox__container"}>
        <div className={`${className} InfoBox__content`}>{text}</div>
      </div>
    </div>
  );
};

export default InfoBox;
