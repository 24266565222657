import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const StyledLinearProgress = styled(LinearProgress)(() => ({
  height: '3.7142857143rem',
  borderRadius: '0.4285714286rem',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#17d175',
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <Box sx={{ width: '100%', position: 'relative'}}>
        <StyledLinearProgress variant="determinate" value={props.value}
                              sx={{ backgroundColor: props.active ? 'rgba(23, 209, 117, 0.3)' : '#17d175'}} />
        <Box sx={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        }}>
          {props.active &&
            <Typography variant="body2" sx={{ fontFamily: 'Arial', color: 'white', fontSize: '15px', fontWeight: 'bold'}}>
              {`${Math.round(props.value)}% ${props.label}`}
            </Typography>}
          {!props.active &&
            <Typography variant="body2" sx={{ fontFamily: 'Arial', color: 'white', fontSize: '15px', cursor: 'pointer', fontWeight: 'bold'}}>Create Instance</Typography>}
        </Box>
      </Box>
    </Box>
  );
}

let timer;

export default function LinearWithValueLabel({active, setActive}) {
  const [progress, setProgress] = React.useState(0);
  const [label, setLabel] = React.useState('');

  React.useEffect(() => {
    if(active) {
      window.history.pushState({}, null, 'create-instance?deploy-in-progress') // eslint-disable-line
      timer = setInterval(() => { // eslint-disable-line
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 2));
        if(progress < 50) {
          setLabel('Validating order...');
        } else {
          setLabel('Provisioning your cloud instance, please wait...');
        }
        if(progress === 96) {
          setLabel('Instance almost done, please wait...');
          clearInterval(timer); // eslint-disable-line
          return
        }
        if(progress >= 100) {
          setLabel('Instance created successfully. You can now start using it.');
          setActive(false);
        }
      }, (30 + Math.random() * 29) * 10); // between 300ms to 590ms per tick so that total time will be between 50-59 seconds
      return () => {
        clearInterval(timer); // eslint-disable-line
      };
    }
  }, [progress, active]);

  const handleCreateClick = () => {
    setActive(true);
    setProgress(1);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} label={label} onClick={handleCreateClick} active={active} />
    </Box>
  );
}
