import React from 'react';
import Locations from './Locations';

const ChooseLocation = ({ t, ...props }) => {
  return (
    <div className="ChooseLocation">
      <h3 className="font-size-27 text-grey-2 font-weight-regular m-b-20 m-t-40 flex flex-justify-content-space-between">
        {t('title')}
      </h3>
      <p className="text-grey-1 font-size-16 m-t-25 m-b-25">
        {t('description')}{' '}
        <a className="font-weight-semibold" href="">
          {t('learnMore')}
        </a>
      </p>
      <Locations {...props} t={t} />
      <p className="text-grey-1 font-size-16 m-t-15 m-b-0">{t('firstLocation')}</p>
    </div>
  );
};

export default ChooseLocation;
