import { createSlice } from "@reduxjs/toolkit";
import { get, post } from "../../components/http";

const slice = createSlice({
  name: "support",
  initialState: {
    tickets: [],
    ticketPaginationData: {},
    ticketsLoading: false,
    ticketDetails: {},
    ticketDetailsLoading: false,
    ticketReplayLoading: false,
    relatedServices: [],
    departments: [],
    openTicketLoading: false,
  },
  reducers: {
    getTickets: (state, action) => {
      state.tickets = action.payload;
    },
    getTicketPaginationData: (state, action) => {
      state.ticketPaginationData = action.payload;
    },
    setTicketsLoading: (state, action) => {
      state.ticketsLoading = action.payload;
    },
    getTicketDetails: (state, action) => {
      state.ticketDetails = action.payload;
    },
    setTicketDetailsLoading: (state, action) => {
      state.ticketDetailsLoading = action.payload;
    },
    getRelatedServices: (state, action) => {
      state.relatedServices = action.payload;
    },
    getDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setOpenTickedLoading: (state, action) => {
      state.openTicketLoading = action.payload;
    },
  },
});

export default slice.reducer;

const {
  getTickets: getTicketsAction,
  getTicketPaginationData,
  setTicketsLoading,
  getTicketDetails: getTicketDetailsAction,
  setTicketDetailsLoading,
  getRelatedServices: getRelatedServicesAction,
  getDepartments: getDepartmentsAction,
  setOpenTickedLoading,
} = slice.actions;

export const getTicketsRequest = (page) => async (dispatch) => {
  dispatch(setTicketsLoading(true));
  const { data: { data: { tickets, paginated } = {} } = {} } = await get(
    "/client/tickets",
    {
      params: { page },
    }
  );
  dispatch(getTicketsAction(tickets));
  dispatch(getTicketPaginationData(paginated))
  dispatch(setTicketsLoading(false));
};

export const getTicketDetailsRequest =
  ({ ticket_number }) =>
  async (dispatch) => {
    dispatch(setTicketDetailsLoading(true));
    const {
      data: { data },
    } = await get(`/client/tickets/${ticket_number}`);
    dispatch(
      getTicketDetailsAction({ ...data, replies: data.replies })
    );
    dispatch(setTicketDetailsLoading(false));
  };

export const replyToTicketRequest =
  ({ ticketNumber, message }) =>
  async (dispatch) => {
    let replyResult;
    dispatch(setTicketDetailsLoading(true));
    try {
      replyResult = await post(`/client/tickets/${ticketNumber}/reply`, {
        body: message,
      });
    } catch (error) {
      dispatch(setTicketDetailsLoading(false));
      throw new Error(error.message);
    }
    if (replyResult.data.error) {
      dispatch(setTicketDetailsLoading(false));
      throw new Error(replyResult.data.error.message);
    }
    dispatch(getTicketDetailsRequest({ ticket_number: ticketNumber }));
  };

export const closeTicketRequest =
  ({ ticketNumber }) =>
    async (dispatch) => {
      let replyResult;
      dispatch(setTicketDetailsLoading(true));
      try {
        replyResult = await post(`/client/tickets/${ticketNumber}/status`, {
          status: 'Closed',
        });
      } catch (error) {
        dispatch(setTicketDetailsLoading(false));
        throw new Error(error.message);
      }
      if (replyResult.data.error) {
        dispatch(setTicketDetailsLoading(false));
        throw new Error(replyResult.data.error.message);
      }
      dispatch(getTicketsRequest());
    };

export const getRelatedServicesRequest = () => async (dispatch) => {
  const {
    data: {
      data: { virtualmachines },
    },
  } = await get(`/client/virtualmachines`);
  dispatch(getRelatedServicesAction(virtualmachines));
};

export const getDepartmentsRequest = () => async (dispatch) => {
  const {
    data: { data },
  } = await get(`/client/tickets/departments`);
  dispatch(getDepartmentsAction(data));
};

export const createNewTicketRequest =
  ({ service, id, subject, message }) =>
  async (dispatch) => {
    dispatch(setOpenTickedLoading(true));
    let createNewTicketResult;
    try {
      createNewTicketResult = await post(`/client/tickets`, {
        dept_id: id,
        subject: subject,
        body: message,
        related_service: service,
      });
    } catch (error) {
      dispatch(setOpenTickedLoading(false));
      throw new Error(error);
    }
    if (createNewTicketResult.data.error) {
      dispatch(setOpenTickedLoading(false));
      throw new Error(createNewTicketResult.data.error.message);
    }
    dispatch(setOpenTickedLoading(false));
  };
