import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Card from '../../../../../components/Card';
import Tabs from '../../../../../components/Tabs';
import engFlag from '../../../../../assets/images/chooseLocations/eng-flag.svg';
import genFlag from '../../../../../assets/images/chooseLocations/ger-flag.svg';
import fraFlag from '../../../../../assets/images/chooseLocations/fra-flag.svg';
import finFlag from '../../../../../assets/images/chooseLocations/fin-flag.svg';
import canFlag from '../../../../../assets/images/chooseLocations/can-flag.svg';
import usaFlag from '../../../../../assets/images/chooseLocations/usa-flag.svg';
import braFlag from '../../../../../assets/images/chooseLocations/bra-flag.svg';
import indFlag from '../../../../../assets/images/chooseLocations/ind-flag.svg';
import rusFlag from '../../../../../assets/images/chooseLocations/rus-flag.svg';
import hkFlag from '../../../../../assets/images/chooseLocations/hk-flag.svg';
import sgFlag from '../../../../../assets/images/chooseLocations/sg-flag.svg';
import japFlag from '../../../../../assets/images/chooseLocations/jap-flag.svg';
import nlFlag from '../../../../../assets/images/chooseLocations/nth-flag.svg';

import {getProductsByCity, setSelectedLocation} from '../../../actions';

// eslint-disable-next-line
export const icons = {
  DE: genFlag,
  UK: engFlag,
  FI: finFlag,
  FIN: finFlag,
  CA: canFlag,
  US: usaFlag,
  NYC: usaFlag,
  FLO: usaFlag,
  BR: braFlag,
  FR: fraFlag,
  IN: indFlag,
  RU: rusFlag,
  NL: nlFlag,
  HK: hkFlag,
  JP: japFlag,
  SG: sgFlag
};

// eslint-disable-next-line
const Locations = ({t, withTitle, isAssignModal = false, isLoadBalancer, enabledLocations}) => {
  const isMount = useRef(false);
  const reduxDispatch = useDispatch();
  const [currentFilter, setCurrentFilter] = React.useState('allLocations');
  const {locations, selectedRegion, products, regions} = useSelector(({products}) => products);

  const unfrozenLocations = locations.slice()

  const locationByRegion = React.useMemo(
    () =>
      currentFilter === 'allLocations'
        ? unfrozenLocations.filter(({visible}) => visible).sort((a, b) => a.sort_order - b.sort_order)
        : unfrozenLocations.filter(({region}) => region === currentFilter).filter(({visible}) => visible).sort((a, b) => a.sort_order - b.sort_order),
    [unfrozenLocations, currentFilter],
  );

  useEffect(() => {
    if (!isMount.current) {
      getProductsByCity(selectedRegion?.city, products)(reduxDispatch);
      if (selectedRegion) {
        isMount.current = true;
      }
    }
  }, [products, selectedRegion, isMount.current]);

  useEffect(() => {
    if (isLoadBalancer) {
      const {disabledLocationIds, type, defaultZoneId} = isLoadBalancer;
      if (type === 'create' && disabledLocationIds) {
        const choosenLocation = locations?.find(location =>  location.acs_id === disabledLocationIds[disabledLocationIds.length - 1]);
        if (choosenLocation) {
          setSelectedLocation(choosenLocation, products)(reduxDispatch)
        }
      }

      if (type === 'edit' && defaultZoneId) {
        const choosenLocation = locations.find(location => location.active && location.acs_id === defaultZoneId);
        if (choosenLocation) {
          setSelectedLocation(choosenLocation, products)(reduxDispatch)
        }
      }
    }
  }, [isLoadBalancer, locations])

  const isDisabled = (acs_id) => {
    if (isLoadBalancer) {
      const {disabledLocationIds, type, defaultZoneId} = isLoadBalancer;
      if (type === 'create' && disabledLocationIds) {
        return !disabledLocationIds?.includes(acs_id);
      }

      if (type === 'edit' && defaultZoneId) {
        return acs_id !== defaultZoneId;
      }
    }
  }

  useEffect(() => {
    if (enabledLocations) {
      const choosenLocation = locations.find(location => location.acs_id === enabledLocations[0]);
      if (choosenLocation) {
        setSelectedLocation(choosenLocation, products)(reduxDispatch)
      }
    }
  }, [enabledLocations, locations])

  return (
    <div className="Locations">
      {withTitle && (
        <h3 className="font-size-20 text-grey-2 font-weight-regular m-b-20 flex flex-justify-content-space-between">
          {t('locations.title')}
        </h3>
      )}
      {!isAssignModal && <Tabs>
        <Tabs.Header>
          <ul className="tabs">
            <li
              className={`tabs-item ${currentFilter === 'allLocations' ? 'tabs-item--active' : ''}`}
              onClick={() => setCurrentFilter('allLocations')}>
              {t('locations.allLocations')}
            </li>
            {regions.map((region) => (
              <li
                className={`tabs-item ${region === currentFilter ? 'tabs-item--active' : ''}`}
                key={region}
                onClick={() => setCurrentFilter(region)}>
                {region}
              </li>
            ))}
          </ul>
        </Tabs.Header>
        <Tabs.Content></Tabs.Content>
      </Tabs>}
      <div className="m-b-20 locations-list">
        {locationByRegion.length > 0 ? (
          locationByRegion.map(({city, country, id, active, acs_id, ...rest}) => {
            return (
              <Card key={id} className="Card--title-bottom Card--full-border">
                <Card.Body
                  disabled={!active || (isLoadBalancer ? isDisabled(acs_id) : false) || (enabledLocations && !enabledLocations?.includes(acs_id))}
                  onClick={() => {
                    !isLoadBalancer?.defaultZoneId && setSelectedLocation({
                      city,
                      country,
                      id,
                      acs_id, ...rest
                    }, products)(reduxDispatch);
                  }}
                  className={`flex-align-items-center text-grey-4 ${id === selectedRegion?.id && 'active'
                  } ${isDisabled(acs_id) || !active || (enabledLocations && !enabledLocations?.includes(acs_id)) ? ' disabled' : ''}`}>
                  <img className="m-r-10 m-l-5" src={icons[country]} alt=""/>
                  <div>
                    <div className="text-grey-2 font-size-16">{city}</div>
                    <div className="text-grey-1 font-size-14">{t(`locations.countries.${country}`)}</div>
                  </div>
                </Card.Body>
              </Card>
            )
          })
        ) : (
          <p className="text-grey-1 font-size-16 font-weight-light p-l-15 no-locations">
            {t('locations.noLocations')}
          </p>
        )}
      </div>
    </div>
  );
};

export default Locations;
