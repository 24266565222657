import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getInstancesByNetworkRequest } from '../../../Dashboard/actions';
import useComponentVisible from '../../../../helpers/useComponentVisible';
import InfoBox from "../../../../components/InfoBox";
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import netIco from '../../../../assets/images/network/network_list_item_icon.svg';
import copyIco from '../../../../assets/images/network/copy_icon.svg';
import ConfigIco from '../../../../assets/images/config-icon.svg';
import TrashIco from '../../../../assets/images/tickets/trash_icon.svg'

const NetworkListItem = ({ network, t, i, instancesbyNetwork, setInstancesbyNetwork, setDetailsNetwork, city, startFetching }) => {
	const [visibleModal, setVisibleModal] = useState(null);
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(null, 'more');
	const dispatch = useDispatch();
	const { t: commonT } = useTranslation('common');
	const { addToast } = useToasts();

	useEffect(() => {
		getInstancesByNetworkRequest(network.accountId, network.id, setInstancesbyNetwork)(dispatch);
	}, [network])

	const toDetails = () => {
		setDetailsNetwork({ ...network, city: city, instancesCount: instancesbyNetwork[network.id].count });
	}

	return (
		<div key={network.id} className='list_item'>
			<div className='Main_content'>
				<div onClick={() => { toDetails() }} className="cursor-pointer" ><img src={netIco} /> {network.name}</div>
				<div>
					<span onClick={() => { toDetails() }} >{network.cidr}</span>
					<CopyToClipboard
						text={network.cidr}
						onCopy={() => {
							addToast(t("networks.successCopy"), {
								appearance: "success",
								autoDismiss: true,
								autoDismissTimeout: 2000,
							});
						}}>
						<img src={copyIco} />
					</CopyToClipboard>
				</div>
				<div onClick={() => { toDetails() }}>{instancesbyNetwork[network.id] && instancesbyNetwork[network.id].count}</div>
				<div onClick={() => { toDetails() }}>{network.state}</div>
			</div>
			<div className="text-align-center text-blue-1 flex flex-align-items-center max-width-70 more position-relative">
				<a
					className="lighter font-weight-regular"
					href="javascript:;"
					id='more'
					onClick={() =>
						isComponentVisible !== i ? setIsComponentVisible(i) : setIsComponentVisible(null)
					}>
					{commonT('more')}
					<span
						className={`arrow fomt-weight-semibold ${isComponentVisible === i ? 'arrow--up' : 'arrow--down'
							} arrow--blue m-l-5`}
					/>
				</a>
				{isComponentVisible !== null && isComponentVisible === i && (
					<div ref={ref} className="keyPop popup m-t-10 popup_menu">
						<div className="text-grey-1 font-size-14 font-weight-semibold text-grey-1" onClick={() => { setVisibleModal("edit") }}>
							<img src={ConfigIco} /> {t('networks.edit')}
						</div>
						<div className="line_cuted m-t-10 m-b-10"></div>
						{instancesbyNetwork[network.id] && instancesbyNetwork[network.id].count > 0 && <InfoBox className="delete_infobox" text={t('networks.details.deleteDisabled')}>
							<div className="text-grey-1 font-size-14 font-weight-semibold text-grey-1 Disabled_Delete_Item">
								<img src={TrashIco} /> <span>{t('networks.delete')}</span>
							</div>
						</InfoBox>}
						{instancesbyNetwork[network.id] && instancesbyNetwork[network.id].count === 0 &&
							<div className="text-grey-1 font-size-14 font-weight-semibold text-grey-1" onClick={() => { setVisibleModal("delete") }}>
								<img src={TrashIco} /> {t('networks.delete')}
							</div>}
					</div>
				)}
			</div>
			<EditModal
				onHide={() => {
					setVisibleModal(null);
					setIsComponentVisible(null);
				}}
				show={visibleModal === 'edit'}
				startFetching={startFetching}
				setDetailsNetwork={setDetailsNetwork}
				network={network}
				t={t}
			/>
			<DeleteModal
				show={visibleModal === 'delete'}
				networkDetails={network}
				setDetailsNetwork={setDetailsNetwork}
				setVisibleModal={setVisibleModal}
				t={t}
				onHide={() => {
					setVisibleModal(null);
					setIsComponentVisible(null);
				}}
				startFetching={startFetching}
			/>
		</div>
	)
}

export default NetworkListItem;
