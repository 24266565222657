import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const publicBandwidth = [
  {
    name: "10:00",
    read: 2,
    write: 2,
  },
  {
    name: "10:30",
    read: 4,
    write: 6,
  },
  {
    name: "11:00",
    read: 8,
    write: 2,
  },
  {
    name: "11:30",
    read: 4,
    write: 2,
  },
  {
    name: "12:00",
    read: 6,
    write: 8,
  },
  {
    name: "12:30",
    read: 10,
    write: 10,
  },
];

const cpuUsage = [
  {
    name: "10:00",
    read: 10,
    write: 30,
  },
  {
    name: "10:30",
    read: 20,
    write: 10,
  },
  {
    name: "11:00",
    read: 30,
    write: 40,
  },
  {
    name: "11:30",
    read: 40,
    write: 60,
  },
  {
    name: "12:00",
    read: 80,
    write: 20,
  },
  {
    name: "12:30",
    read: 20,
    write: 10,
  },
];

const CustomTooltip = ({ payload, label, currency }) => {
  return (
    <div className="custom-tooltip">
      <p className="label text-grey-1 m-b-0 m-t-0">{label}</p>
      {payload.map(({ stroke, name, value }) => (
        <div key={name} className="flex flex-align-items-center">
          <span className="line" style={{ background: stroke }}></span>
          <span className="name text-grey-1 to-rem-12 m-r-5">{name}:</span>
          <span className="value" style={{ color: stroke }}>
            {value} {currency}
          </span>
        </div>
      ))}
    </div>
  );
};

const CustomLabel = (value) => {
  return value ? `${value} Kbps` : "";
};

const CustomPercentageLabel = (value) => {
  return value ? `${value} %` : "";
};

const Graphs = ({ t }) => {
  return (
    <div className="Graphs flex-grow-1">
      <div className="Grafs__content max-width-full-width">
        <div className="flex row m-b-50">
          <div className="width-half m-l-15 m-r-15" style={{ height: 200 }}>
            <h3>{t("requestsPerSecond")}</h3>
            <ResponsiveContainer>
              <LineChart
                width={500}
                height={300}
                data={publicBandwidth}
                margin={{
                  top: 5,
                  bottom: 5,
                  left: 0,
                }}
              >
                <CartesianGrid stroke="#C4C8D6" vertical={false} />
                <XAxis
                  dataKey="name"
                  stroke="0"
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis tickFormatter={CustomLabel} stroke="0" tickCount="6" />
                <Tooltip content={<CustomTooltip currency="Kbps" />} />
                <Line
                  type="linear"
                  dataKey="read"
                  stroke="#17D175"
                  dot={{ r: 5 }}
                />
                <Line
                  type="linear"
                  dataKey="write"
                  stroke="#0069FF"
                  dot={{ r: 5 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="width-half m-l-15 m-r-15" style={{ height: 200 }}>
            <h3>{t("connections")}</h3>
            <ResponsiveContainer>
              <LineChart
                width={500}
                height={300}
                data={cpuUsage}
                margin={{
                  top: 5,
                  bottom: 5,
                  left: 0,
                }}
              >
                <CartesianGrid stroke="#C4C8D6" vertical={false} />
                <XAxis
                  dataKey="name"
                  stroke="0"
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis
                  tickFormatter={CustomPercentageLabel}
                  stroke="0"
                  tickCount="6"
                />
                <Tooltip content={<CustomTooltip currency="Kbps" />} />
                <Line
                  type="linear"
                  dataKey="read"
                  stroke="#17D175"
                  dot={{ r: 5 }}
                />
                <Line
                  type="linear"
                  dataKey="write"
                  stroke="#0069FF"
                  dot={{ r: 5 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="flex row m-b-50">
          <div className="width-half m-l-15 m-r-15" style={{ height: 200 }}>
            <h3>{t("responses")}</h3>
            <ResponsiveContainer>
              <LineChart
                width={500}
                height={300}
                data={publicBandwidth}
                margin={{
                  top: 5,
                  bottom: 5,
                  left: 0,
                }}
              >
                <CartesianGrid stroke="#C4C8D6" vertical={false} />
                <XAxis
                  dataKey="name"
                  stroke="0"
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis tickFormatter={CustomLabel} stroke="0" tickCount="6" />
                <Tooltip content={<CustomTooltip currency="Kbps" />} />
                <Line
                  type="linear"
                  dataKey="read"
                  stroke="#17D175"
                  dot={{ r: 5 }}
                />
                <Line
                  type="linear"
                  dataKey="write"
                  stroke="#0069FF"
                  dot={{ r: 5 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="width-half m-l-15 m-r-15" style={{ height: 200 }}>
            <h3>{t("trafficReceived")}</h3>
            <ResponsiveContainer>
              <LineChart
                width={500}
                height={300}
                data={cpuUsage}
                margin={{
                  top: 5,
                  bottom: 5,
                  left: 0,
                }}
              >
                <CartesianGrid stroke="#C4C8D6" vertical={false} />
                <XAxis
                  dataKey="name"
                  stroke="0"
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis
                  tickFormatter={CustomPercentageLabel}
                  stroke="0"
                  tickCount="6"
                />
                <Tooltip content={<CustomTooltip currency="Kbps" />} />
                <Line
                  type="linear"
                  dataKey="read"
                  stroke="#17D175"
                  dot={{ r: 5 }}
                />
                <Line
                  type="linear"
                  dataKey="write"
                  stroke="#0069FF"
                  dot={{ r: 5 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="flex row m-b-50">
          <div className="width-half m-l-15 m-r-15" style={{ height: 200 }}>
            <h3>{t("sessionDuration")}</h3>
            <ResponsiveContainer>
              <LineChart
                width={500}
                height={300}
                data={publicBandwidth}
                margin={{
                  top: 5,
                  bottom: 5,
                  left: 0,
                }}
              >
                <CartesianGrid stroke="#C4C8D6" vertical={false} />
                <XAxis
                  dataKey="name"
                  stroke="0"
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis tickFormatter={CustomLabel} stroke="0" tickCount="6" />
                <Tooltip content={<CustomTooltip currency="Kbps" />} />
                <Line
                  type="linear"
                  dataKey="read"
                  stroke="#17D175"
                  dot={{ r: 5 }}
                />
                <Line
                  type="linear"
                  dataKey="write"
                  stroke="#0069FF"
                  dot={{ r: 5 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="width-half m-l-15 m-r-15" style={{ height: 200 }}>
            <h3>{t("averageResponse")}</h3>
            <ResponsiveContainer>
              <LineChart
                width={500}
                height={300}
                data={cpuUsage}
                margin={{
                  top: 5,
                  bottom: 5,
                  left: 0,
                }}
              >
                <CartesianGrid stroke="#C4C8D6" vertical={false} />
                <XAxis
                  dataKey="name"
                  stroke="0"
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis
                  tickFormatter={CustomPercentageLabel}
                  stroke="0"
                  tickCount="6"
                />
                <Tooltip content={<CustomTooltip currency="Kbps" />} />
                <Line
                  type="linear"
                  dataKey="read"
                  stroke="#17D175"
                  dot={{ r: 5 }}
                />
                <Line
                  type="linear"
                  dataKey="write"
                  stroke="#0069FF"
                  dot={{ r: 5 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graphs;
