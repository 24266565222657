import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../../pages/Volumes/components/Loader/Loader";
import {Link, useHistory} from "react-router-dom";
import {getTicketsRequest} from "../../../../pages/Tickets/actions";
import line from "../../../../assets/images/fadding-line.svg"
import technicalIcoActive from "../../../../assets/images/header/technical-ico.svg"
import billingIcoActive from "../../../../assets/images/header/billing-ico.svg"
import technicalIco from "../../../../assets/images/header/technical-ico-grey.svg"
import billingIco from "../../../../assets/images/header/billing-ico-grey.svg"

const deptIcon = (isNew, deptname) => {
  if (deptname?.toLowerCase().includes('technical')) {
    return isNew ? technicalIcoActive : technicalIco
  } else if (deptname?.toLowerCase().includes('billing')) {
    return isNew ? billingIcoActive : billingIco
  } else if (deptname?.toLowerCase().includes('commercial')) {
    return isNew ? billingIcoActive : billingIco
  }
};

const Notifications = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation("header");
  const {tickets, ticketsLoading} = useSelector(({support}) => support);
  const history = useHistory()

  const handleTicketStatus = (status) => {
    if (status === "Answered") return "new";
    if (status === "Open") {
      return "closed";
    }
    return "pending";
  }

  useEffect(() => {
    getTicketsRequest()(dispatch);
  }, []);
  return (
    <div className="Notifications width-full-width">
      <div
        className="Notifications__heading flex flex-justify-content-space-between flex-align-items-center p-l-20 p-r-20 m-t-30 m-b-20">
        <span className="text-grey-1 font-weight-medium">{t("notifications.title")}</span>
        <Link to="/tickets" className="button button--sm">
          {t("notifications.button")}
        </Link>
      </div>
      <div className="Notifications__content">
        {ticketsLoading ? (
          <Loader/>
        ) : (
          tickets.slice(0, 5).map(({id, tsubject, deptname, lastreply, status}) => (
            <div onClick={() => history.push('/tickets')} key={id} className={`cursor-pointer flex ${status !== "Open" && "new"}`}>
              <div
                className={`flex flex-align-items-center flex-justify-content-flex-start status status--${handleTicketStatus(status)}`}
              />
              <div className={`flex flex-align-items-center ${status !== "Open" && "text-blue-1"}`}>{tsubject}</div>
              <div className={`flex flex-align-items-center min-width-120 ${status !== "Open" && "text-blue-1"}`}>
                <img src={deptIcon(status !== "Open", deptname)} alt={deptname}/>
                {deptname}
              </div>
              <div className={`flex flex-justify-content-flex-end flex-align-items-center ${status !== "Open" && "text-blue-1"}`}>
                <div>{lastreply}</div>
              </div>
              <img className="line" src={line}/>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Notifications;
