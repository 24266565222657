import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import CustomSelect from "../../components/CustomSelect";
import CustomField from "../../components/CustomField";
import BillingGrey from "../../assets/images/tickets/billing_gray.svg";
import TechGray from "../../assets/images/tickets/tech_gray.svg";
import SubmitIcon from "../../assets/images/tickets/submit_icon.svg";
import RelServiceIco from "../../assets/images/tickets/related-service-icon.svg";
import BackArrow from "../../assets/images/tickets/back-arrow.svg";
import {
  createNewTicketRequest,
  getDepartmentsRequest,
  getRelatedServicesRequest,
} from "./actions";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]); //eslint-disable-line
}

const NewTicket = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { relatedServices, departments, openTicketLoading } = useSelector(
    ({ support }) => support
  );
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const { t } = useTranslation("tickets");
  useEffect(() => {
    getDepartmentsRequest()(dispatch);
    getRelatedServicesRequest()(dispatch);
  }, []);

  const query = useQuery();
  const queryMessage = query.get('message')

  const icons = {
    1: TechGray,
    2: BillingGrey
  }
  return (
    <div className="NewTicket">
      <div className="container">
        <div>
        <Link to="/tickets" className="flex">
          <img src={BackArrow} className="m-r-15"/>
          <h2 className="title text-blue-1">{t("newTicketTitle")} </h2>
        </Link>
        <Formik
          initialValues={{
            subject: queryMessage,
            message: "",
            other: null,
          }}
          onSubmit={async ({ subject, message, other }) => {
            try {
              await createNewTicketRequest({
                subject,
                message,
                service: selectedService
                  ? selectedService === "Other"
                    ? other
                    : selectedService
                  : relatedServices[0]?.name,
                id: selectedDepartment
                  ? selectedDepartment
                  : departments[0]?.id,
              })(dispatch);
              history.push("/tickets");
              return addToast(t("Created"), {
                appearance: "success",
                autoDismiss: true,
              });
            } catch (error) {
              return addToast(error.message, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }}
        >
          {({ errors, touched, values }) => (
            <Form>
              <div className="m-b-40">
                <div className="">
                  <p className="font-size-16 text-grey-1 line-height-1-4">
                    {t("newTicket.description")}
                  </p>
                  <div className="departament ">
                    <h3 className="font-size-20   text-grey-1 font-weight-regular">
                      {t("newTicket.departament")}
                    </h3>
                    <div className="flex related">
                      <CustomSelect
                        Ico={() => <img src={icons[selectedDepartment] || icons[1]} />}
                        className="max-width-222 m-r-30 text-grey-1"
                        onChange={(value) => {
                          setSelectedDepartment(value)
                        }}
                        options={[
                          ...departments.map((department) => ({
                            text: department.name,
                            value: department.id,
                          })),
                        ]}
                      />
                      <div className="description-container">
                        <p className="font-size-16 text-grey-1">
                          {t("newTicket.departamentDescription")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex subject-container">
                    <div className="width-full-width">
                      <h3 className="font-size-20 text-grey-1 font-weight-regular">
                        {t("newTicket.subject")}
                      </h3>
                      <CustomField
                        {...{
                          errors,
                          touched,
                          className: "",
                          name: "subject",
                        }}
                      >
                        <Field name="subject" type="text" placeholder={t("")} />
                      </CustomField></div>
                    <div className="flex flex-align-items-flex-end width-full-width">
                      <div className="width-full-width related">
                        <h3 className="font-size-20 text-grey-1 font-weight-regular">
                          {t("newTicket.relatedServices")}
                        </h3>
                        <CustomSelect
                          Ico={() => <img src={RelServiceIco} />}
                          className="blue-text-select"
                          onChange={(text) => setSelectedService(text)}
                          options={[
                            ...relatedServices.map((instance) => ({
                              text: instance.displayname,
                              value: instance.displayname,
                            })),
                            { text: "Other", value: "Other" },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="message">
                    <h3 className="font-size-20 text-grey-1 font-weight-regular">
                      {t("newTicket.yourMessage")}
                    </h3>
                    <Field
                      as="textarea"
                      name="message"
                      className="border-radius-6 m-b-30"
                      style={{ resize: "none" }}
                    />
                    <div className="flex m-b-30 flex-justify-content-space-between">
                      <div/>
                      <button
                        className="button button--blue min-width-150 flex buttom-file-upload"
                        type="submit"
                        disabled={
                          !values.message ||
                          !values.subject ||
                          openTicketLoading
                        }
                      >
                        {!(!values.message ||
                          !values.subject ||
                          openTicketLoading) && <img src={SubmitIcon}/>}
                        {t("newTicket.submit")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        </div>
      </div>
    </div>
  );
};

export default NewTicket;
