import React from "react";
import CustomSelect from "../../components/CustomSelect";

const ListItem = ({ text, ...props }) => (
  <li {...props} className="flex flex-align-items-center p-l-5 p-t-10 p-b-10">
    <div>
      <div className="text-grey-3 font-size-15 font-weight-semibold">
        {text}
      </div>
    </div>
  </li>
);

const SelectDomain = ({ domainList, onChange, large }) => {
  return (
    <div>
      <CustomSelect
        className={`${large ? "Field--md" : "Field--sm"} m-b-10`}
        search
        noCustomStyle
        ListItem={ListItem}
        onChange={(domainName) => {
          const [selectedDomain] = domainList.filter(
            ({ value }) => value === domainName
          );
          onChange && onChange(selectedDomain);
        }}
        options={
          domainList &&
          domainList.map(({ name, ...props }) => ({
            value: name,
            text: name,
            ...props,
          }))
        }
      />
    </div>
  );
};

export default SelectDomain;
