import React, { useState, useMemo } from 'react'
import CustomField from '../../../../components/CustomField';
import FirewallRule from './FirewallRule';
import Loader from '../../../../components/Loader/Loader';

const FirewallRulesList = ({ t, rules, handleRuleDelete, selectedFirewall, handleRuleEdit, handleCreate, setFirewallName, firewallName, isButtonDisabled, handleEdit }) => {
    const [loading, setLoading] = useState(false);
    const { rulesICMP, otherRules } = useMemo((() => {
        const rulesICMP = rules?.filter(rule => rule.protocol === 'icmp');
        const otherRules = rules?.filter(rule => rule.protocol !== 'icmp');

        return { rulesICMP, otherRules }
    }), [rules]);

    const isEditButtonDisabled = useMemo(() => {
        const rulesType = [];

        if(rules?.length !== selectedFirewall?.rules?.length) {
            return false;
        }

        for (const rule of rules) {
            if (!rule.cidrlist) {
                return true;
            }

            if ((rule.protocol !== 'icmp') && (!rule.startport || !rule.endport)) {
                return true;
            }

            if ((rule.protocol === 'icmp') && (!rule.icmptype || !rule.icmpcode)) {
                return true;
            }

            if(rule.type) {
                rulesType.push(rule.type)
            }
        }

        if(!rulesType.length) {
            return true;
        }

        return false;
    }, [rules]);

    return (
        <div className='Firewall_rules'>
            {otherRules && otherRules.length ? <div className='Rules_list'>
                <div className='List_header'>
                    <div>{t('firewallRules.protocol')}</div>
                    <div>{t('firewallRules.portRange')}</div>
                    <div>{t('firewallRules.source')}</div>
                </div>
                <div className='List_body'>
                    {otherRules.map((rule, i) =>
                        <FirewallRule
                            handleRuleDelete={handleRuleDelete}
                            handleRuleEdit={handleRuleEdit}
                            deletable={rules.length > 1 ? true : false}
                            variant='details'
                            key={rule.id}
                            rule={rule}
                            i={i}
                            t={t}
                        />)}
                </div>
            </div> : null}
            {rulesICMP && rulesICMP.length ? <div className='Rules_list_ICMP'>
                <div className='List_header'>
                    <div>{t('firewallRules.protocol')}</div>
                    <div>{t('firewallRules.type')}</div>
                    <div>{t('firewallRules.code')}</div>
                    <div>{t('firewallRules.source')}</div>
                </div>
                <div className='List_body'>
                    {rulesICMP.map((rule, i) =>
                        <FirewallRule
                            handleRuleDelete={handleRuleDelete}
                            handleRuleEdit={handleRuleEdit}
                            deletable={rules.length > 1 ? true : false}
                            key={rule.id}
                            rule={rule}
                            i={i}
                            t={t}
                        />)}
                </div>
            </div> : null}
            {selectedFirewall ? <button
                disabled={isEditButtonDisabled}
                onClick={async () => { await handleEdit(setLoading) }}
                className='button button--blue full_button m-t-20'
            >
                {loading ? <div className='flex flex-justify-content-center'><Loader /></div> : t("firewallRules.saveRules")}
            </button> : null}
            {!selectedFirewall ? <div className='m-t-40'>
                <div className='Name_input_container'>
                    <div className='font-size-27 text-grey-3'>{t('firewallRules.name')}</div>
                    <div className='font-size-16 text-grey-1'>{t('firewallRules.description')}</div>
                    <CustomField>
                        <input
                            onChange={(e) => { setFirewallName(e.target.value) }}
                            value={firewallName}
                            placeholder={t("firewallRules.placeholder")}
                            type="text"
                        />
                    </CustomField>
                </div>
                <button
                    className='button button--blue full_button'
                    onClick={async () => { await handleCreate(setLoading) }}
                    disabled={isButtonDisabled}
                >
                    {loading ? <div className='flex flex-justify-content-center'><Loader /></div> : t("firewallRules.save")}
                </button>
            </div> : null}
        </div>
    )
}

export default FirewallRulesList;
