import React from "react";
import { Link } from "react-router-dom";
import Tabs from "../../../../../components/Tabs";
import * as Components from "../components";
import LoadBalancerNameAndState from "../components/LoadBalancerNameAndState";
import LearnAbout from "../components/LearnAbout";

const tabs = ["instances", "graphs", "settings"];

const BalancerDetails = ({
  t,
  loadBalancerId,
  activeBalancer: { status, id, name, location, instances } = {},
  networkSubTab,
}) => {
  const TabComponent = Components[networkSubTab || "instances"];

  return (
    <div className="m-t-40">
      <Link to={`/network/load-balancers`}>
        {" "}
        <span className="font-weight-semibold display-inline-block m-b-30">
          {"<"} {t(`goBack`)}
        </span>
      </Link>
      <LoadBalancerNameAndState
        {...{ t, status, id, name, location, instances, withLink: false }}
      />
      <Tabs className="m-t-30">
        <Tabs.Header>
          <ul>
            {tabs.map((tab) => {
              return (
                <li key={tab}>
                  <Link
                    className={networkSubTab === tab && "active"}
                    to={`/network/load-balancers/${loadBalancerId}/${tab}`}
                  >
                    {t(`tabs.${tab}`)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Tabs.Header>
        <Tabs.Content>
          <TabComponent t={t} />
        </Tabs.Content>
      </Tabs>
      <br />
      <br />
      <LearnAbout t={t} />
    </div>
  );
};

export default BalancerDetails;
