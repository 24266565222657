import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { availableAlgorithm, availableRules } from "../../../../Create/CreateOrEditLoadBalancer/constants";
import { useTranslation } from "react-i18next";
import { icons } from "../../../../Create/CreateInstance/components/ChooseLocations";
import LoadBalancerNameAndState from "./LoadBalancerNameAndState";
import useComponentVisible from "../../../../../helpers/useComponentVisible";
import DeleteBalancer from "./DeleteBalancer/DeleteBalancer";
import DeleteIco from "../../../../../assets/images/tickets/trash_icon.svg";
import ConfigIco from "../../../../../assets/images/config-icon.svg";

const LoadBalancersList = ({ t, loadBalancers, accountid }) => {
  const [deleteLb, setDeleteLb] = useState(null);
  const { id } = useParams();
  const { t: commonT } = useTranslation("common");
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(null, 'more');

  const handleProtocol = (protocol) => {
    const choosenProtocol = availableRules.find(proto => proto.value === protocol);

    return choosenProtocol?.text;
  }

  const handleAlgorithm = (algorithm) => {
    const choosenAlgorithm = availableAlgorithm.find(algo => algo.value === algorithm);

    return choosenAlgorithm?.text;
  }

  const getRegionIcon = (zonename) => {
    let regionKey;
    Object.keys(icons).forEach((key) => {
      if (zonename?.toLowerCase().includes(key.toLowerCase())) {
        regionKey = key;
      }
    });

    return icons[`${regionKey}`];
  }

  return (
    <div>
      <div className="Create">
        <div className="container">
          <div className="flex flex-justify-content-space-between flex-align-items-center create-lb m-t-30 m-b-40">
            <div className="max-width-850">
              <div className="font-weight-light font-size-36 text-grey-2 m-b-25">{t("title")}</div>
              <div className="font-weight-regular font-size-16 text-grey-1 line-height-1-6">{t("description")}</div>
            </div>
            <Link to={id && accountid ? `/create-load-balancer/${id}/${accountid}` : "/create-load-balancer/"}>
              <button className="button button--blue button--md m-t-0 Create_lb_button">
                + {t("noLoadBalancers.button")}
              </button>
            </Link>
          </div>
          <div className="LB_list font-size-16 m-b-40">
            <div className="List_header flex">
              <div>{t("name")}</div>
              <div>{t("region")}</div>
              <div>{t("instances")}</div>
              <div>{t("protocols")}</div>
              <div>{t("algoritms")}</div>
              <div className="text-align-center text-blue-1 flex flex-align-items-center max-width-70 more position-relative"></div>
            </div>
            <div className="List_body">
              {loadBalancers.map(
                (loadBalancer, i) => (
                  <div key={loadBalancer.id} className="flex flex-align-items-center width-full-width text-grey-1 List_item">
                    <Link to={`/load-balancer/${loadBalancer.id}/edit`} className="Main_content">
                      <div className="m-t-0 m-b-0 m-l-0">
                        <LoadBalancerNameAndState {...loadBalancer} />
                      </div>
                      <div className="m-t-0 m-b-0 m-l-0">
                        <div className="flex flex-align-items-center font-size-15 text-grey-1">
                          <img width="32" height="22" className="m-r-5" src={getRegionIcon(loadBalancer?.zonename)} />
                          {loadBalancer?.zonename}
                        </div>
                      </div>
                      <div className="m-t-0 m-b-0 text-grey-3 font-weight-semibold font-size-15">
                        <div>{loadBalancer.instancesCount}</div>
                      </div>
                      {loadBalancer.protocols.length ? <div className="m-t-0 m-b-0 Prot_Alg_Container">
                        <div className="flex flex-align-items-center">
                          <div className="Prot_Alg_Content">
                            {handleProtocol(loadBalancer?.protocols[0])}
                          </div>
                          <span className="text-blue-1 font-size-13 font-weight-semibold m-l-10">
                            {loadBalancer?.protocolsCount - 1 > 0 ? `+${loadBalancer?.protocolsCount - 1}` : ''}
                          </span>
                        </div>
                      </div> : <div></div>}
                      {loadBalancer.algorithms.length ? <div className="m-t-0 m-b-0 Prot_Alg_Container">
                        <div className="flex flex-align-items-center">
                          <div className="Prot_Alg_Content">
                            {handleAlgorithm(loadBalancer?.algorithms[0])}
                          </div>
                          <span className="text-blue-1 font-size-13 font-weight-semibold m-l-10">
                            {loadBalancer?.algorithmsCount - 1 > 0 ? `+${loadBalancer?.algorithmsCount - 1}` : ''}
                          </span>
                        </div>
                      </div> : <div></div>}
                    </Link>
                    <div className="text-align-center text-blue-1 flex flex-align-items-center max-width-70 more position-relative">
                      <a
                        className="lighter font-weight-regular"
                        href="javascript:;"
                        id='more'
                        onClick={() =>
                          isComponentVisible !== i ? setIsComponentVisible(i) : setIsComponentVisible(null)
                        }>
                        {commonT('more')}
                        <span
                          className={`arrow fomt-weight-semibold ${isComponentVisible === i ? 'arrow--up' : 'arrow--down'
                            } arrow--blue m-l-5`}
                        />
                      </a>
                      {isComponentVisible !== null && isComponentVisible === i && (
                        <div ref={ref} className="keyPop popup m-t-10 popup_menu">
                          <Link to={`/load-balancer/${loadBalancer.id}/edit`} className="flex text-grey-1 font-size-14 font-weight-semibold text-grey-1">
                            <img src={ConfigIco} className="m-r-7" /> {t("more.configure")}
                          </Link>
                          <div className="line_cuted m-t-10 m-b-10"></div>
                          <div className="text-grey-1 font-size-14 font-weight-semibold text-grey-1" onClick={() => setDeleteLb(loadBalancer)}>
                            <img src={DeleteIco} /> {t("more.delete")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>)
              )}
            </div>
            <DeleteBalancer
              show={!!deleteLb}
              loadBalancer={deleteLb}
              t={t}
              onHide={() => {
                setDeleteLb(null);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadBalancersList;
