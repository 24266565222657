import React, { useState, useEffect } from "react";
import Modal from "../../../../components/Modal";
import { useSelector, useDispatch } from "react-redux";
import CustomSelect from "../../../../components/CustomSelect";
import { getAssignSecondaryIpRequest, getNicsListRequest } from "../../../Dashboard/actions";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../Volumes/components/Loader/Loader";

const AssignModal = ({ show, onHide, accountid, id, title, nic, t, startFetching }) => {
  const { nicsList } = useSelector(({ instances }) => instances);
  const [selectedNic, setSelectedNic] = useState(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = useToasts();
  //need this, because initially we show first nic in dropdown
  useEffect(() => {
    nicsList.length && setSelectedNic(nicsList[0].id);
  }, [nicsList]);
  const handleAssign = async () => {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await getAssignSecondaryIpRequest({
        accountid,
        machineId: id,
        nicId: nic ? nic : selectedNic
      })(dispatch);
      startFetching(jobid, () => {
        getNicsListRequest({ accountid, virtualmachineId: id })(dispatch);
        setIsLoading(false);

        onHide();
        addToast(t("nics.assignModal.success"), {
          appearance: "success",
          autoDismiss: true
        });
      });
    } catch (error) {
      onHide();
      setIsLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  return (
    <div>
      <div>
        <Modal
          Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{title}</h2>}
          onHide={onHide}
          show={show}
        >
          {!nic && (
            <CustomSelect
              onChange={(selected) => setSelectedNic(selected)}
              options={
                nicsList &&
                nicsList.map(({ id, deviceid, ...props }) => ({
                  value: id,
                  text: `NIC - ${deviceid}`,
                  ...props
                }))
              }
            />
          )}
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex row m-t-20">
              <button onClick={onHide} className="button button--grey width-half flex-grow-1 m-l-15 m-r-15">
                {t("nics.cancel")}
              </button>
              <button onClick={handleAssign} className="button width-half flex-grow-1 m-l-15 m-r-15">
                {t("nics.confirm")}
              </button>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default AssignModal;
