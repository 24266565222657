export default {
  nav: {
    dashboard: "Dashboard",
    profile: "Profile",
    profileDescription: "Information and other data",
    billing: "Billing",
    billingDescription: "Invoices, bills and plans",
    network: "Network",
    networkDescription: "Network",
    support: "Support",
    volumes: "Volumes",
    volumesDescription: "Volumes",
    supportDescription: "Contact us",
    loadBalancers: "Load Balancers",
    loadBalancersDescription: "Load Balancers",
    affiliate: "Affiliate",
    affiliateDescription: "Your personal earnings",
    account: "Account",
    accountDescription: "Personal data and stats",
    notifications: "Tickets List",
    notificationsDescription: "You have one new notifications",
    claimbonus: "Claim Coupon",
  },
};
