import React, {useEffect, useRef, useState} from 'react';
import {getAccountsRequest, getNetworksRequest} from '../../../../Dashboard/actions';
import {useDispatch, useSelector} from 'react-redux';
import {getShapehostNetworksRequest, setNetworkType} from '../../../actions';
import Checkbox from "../../../../../components/Checkbox";
import AdvancedNetworks from "./AdvancedNetworks";
import OutsideAlerter from "../../../../../components/OutsideClick";

const VpcNetwork = ({
    t,
    dispatch,
    createInstanceAction,
    state: {createInstance: {vpcNetwork = 0, networkType, advancedNetwork, isWindowsOS} = {}} = {}
  }) => {
  const reduxDispatch = useDispatch();
  const mounted = useRef();
  const [openAdvanced, setOpenAdvanced] = useState(false)
  //   const networks = useSelector((state) => state.instances.networks);
  //   const userHaveAccounts = useSelector(
  //     (state) => !!state.instances.accounts.filter((account) => account.status === 'Active').length
  //   );
  const selectedRegion = useSelector((state) => state.products.selectedRegion);
  //   const transformedNetworks =
  //     networks &&
  //     selectedRegion &&
  //     networks
  //       .filter((network) => network.zoneid === selectedRegion.acs_id)
  //       .map((network) => ({
  //         ...network,
  //         text: network.name,
  //         value: network.id
  //       }));

  useEffect(() => {
    const onMount = async () => {
      await getNetworksRequest()(reduxDispatch);
      await getAccountsRequest()(reduxDispatch);
    };
    if (vpcNetwork && !mounted.current) {
      mounted.current = true;
      onMount();
    }
  }, [vpcNetwork, mounted.current]);

  //   useEffect(() => {
  //     if (networkIdForNoVpc?.id) {
  //       createInstanceAction({ networkId: networkIdForNoVpc.acs_id })(dispatch);
  //     }
  //   }, [networkIdForNoVpc, selectedRegion]);

  useEffect(() => {
    const onMount = async () => {
      await getShapehostNetworksRequest()(reduxDispatch);
      createInstanceAction({networkType: 'basic'})(dispatch);
    };
    onMount();
  }, []);

  useEffect(() => {
    setOpenAdvanced(networkType === 'advanced')
  }, [networkType])

  //   useEffect(() => {
  //     const needToValidateNetwork = !!transformedNetworks?.length && userHaveAccounts;
  //     reduxDispatch(setNeedToValidateNetwork(needToValidateNetwork));
  //   }, [userHaveAccounts, transformedNetworks]);

  const handleChangeVpc = (e) => {
    reduxDispatch(setNetworkType(e.target.value));
    createInstanceAction({networkType: e.target.value})(dispatch);
  };

  return (
    <div className="VpcNetwork m-b-30">
      <h3 className="font-size-20 text-grey-2 font-weight-regular m-b-15 m-t-25">{t('title')}</h3>
      <div className='flex row flex-mobile-column'>
        <div className='p-l-15 p-r-15 flex-grow-1 width-half'>
          <label
            className={`auth-box flex flex-grow-1 flex-justify-content-space-between ${networkType === 'basic' && 'active'}`}
          >
            <div className='flex'>
              <Checkbox
                checked={networkType === 'basic'}
                onChange={handleChangeVpc}
                className='Checkbox--full-box m-r-10'
                type='radio'
                value='basic'
                name='network'
              />
              <div>
                <div className='text-grey-6 font-size-16 font-weight-semibold'>{t('basic')}</div>
                <div className='text-grey-1 font-size-16'>{t('basicDescription')}</div>
              </div>
            </div>
          </label>
        </div>
        <div className={`p-l-15 p-r-15 flex-grow-1 width-half ${(!selectedRegion?.advanced_networking || isWindowsOS) && 'grey-out'}`}>
          <label
            onClick={() => {
              selectedRegion?.advanced_networking && setOpenAdvanced(true)
            }}
            className={`auth-box flex flex-grow-1 flex-justify-content-space-between ${openAdvanced && 'open'} ${networkType === 'advanced' && 'active'}`}
          >
            <div className='flex width-full-width'>
              <Checkbox
                checked={networkType === 'advanced'}
                onChange={handleChangeVpc}
                className='Checkbox--full-box m-r-10'
                type='radio'
                value='advanced'
                name='network'
              />
              <div className={`width-full-width`}>
                {
                  !advancedNetwork ?
                    <>
                      <div className='text-grey-6 font-size-16 font-weight-semibold'>{t('advanced')}</div>
                      <div className='text-grey-1 font-size-16'>{t('advancedDescription')}</div>
                    </> :
                    <div className={`selectedAdvanced`}>
                      <span className={`name`}>{advancedNetwork?.displaytext}</span>
                      <span className={`ip`}>{advancedNetwork?.cidr}</span>
                    </div>
                }
              </div>
            </div>
          </label>
          {networkType === 'advanced' && <OutsideAlerter outsideClick={() => {
            setOpenAdvanced(false)
          }}>
            <AdvancedNetworks
              onClick={(value) => {
                createInstanceAction({advancedNetwork: value})(dispatch)
              }} selectedRegion={selectedRegion} open={openAdvanced} setOpen={setOpenAdvanced} advancedNetwork={advancedNetwork}
            />
          </OutsideAlerter>}
        </div>
      </div>
    </div>
  );
};

export default VpcNetwork;
