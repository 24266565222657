import React from "react";
import { Nav } from "../../Sidebar/index.js";
import { useTranslation } from "react-i18next";

import userIco from "../../../../assets/images/header/user-ico.svg";
import logoutIco from "../../../../assets/images/header/logout-ico.svg";
import { post } from "../../../http";
import notificationIco from "../../../../assets/images/header/notification-ico.svg";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const MobileNav = ({
  clientDetails: { firstname, lastname, email },
  setActiveDropdown,
}) => {
  const { t } = useTranslation("sidebar");
  const { t: headerT } = useTranslation("header");
  const history = useHistory();
  const { addToast } = useToasts();
  return (
    <div className="MobileNav">
      <ul className="profile no-top-border p-t-0">
        <li>
          <Link className="openDropdown" to="/profile">
            <img className="openDropdown" src={userIco} alt="" />
            <div>
              <span className="openDropdown">
                {(firstname || lastname) && `${firstname} ${lastname}`}
              </span>
              <span className="description openDropdown">{email}</span>
            </div>
          </Link>
        </li>
      </ul>
      <Nav t={t} removeItems={["profile", "dashboard"]} />
      <ul className="profile">
        <li>
          <a
            href="javascript:;"
            onClick={() => setActiveDropdown("Notifications")}
          >
            <div className="notification cursor-pointer m-r-20">
              <img className="openDropdown" src={notificationIco} alt="" />
              <span className="openDropdown">1</span>
            </div>
            <div>
              <span className="openDropdown">{t("nav.notifications")}</span>
              <span className="description openDropdown">
                {t("nav.notificationsDescription")}
              </span>
            </div>
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <a
            href="javascript:;"
            onClick={async () => {
              try {
                await post("logout");
                localStorage.removeItem("atkn"); //eslint-disable-line
                history.push("/login");
              } catch (error) {
                return addToast("Something went wrong", {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            }}
            className="m-r-10"
          >
            <img width="30" src={logoutIco} alt="" />
            <div>
              <span>{headerT("nav.signout")}</span>
              <span className="description">
                {headerT("nav.signoutDescription")}
              </span>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MobileNav;
