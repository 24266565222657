import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "../../../../../../components/Modal";
import {useToasts} from "react-toast-notifications";

const SavePassword = ({ t, password, onHide }) => {
  const { addToast } = useToasts();
  return (
    <div>
      <Modal
        onHide={onHide}
        show={true}
      >
          <>
            <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-25">
              {t("resetPassword.modal.resetedDescription1")}
              <span className="font-weight-semibold text-grey-3">{t("resetPassword.modal.resetedDescriptionBold1")}</span>
              {t("resetPassword.modal.resetedDescription2")}
              <span className="font-weight-semibold text-grey-3">{t("resetPassword.modal.resetedDescriptionBold2")}</span>
              {t("resetPassword.modal.resetedDescription3")}
            </p>
            <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-25">
              {password}
              <CopyToClipboard
                text={password}
                className="m-l-10 text-blue-1 font-size-15 cursor-pointer"
                onCopy={() => {
                  addToast(t("resetPassword.successCopy"), {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 2000,
                  });
                }}>
                <span className="text-blue-1 font-size-15">{t("resetPassword.modal.copy")}</span>
              </CopyToClipboard>
            </p>
            <button
              onClick={() => {
                onHide();
              }}
              className="button button--green width-full-width"
            >
              {t("resetPassword.modal.success")}
            </button>
          </>
      </Modal>
    </div>
  );
};

export default SavePassword;
