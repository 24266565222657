import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import supportIco from "../../../assets/images/header/support-ico.svg";
import supportIcoBlue from "../../../assets/images/header/support-ico-blue.svg";
import notificationIco from "../../../assets/images/header/notification-ico.svg";
import notificationIcoBlue from "../../../assets/images/header/notification-ico-blue.svg";
import userIco from "../../../assets/images/header/user-ico.svg";
import userIcoDark from "../../../assets/images/header/user-ico-dark.svg";
import newIco from "../../../assets/images/header/new-ico.svg";
import Dropdowns from "./Dropdowns";
import Card from "../../Card";
import { store } from "../../../store";
import Loader from "../../Loader/Loader";

const Nav = ({ t }) => {
  const location = useLocation();
  const globalState = useContext(store);
  const { state: { clientDetails } = {} } = globalState;
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { credit } = clientDetails || {};

  useEffect(() => {
    //eslint-disable-next-line
    window.addEventListener("click", (e) => {
      if (
        e.target.className &&
        e.target.className.includes &&
        !e.target.className.includes("openDropdown")
      ) {
        setActiveDropdown(null);
      }
    });
  }, []);

  useEffect(() => {
    setActiveDropdown(false);
  }, [location]);

  const Dropdown = (props) =>
    Dropdowns[activeDropdown] ? (
      <Card>
        <Card.Body>{Dropdowns[activeDropdown](props)}</Card.Body>
      </Card>
    ) : (
      ""
    );
  return (
    <nav className={`Nav Nav__${activeDropdown} flex flex-align-items-center`}>
      <a
        href="javascript:;"
        onClick={() =>
          setActiveDropdown(activeDropdown === "Create" ? null : "Create")
        }
        className="button button--blue button--md m-r-30 openDropdown"
      >
        <img src={newIco} />
        {t("button")}
      </a>
      <div className="hidden-xs hidden-xs-flex">
        <Link to="/tickets" className="m-r-10 hover-parent">
          <img width="30" className={"not-hover"} src={supportIco} alt="" />
          <img width="30" className={"hover"} src={supportIcoBlue} alt="" />
        </Link>
        <div
          onClick={() =>
            setActiveDropdown(
              activeDropdown === "Notifications" ? null : "Notifications"
            )
          }
          className={`notification hover-parent ${activeDropdown === "Notifications" && 'active'} openDropdown cursor-pointer m-r-20`}
        >
          <img className="openDropdown not-hover" src={notificationIco} alt="" />
          <img className="openDropdown hover" src={notificationIcoBlue} alt="" />
          <span className="openDropdown">1</span>
        </div>
        <div
          onClick={() =>
            setActiveDropdown(activeDropdown === "Profile" ? null : "Profile")
          }
          className="user openDropdown cursor-pointer p-l-15"
        >

          <Link
            to={`/billing`}
          >
            <div className="m-r-15 credit">
              <div className="text-align-right text-grey-1 font-size-10 font-weight-semibold">
                {t("usage")}
              </div>
              {credit ? <div className="text-grey-1 font-size-12">$ {credit}</div> : <Loader size={"extra-small"} />}
            </div>
          </Link>

          <div className={`hover-parent ${activeDropdown === "Profile" && 'active'} flex flex-align-items-center`}>
            <img className="openDropdown not-hover" src={userIco} alt="" />
            <img className="openDropdown hover" src={userIcoDark} alt="" />
          </div>
        </div>
      </div>
      <a
        href="javascript:;"
        onClick={() =>
          setActiveDropdown(activeDropdown === "MobileNav" ? null : "MobileNav")
        }
        className="menu visible-xs openDropdown"
      ></a>
      <div className="Nav__Dropdown">
        <Dropdown
          clientDetails={clientDetails}
          setActiveDropdown={setActiveDropdown}
        />
      </div>
    </nav>
  );
};

export default Nav;
