import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import TabContent from "./components";
import { useTranslation } from "react-i18next";
import Tabs from "../../components/Tabs";
import { getPayModulesRequest } from "./billingActions";

const Billing = () => {
  const { tab = "add-credit" } = useParams();
  const TabComponent = TabContent[tab];
  const { t } = useTranslation("billing");
  const dispatch = useDispatch();
  useEffect(() => {
    getPayModulesRequest()(dispatch);
  }, []);

  return (
    <div className="BillingPage">
      <div className="container">
        <h2 className="title">{t("title")}</h2>
        <Tabs>
          <Tabs.Header>
            <ul>
              <li>
                <Link
                  className={tab === "add-credit" && "active"}
                  to="/billing/add-credit"
                >
                  {t("tabs.addCredit")}
                </Link>
              </li>

              <li>
                <Link
                  className={tab === "usage" && "active"}
                  to="/billing/usage"
                >
                  {t("tabs.usage")}
                </Link>
              </li>
            </ul>
          </Tabs.Header>
          <Tabs.Content>
            <TabComponent t={t} />
          </Tabs.Content>
        </Tabs>
      </div>
    </div>
  );
};

export default Billing;
