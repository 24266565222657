import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getProducts } from '../actions';

import ChooseLocation from './components/ChooseLocation';
import ChooseName from './components/ChooseName';
import ConfigurePrivateIpRange from './components/ConfigurePrivateIpRange';

const CreateVpcNetwork = () => {
  const { t } = useTranslation('createVpcNetwork');
  const translationsByComponent = (component) => (text) => t(`${component}.${text}`);
  const dispatch = useDispatch();

  useEffect(() => {
    const onMount = async () => {
      await getProducts({ params: {} })(dispatch);
    };
    onMount();
  }, []); //eslint-disable-line

  return (
    <div className="Create CreateVpcNetwork">
      <div className="container">
        <h2 className="title m-t-30">{t('title')}</h2>
        <p className="text-grey-1 font-size-16 m-t-25">{t('description')}</p>
        <ChooseLocation t={translationsByComponent('chooseLocation')} />
        <ConfigurePrivateIpRange t={translationsByComponent('configurePrivateIpRange')} />
        <ChooseName t={translationsByComponent('chooseName')} />
        <button className="button button--green m-t-30 m-b-50 width-full-width">
          {t('button')}
        </button>
      </div>
    </div>
  );
};

export default CreateVpcNetwork;
