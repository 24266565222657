import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "../../../components/Modal";

const InfoModalModal = ({ t, show, onHide, setVisibleModal }) => {
  return (
    <div>
      <div className="">
        <Modal
          size="large"
          Title={() => (
            <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">
              {t("infoModal.title")}
            </h2>
          )}
          onHide={onHide}
          show={show}
        >
          <p className="font-size-16 text-grey-1 line-height-1-6 m-t--20 m-b-30">
            {t("infoModal.description")}
          </p>
          <h2 className="font-size-24 font-weight-regular text-grey-3 m-t-0 m-b-0">
            {t("infoModal.logInto.title")}
          </h2>
          <div className="grey-info m-t-30 m-b-30 flex flex-justify-content-space-between flex-align-items-center">
            <div>
              $ <strong className="text-blue-1">ssh</strong>{" "}
              root@192.198.125.184.77
            </div>
            <CopyToClipboard text={"ssh root@192.198.125.184.77"}>
              <button className="button button--s button--white">Copy</button>
            </CopyToClipboard>
          </div>

          <h2 className="font-size-24 font-weight-regular text-grey-3 m-t-0 m-b-0">
            {t("infoModal.format.title")}
          </h2>
          <p className="font-size-16 text-grey-1 line-height-1-6 m-t-10 m-b-30">
            <strong className="text-red-1">
              {t("infoModal.format.warning")}
            </strong>
            {t("infoModal.format.description")}
          </p>
          <div className="grey-info flex flex-justify-content-space-between flex-align-items-center">
            $ mkfs.ext4 /dev/disk/by-id/scsi-0DO_Volume_volume-nyc1-01
            <CopyToClipboard
              text={"mkfs.ext4 /dev/disk/by-id/scsi-0DO_Volume_volume-nyc1-01"}
            >
              <button className="button button--s button--white">Copy</button>
            </CopyToClipboard>
          </div>
          <h2 className="font-size-24 font-weight-regular text-grey-3 m-t-30 m-b-0">
            {t("infoModal.mount.title")}
          </h2>
          <p className="font-size-16 text-grey-1 line-height-1-6 m-t-10 m-b-30">
            {t("infoModal.mount.description")}
          </p>
          <div className="grey-info">
            <p className="text-grey-8 font-style-italic m-b-5">
              {t("infoModal.mount.createMount")}
            </p>
            $ <strong className="text-blue-1">sudo mkdir</strong> -p
            /mnt/volume_nyc1_01 <br />
            <br />
            <p className="text-grey-8 font-style-italic m-b-5">
              {t("infoModal.mount.mountVolume")}{" "}
            </p>
            $ <strong className="text-blue-1">sudo mount</strong> -o
            discard,defaults
            /dev/disk/by-id/scsi-0DO_Volume_volume-nyc1-01/mnt/volume_nyc1_01
            <br />
            <br />
            <p className="text-grey-8 font-style-italic m-b-5">
              {t("infoModal.mount.changeMount")}
            </p>{" "}
            $ <strong className="text-blue-1">sudo</strong> ros config{" "}
            <strong className="text-blue-1">set</strong> mounts <br />
            <span className="text-green-1 m-t-5 m-b-15 display-block">
              {`'[["/dev/disk/by-id/scsi-0DO_Volume_volume-nyc1-01", "/mnt/volume_nyc1_01", "ext4",
              "discard,noatime"]]'`}
            </span>
          </div>
          <p className="font-size-16 text-grey-1 line-height-1-6 m-t-30 m-b-30">
            {t("infoModal.learnMore")}
            <a href="https://do.co/volume-tutorial">
              https://do.co/volume-tutorial.
            </a>
          </p>
          <div className="flex">
            <button className="button button--blue flex-grow-1" onClick={() => setVisibleModal(null)}>
              {t("infoModal.button")}
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default InfoModalModal;
