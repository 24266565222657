import AlgorithmIco from '../../../assets/images/loadBalancers/algorithm_icon.svg'
import ProtocolIco from '../../../assets/images/loadBalancers/protocol_icon.svg'

export const availableRules = [
  { value: "tcp", text: "TCP", icon: ProtocolIco },
  { value: "tcp-proxy", text: "TCP Proxy", icon: ProtocolIco },
].map(({ value, text, icon }) => ({
  value,
  text,
  icon,
}));

export const availableAlgorithm = [
  { value: "roundrobin", text: "Round Robin", icon: AlgorithmIco },
  { value: "leastconn", text: "Least Connection", icon: AlgorithmIco },
  { value: "source", text: "Source", icon: AlgorithmIco },
].map(({ value, text, icon }) => ({
  value,
  text,
  icon,
}));
