import React from "react";
import Slider from "react-slick";

import AddNew from "../../common/AddNew";

import uploadIconIso from "../../../../../../assets/images/chooseImage/upload-image-icon.svg";

const uploadIsoImages = [
  {
    name: "Image_nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_3nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_4nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_4nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_5nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_6nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_4nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_4nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_5nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_6nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_5nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_6nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_4nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_4nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_5nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Image_6nam",
    created: "4 days ago",
    size: 19.8,
  },
];

const UploadIso = () => {
  return (
    <div className="UploadIso flex row image-box-container">
      <AddNew className="m-l-20 m-r-20" />
      {uploadIsoImages.map(({ name, created }) => (
        <div
          key={name}
          className="flex flex-align-items-center flex-justify-content-space-between hidden-xs hidden-xs-flex image-box m-l-20 m-r-20"
        >
          <div className="flex flex-align-items-center">
            <img className="m-r-10" src={uploadIconIso} alt="" />
            <div className="text-grey-1 font-weight-regular">
              <div className="text-grey-2 font-weight-16 font-weight-semibold">
                {name}
              </div>
              {created}
            </div>
          </div>
        </div>
      ))}
      <div className={`visible-xs`}>
        <Slider
          {...{
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  rows: 5,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerPadding: 50,
                  centerMode: true,
                  arrows: false,
                },
              },
            ],
          }}
        >
          {uploadIsoImages.map(({ name, created }) => (
            <div key={name} className="p-l-20 p-r-20 m-b-15">
              <div className="flex flex-align-items-center image-box">
                <div className="flex flex-align-items-center">
                  <img className="m-r-10" src={uploadIconIso} alt="" />
                  <div className="text-grey-1 font-weight-regular">
                    <div className="text-grey-2 font-weight-16 font-weight-semibold">
                      {name}
                    </div>
                    {created}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default UploadIso;
