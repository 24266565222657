import React, {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader/Loader";
import {get} from "../../components/http";

const ResendConfirmationEmail = () => {
  const [loading, setLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const { t } = useTranslation("resetPassword");
  const { email } = useParams();

  useEffect(() => {
    const onMount = async () => {
      try {
        const {data: {error} = {}} = await get(`/email/resend/${email}`, {}, false)

        if (error) {
          setIsError(error?.message)
        }

        setLoading(false);
      } catch (error) {
        setIsError(error?.message)
        setLoading(false);
      }
    }

    onMount()
  }, []);
  return (
    <div className="ResetPasswordPage ResendConfirmationEmailPage">
      <div className="card p-t-50 p-b-50">
        <h2 className="m-b-30">{t("resend.title")}</h2>
        <div className={'flex flex-justify-content-center p-t-50 p-b-50'}>
          {loading ? <Loader/> :
            <>
            {isError ? <p className="text-align-center text-red-2 font-size-30 m-t-20">{isError}</p> :
              <p className="text-align-center text-green-1 font-size-20 m-t-20">{t("resend.success")}</p>
              }
            </>
          }
        </div>
        <p className="text-align-center text-grey-1 font-size-16 m-t-20 font-weight-light">
          <Link to="/login" className="font-weight-semibold lighter">
            {t("loginLink")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResendConfirmationEmail;
