import { del, get, post } from '../../components/http';

import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'profile',
    initialState: {
        sshKeys: [],
        domains: [],
        keys: [],
        keysLoading: false,
        createLoading: false,
        createResponse: null
    },
    reducers: {
        getSshKeys: (state, action) => {
            state.sshKeys = action.payload;
        },
        getDomains: (state, action) => {
            state.domains = action.payload;
        },
        getListKeys: (state, action) => {
            state.keys = action.payload;
        },
        setListKeysLoading: (state, action) => {
            state.keysLoading = action.payload;
        },
        setCreateLoading: (state, action) => {
            state.createLoading = action.payload;
        },
        setCreateResponse: (state, action) => {
            state.createResponse = action.payload;
        }
    }
});
export default slice.reducer;

// Actions
const {
    getSshKeys: getSshKeysAction,
    getDomains,
    getListKeys,
    setListKeysLoading,
    setCreateLoading,
    setCreateResponse
} = slice.actions;

export const getSshKeys =
    ({ ...params }) =>
    async (dispatch) => {
        let clientDetailResponse;
        try {
            clientDetailResponse = await get(`/client/sshkeys`, { params });
        } catch (error) {
            return error;
        }
        const { data: { data } = {} } = clientDetailResponse || {};
        dispatch(getSshKeysAction({ sshKeys: data }));
    };

//get client domains
export const getClientDomainsRequest = () => async (dispatch) => {
    try {
        const {
            data: {
                data: { domains }
            }
        } = await get(`client/domains`);
        const domainsArray = Object.keys(domains).map((key) => domains[key])
        dispatch(getDomains(domainsArray));
    } catch (error) {
        dispatch(getDomains([]));
    }
};
// get keys list
export const getListKeysRequest =
    (params) =>
    async (dispatch) => {
        dispatch(setListKeysLoading(true));

        const result = await get(`/client/sshkeys`, {params});

        dispatch(getListKeys(result?.data?.data?.sshkeys));
        dispatch(setListKeysLoading(false));
    };

//register ssh key
export const getRegisterSshRequest =
    ({ name, publickey, accountid, domains }) =>
    async (dispatch) => {
        let registerResponse;
        try {
            registerResponse = await post(`/accounts/${accountid}/sshkeys/register`, {
                name,
                publickey
            });
        } catch (error) {
            registerResponse = { error };
        }
        if (registerResponse.data.error) {
            throw new Error(registerResponse.data.error.message);
        }
        dispatch(getListKeysRequest({ domains }));
    };

//create key
export const getCreateKeyRequest =
    ({ accountid, name, domains }) =>
    async (dispatch) => {
        let createResponse;
        dispatch(setCreateLoading(true));
        try {
            createResponse = await post(`/accounts/${accountid}/sshkeys/create`, {
                name
            });
            dispatch(setCreateResponse(createResponse.data.data));
        } catch (error) {
            createResponse = { error };
            dispatch(setCreateLoading(false));
        }
        if (createResponse.data.error) {
            dispatch(setCreateLoading(false));

            throw new Error(createResponse.data.error.message);
        }
        dispatch(setCreateLoading(false));
        dispatch(getListKeysRequest({ domains }));
    };

//delete key
export const deleteKeyRequest =
    ({ domains, accountid, name }) =>
    async (dispatch) => {
        let deleteResult;
        try {
            deleteResult = await del(`/accounts/${accountid}/sshkeys`, {
                name
            });
        } catch (error) {
            deleteResult = { error };
        }
        if (deleteResult.data.error) {
            throw new Error(deleteResult.data.error.message);
        }
        dispatch(getListKeysRequest({ domains }));
    };
