export default {
  copy: "2014-{{endDate}} © Shapehost.",
  allRights: " All Rights Reserved.",
  made: "Made with {{ love}} in Romania",
  nav: {
    support: "Support",
    terms: "Terms",
    privacy: "Privacy"
  }
}
