import React, { useEffect, useMemo } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import TabContent from './components';
import { useTranslation } from 'react-i18next';
import Tabs from '../../components/Tabs';
import { useDispatch } from 'react-redux';
import OrizontalScroll from '../../components/OrizontalScroll';
import { getAccountsRequest } from '../Dashboard/actions';

const Network = ({ accountid, domainid, type }) => {
  const underInstance = !!accountid;
  const dispatch = useDispatch();
  let {
    tab = type === 'Shared' ? 'public-ips' : 'vpc-networks',
    subTab,
    networkSubTab,
    id
  } = useParams();
  subTab =
    tab === 'networking' && !subTab ? (type === 'Shared' ? 'public-ips' : 'vpc-networks') : subTab;
  const { pathname } = useLocation();
  const TabComponent = TabContent[tab === 'networking' ? subTab : tab];
  const { t } = useTranslation('network');

  const path = useMemo(() => {
    return "/vpc-network";
  }, [pathname]);


  useEffect(() => {
    getAccountsRequest()(dispatch);
  }, []);
  return (
    <div className="NetworkPage flex-grow-1">
      <div className="container">
        {!(id && networkSubTab) && <h2 className="title">{t('title')}</h2>}
        <OrizontalScroll>
          <OrizontalScroll.Scrollable>
            {type === 'Shared' ? (
              <Tabs>
                {!(id && networkSubTab) && (
                  <Tabs.Header>
                    <ul>
                      <li>
                        <Link
                          className={(tab === 'public-ips' || subTab === 'public-ips') && 'active'}
                          to={`${path}/public-ips`}>
                          {t('tabs.publicIps')}
                        </Link>
                      </li>
                    </ul>
                  </Tabs.Header>
                )}
                <Tabs.Content>
                  <TabComponent
                    t={t}
                    networkSubTab={networkSubTab}
                    id={id}
                    accountid={accountid}
                    domainid={domainid}
                  />
                </Tabs.Content>
              </Tabs>
            ) : (
              <Tabs>
                {!(id && networkSubTab) && (
                  <Tabs.Header>
                    <ul>
                      <li>
                        <Link
                          className={
                            (tab === 'vpc-networks' || subTab === 'vpc-networks') && 'active'
                          }
                          to={`${path}/vpc-networks`}>
                          VPC
                        </Link>
                      </li>
                      {underInstance && (
                        <li>
                          <Link
                            className={(tab === 'nics' || subTab === 'nics') && 'active'}
                            to={`${path}/nics`}>
                            {t('tabs.nics')}
                          </Link>
                        </li>
                      )}

                      <li>
                        <Link
                          className={
                            (tab === 'extra-ips' || subTab === 'extra-ips') && 'active'
                          }
                          to={`${path}/extra-ips`}>
                          {t('tabs.extraIPs')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={(tab?.includes('firewalls') || subTab?.includes('firewalls')) && 'active'}
                          to={`${path}/firewalls`}>
                          {t('tabs.firewalls')}
                        </Link>
                      </li>
                    </ul>
                  </Tabs.Header>
                )}
                <Tabs.Content>
                  <TabComponent
                    t={t}
                    networkSubTab={networkSubTab}
                    id={id}
                    accountid={accountid}
                    domainid={domainid}
                    underInstance={underInstance}
                  />
                </Tabs.Content>
              </Tabs>
            )}
          </OrizontalScroll.Scrollable>
          <OrizontalScroll.Slider className="flex flex-justify-content-center m-b-20" />
        </OrizontalScroll>
      </div>
    </div>
  );
};

export default Network;
