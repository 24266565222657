import React from "react";
import { useTranslation } from "react-i18next";
import ForwardRules from "../../../../../../Create/CreateOrEditLoadBalancer/components/ForwardRules";

const ForwardRulesTab = ({
  settings = {},
  type,
  t,
  activeComponent,
  setActiveComponent,
}) => {
  const { to, from } = settings[type] || {};
  const { t: createLoadBalancerTrans } = useTranslation("createLoadBalancer");
  const translationsByComponent = (component) => (text) =>
    createLoadBalancerTrans(`${component}.${text}`);
  if (activeComponent === type) {
    return (
      <div className="Create CreateLoadBalancer p-t-40 p-b-40 width-full-width">
        <ForwardRules
          {...{
            hideTitle: true,
            activeComponent,
            setActiveComponent,
            t: translationsByComponent("forwardRules"),
          }}
        />
        <div className="flex m-t-30">
          <button
            onClick={() => setActiveComponent(false)}
            className="button button--blue button--sm min-width-100 m-r-20"
          >
            {t("settings.save")}
          </button>
          <button
            onClick={() => setActiveComponent(false)}
            className="button button--grey button--sm min-width-100"
          >
            {t("settings.cancel")}
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-grow-1 flex-align-items-center flex-justify-content-space-between font-size-16 text-grey-1">
      <div>
        {to?.protocol} {t("settings.onPort")} {to?.port} {">"} {from?.protocol}{" "}
        {t("settings.onPort")} {from?.port}
      </div>
      <button
        onClick={() => setActiveComponent(type)}
        className="button button--grey button--sm min-width-100"
      >
        {t("settings.edit")}
      </button>
    </div>
  );
};

export default ForwardRulesTab;
