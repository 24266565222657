import React, { useState } from "react";
import { icons } from "../../../../Create/CreateInstance/components/ChooseLocations";
import useComponentVisible from "../../../../../helpers/useComponentVisible";

const ChooseRegion = ({ locations, t, onChange }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false, 'input');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchedLocations, setSearchedLocations] = useState(locations);

  const handleRegionSelect = (location) => {
    setSelectedLocation(location);
    onChange(location);
    setIsComponentVisible(false);
  }

  const handleRegionChange = () => {
    setSelectedLocation(null);
    onChange(null);
    setIsComponentVisible(true);
    setSearchedLocations(locations);
  }

  const handleSearch = (text) => {
    const searchedLocations = locations.filter(({ city, name, country }) => {
      return city.toLowerCase().includes(text.toLowerCase())
        || name.toLowerCase().includes(text.toLowerCase())
        || t(`networks.countries.${country}`).toLowerCase().includes(text.toLowerCase())
    });

    setSearchedLocations(searchedLocations);
  }

  return (
    <>
      <div className="Choose_region_container">
        {selectedLocation ? <div className="p-l-20 p-t-10 p-b-10 border-grey-1" onClick={() => { handleRegionChange() }}>
          <div className="flex">
            <img className="m-r-15" src={icons[selectedLocation.country]} width={50} height={35} />
            <div>
              <div className='font-size-16 text-grey-3'>{selectedLocation.city}, {t(`networks.countries.${selectedLocation.country}`)}</div>
              <div className='font-size-14 text-grey-1'>{selectedLocation.name}</div>
            </div>
          </div>
        </div> :
          <div>
            <input
              id="input"
              placeholder={t("networks.createModal.placeholderRegion")}
              onClick={() => { setIsComponentVisible(true) }}
              onChange={(e) => { handleSearch(e.target.value) }}
            />
            {isComponentVisible && searchedLocations && searchedLocations.length ? <div className="arrow-cont">
              <div className="location_container" ref={ref}>
                {searchedLocations.map((location, index) => {
                  return <div key={location.id} className="locations_item" onMouseDown={() => { handleRegionSelect(location) }}>
                    <div className="m-t-15 m-b-15">
                      <div className='flex'>
                        <img className="m-r-15" src={icons[location.country]} width={50} height={35} />
                        <div>
                          <div className='font-size-16 text-grey-3'>{location.city}, {t(`networks.countries.${location.country}`)}</div>
                          <div className='font-size-14 text-grey-1'>{location.name}</div>
                        </div>
                      </div>
                    </div>
                    {index + 1 !== locations.length && <div className="line_cuted m-t-0 m-b-0"></div>}
                  </div>
                })}
              </div>
            </div> : null}
          </div>}
      </div>
    </>
  );
}

export default ChooseRegion;
