import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { post } from "../../../../../../components/http";
import { useLoopFetching } from "../../../../../../components/hooks/useLoopFetching";
import { updateInstanceStatus } from "../../../../actions";
import checkJobStatus from "../../../../../../helpers/checkJobStatus";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";

const ResetModal = ({ show, onHide, t, accountid, instanceId, instantState }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [initialState] = useState(instantState);


  const resetPassword = async () => {
    setLoading(true);
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await post(`/accounts/${accountid}/vm/${instanceId}/reset-password`);
      startFetchingReset(jobid, () => {
        if (initialState === 'Running') {
          handleStatusChange(true);
        }
        setSuccess(true);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  }

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      if (initialState === 'Running') {
        await handleStatusChange(false, true);
      } else {
        await resetPassword()
      }
    } catch (error) {
      setLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };

  const handleStatusChange = async (start, isForced) => {//eslint-disable-line
    setLoading(true);
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await updateInstanceStatus({
        accountid,
        vmId: instanceId,
        start: start,
        isForced
      })(dispatch);
      startFetching(jobid, async () => {
        if (initialState === 'Running' && start === false) {
          await resetPassword();
        }
      });
    } catch (error) {
      setLoading(false);
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  }

  const request = async (data, cb) => {
    try {
      const result = await checkJobStatus(accountid, data);
      if (result.status === "success") {
        cancelFetching();
        cb();
      }
      if (result.status === "error") {
        cancelFetching();
        setLoading(false);
        addToast(t(result.error_message.errortext), {
          appearance: "error",
          autoDismiss: true
        });
      }
    } catch (error) {
      setLoading(false);
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
      cancelFetching();
    }
  };

  const requestForReset = async (data, cb) => {
    try {
      const result = await checkJobStatus(accountid, data);
      if (result.status === "success") {
        cancelFetchingReset();
        cb();
        setNewPassword(result.result.virtualmachine.password);
      }
      if (result.status === "error") {
        cancelFetching();
        setLoading(false);
        addToast(t(result.error_message.errortext), {
          appearance: "error",
          autoDismiss: true
        });
      }
    } catch (error) {
      setLoading(false);
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
      cancelFetchingReset();
    }
  };

  const { startFetching, cancelFetching } = useLoopFetching(request);
  const { startFetching: startFetchingReset, cancelFetching: cancelFetchingReset } = useLoopFetching(requestForReset);

  return (
    <div>
      <Modal
        Title={() => <span className="font-size-38 text-grey-3 m-t-0 m-b-0">{t("resetPassword.modal.title")}</span>}
        onHide={onHide}
        show={show}
      >
        {isSuccess ? (
          <>
            <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-25">
              {t("resetPassword.modal.resetedDescription1")}
              <span className="font-weight-semibold text-grey-3">{t("resetPassword.modal.resetedDescriptionBold1")}</span>
              {t("resetPassword.modal.resetedDescription2")}
              <span className="font-weight-semibold text-grey-3">{t("resetPassword.modal.resetedDescriptionBold2")}</span>
              {t("resetPassword.modal.resetedDescription3")}
            </p>
            <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-25">
              {newPassword}
              <CopyToClipboard
                text={newPassword}
                className="m-l-10 text-blue-1 font-size-15 cursor-pointer"
                onCopy={() => {
                  addToast(t("resetPassword.successCopy"), {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 2000,
                  });
                }}>
                <span className="text-blue-1 font-size-15">{t("resetPassword.modal.copy")}</span>
              </CopyToClipboard>
            </p>
            <button
              onClick={() => {
                onHide();
                setSuccess(false);
              }}
              className="button button--green width-full-width"
            >
              {t("resetPassword.modal.success")}
            </button>
          </>
        ) : (
          <div className="flex flex-column">
            <p className="font-size-16 text-grey-1 m-t-0 line-height-1-6">
              {t("resetPassword.modal.warning1")} <span className="font-weight-semibold text-grey-3">{t("resetPassword.modal.confirm")}</span> {t("resetPassword.modal.warning2")}
            </p>
            <p className="font-size-16 text-grey-1 m-b-40"> {t("resetPassword.modal.warningQuestion")}</p>
            <div className="flex">
              <button onClick={onHide} className="button button--grey width-half flex-grow-1 m-r-15">
                {t("resetPassword.modal.cancel")}
              </button>
              <Button
                loading={loading}
                className="button button--full-red width-half flex-grow-1 m-l-15"
                onClick={() => { handleResetPassword() }}
              >
                {t("resetPassword.modal.confirm")}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ResetModal;
