import React from "react";
import { useState, useEffect } from "react";

const Slider = ({ value, setValue, max, className, children }) => {
  return (
    <div className={`OrizontalScroll__slider-wrapper ${className}`}>
      {children}
      <div className={`OrizontalScroll__slider`}>
        <input
          type="range"
          min="0"
          max={max}
          onChange={(e) => {
            const scrollableEl = e.currentTarget
              .closest(".OrizontalScroll")
              .querySelector(".OrizontalScroll__scrollable");
            scrollableEl.scrollLeft = e.target.value;
            setValue(e.target.value);
          }}
          value={value}
        />
      </div>
    </div>
  );
};

const Scrollable = ({ children }) => {
  return <div className="OrizontalScroll__scrollable">{children}</div>;
};

const OrizontalScroll = ({ children }) => {
  const [value, setValue] = useState(0);
  const [max, setMax] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line
    const { scrollWidth } = document.querySelector(
      ".OrizontalScroll__scrollable > div"
    );
    // eslint-disable-next-line
    const { clientWidth } = document.querySelector(
      ".OrizontalScroll__scrollable"
    );
    setMax(scrollWidth - clientWidth);
  }, []);
  return (
    <div
      onScroll={(e) => setValue(e.target.scrollLeft)}
      className="OrizontalScroll"
    >
      {children.map((Children) =>
        React.cloneElement(Children, { value, setValue, max })
      )}
    </div>
  );
};

OrizontalScroll.Slider = Slider;
OrizontalScroll.Scrollable = Scrollable;

export default OrizontalScroll;
