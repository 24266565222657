import React, { useState } from "react";
import CustomField from "../../../../../../../components/CustomField";
import Modal from "../../../../../../../components/Modal";

const Destroy = ({ t }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="flex flex-grow-1 flex-align-items-center flex-justify-content-space-between font-size-16 text-grey-1">
      <div className="m-r-100">{t("settings.destroyDescription")}</div>
      <button
        onClick={() => setShow(true)}
        className="button button--full-red button--sm min-width-100"
      >
        {t("settings.destroy")}
      </button>
      <Modal
        Title={() => (
          <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">
            {t("settings.destroyModal.title")}
          </h2>
        )}
        onHide={setShow}
        show={show}
      >
        <p className="font-size-16 text-grey-1 line-height-1-6 m-t--10 m-b-30">
          {t("settings.destroyModal.description")}
        </p>
        <p className="font-size-16 text-grey-1 line-height-1-6 m-t--10 m-b-30">
          {t("settings.destroyModal.confirmDescription")}
        </p>
        <CustomField className="m-b-30">
          <input
            type="text"
            placeholder={t("settings.destroyModal.placeholder")}
          />
        </CustomField>
        <div className="flex row">
          <button
            onClick={() => setShow(null)}
            className="button button--grey width-half flex-grow-1 m-l-15 m-r-15"
          >
            {t("settings.destroyModal.cancel")}
          </button>
          <button
            onClick={async () => {}}
            className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15"
          >
            {t("settings.destroyModal.confirm")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Destroy;
