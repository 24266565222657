// store.js
import React, { createContext, useReducer } from "react";
import {
  type as createInstanceType,
  createInstanceReducer,
  createInstanceState,
  getProductsType,
  getProductsReducer,
  getProductsState,
} from "./pages/Create/CreateInstance/actions";

import {
  getClientDetailsReducer,
  getClientDetailsState,
  getClientDetailsType,
} from "./components/PrivateComponent/actions";

import {
  getInvoicesReducer,
  getInvoicesState,
  getInvoicesType,
  getInvoicesPdfReducer,
  getInvoicesPdfState,
  getInvoicesPdfType,
} from "./pages/Billing/actions";

const initialState = {
  ...createInstanceState,
  ...getProductsState,
  ...getClientDetailsState,
  ...getInvoicesState,
  ...getInvoicesPdfState,
  loading: false,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((reducerState, action) => {
    switch (action.type) {
      case "loading":
        return {
          ...reducerState,
          loading: action.data,
        };
      case createInstanceType:
        return createInstanceReducer(reducerState, action);
      case getProductsType:
        return getProductsReducer(reducerState, action);
      case getClientDetailsType:
        return getClientDetailsReducer(reducerState, action);

      case getInvoicesType:
        return getInvoicesReducer(reducerState, action);
      case getInvoicesPdfType:
        return getInvoicesPdfReducer(reducerState, action);
      default:
        return;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
