import React, { useState } from "react";
import { restartVPC, getVpcRequest } from "../../../../Dashboard/actions";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import Modal from "../../../../../components/Modal";
import Toggler from "./Toggler";

const RestartVpc = ({ show, onHide, t, accountid, vpcid, startFetching, underInstance }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isCleanUp, setCleanUp] = useState(false);
  const [isRedundant, setRedundant] = useState(false);

  return (
    <Modal
      size="large"
      Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("restartModal.title")}</h2>}
      onHide={onHide}
      show={show}
    >
      <div className="modal-inner">
        <div className="text-grey-1 line-height-1-6 m-t--10 m-b-30">
          <p className="text-red-1 font-size-24 text-align-left m-b-0 m-t-0">{t("restartModal.description")}</p>
          <p className="font-style-italic font-size-16 text-align-left m-b-0 m-t-0">{t("restartModal.remark")}</p>
        </div>
        <Toggler
          isToggled={isCleanUp}
          onChange={() => setCleanUp((prev) => !prev)}
          text={t("restartModal.clean")}
          tooltip={t("restartModal.cleanTooltip")}
        />
        <Toggler
          isToggled={isRedundant}
          onChange={() => setRedundant((prev) => !prev)}
          text={t("restartModal.redundant")}
          tooltip={t("restartModal.redundantTooltip")}
        />
        <div className="flex row m-t-10">
          <button onClick={() => onHide()} className="button button--grey width-half flex-grow-1 m-l-15 m-r-15">
            {t("restartModal.cancel")}
          </button>
          <button
            onClick={async () => {
              try {
                const {
                  data: {
                    data: { jobid }
                  }
                } = await restartVPC({
                  accountid,
                  id: vpcid,
                  cleanup: isCleanUp,
                  makeredundant: isRedundant
                })(dispatch);
                onHide();
                startFetching(jobid, () => {
                  addToast(t("restartModal.success"), {
                    appearance: "success",
                    autoDismiss: true
                  });
                  underInstance ? getVpcRequest({ accountid })(dispatch) : getVpcRequest()(dispatch);
                });
              } catch (error) {
                return addToast(error.message, {
                  appearance: "error",
                  autoDismiss: true
                });
              }
            }}
            className="button width-half flex-grow-1 m-l-15 m-r-15"
          >
            {t("restartModal.restart")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RestartVpc;
