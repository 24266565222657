import React, { useState } from "react";
import Card from "../../../../../../components/Card";
import ResetModal from "./ResetModal";

const ResetPassword = ({ t, accountid, instanceId, instantState }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <div>
      <Card>
        <Card.Body className="width-full-width flex-direction-column p-l-40 p-r-40 p-t-20 p-b-20">
          <h3 className="font-size-27 text-grey-3 font-weight-regular m-b-10">{t("resetPassword.title")}</h3>
          <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-20">
            {t("resetPassword.description")}
          </p>
          <button className="button button--md button--grey max-width-250 m-b-20" onClick={() => setModalOpen(true)}>
            {t("resetPassword.button")}
          </button>
        </Card.Body>
      </Card>
      <ResetModal
        show={isModalOpen}
        onHide={() => setModalOpen(false)}
        instantState={instantState}
        t={t}
        accountid={accountid}
        instanceId={instanceId}
      />
    </div>
  );
};

export default ResetPassword;
