import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import ReactSlider from 'react-slider';

import Modal from "../../../../components/Modal";
import { createVolume, getVolumesRequest } from "../../actions";
import { getLocationsRequest } from "../../../Create/actions";
import CustomField from "../../../../components/CustomField";
import VolumeNameIco from "../../../../assets/images/volumes/volume-name-input.png"
import Search from "../../../../components/Layout/Header/Search";
import { maxSizeInGB, pricePerGB } from "../ResizeModal";
import Button from "../../../../components/Button";

const offeringIds = [
  { host: 'https://acs-emea.shape.host:8443', offeringId: 'f07a1db8-f833-4c77-9475-bdd6aeb16bbd' },
  { host: 'https://cloud-ams.shape.host:8443', offeringId: 'aee667d7-29e5-4e0d-b2cd-2f2dcda28d00' },
  { host: 'https://cloud-emea.shape.host:8443', offeringId: '6a3eeaa0-5983-4222-8e9c-0133009b707d' },
  { host: 'https://acs-test.shape.host:8443', offeringId: '647e2c5e-52a6-4a5c-88f5-6f3486ec21e7' },
  { host: 'https://acs-apac.shape.host:8443', offeringId: 'bb2a3c40-cb3f-4ad5-af49-9728e43e74a8' },
];

const CreateModal = ({ t, show, onHide, setVisibleModal, snapshotDetails, startFetching, isDashboard, zoneid, instanceId, accountid }) => {
  const [selectedSize, setSelectedSize] = useState(10);
  const [focused, setFocused] = useState(null);
  const [lastSelected, setLastSelected] = useState(null);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [name, setName] = useState(null);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { loading } = useSelector(({ volumes }) => volumes);
  const { locations } = useSelector((state) => state.products);

  useEffect(() => {
    getLocationsRequest()(dispatch);
  }, [dispatch]);

  const volumes = [10, 25, 50, 100, 200, 500];

  let handleInputChange = (value) => {
    const isNumberRegex = /^[0-9]*$/;
    if (isNumberRegex.test(value)) {
      setSelectedSize(value);
      setLastSelected(value);
    }
  }

  const offeringId = useMemo(() => {
    let selectedLocation;
    if (isDashboard && zoneid) {
      selectedLocation = locations.find(location => location.acs_id === zoneid);
    }

    if(!isDashboard && selectedInstance) {
      selectedLocation = locations.find(location => location.acs_id === selectedInstance.zoneid);
    }

    const diskofferingid = offeringIds.find(offeringId => offeringId.host === selectedLocation?.host);

    return diskofferingid?.offeringId;
  }, [locations, zoneid, selectedInstance]);

  const buttonDisabled = useMemo(() => {
    if (selectedInstance && name) {
      return false;
    }

    if (name && isDashboard) {
      return false;
    }

    return true;
  }, [isDashboard, name, selectedInstance]);

  return (
    <div className="IncreaseModal CreateModal">
      <Modal
        Title={() => <span className="font-size-38 text-grey-3 m-t-0 m-b-0">{t("createModal.title")}</span>}
        size="middle"
        onHide={onHide}
        show={show}
      >
        <p className="font-size-27 text-blue-2 font-weight-semibold line-height-1-6 m-t--20 m-b-60">{t("createModal.section1")}</p>
        <div className="slider">
          <ReactSlider
            className="horizontal-slider"
            marks
            markClassName="example-mark"
            min={0}
            max={volumes.length - 1}
            value={volumes.findIndex(el => (el == selectedSize))}
            thumbClassName="example-thumb"
            trackClassName="example-track"
            onChange={value => {
              setSelectedSize(volumes[value])
              setLastSelected(volumes[value])
            }}
            renderThumb={(props, state) => (<div {...props}><div className={"tooltip"}>${volumes[state.valueNow] * pricePerGB}/month</div></div>)}
            renderMark={(props) => (<span {...{ ...props, className: `${props.className} mark-${props?.key}` }}>{volumes[props?.key]}GB</span>)}
          />
        </div>
        <div className={"otherAmountContainer flex flex-align-items-center m-t-30"}>
          <p className={"p-r-30"}>{t("resizeModal.otherAmount")}</p>
          <div className={"input--with-tooltip"}>
            <CustomField className="size-manual" name={"amount"} submitCount={1} errors={Number(selectedSize) > maxSizeInGB && { amount: `Must be max ${maxSizeInGB} GB` }} >
              <input
                value={selectedSize && !focused ? `${selectedSize} GB` : selectedSize}
                name={"amount"}
                maxLength={4}
                placeholder={" "}
                onFocus={() => {
                  setFocused(true);
                  setSelectedSize("");
                }}
                onBlur={() => {
                  setFocused(false);
                  setSelectedSize(lastSelected);
                }}
                onChange={(e) => handleInputChange(e.target.value)}
                type="text"
              />
            </CustomField>
            <div className={"tooltip"}>${(selectedSize * pricePerGB).toFixed(1)}/month</div>
          </div>
        </div>
        <div className="m-t-40">
          {!isDashboard && <div className="Choose__instance flex-grow-1">
            <label className="font-size-27 text-blue-2 font-weight-semibold line-height-1-6 m-b-10 display-block">
              {t("createModal.section2")}
            </label>
            <Search onChange={setSelectedInstance} selectedInstance={selectedInstance} hideOverley short fullWidth />
          </div>}
          <div className="flex-grow-1 m-t-60">
            <p className="font-size-27 text-blue-2 font-weight-semibold line-height-1-6 m-b-10 display-block">
              {isDashboard ? t("createModal.dashboardSection2") : t("createModal.section3")}
            </p>
            <CustomField Ico={() => <img src={VolumeNameIco} className='ico' />}>
              <input
                placeholder={t("createModal.name.placeholder")}
                onChange={(e) => setName(e.target.value)}
                type="text"
              />
            </CustomField>
          </div>
        </div>
        <div className="flex flex-justify-content-flex-end m-t-40 width-full-width">
          <Button
            disabled={buttonDisabled}
            loading={loading}
            onClick={async () => {
              try {
                const {
                  data: {
                    data: { jobid }
                  }
                } = await createVolume({
                  name,
                  accountId: isDashboard ? accountid : selectedInstance.accountid,
                  diskofferingid: offeringId,
                  snapshotid: snapshotDetails?.id,
                  zoneid: isDashboard ? zoneid : selectedInstance.zoneid,
                  virtualmachineid: isDashboard ? instanceId : selectedInstance.id,
                  domainid: "",
                  projectid: "",
                  displayvolume: "",
                  size: selectedSize
                })(dispatch);
                startFetching(
                  jobid,
                  () => {
                    setVisibleModal(null);
                    setSelectedInstance(null);
                    setName(null);
                    addToast(t("createModal.success"), {
                      appearance: "success",
                      autoDismiss: true
                    });
                    getVolumesRequest()(dispatch);
                  },
                  isDashboard ? accountid : selectedInstance.accountid
                );
              } catch (error) {
                setVisibleModal(null);
                setSelectedInstance(null);
                setName(null);
                return addToast(t(error.message), {
                  appearance: "error",
                  autoDismiss: true
                });
              }
            }}
            className="button button--blue"
          >
            {t("createModal.button")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateModal;
