import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInstancesRequest } from "../../../../Dashboard/actions";
import { icons } from "../../../CreateInstance/components/ChooseLocations";
import { osicons } from "../../../CreateInstance/components/ChooseImage/Tabs/Distributions";
import instancesIco from "../../../../../assets/images/instance-icon.svg";
import Search from "../../../../../components/Layout/Header/Search";
import pretty from "prettysize";

const SelectInstance = ({ ruleId, handleChangeInstances, t, defaultInstances }) => {
  const dispatch = useDispatch();
  const { instances } = useSelector((state) => state.instances) || {};
  const { selectedRegion } = useSelector(({ products }) => products);

  useEffect(() => {
    async function onMount() {
      await getInstancesRequest(true)(dispatch);
    }
    if (instances === null) {
      onMount();
    }
  }, []); //eslint-disable-line


  const checkedInstances = useMemo(() => {
    if(defaultInstances && defaultInstances.length) {
      const instancesIds = defaultInstances.map(instance => instance.id);

      return instances?.filter(instance => instancesIds.includes(instance.id));
    }
    return [];
  }, [defaultInstances])

  const handleInstanceCheck = (instance) => {
    handleChangeInstances(ruleId, [...checkedInstances, instance]);
  }

  const handleInstanceDelete = (instanceId) => {
    handleChangeInstances(ruleId, checkedInstances.filter(({ id }) => instanceId !== id));
  }

  const getOsIcon = (instance) => {
    let osKey;
    Object.keys(osicons).forEach((key) => {
      if (instance.templatename.toLowerCase().includes(key.toLowerCase())) {
        osKey = key;
      }
    });

    return osicons[`${osKey}`];
  }

  const getRegionIcon = ({ zonename }) => {
    let regionKey;
    Object.keys(icons).forEach((key) => {
      if (zonename.toLowerCase().includes(key.toLowerCase())) {
        regionKey = key;
      }
    });

    return icons[`${regionKey}`];
  }

  const extraFilter = (instance) => {
    const checkedIds = checkedInstances.map(instance => instance.id);
    return instance.zoneid === selectedRegion?.acs_id && !checkedIds.includes(instance.id);
  }

  return (
    <div className="InstanceSelect">
      <div className="position-relative">
        <Search
          placeholder={<div className="text-grey-9 font-size-16">{t("Search for an available instance")}</div>}
          onChange={handleInstanceCheck}
          extraFilter={extraFilter}
          hideOverley
          fullWidth
          short
        />
      </div>
      {checkedInstances && checkedInstances.length > 0 ? <div className="SelectedInsctanceCont flex flex-column">
        {checkedInstances.map((instance, i) => (
          <div key={instance.id} className="Instance_items_container">
            <div className="flex flex-align-items-center Instance_item">
              <div className="flex">
                <div className="Instance position-relative m-r-14">
                  <img src={instancesIco} />
                  <span className={`status ${(instance?.state === 'Running' && 'Instance__active') ||
                    (['Starting', 'Stopping'].includes(instance?.state)
                      ? 'Instance__pending'
                      : 'Instance__inactive')}`
                  } />
                </div>
                <div className="flex flex-column flex-justify-content-center">
                  <div className="text-blue-1 font-weight-semibold">{instance.displayname}</div>
                  <div className="text-size-12 font-weight-semibold text-grey-8">{`${instance.cpunumber} vCPUs ${pretty(instance.memory * 1024 * 1024)} RAM`}</div>
                </div>
              </div>
              <div className="text-grey-1 font-size-15">{instance.nic && instance.nic[1].ipaddress}</div>
              <div><img width={27} height={27} src={getOsIcon(instance)} /></div>
              <div className="flex flex-align-items-center font-size-15 text-grey-1">
                <img className="m-r-5" width={34} height={24} src={getRegionIcon(instance)} />
                {instance.zonename}
              </div>
              <div className="deleteRule">
                <button
                  className="button button--red button--sm remove_button"
                  onClick={() => handleInstanceDelete(instance.id)}
                >
                  {t("remove")}
                </button>
              </div>
            </div>
            <div className={checkedInstances.length === (i + 1) ? "display-none" : "line_cuted m-t-15 m-b-15"} ></div>
          </div>
        ))}
      </div> : null}
    </div>)
}

export default SelectInstance;
