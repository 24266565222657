import React from 'react'
import CustomField from "../../../../components/CustomField";
import couponIco from "../../../../assets/images/coupon.svg";
import {Field, Form, Formik} from "formik";
import Checkbox from "../../../../components/Checkbox";
import {useTranslation} from "react-i18next";
import Button from "../../../../components/Button";
import {Link} from "react-router-dom";
import {images, links} from "../../index";
import * as Yup from "yup";
import prettyDate from "pretty-date";

const createDetails = [
  "instance",
  "loadBalancers",
  "firewall",
  "extraIps",
];

const validationSchema = Yup.object().shape({
  coupon: Yup.string().required("Required"),
  tos: Yup.boolean().required("Required"),
})

const ClaimBonus = ({onSubmit, success, error}) => {
  const {t} = useTranslation("dashboard");

  const timeUntilExpiry = success ? new Date(success?.data?.expiry_date)-new Date() : 0;
  const date = success ? prettyDate.format(new Date(new Date().getTime() - timeUntilExpiry)).replace("ago", "") : "1 month";

  return (
    <div className={'ClaimBonus'}>
      <p>{t(!error ? 'claimBonus.congrats' : 'claimBonus.errorEligible')}</p>
      {success ?
        (
          <>
          <p className={'m-b-30'}>A <strong>$50</strong> credit was added to your account, valid for <strong>{date}</strong>. If the credit is not used by the end of this period, it will expire.</p>
          <p className={'m-b-30'}><strong>Start exploring our services today.</strong></p>
          <div>
            <div className="Dashboard__links m-t-30">
              <div className="flex row flex-mobile-column">
                <div className="flex-grow-1 width-half-width p-l-15 p-r-15">
                  <h3>{t("create.title")}</h3>
                  <div className={'flex flex-wrap'}>
                    {createDetails.map((detail) => (
                      <Link className={"width-half"} to={links[detail]} key={detail}>
                        <div className="flex">
                          <div className="image m-r-10">
                            <img src={images[detail]} alt=""/>
                          </div>
                          <div>
                            <h4 className="text-grey-1 font-size-18 font-weight-regular m-t-0 m-b-5">
                              {detail === "loadBalancers" ? t(`afterCreate.${detail}.title`) : t(`create.${detail}.title`)}
                            </h4>
                            <p className="font-size-15 m-t-0 text-blue-1">
                              {detail === "loadBalancers" ? t(`afterCreate.${detail}.description`) : t(`create.${detail}.description`)}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              </div>
            </div>
          </>
          ) :
          <>
            <p className={'m-b-30'}>{t(error ? 'claimBonus.disableAdblocker' : 'claimBonus.eligible')}</p>
            <Formik
              initialValues={{
                coupon: "",
              }}
              validationSchema={validationSchema}
              onSubmit={values => {
                onSubmit(values?.coupon)
              }}
            >
              {({errors, touched, submitCount, handleChange}) => (
                <Form>
                  <CustomField
                    {...{
                      errors,
                      className: error && "grey-out",
                      touched,
                      name: "coupon",
                      Ico: () => <img src={couponIco} className="ico" alt=""/>,
                    }}
                  >
                    <Field
                      name="coupon"
                      type="text"
                      placeholder={t("claimBonus.placeholder")}
                    />
                  </CustomField>
                  <label className={`flex flex-align-items-center m-b-30 ${error && "grey-out"}`}>
                    <Checkbox
                      errors={errors}
                      touched={touched}
                      submitCount={submitCount}
                      className={'m-r-10'} name="tos" onChange={handleChange}/>
                    I agree to <a target={"_blank"} rel="noreferrer" href="https://shape.host/terms/" className={'m-l-5'}><strong>Terms and Conditions</strong></a>.
                  </label>
                  <Button disabled={error} type="submit" className={`button button--green width-full-width ${error && "grey-out"}`}>
                    {t("claimBonus.button")}
                  </Button>
                </Form>
              )}
            </Formik>
          </>
          }
          </div>
        )
      }

      export default ClaimBonus
