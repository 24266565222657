// import creditCard from "./CreditCard";
import Invioces from "./Invoices";
import Paypal from "./Paypal";
import Crypto from "./Crypto";
import Usage from "./Usage/Usage";

export default {
  // "credit-card": creditCard,
  "add-credit": Invioces,
  invoices: Invioces,
  paypal: Paypal,
  crypto: Crypto,
  usage: Usage,
};
