import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Volumes/components/Loader/Loader';
import { getFirewallsRequest, getIpAddressesRequest } from '../../../Dashboard/actions';
import { useLoopFetching } from '../../../../components/hooks/useLoopFetching';
import checkJobStatus from '../../../../helpers/checkJobStatus';
import { useToasts } from 'react-toast-notifications';
import FirewallsEmpty from './FirewallsEmpty';
import FirewallsList from './FirewallsList';

const Firewalls = ({ accountid }) => {
  const { t: networkT } = useTranslation('network');
  const t = (path) => networkT(`firewalls.${path}`);
  const { firewalls, firewallsLoading, instances, floatingIpList } = useSelector(({ instances }) => instances);
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!firewalls.length) {
      getFirewallsRequest()(dispatch);
    }
    if(!floatingIpList.length){
      getIpAddressesRequest()(dispatch);
    }
  }, [dispatch]);

  const firewallsWithInstances = useMemo(() => {
    if (firewalls.length) {
      const firewallsCopy = [];
      for (const firewall of firewalls) {
        const instanceIds = [];
        const usedInstances = [];
        const ipList = firewall.rules?.map(rule => rule.ipaddressid);
  
        console.log(ipList, "IP LIST"); //eslint-disable-line
  
        floatingIpList?.forEach(extraIp => {
            if (ipList?.includes(extraIp.id)) {
                instanceIds.push(extraIp.virtualmachineid);
            }
        });
  
        instances?.forEach(instance => {
            if (instanceIds.includes(instance.id)) {
                usedInstances.push(instance);
            }
        });
  
        const firewallCopy = { ...firewall, instances: usedInstances }
        
        firewallsCopy.push(firewallCopy);
      }


      return firewallsCopy;
  }
  }, [firewalls, instances]);

  const request = async (data, cb, accId) => {
    try {
      const result = await checkJobStatus(accountid ? accountid : accId, data);
      if (result.status === 'success') {
        cancelFetching();
        cb();
      }
      if (result.status === 'error') {
        cancelFetching();
        addToast(t(result.error_message.errortext), {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (error) {
      // CATCH FOR CHECK JOB STATUS
      // eslint-disable-next-line no-undef
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      });
      cancelFetching();
    }
  };

  const { startFetching, cancelFetching } = useLoopFetching(request);

  const handleFirewallScreens = () => {
    if (firewallsLoading) return <div className='text-align-center p-t-30 p-b-30'><Loader /></div>;

    if (firewalls) {
      return <FirewallsList
        firewalls={firewallsWithInstances}
        startFetching={startFetching}
      />
    } else {
      return <FirewallsEmpty t={t} />
    }
  }

  return (
    <div className="Firewalls">
      {handleFirewallScreens()}
    </div>
  );
};

export default Firewalls;
