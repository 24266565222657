export default {
  title: "Account",
  tabs: {
    profile: "Profile",
    security: "Security",
    api: "API",
    users: "Users",
    referrals: "Referrals",
    notifications: "Notifications",
  },
  profile: {
    profileDetails: {
      memberSince: "Shapehost member since",
      addAddress: "Add your address",
      serverLimit: "Your Account Server Limit",
      button: "Edit profile",
    },
    signIn: {
      title: "Sign-in method",
      withEmail: "Sign-in with email address",
      button: "Change",
    },
    password: {
      title: "Password",
      button: "Reset password",
    },
    deactivate: {
      title: "Deactivate account",
      description:
        "This will remove your account from all teams and disable your account.",
      button: "Deactivate account",
    },
    modals: {
      editProfile: {
        title: "Edit Profile",
        firstname: "First Name",
        lastname: "Last Name",
        name: "Name",
        phoneNumber: "Phone Number",
        company: "Your company",
        address: "Your address",
        button: "Update your profile",
      },
      changeEmail: {
        title: "Change email",
        oldEmail: "Old email",
        newEmail: "New email",
        confirmEmail: "Retype new email",
        button: "Change email",
      },
      changePassword: {
        title: "Change password",
        oldPassword: "Old password",
        newPassword: "New password",
        confirmPassword: "Retype new password",
        button: "Change password",
      },
      deactivateAccount: {
        title: "Deactivate Account",
        description1:
          "We are sorry to see you go and would love to know if we can somehow change your mind.",
        contactButton: "Contact Us",
        description2:
          "If you're sure you want to go on deactivating your account, you can purge all of your account data.",
        heading: "Purge all account data?",
        label: "Purge all of my account data",
        deactivateButton: "Deactivate Account",
      },
    },
  },
  security: {
    secureAccount: {
      title: "Secure your account",
      description:
        "Two-factor authentication adds an extra layer of security to your account. To log in, you'll need to provide a code along with your username and password. This lets us know it's actually you.",
      button: "Enable Two-Factor Authentification",
    },
    ssh: {
      title: "SSH keys",
      description:
        "SSH keys provide a more secure way of logging into a virtual private server with SSH than using a password alone.",
      link: "Learn more",
      button: "Add SSH key",
      editKey: "Edit key",
      deleteKey: "Delete key",
    },
    certificate: {
      title: "Certificates for Load Balancers and Spaces",
      description:
        "You can add your own certificates, or create them for free with Let's Encrypt. Certificates can be used for secure traffic forwarding with load balancers, and spaces CDNs.",
      link: "Using certificates with Shapehost resources",
      button: "Add certificate",
    },
    history: {
      title: "Security history",
      description: "Your most recent account events",
      tableHeads: {
        keyname: "Name",
        id: "Invoice #NO",
        fingerprint: "Fingerprint",
        domain: "Domain",
        action: "Action",
        user: "Account",
        ip: "IP Address",
        time: "Time",
      },
    },
    createKey: {
      title: "Create a SSH key pair",
      created: "Create a SSH key pair",
      description:
        "Please fill in the following data to create or register a ssh key pair.",
      download: "Download",
      copy: "Copy to clipboard",
      domain: "Domain Select",
      ok: "OK",
      cancel: "Cancel",
      close: "Close",
      name: "Name",
      success: "Successfully created key",
      successCopy: "Copied",
      button: "Create key",
    },
    deleteKey: {
      title: "Delete SSH Key",
      description: "Are you sure you want to delete SSH key?",
      button: "Delete",
      cancel: "Cancel",
      success: "Successfully deleted key",
    },
  },
  notifications: {
    maintenance: {
      title: "Maintenance and Outage Notifications",
      outage: "Send E-mails for Outage and Maintenance",
    },
    bandwidth: {
      title: "Bandwidth Preferences",
      sendEmail: "Send E-mails on Bandwidth Usage",
      stopServers: "Stop Servers on Bandwidth Usage",
    },
  },
};
