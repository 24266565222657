import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { deleteSecondaryIpRequest, getNicsListRequest } from "../../../Dashboard/actions";
import Loader from "../../../Volumes/components/Loader/Loader";

const DeleteModal = ({ show, onHide, t, nicToDelete, accountid, id, isSecondaryToDelete, startFetching }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await deleteSecondaryIpRequest({
        accountid,
        virtualmachineId: id,
        nicId: nicToDelete,
        isSecondaryToDelete
      })(dispatch);
      startFetching(jobid, () => {
        setIsLoading(false);
        onHide();
        getNicsListRequest({ accountid, virtualmachineId: id })(dispatch);
        addToast(t("nics.deleteModal.success"), {
          appearance: "success",
          autoDismiss: true
        });
      });
    } catch (error) {
      onHide();
      setIsLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  return (
    <div>
      <Modal
        Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("nics.deleteModal.title")}</h2>}
        onHide={onHide}
        show={show}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex row m-t-20">
            <button onClick={onHide} className="button button--grey width-half flex-grow-1 m-l-15 m-r-15">
              {t("nics.deleteModal.cancel")}
            </button>
            <button onClick={handleDelete} className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15">
              {t("nics.deleteModal.delete")}
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DeleteModal;
