import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import ReactSlider from 'react-slider';

import CustomField from "../../../../components/CustomField";
import Modal from "../../../../components/Modal";
import ResizeIco from "../../../../assets/images/volumes/resize-icon.svg";
import { increaseVolumeSize, getVolumesRequest } from "../../actions";
import Button from "../../../../components/Button";

export const pricePerGB = 0.2;
export const maxSizeInGB = 500;

const ResizeModal = ({ t, show, onHide, volumeDetails, setVisibleModal, startFetching, loading }) => {
  const [selectedSize, setSelectedSize] = useState(null);
  const [focused, setFocused] = useState(null);
  const [lastSelected, setLastSelected] = useState(null);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (volumeDetails) {
      setSelectedSize((volumeDetails.size / 1073741824).toFixed());
      setLastSelected((volumeDetails.size / 1073741824).toFixed());
    }
  }, [volumeDetails]);

  const volumes = [10, 25, 50, 100, 200, 500].filter(value => value > (volumeDetails?.size / 1073741824).toFixed());

  let handleInputChange = (value) => {
    const isNumberRegex = /^[0-9]*$/;
    if (isNumberRegex.test(value)) {
      setSelectedSize(value);
      setLastSelected(value);
    }
  }

  return (
    <div className="resizeModal">
      <Modal
        Title={() => <h2 className="font-size-36 font-weight-light text-grey-3 m-t-0 m-b-0">{t("resizeModal.title")}</h2>}
        onHide={onHide}
        show={show}
      >
        <p className="font-size-27 text-blue-2 font-weight-semibold line-height-1-6 m-t--20 m-b-60">{t("resizeModal.description")}</p>
        <div className="slider">
          <ReactSlider
            className="horizontal-slider"
            marks
            markClassName="example-mark"
            min={0}
            max={volumes.length - 1}
            value={volumes.findIndex(el => (el == selectedSize))}
            thumbClassName="example-thumb"
            trackClassName="example-track"
            onChange={value => {
              setSelectedSize(volumes[value])
              setLastSelected(volumes[value])
            }}
            renderThumb={(props, state) => (<div {...props}><div className={"tooltip"}>${volumes[state.valueNow] * pricePerGB}/month</div></div>)}
            renderMark={(props) => (<span {...{ ...props, className: `${props.className} mark-${props?.key}` }}>{volumes[props?.key]}GB</span>)}
          />
        </div>
        <div className={"otherAmountContainer flex flex-align-items-center m-t-30"}>
          <p className={"p-r-30"}>{t("resizeModal.otherAmount")}</p>
          <div className={"input--with-tooltip"}>
            <CustomField className="size-manual" name={"amount"} submitCount={1} errors={Number(selectedSize) < (volumeDetails?.size / 1073741824).toFixed() && {amount: `Must be at least ${(volumeDetails?.size / 1073741824).toFixed()} GB`} || Number(selectedSize) > maxSizeInGB && {amount: `Must be max ${maxSizeInGB} GB`}} >
              <input
                value={selectedSize && !focused ? `${selectedSize} GB` : selectedSize}
                name={"amount"}
                maxLength={4}
                placeholder={" "}
                onFocus={() => {
                  setFocused(true);
                  setSelectedSize("");
                }}
                onBlur={() => {
                  setFocused(false);
                  setSelectedSize(lastSelected);
                }}
                onChange={(e) => handleInputChange(e.target.value)}
                type="text"
              />
            </CustomField>
            <div className={"tooltip"}>${(selectedSize * pricePerGB).toFixed(1)}/month</div>
          </div>
        </div>
        <div className="flex width-full-width flex-justify-content-flex-end m-t-30">
          <Button
            disabled={!selectedSize || Number(selectedSize) < (volumeDetails?.size / 1073741824).toFixed()}
            loading={loading}
            onClick={async () => {
              try {
                const {
                  data: {
                    data: { jobid }
                  }
                } = await increaseVolumeSize({
                  newSize: selectedSize,
                  ...volumeDetails
                })(dispatch);
                startFetching(jobid, () => {
                  setVisibleModal(null);
                  addToast(t("resizeModal.success"), {
                    appearance: "success",
                    autoDismiss: true
                  });
                  getVolumesRequest()(dispatch);
                });
              } catch (error) {
                return addToast(t("resizeModal.error"), {
                  appearance: "error",
                  autoDismiss: true
                });
              }
            }}
            className="flex flex-align-items-center button button--blue"
          >
            <img src={ResizeIco} className="m-r-5" />
            {t("resizeModal.button")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ResizeModal;
