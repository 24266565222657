import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation('footer');
  return (
    <footer className="Footer">
      <div className="container">
        <div className="flex text-grey-1 font-weight-light flex-align-items-center flex-justify-content-space-between">
          <div>
            <span className="font-weight-bold">{t('copy', {endDate: (new Date()).getFullYear()})} </span>
            {t('allRights')}
          </div>{' '}

          <ul>
            <li>
              <a className="font-weight-bold lighter" href="https://shape.host/terms">
                {t('nav.terms')}
              </a>
            </li>
            <li>
              <a className="font-weight-bold lighter" href="https://shape.host/privacy">
                {t('nav.privacy')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
