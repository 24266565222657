import React, {useEffect, useState} from 'react'
import ico from '../../assets/images/date-ico.svg';
import CountInput from "../CountInput";

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


const MonthList = ({setMonth, month: activeMonth}) => {
  return (
    <div className={"MonthList"}>
      {months.map((month, index) => <div className={`MonthList__button ${activeMonth === index && "active"}`} onClick={() => setMonth(index)} key={month}>{month}</div>)}
    </div>
  )
}

const Datepicker = ({selected, onChange}) => {
  const thisYear = new Date().getFullYear()
  const [year, setYear] = useState(thisYear);
  const [month, setMonth] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    onChange && onChange({year, month})
  }, [year, month])


  useEffect(() => {
    //eslint-disable-next-line
    window.addEventListener("click", (e) => {
      console.log(e.target.className) //eslint-disable-line
      if (
        !e?.target?.className?.includes("CountInput") && !e?.target?.className?.includes("input") && !e?.target?.className?.includes("MonthList__button")
      ) {
        setShowDropdown(null);
      }
    });
  }, []);

  return (
    <div className={"Monthpicker"}>
      <div className={"Monthpicker__input"}>
        <img src={ico} alt=""/>
        <input className={`input ${showDropdown && 'active'}`} placeholder={"Choose month"} onClick={() => setShowDropdown(!showDropdown)} value={month !== null ? `${months[month]}, ${year}` : ''} readOnly {...{selected, onChange}} />
        <span className="arrow fomt-weight-semibold arrow--down arrow--blue m-l-5"></span>
      </div>
      {showDropdown && <div className={"Monthpicker__selector"}>
        <CountInput minValue={1970} value={year} maxValue={thisYear} onChange={setYear}/>
        <MonthList month={month} setMonth={setMonth}/>
      </div>}
    </div>
  )
}

export default Datepicker
