import React from 'react';
import NetworkEmptyIco from '../../../../assets/images/network/Network_empty_icon.svg';

const NetworkEmpty = ({ setVisibleModal, t }) => {
    return (
        <div className='Empty_container'>
            <img src={NetworkEmptyIco} width={150} height={150} />
            <span className='text-grey-1 font-size-16 m-t-20 line-height-1-6'>{t('networks.emptyDescription')}</span>
            <button
                className="button button--blue button--md flex create_button"
                onClick={() => setVisibleModal('create')}>
                {t('networks.create')}
            </button>
        </div>
    )
}

export default NetworkEmpty;
