export default {
  title: "Create Account",
  description: "You're moments away from launching a blazing fast SSD Cloud Server. Enter your email and create a password to get started!",
  placeholders: {
    email: "Email address",
    password: "Password",
    ref: "Referral Code"
  },
  labels: {
    confirm: "I confirm having read the"
  },
  links: {
    gdpr: "Privacy Policy",
    logIn: "Log In Here"
  },
  button: "Create Account",
  alreadyAMember: "Already a member?"
}
