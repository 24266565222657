import { get } from '../../../components/http';

// Get hosting products
export const getProductsReducer = (reducerState, action) => {
    return {
        ...reducerState,
        products: (action.data && action.data.products) || reducerState.products,
        productsByType: (action.data && action.data.productsByType) || action.data.products,
        locations: ((action.data && action.data.products) || reducerState.products)
            .map(({ locations }) => locations)
            .flat()
            .filter(({ name }, index, self) => self.map((value) => value.name).indexOf(name) === index),
        osdistributions: ((action.data && action.data.products) || reducerState.products)
            .map(({ osdistributions }) => osdistributions)
            .flat()
            .filter(({ name }, index, self) => self.map((value) => value.name).indexOf(name) === index)
    };
};

export const getProductsState = { products: [] };

export const getProductsType = 'GET_PRODUCTS';

export const getProducts =
    (params = {}) =>
    async (dispatch) => {
        let productsResponse;
        try {
            productsResponse = await get('/products', { params });
        } catch (error) {
            throw new Error(error);
        }
        const {
            data: { data }
        } = productsResponse;
        const [products] = data || [];
        dispatch({ type: getProductsType, data: { products } });
    };

// CREATE INSTANCE ACTION
export const type = 'SET_CREATE_INSTANCE';

export const createInstanceState = {
    createInstance: {
        plan: 'standard'
    }
};

export const createInstanceReducer = (reducerState, action) => {
  return {
  ...reducerState,
    createInstance: {
    ...reducerState.createInstance,
    ...action.data
    }
  }
};

export const createInstanceAction = (data) => async (dispatch) => {
    if (data && data.plan) {
        const { products } = data;
        const planType = {
            S: 'standard',
            GP: 'generalPurpose',
            CPU: 'cpuOptimized',
            MEM: 'memoryOptimized'
        };

        const productsByType = products.filter(({ name }) => planType[name.substr(0, name.indexOf('-'))] === data.plan);
        dispatch({ type: getProductsType, data: { productsByType } });
    }
    dispatch({ type, data });
};
