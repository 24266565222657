import React from "react";
import MonthPicker from "../../../components/MonthPicker";

const UsageHeading = ({usage}) => {

  return (
      <div className={"AccountBalance"}>
        <div className={"flex flex-justify-content-space-between"}>
          <div>
            <h3>Usage</h3>
            <span className={"price"}>$ {usage}</span>
          </div>
          <div className="MonthYearPicker">
            <MonthPicker onChange={date => console.log(date)} /> {/* eslint-disable-line */}
          </div>
        </div>
      </div>
    )

}

export default UsageHeading
