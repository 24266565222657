import React from "react";
import { useTranslation } from "react-i18next";
import VpcList from "./VpcList/VpcList";

const leanAboutItems = ["productDocs", "tutorials", "api"];

const LearnAbout = ({ t }) => (
  <div className="Vpc__LearnAbout card border-grey-1 max-width-full-width">
    <h2 className="font-weight-regular text-grey-3 font-size-27 m-b-0 m-t-0">
      {t("title")}
    </h2>
    <div className="flex row">
      {leanAboutItems.map((item) => (
        <div key={item} className="m-r-40 m-l-40">
          <div className="flex flex-align-items-center m-t-20">
            <h2 className="font-weight-semibold text-grey-3 font-size-16 m-t-0 m-b-0 m-r-10">
              {t(`${item}.title`)}
            </h2>
            <a className="font-size-16" href="">
              {t(`${item}.link`)}
            </a>
          </div>
          <p className="text-grey-1 font-size-16">
            {t(`${item}.description`)}{" "}
          </p>
        </div>
      ))}
    </div>
  </div>
);

// const VpcItem = ({
//   location: { country, city } = {},
//   info: { name, tag, ip, resources } = {},
// }) => (
//   <div className="Vpc__item m-t-30 m-b-30 flex">
//     <div className="Vpc__item-country card border-grey-1 flex flex-align-items-center max-width-full-width p-t-15 p-b-15 min-width-250 m-r-30">
//       <img className="m-r-15" src={genFlag} alt="" />
//       <div>
//         <div className="text-grey-2">{city}</div>
//         <div className="text-grey-1 font-size-12">{country}</div>
//       </div>
//     </div>
//     <div className="Vpc__item-info card flex flex-align-items-center flex-justify-content-space-between border-grey-1 max-width-full-width flex-grow-1 p-t-15 p-b-15 font-size-16 text-grey-1">
//       <div className="flex flex-align-items-center">
//         <div className="min-width-150 m-r-80">
//           {name} <span className="badge">{tag}</span>
//         </div>
//         {ip}
//       </div>
//       <div className="flex flex-align-items-center">
//         {resources}
//         <img className="m-l-40" src={showMoreIco} alt="" />
//       </div>
//     </div>
//   </div>
// );

const Vpc = ({ accountid, underInstance }) => {
  const { t: networkT } = useTranslation("network");
  const t = (path) => networkT(`vpc.${path}`);

  return (
    <div className="Vpc">
      <h2 className="font-weight-regular text-grey-3 font-size-27 m-b-0">
        {t("title")}
      </h2>
      <VpcList t={t} accountid={accountid} underInstance={underInstance} />
      <LearnAbout t={(path) => t(`learnAbout.${path}`)} />
    </div>
  );
};

export default Vpc;
