import React, { useState, useMemo, useEffect } from 'react';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
} from 'recharts';

import refreshIco from '../../../../../assets/images/refresh.svg';
import SelectWithIcons from '../../../../../components/SelectWithIcons';
import { getMetricRequest } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { convertUTCDateToLocalDate } from '../../../../../helpers/dateTime';

const CustomLabelMemory = (value) => {
  if (value === 0) {
    return '0 MB';
  }
  return value ? `${(value/1024).toFixed(2)} MB` : '';
};

const CustomLabelCpuUsage = (value) => {
  if (value === 0) {
    return '0%';
  }
  return value ? `${value}%` : '';
};

const CustomLabelBandwidth = (value) => {
  if (value === 0) {
    return '0 Mb/s';
  }
  return value ? `${value} Mb/s` : '';
}

const CustomTooltip = ({ payload, currency, legends }) => {
  let fullDate;
  let fullTime;
  let usedMemoryInPercents = undefined;
  let memoryUsage = undefined;
  const filteredPayload = payload?.filter((el) => (el.stroke));

  if (filteredPayload && filteredPayload.length) {
    fullDate = filteredPayload[0]?.payload.fullDate;
    fullTime = filteredPayload[0]?.payload.fullTime;
    usedMemoryInPercents = filteredPayload[0]?.payload.usedMemoryInPercents;
    memoryUsage = filteredPayload[0]?.payload.memoryUsage;
  }

  const handleContent = (value) => {
    if (usedMemoryInPercents !== undefined && memoryUsage !== undefined) {

      return usedMemoryInPercents;
    }
    return value
  }

  return (
    <div className="custom-tooltip">
      <div className="line text-grey-3 font-size-15 font-weight-semibold m-b-20 m-t-0 width-full-width">
        <div>{fullDate}</div>
        <div>{fullTime}</div>
      </div>
      <div className="content_contaienr">
        {filteredPayload?.map(({ name, value, stroke }, i) => (
          <div key={name} className="flex flex-align-items-center line">
            <span className="flex flex-align-items-center">
              <div className="Legend m-r-5" style={{ border: `2px solid ${stroke}` }}></div>
              <span className="name text-grey-8 font-size-14 m-r-5">{legends[i]}:</span>
            </span>
            <span className="value text-grey-3 font-size-15 font-weight-semibold">
              {handleContent(value)} {currency}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const Graphs = ({ t: dashboardTrans, instanceId, accountid }) => {
  const t = (path) => dashboardTrans(`instance.graph.${path}`);
  const dispatch = useDispatch();
  const metric = useSelector((state) => state.instances.metric);

  const filterOptions = [
    // { value: '1h', text: `1 ${t('hour')}`, hours: 1, scale: 'point', interval: 0 },
    // { value: '6h', text: `6 ${t('hours')}`, hours: 6, scale: 'point', interval: 6 },
    { value: '1d', text: `1 ${t('day')}`, hours: 24, scale: 'point', interval: 24 },
    { value: '7d', text: `7 ${t('days')}`, hours: 168, scale: 'auto', interval: 168, disabled: true },
    { value: '1m', text: `1 ${t('month')}`, hours: 24*30, scale: 'auto', interval: 168, disabled: true },
  ]

  const [filterOption, setFilterOption] = useState(filterOptions[0]);

  useEffect(() => {
    if (instanceId && accountid) {
      let startDate = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
      startDate = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`
      let endDate = new Date()
      endDate = `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`
      getMetricRequest({ instanceId, accountid, startDate, endDate })(dispatch);
    }
  }, [instanceId, accountid]);

  const fliteredMetrics = useMemo(() => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setHours(startDate.getHours() - filterOption.hours);

    const filteredByDate = metric.filter(value => {
      const valueDate = convertUTCDateToLocalDate(new Date(value.timestamp));
      return startDate < valueDate && valueDate < endDate
    });

    const handleInOutBound = (value) => {
      const [size, type] = typeof value === 'string' ? value?.split(' ') : [value];

      if (type === 'MB') {
        return +size;
      }

      if (type === 'KB' || typeof value === 'number') {
        return (size / 1024).toFixed(2);
      }
    }

    const graphs = ['cpu', 'bandwidth', 'memory'];


    const formattedData = graphs.reduce(
      (acc, graphKey) => {
        filteredByDate.map((item) => {
          const localDate = convertUTCDateToLocalDate(new Date(item?.timestamp));
          const [hours, minutes] = localDate.toString().split(' ')[4].split(':');
          const [, month, day, year, time] = localDate.toString().split(' ');
          switch (graphKey) {
            case 'cpu':
              acc[graphKey].push({
                time: `${hours}:${minutes}`,
                fullDate: `${day} ${month} ${year}`,
                fullTime: time,
                usage: Number(item?.cpuused.slice(0, -1)),
              });
              break;
            case 'bandwidth': {
              acc[graphKey].push({
                time: `${hours}:${minutes}`,
                fullDate: `${day} ${month} ${year}`,
                fullTime: time,
                inbound: (item?.networkkbsread ? handleInOutBound(item?.networkkbsread) : item?.networkkbsread) * 1000,
                outbound: (item?.networkkbswrite ? handleInOutBound(item?.networkkbswrite) : item?.networkkbswrite) * 1000,
              });
              break;
            }
            case 'memory':
              acc[graphKey].push({
                time: `${hours}:${minutes}`,
                fullDate: `${day} ${month} ${year}`,
                fullTime: time,
                memoryUsage: item?.memorykbs - item?.memoryintfreekbs,
                usedMemoryInPercents: ((item?.memorykbs - item?.memoryintfreekbs)/item?.memorykbs*100).toFixed(2),
              });
              break;
            default:
              break;
          }
        });
        return acc;
      },
      {
        cpu: [],
        bandwidth: [],
        memory: []
      }
    );

    return formattedData;
  }, [metric, filterOption]);

  const handleBandwidthLegends = () => {
    const legends = [
      { name: t('inbound'), color: "#0069FF" },
      { name: t('outbound'), color: "#1AE07E" }
    ]

    return (
      <div className="flex m-r-10">
        {
          legends.map(({ name, color }) => (
            <div key={name} className="flex flex-align-items-center font-size-14 text-grey-8 m-b-30 m-r-20"><div className='Legend m-r-6' style={{ border: `2px solid ${color}` }}></div> {name}</div>
          ))
        }
      </div>
    )
  }

  return (
    <div className="Graphs flex-grow-1">
      <div className="flex flex-align-items-center flex-justify-content-flex-start m-b-20">
        <div className="filters flex m-r-30">
          <SelectWithIcons
            options={filterOptions}
            defaultOption={filterOption}
            onChange={setFilterOption}
            isChartFilter
          />
          <div className="refresh_icon m-r-5" onClick={() => { setFilterOption(filterOptions[0]) }}>
            <img src={refreshIco} alt="" />
          </div>
        </div>
      </div>
      <div className="Charts_conatiner max-width-full-width">
        <div className="Chart_container" style={{ height: 410 }}>
          <div className="flex flex-align-items-center flex-justify-content-space-between">
            <h3 className="Chart_title m-t-0" >{t('cpuUsage')}</h3>
            <div className="flex flex-align-items-center font-size-14 text-grey-8 m-b-30 m-r-30"><div className='Legend m-r-6'></div>{t('system')}</div>
          </div>
          <ResponsiveContainer>
            <ComposedChart
              data={fliteredMetrics.cpu}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#0069FF" stopOpacity={0.3} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.3} />
                </linearGradient>
              </defs>
              <CartesianGrid stroke="#C4C8D6" strokeDasharray="9" vertical={false} />
              <XAxis tick={(props) => {
                const { x, y, payload, index } = props;
                return (
                  <g>
                    <g>
                      <text fill={'#C4C8D6'} x={x} y={y + 15}>
                        {index % 5 === 0 && payload?.value}
                      </text>
                    </g>
                  </g>
                );
              }} scale={filterOption.scale} interval={filterOption.interval} dataKey="time"  tickLine={{ stroke: '#C4C8D6' }} height={50} dy={25} />
              <YAxis tickFormatter={CustomLabelCpuUsage} width={110} tick={{ fill: '#C4C8D6' }} tickLine={{ stroke: '#C4C8D6' }} dx={-25} />
              <Tooltip content={<CustomTooltip currency="%" legends={['CPU Usage']} />} />
              <Line style={{ rx: "100px", ry: "100px" }} dataKey="usage" stroke="#0069FF" dot={false} type="linear" activeDot={{ r: 8 }} />
              <Area type="linear" dataKey="usage" stroke={false} strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="Chart_container" style={{ height: 410 }}>
          <div className="flex flex-align-items-center flex-justify-content-space-between">
            <h3 className="Chart_title m-t-0" >{t('memoryUsage')}</h3>
            <div className="flex flex-align-items-center font-size-14 text-grey-8 m-b-30 m-r-30"><div className='Legend m-r-6'></div>{t('usedMemory')}</div>
          </div>
          <ResponsiveContainer>
            <ComposedChart
              data={fliteredMetrics.memory}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#0069FF" stopOpacity={0.2} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid stroke="#C4C8D6" strokeDasharray="9" vertical={false} />
              <XAxis tick={(props) => {
                const { x, y, payload, index } = props;
                return (
                  <g>
                    <g>
                      <text fill={'#C4C8D6'} x={x} y={y + 15}>
                        {index % 5 === 0 && payload?.value}
                      </text>
                    </g>
                  </g>
                );
              }}  scale={filterOption.scale} interval={filterOption.interval} dataKey="time" tickLine={{ stroke: '#C4C8D6' }} height={50} dy={25} />
              <YAxis tickFormatter={CustomLabelMemory} width={110} tick={{ fill: '#C4C8D6' }} tickLine={{ stroke: '#C4C8D6' }} dx={-25} />
              <Tooltip content={<CustomTooltip currency="%" legends={['Used memory']} />} />
              <Line type="linear" dataKey="memoryUsage" stroke="#0069FF" dot={false} activeDot={{ r: 8 }} />
              <Area type="linear" dataKey="memoryUsage" stroke={false} strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="Chart_container" style={{ height: 410 }}>
          <div className="flex flex-align-items-center flex-justify-content-space-between">
            <h3 className="Chart_title m-t-0" >{t('publicBandwidth')}</h3>
            {handleBandwidthLegends()}
          </div>
          <ResponsiveContainer>
            <ComposedChart
              data={fliteredMetrics.bandwidth}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="9" vertical={false} />
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#0069FF" stopOpacity={0.2} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#1AE07E" stopOpacity={0.2} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis tick={(props) => {
                const { x, y, payload, index } = props;
                return (
                  <g>
                    <g>
                      <text fill={'#C4C8D6'} x={x} y={y + 15}>
                        {index % 5 === 0 && payload?.value}
                      </text>
                    </g>
                  </g>
                );
              }} scale={filterOption.scale} interval={filterOption.interval} dataKey="time" tickLine={{ stroke: '#C4C8D6' }} height={50} dy={25} />
              <YAxis tickFormatter={CustomLabelBandwidth} tick={{ fill: '#C4C8D6' }} tickLine={{ stroke: '#C4C8D6' }} width={110} dx={-25} />
              <Tooltip content={<CustomTooltip currency="Mb/s" legends={['Inbound', 'Outbound']} />} />
              <Line type="linear" dataKey="inbound" stroke="#0069FF" dot={false} activeDot={{ r: 8 }} />
              <Area type="linear" dataKey="inbound" stroke={false} strokeWidth={2} fillOpacity={1} fill="url(#colorUv)" />
              <Line type="linear" dataKey="outbound" stroke="#1AE07E" dot={false} activeDot={{ r: 8 }} />
              <Area type="linear" dataKey="outbound" stroke={false} strokeWidth={2} fillOpacity={1} fill="url(#colorGreen)" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Graphs;
