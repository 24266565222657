export default {
  title: "Volumes",
  noVolumes: {
    title: "Volumes",
    description:
      "As soon as you will be charged, this area will become populated.",
    description2:
      "Volumes are highly available units of block storage that you can attach anytime to an Instance to increase its space.",
    button: "Create volume",
    buttonLoading:"Loading...",
  },
  noVolumesDashboard: {
    description: "Volumes are highly available units of block storage that you can attach anytime to an Instance to increase its space."
  },
  list: {
    name: "VOLUME",
    instance: "INSTANCE",
    created: "CREATED",
  },
  snapshotList:{
    name: "Name",
    interval: "Interval type",
    created: "Created",
    template:"Create Template",
    volume: "Create Volume",
    delete:"Delete",
    title:'Volumes Snapshots',
    deleteModal:{
      title:'Delete snapshot',
      deleting:"Deleting a snapshot",
      description:" need text here",
      success:"Successfully deleted snapshot",
    }
  },
  detach: "Detach Instance",
  attach:"Attach Instance",
  increase: "Increase size",
  resize: "Resize volume",
  snapshot: "Take snapshot",
  configInfo: "Config info",
  deleteKey: "Delete",
  deleteModal: {
    title: "Delete volume",
    success: "Successfully deleted volume",
    error: "Somethig went wrong",
    deleting: "Deleting a volume from a running Instance",
    description:
      " may cause errors on your Instance. Power the Instance off first, or make sure it isn’t currently writing to the volume before proceeding. Additionally, all of your data will be lost forever, and you will not be charged for it going forward. ",
    areYouSure: "Are you sure you want to proceed?",
    cancel: "Cancel",
    confirm: "Confirm",
  },
  detachModal: {
    title: "Detach volume",
    detaching: "Detaching a volume from a running Instance may cause data loss. ",
    description1:
    "Power off the Instance first, or make sure it isn’t currently writing to the volume before proceeding. You’ll be able to access your detached volume and data in the",
    description2: "tab, and you will still be charged for it",
    volume: " Volumes ",
    areYouSure: "Are you sure you want to proceed?",
    cancel: "Cancel",
    confirm: "Confirm",
    success: "Successfully detached volume",
    error: "Somethig went wrong",
  },
  attachModal:{
    title:"Attach volume",
    cancel: "Cancel",
    confirm: "Confirm",
    success: "Successfully attached volume",
    error: "Somethig went wrong",
  },
  snapshotModal: {
    title: "Take a snapshot",
    description: "Snapshots cost is based on space used and charged at ",
    price: "$0.05/GB/Month.",
    placeholder: "Snapshot name",
    button: "Take snapshot",
    success: "Successfully taked snapshot",
    error: "Somethig went wrong",
  },
  increaseModal: {
    title: "Increase volume size",
    description:
      "Select or enter a new size for your volume. Must be greater than 200 GB. This change is not reversible",
    hour: "hour",
    customPlaceholder: "Enter size in GB",
    button: "Resize volume",
    success: "Successfully resized volume",
    error: "Somethig went wrong",
  },
  resizeModal: {
    title: "Resize volume",
    description: "Select Size",
    placeholder: "Disk size (in GB)",
    ok: "OK,",
    shrink: "go ahead with the shrinking",
    button: "Resize Volume",
    success:"Volume successfully resized",
    otherAmount: "or enter your own custom size",
  },
  infoModal: {
    title: "Configure your volume",
    description: "To configure your volume, do the following:",
    copy: "copy",
    logInto: {
      title: "Log into your Instance",
    },
    format: {
      title: "Format the volume: Only for the first time",
      warning: "WARNING:",
      description:
        " This step will destroy all existing data on the volume. Only run this command on a new volume or if you're sure you want to destroy the existing data.",
    },
    mount: {
      title: "Mount your volume",
      description:
        "Run the following commands on the Droplet to make your volume accessible:",
      createMount: "# Create a mount point for your volume: ",
      mountVolume: "# Mount your volume at the newly-created mount point:",
      changeMount:
        "# Change fstab so the volume will be mounted after a reboot",
    },
    learnMore: "Learn more about Block Storage at ",
    button: "Ok",
  },
  createModal: {
    title: "Create Volume",
    section1: "1. Select Size",
    section2: "2. Select Instance",
    section3: "3. Name Volume",
    dashboardSection2: "2. Name Volume",
    hour: "hour",
    button: "+ Create Volume",
    search: "Search",
    name: {
      label: "NAME VOLUME",
      placeholder: "Enter volume name",
    },
    instance: {
      label: "SELECT INSTANCE TO ATTACH TO",
      placeholder: "Select an instance",
    },
    automatically: "Automatically Format & Mount",
    fileSystem: "Choose a filesystem",
    blockStorage:
      "Block storage volumes are created in the same region and project as the Instance they are attached to.",
    automaticallyDescription:
      "We will choose the appropriate default configurations. These settings can be changed later via ssh.",
    manually: "Manually Format & Mount",
    manuallyDescription:
      "We will attach the volume for you. Then you can manually format and later mount the newly made volume.",
    success: "Successfully created volume",
    error: "Couldn't create volume",
  },
  learn: {
    title: "Block storage basics",
    overview: {
      link: "Overview",
      description: "Discover block storage, and what you can do with volumes.",
    },
    api: {
      link: "API docs",
      description: "Use block storage volumes via ShapeHost",
    },
    about: {
      link: "Tell us what you think",
      description: "Submit your feedback on block storage",
    },
  },
};
