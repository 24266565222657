import React, {useState} from "react"
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useTranslation} from "react-i18next";
import instancesIco from '../../../assets/images/create/instances2.svg';

const InstancesPassword = ({instancesPasswords, onClose}) => {
  const [page, setPage] = useState(1);
  const [isCopied, setIsCopied] = useState(null);
  const {t: commonT} = useTranslation('common');
  const { t } = useTranslation("dashboard");

  const onCopy = (ip) => {
    setIsCopied(ip)
    setTimeout(() => setIsCopied(null), 2000) // eslint-disable-line
  }

  const copyAllText = instancesPasswords.map(({name, password}) => `${name}: ${password}`).join("\n\n")

  return (
    <div className={"InstancesPassword"}>
      <h2>{t("passwords.title")}</h2>
      <p>{t("passwords.description")}</p>
      <div className={"password"}>
        <h3>
          <img src={instancesIco} alt=""/>
          {instancesPasswords[page - 1]?.name}
        </h3>
        <div>
          {instancesPasswords[page - 1]?.password}
          <CopyToClipboard text={instancesPasswords[page - 1]?.password} onCopy={onCopy}>
            <span
              className='copy text-blue-1 m-l-10 position-relative'
              style={{zIndex: '1', cursor: 'pointer'}}
            >
              {isCopied === instancesPasswords[page - 1]?.password ? commonT('copied') : commonT('copy')}
            </span>
          </CopyToClipboard>
        </div>
      </div>
      <div className={"actions"}>
        {page === instancesPasswords.length ?
          <button onClick={onClose} className={"button button--green width-full-width"}>{t("passwords.close")}</button>
          :
          <>
            <CopyToClipboard text={copyAllText} onCopy={onCopy}>
              <span
                style={{zIndex: '1', cursor: 'pointer'}}
              >
                {isCopied ? commonT('copied') : commonT('Copy all')}
              </span>
            </CopyToClipboard>
            <button className={"button button--green"} onClick={() => setPage(page + 1)}>{t("passwords.next")}</button>
          </>
        }
      </div>
    </div>
  )
}

export default InstancesPassword
