import { useState, useEffect, useRef } from 'react';

export default function useComponentVisible(initialIsVisible, id) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && event.target.id !== id) {
            setIsComponentVisible(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true); //eslint-disable-line
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true); //eslint-disable-line
        };
    }, []);

    return { ref, isComponentVisible, setIsComponentVisible };
}
