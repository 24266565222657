import React from "react";
import {useHistory, useParams} from "react-router-dom";
import Card from "../../../../../components/Card";
import { get } from "../../../../../components/http";
import ResetPassword from "./ResetPassword/ResetPassword";
import SavePassword from "./ResetPassword/SavePassword";

const Access = ({ t: dashboardTrans, accountid, instanceId, instantState }) => {
  const t = (path) => dashboardTrans(`instance.access.${path}`);
  const history = useHistory();
  const { id, subTab } = useParams();
  const getConsoleUrl = async () => {
    const {
      data: {
        data: { console_url }
      }
    } = await get(`/accounts/${accountid}/vm/${id}/console`);
    // eslint-disable-next-line no-undef
    window.open(console_url, "_blank", "noopener,noreferrer, width=300,resizable=yes");
  };

  return (
    <div className="Access_tab flex flex-column flex-justify-content-space-between">
      <Card>
        <Card.Body className="width-full-width flex-direction-column p-l-40 p-r-40 p-t-20 p-b-20">
          <h3 className="font-size-27 text-grey-3 font-weight-regular m-b-10">{t("console.title")}</h3>
          <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-25">{t("console.description")}</p>
          <button className="button button--md button--blue max-width-250 m-b-20" onClick={getConsoleUrl}>
            {t("console.button")}
          </button>
        </Card.Body>
      </Card>
      <ResetPassword t={t} accountid={accountid} instanceId={instanceId} instantState={instantState} />
      {subTab && <SavePassword onHide={() => {
        history.push(`/instance/${id}/access`);
      }} t={t} password={subTab} />}
    </div>
  );
};

export default Access;
