import React, { useState } from 'react';
import { convertUTCDateToLocalDate } from '../../../../helpers/dateTime';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import useComponentVisible from '../../../../helpers/useComponentVisible';
import TrashIco from '../../../../assets/images/tickets/trash_icon.svg';
import ConfigIco from '../../../../assets/images/config-icon.svg';
import DeleteModal from './DeleteModal';
import prettyDate from 'pretty-date';
import FirewallIco from '../../../../assets/images/dashboard/firewall_icon.svg';

const FirewallsList = ({ firewalls }) => {
    const [deleteFirewall, setDeleteFirewall] = useState(null);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(null, 'more');
    const { t: networkT } = useTranslation('network');
    const t = (path) => networkT(`firewalls.${path}`);
    const { t: commonT } = useTranslation("common");

    return (
        <div className="Firewalls_list">
            <div className="Firewalls_list_header">
                <div className="Title_description">
                    <div className="font-size-27 text-grey-3">{t('firewallsList.title')}</div>
                    <div className="font-size-16 text-grey-1 line-height-1-6">{t('firewallsList.description')}</div>
                </div>
                <Link to="firewalls-create">
                    <button className="button button--blue">{t('firewallsList.button')}</button>
                </Link>
            </div>
            <div className="Firewalls_table">
                <div className="List_header">
                    <div>{t('firewallsList.name')}</div>
                    <div>{t('firewallsList.instances')}</div>
                    <div>{t('firewallsList.rules')}</div>
                    <div>{t('firewallsList.created')}</div>
                </div>
                <div className="List_body">
                    {firewalls && firewalls.length && firewalls.map((firewall, i) => {
                        return (
                            <div key={firewall.name} className="List_item">
                                <Link to={`/vpc-network/firewalls-edit/${firewall.id}`}>
                                    <div className='Main_content'>
                                        <div className="text-grey-3"><img src={FirewallIco} /> {firewall.name}</div>
                                        <div className="text-grey-3">{firewall.instances.length}</div>
                                        <div className="text-grey-3">{firewall.rules.length}</div>
                                        <div>{prettyDate.format(convertUTCDateToLocalDate(new Date(firewall.created_at)))}</div>
                                    </div>
                                </Link>
                                <div className="text-align-center text-blue-1 flex flex-align-items-center max-width-70 more position-relative">
                                    <a
                                        id='more'
                                        className="lighter font-weight-semibold font-size-16"
                                        href="javascript:;"
                                        onClick={() => {
                                            isComponentVisible !== i ? setIsComponentVisible(i) : setIsComponentVisible(null);
                                        }}
                                    >
                                        {commonT("more")}
                                        <span
                                            className={`arrow fomt-weight-semibold ${isComponentVisible === i ? "arrow--up" : "arrow--down"
                                                } arrow--blue m-l-5`}
                                        />
                                    </a>
                                    {isComponentVisible !== null && isComponentVisible === i && (
                                        <div ref={ref} className="keyPop popup text-grey-1 font-size-13 font-weight-semibold m-t-10 popup_menu">
                                            <Link to={`/vpc-network/firewalls-edit/${firewall.id}`}>
                                                <div>
                                                    <img src={ConfigIco} />
                                                    <span className='text-grey-1'>{t('firewallsList.configureKey')}</span>
                                                </div>
                                            </Link>
                                            <div className="line_cuted m-t-15 m-b-15"></div>
                                            <div onClick={() => setDeleteFirewall(firewall)}><img src={TrashIco} /> {t('firewallsList.deleteKey')}</div>
                                        </div>
                                    )}
                                </div>
                            </div>)
                    })}
                </div>
            </div>
            <DeleteModal
                onHide={() => setDeleteFirewall(null)}
                firewall={deleteFirewall}
                show={!!deleteFirewall}
                t={t}
                deleteItem='group'
            />
        </div>
    );
};

export default FirewallsList;
