import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getListKeysRequest } from '../../../Profile/actions';
import { useDispatch } from 'react-redux';

import Checkbox from '../../../../components/Checkbox';
import SshModal from '../../../../components/SshModal';

import { store } from '../../../../store';
import { createInstanceAction } from '../actions';
import redAlertIco from '../../../../assets/images/red-alert.svg';
import redAlertTriangleIco from '../../../../assets/images/red-triangle-alert.svg';


const SshKey = ({ t, isVisible }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const reduxDispatch = useDispatch();
    const globalState = useContext(store);
    const {
        selectedRegion
    } = useSelector(({ products }) => products);
    const {
        state: {
            createInstance: { sshKeys: selectedSshKeys = [], ssh }
        },
        dispatch
    } = globalState;

    const { domains, keys = [] } = useSelector(({ profile }) => profile);

    useEffect(() => {
        if(selectedRegion) {
            getListKeysRequest({acsUrl: selectedRegion?.name?.split("-")[0].toLowerCase()})(reduxDispatch);
        }
    }, [selectedRegion]);

    const onAddKey = ({name: sshkeyname, key: sshpublickey}) => {
        createInstanceAction({ssh: {sshkeyname, sshpublickey}, sshKeys: [...selectedSshKeys, sshkeyname]})(dispatch)
        setShowPopup(false)
    }
    return (
        <div className={`${isVisible ? '' : 'display-none'}`}>
            <div className={`ssh-keys ${!(keys.length || ssh?.sshkeyname) && 'error'}`}>
                {keys.length || ssh?.sshkeyname ? (
                    <div className='sshKeys m-b-30'>
                        <h3 className='font-size-16 text-grey-2 font-weight-regular m-b-25 m-t-0 flex'>
                            {t('ssh.title')}
                            {!selectedSshKeys?.length &&
                              <div className="error m-l-35 flex flex-align-items-center">
                                  <img className='m-r-5' src={redAlertTriangleIco} alt='' />
                                  <span className="text-red-2">{t('ssh.error')}</span>
                              </div>
                            }
                        </h3>
                        <div className={'flex m-b-10'}>
                        <label className={`flex flex-align-items-center m-r-20 ${selectAll ? 'text-blue-1' : 'text-grey-1'}`}>
                            <Checkbox
                                className={`Checkbox--full-box m-r-10`}
                                type='checkbox'
                                value='ipv6'
                                name='sshKeys'
                                onChange={(e) => {
                                    setSelectAll(e.target.checked);
                                    createInstanceAction({
                                        sshKeys: e.target.checked ? keys.map(({ name }) => name) : []
                                    })(dispatch);
                                }}
                            />
                            {t('ssh.selectAll')}
                        </label>
                        {[...keys, {name: ssh?.sshkeyname}]?.map(({ name }) => name && (
                            <label
                                key={name}
                                className={`flex flex-align-items-center  m-r-20 ${
                                    selectedSshKeys.includes(name) ? 'text-blue-1' : 'text-grey-1'
                                }`}
                            >
                                <Checkbox
                                    className='Checkbox--full-box m-r-10'
                                    type='checkbox'
                                    value={name}
                                    checked={selectedSshKeys.includes(name)}
                                    name='sshKeys'
                                    onChange={(e) => {
                                        createInstanceAction({
                                            sshKeys: e.target.checked
                                                ? [...selectedSshKeys, e.target.value]
                                                : selectedSshKeys.filter((key) => key !== e.target.value)
                                        })(dispatch);
                                    }}
                                />
                                {name}
                            </label>
                        ))}
                        </div>
                    </div>
                ) : (
                    <div className='error flex flex-align-items-center m-b-15 text-red-2 font-weight-regular'>
                        <img className='m-r-5' src={redAlertIco} alt='' />
                        {t('ssh.error')}
                    </div>
                )}
                <button
                    onClick={() => setShowPopup(true)}
                    className='button button--grey-2 font-weight-regular button--sm'
                >
                    {t('ssh.button')}
                </button>
            </div>
            <SshModal onSubmit={onAddKey} domains={domains} accountid={domains[0]?.id} show={showPopup} onHide={() => setShowPopup(false)} />  {/*eslint-disable-line*/}
        </div>
    );
};

export default SshKey;
