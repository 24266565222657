import React, { useState} from 'react';

const CustomField = ({
  errors = {},
  touched = {},
  Ico,
  name,
  children,
  className,
  submitCount,
  required,
}) => {
  const [hasvalue, setHasValue] = useState(false)
  let parsedChilderen = []
  const placeholders = []
  let hasValue

  const changedProps = (child) => ({
    placeholder: '',
      onFocus: (e) => {
        if (child?.props?.placeholder) {
          setHasValue(true)
        }
        child?.props?.onFocus && child?.props?.onFocus(e)
    },
      onBlur: (e) => {
      if (!e.target.value) {
        setHasValue(false)
      }
      child?.props?.onBlur && child?.props?.onBlur(e)
    },
  });

  if (children.length) {
    children.forEach((child) => {
      if (child?.props?.placeholder) {
        placeholders.push(child?.props?.placeholder)
        parsedChilderen.push(React.cloneElement(child, changedProps(child)))
        if (child?.props?.value || child?.props?.defaultValue || child.type === "textarea") {
          hasValue = true
        }
      }
    });
  } else {
    placeholders.push(children?.props?.placeholder)
    parsedChilderen = React.cloneElement(children, changedProps(children));
    if (children?.props?.value !== "" && children?.props?.value !== undefined && children?.props?.value !== null || children?.props?.defaultValue !== "" && children?.props?.defaultValue !== undefined && children?.props?.defaultValue !== null || children.type === "textarea") {
      hasValue = true
    }
  }

  return (
    <div
      className={`Field ${errors[name] && (touched[name] || submitCount) && 'Field--with-error'} ${
        Ico && 'Field--with-ico'
      } ${className} ${(hasValue || hasvalue) && 'Field--with-placeholder'}`}
    >
      <div className="Field__content">
        {Ico && <Ico />}
        { !(errors[name] && (touched[name] || submitCount) && errors[name] ) && <div
          className={`placeholder ${(hasValue || hasvalue) && 'placeholder--with-value'}`}>{placeholders.map(placeholder =>
          <div key={placeholder}>{placeholder} {required && <span className={"text-red-2 font-weight-bold"}>*</span>}</div>)}</div>
        }
        {parsedChilderen}
        <div className="error">
          {errors[name] && (touched[name] || submitCount) && errors[name]}
        </div>
      </div>
    </div>
  );
};

export default CustomField;
