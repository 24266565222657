import React from "react";
import Checkbox from "../../../../../../../components/Checkbox";

const sizes = [
  {
    type: "small",
    price: 10,
  },
  {
    type: "medium",
    price: 30,
  },
  {
    type: "large",
    price: 60,
  },
];

const SetSize = ({ size, price, t, setActiveComponent }) => {
  return (
    <div className="font-size-16 text-grey-1 m-t-30">
      <p>
        {t("settings.sizeDetails.resizeText")}
        <a href="">{t("settings.sizeDetails.productDocs")}</a>.
      </p>
      <div className="m-b-20">
        {t("settings.sizeDetails.currentSize")}{" "}
        <strong className="">{size}</strong> ${price} /{" "}
        {t("settings.sizeDetails.month")}
      </div>
      <div className="flex row flex-mobile-column">
        {sizes.map(({ type }) => {
          return (
            <div key={type} className="p-l-15 p-r-15 flex-grow-1 width-half">
              <label
                className={`auth-box flex flex-grow-1 p-t-10 p-b-10 flex-justify-content-space-between`}
              >
                <div className="flex">
                  <Checkbox
                    className="Checkbox--full-box m-r-10"
                    type="radio"
                    value="ram"
                    name="boot"
                  />
                  <div>
                    <div className="text-grey-1 font-size-16 font-weight-semibold m-b-5">
                      {t(`settings.sizeDetails.${type}`)} {price} /{" "}
                      {t("settings.sizeDetails.month")}
                    </div>
                    <div className="text-grey-1 font-size-16">
                      {t(`settings.sizeDetails.${type}Description`)}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          );
        })}
      </div>
      <div className="flex m-t-30 m-b-30">
        <button
          onClick={() => setActiveComponent(false)}
          className="button button--blue button--sm min-width-100 m-r-20"
        >
          {t("settings.save")}
        </button>
        <button
          onClick={() => setActiveComponent(false)}
          className="button button--grey button--sm min-width-100"
        >
          {t("settings.cancel")}
        </button>
      </div>
    </div>
  );
};

const Size = ({
  settings = {},
  type,
  t,
  activeComponent,
  setActiveComponent,
}) => {
  const { size, price } = settings[type] || {};
  if (activeComponent === type) {
    return (
      <SetSize {...{ activeComponent, setActiveComponent, t, size, price }} />
    );
  }
  return (
    <div className="flex flex-grow-1 flex-align-items-center flex-justify-content-space-between font-size-16 text-grey-1">
      <div>
        <strong className="m-r-10">{size}</strong> ${price} / Month
      </div>
      <button
        onClick={() => setActiveComponent(type)}
        className="button button--grey button--sm min-width-100"
      >
        {t("settings.resize")}
      </button>
    </div>
  );
};

export default Size;
