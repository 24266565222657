import React from 'react';
import FirewallsEmptyIco from '../../../../assets/images/network/Firewalls_empty_icon.svg';

const FirewallsEmpty = ({t, setCreate}) => {
    return (
        <div className='Empty_container'>
            <img src={FirewallsEmptyIco} width={150} height={150} />
            <span className='text-grey-1 font-size-16 m-t-20 line-height-1-6'>{t('emptyFirewalls.description')}</span>
            <button
                className="button button--blue button--md flex create_button"
                onClick={() => setCreate(true)}
                >
                {t('emptyFirewalls.button')}
            </button>
        </div>
    )
}

export default FirewallsEmpty;
