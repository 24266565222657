import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNicsListRequest } from "../../../Dashboard/actions";
import AssignModal from "../Nics/AssignModal";
import { useLoopFetching } from "../../../../components/hooks/useLoopFetching";
import checkJobStatus from "../../../../helpers/checkJobStatus";
import { useToasts } from "react-toast-notifications";

const PublicIps = ({ accountid, id, t }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { nicsList } = useSelector(({ instances }) => instances);
  const [visibleModal, setVisibleModal] = useState(null);
  useEffect(() => {
    if (accountid)
      getNicsListRequest({ accountid, virtualmachineId: id })(dispatch);
  }, []);

  const request = async (data, cb) => {
    try {
      const result = await checkJobStatus(accountid, data);
      if (result.status === "success") {
        cancelFetching();
        cb();
      }
      if (result.status === "error") {
        cancelFetching();
        addToast(t(result.error_message.errortext), {
          appearance: "error",
          autoDismiss: true,
        });
        setVisibleModal(null);
      }
    } catch (error) {
      // CATCH FOR CHECK JOB STATUS
      // eslint-disable-next-line no-undef
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
      cancelFetching();
      setVisibleModal(null);
    }
  };

  const { startFetching, cancelFetching } = useLoopFetching(request);

  return (
    <div className="Public-ips">
      <button
        className="button button--sm p-r-40 p-l-40"
        onClick={() => setVisibleModal("assign")}
      >
        {t("publicIps.button")}
      </button>
      <div className="Public-ips__list m-t-30">
        {nicsList && !!nicsList.length && (
          <>
            <div className="list-table ssh m-b-30">
              <div className="list-table__heading">
                <div className="text-blue-1"> {t("publicIps.ip")}</div>
                <div className="text-align-left"> {t("publicIps.type")}</div>
                <div className="max-width-70" />
              </div>
              <div className="list-table__body">
                {nicsList.map(({ id, ipaddress, isdefault, secondaryip }) => {
                  return (
                    <>
                      <div key={id} className="list-table__row">
                        <div className="flex flex-align-items-center font-weight-semibold  ">
                          {ipaddress}
                        </div>
                        <div className="flex flex-align-items-center font-weight-semibold  ">
                          {isdefault ? "Primary" : "Secondary"}
                        </div>
                      </div>
                      {secondaryip &&
                        !!secondaryip.length &&
                        secondaryip.map((secondary) => {
                          return (
                            <div key={id} className="list-table__row">
                              <div className="flex flex-align-items-center font-weight-semibold  ">
                                {secondary.ipaddress}
                              </div>
                              <div className="flex flex-align-items-center font-weight-semibold  ">
                                Secondary
                              </div>
                              <button className="button button--red button--sm">
                                {t("publicIps.delete")}
                              </button>
                            </div>
                          );
                        })}
                    </>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
      <AssignModal
        show={visibleModal === "assign"}
        onHide={() => {
          setVisibleModal(null);
        }}
        accountid={accountid}
        id={id}
        title={t("nics.secondTitle")}
        nic={nicsList[0]?.id}
        t={t}
        startFetching={startFetching}
      />
    </div>
  );
};

export default PublicIps;
