import React, { useState, useEffect, useMemo } from 'react';
import Modal from '../../../../components/Modal';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import Locations from '../../../Create/CreateInstance/components/ChooseLocations'
import { getLocationsRequest } from '../../../Create/actions';
import {getInstancesRequest, getNetworksRequest} from '../../../Dashboard/actions';
import {
  getAssignFloatingIpRequest,
  getIpAddressesRequest
} from '../../../Dashboard/actions';
import SelectInstance from './SelectInstance';
import instancesIco from '../../../../assets/images/create/instances2.svg';
import pretty from "prettysize";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loader/Loader";

const AssignFloating = ({ show, onHide, t, startFetching }) => { //eslint-disable-line
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [expanded, setExpanded] = useState(false)
  const [selectedInstance, setSelectedInstance] = useState(null)
  const [searchText, setSearchText] = useState("");
  const { t: commonT } = useTranslation('common');

  const [loading, setLoading] = useState(false);
  const { instances, networks } = useSelector((state) => state.instances); //eslint-disable-line

  const { selectedRegion } = useSelector(({ products }) => products);

  const filteredInstances = useMemo(() => {
    const filterVPCassigned = instances?.filter(instance => instance.nic.length > 1);
    const filtered = filterVPCassigned?.filter(instance => instance?.zoneid === selectedRegion?.acs_id);

    if (searchText) {
      return filtered.filter(instance => instance?.displayname.toLowerCase().includes(searchText.toLowerCase()));
    }

    return filtered;
  }, [selectedRegion, instances, searchText]);

  useEffect(() => {
    const onMount = async () => {
      await Promise.all([getLocationsRequest()(dispatch), getInstancesRequest()(dispatch), getNetworksRequest()(dispatch)]);
    };
    onMount();
  }, []); //eslint-disable-lineIsolated

  const handleAssign = async () => {
    setLoading(true);
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await getAssignFloatingIpRequest({
        accountid: selectedInstance.accountid,
        networkid: selectedInstance?.nic[1].networkid,
        virtualmachineid: selectedInstance.id,
        vmguestip: selectedInstance.nic[1].ipaddress,
      })(dispatch);
      startFetching(jobid, () => {
        dispatch(getIpAddressesRequest());
        onHide();
        addToast(commonT('success'), {
          appearance: 'success',
          autoDismiss: true
        });
        setLoading(false);
      }, selectedInstance.accountid);
    } catch (error) {
      setLoading(false);
      return addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  const enabledLocations = useMemo(() => {
      return networks.map(net => net.zoneid)
  }, [instances, networks]);

  return (
    <div>
      <div>
        <Modal
          onHide={onHide}
          show={show}
          size="large">
          <div className="font-weight-light text-grey-3 font-size-36 m-b-15">{t("assignModal.selectRegion")}</div>
          {networks?.length ? <Locations t={t} isAssignModal enabledLocations={enabledLocations}/> : <div className={`flex flex-justify-content-center m-b-10`}><Loader /></div>}
          <div className="font-weight-light text-grey-3 font-size-36 m-b-30">{t("assignModal.selectInstance")}</div>
          {selectedInstance ?
            <div className="Seleted_item_container" onMouseDown={() => {
              setSelectedInstance(null);
              setExpanded(true);
            }}>
              <div className="flex item">
                <div className="Instance position-relative m-r-14">
                  <img src={instancesIco} />
                  <span className={`status ${(selectedInstance?.state === 'Running' && 'Instance__active') ||
                    (['Starting', 'Stopping'].includes(selectedInstance?.state)
                      ? 'Instance__pending'
                      : 'Instance__inactive')}`
                  } />
                </div>
                <div className="Instance_info_container">
                  <div className="font-size-15 font-weight-semibold text-grey-3">{selectedInstance?.displayname}</div>
                  <div className="font-size-12 font-weight-semibold text-grey-8 Instance_info">
                    <span>{selectedInstance?.cpunumber} {t('list.cpu')}</span>
                    <span>{pretty(selectedInstance?.memory * 1024 * 1024)} {t('list.ram')}</span>
                    <span>{selectedInstance?.zonename}</span>
                    <span>{selectedInstance?.templatedisplaytext}</span>
                  </div>
                </div>
              </div>
            </div>
            : <SelectInstance
              SearchClassName="Search_container_assign"
              triangleClassname="triangle_position_assign"
              setSelectedInstance={setSelectedInstance}
              handleOptionsSelect={setExpanded}
              setSearchText={setSearchText}
              placeholder={t("assignModal.selectPlaceholder")}
              instances={filteredInstances}
              expanded={expanded}
              t={t}
            />}
          <div className="flex row m-t-50">
            <>
              <button
                onClick={onHide}
                className="button button--grey width-half flex-grow-1 m-l-15 m-r-15">
                {t("assignModal.cancel")}
              </button>
              <Button
                loading={loading}
                className="button button--blue width-half flex-grow-1 m-l-15 m-r-15"
                disabled={!selectedInstance}
                onClick={handleAssign}
              >
                {t('assignModal.assign')}
              </Button>
            </>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AssignFloating;
