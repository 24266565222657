import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import { useSelector, useDispatch } from "react-redux";
import CustomSelect from "../../../../components/CustomSelect";
import { addNetworkToVmRequest, getNetworksRequest, getNicsListRequest } from "../../../Dashboard/actions";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../Volumes/components/Loader/Loader";

const AddModal = ({ show, onHide, accountid, id, t, startFetching }) => {
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [filteredNetworks, setFilteredNetworks] = useState([]);
  const [loading, setLoading] = useState(false);
  const { networks, nicsList, networksLoading } = useSelector(({ instances }) => instances);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  useEffect(() => {
    const onMount = async () => {
      await getNetworksRequest({ accountid })(dispatch);
    };
    show && onMount();
  }, [accountid, show]);

  useEffect(() => {
    const availableNetworks = networks.filter((network) =>
      nicsList.some(function (nic) {
        return network.id !== nic.networkid;
      })
    );
    const networksId = nicsList.map(({ networkid }) => networkid);
    const available = networks.filter(({ id }) => networksId.indexOf(id) === -1);

    //need this, because initially we show first network in dropdown
    availableNetworks.length && setSelectedNetwork(availableNetworks[0]?.id);
    setFilteredNetworks(available);
    return () => {
      setLoading(false);
    };
  }, [networks, nicsList]);
  return (
    <div>
      <div>
        <Modal
          Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("nics.addModal.title")}</h2>}
          onHide={onHide}
          show={show}
          size="large"
        >
          {networksLoading ? (
            <Loader />
          ) : filteredNetworks.length === 0 ? (
            <p className="font-size-16">{t("nics.addModal.noNetworks")}</p>
          ) : (
            <CustomSelect
              onChange={(selected) => setSelectedNetwork(selected)}
              options={
                filteredNetworks &&
                filteredNetworks.map(({ id, name }) => ({
                  value: id,
                  text: name
                }))
              }
            />
          )}
          {loading ? (
            <Loader />
          ) : (
            <div className="flex row m-t-20">
              <button onClick={onHide} className="button button--grey width-half flex-grow-1 m-l-15 m-r-15">
                {t("nics.cancel")}
              </button>
              <button
                disabled={networksLoading || !selectedNetwork || !filteredNetworks.length}
                onClick={async () => {
                  setLoading(true);
                  try {
                    const {
                      data: {
                        data: { jobid }
                      }
                    } = await addNetworkToVmRequest({
                      accountid,
                      virtualmachineId: id,
                      networkId: selectedNetwork
                    })(dispatch);
                    startFetching(jobid, () => {
                      setLoading(false);
                      getNicsListRequest({ accountid, virtualmachineId: id })(dispatch);
                      onHide();
                      addToast(t("nics.addModal.success"), {
                        appearance: "success",
                        autoDismiss: true
                      });
                    });
                  } catch (error) {
                    setLoading(false);
                    onHide();
                    return addToast(error.message, {
                      appearance: "error",
                      autoDismiss: true
                    });
                  }
                }}
                className="button width-half flex-grow-1 m-l-15 m-r-15"
              >
                {t("nics.confirm")}
              </button>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default AddModal;
