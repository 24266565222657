import React, {useEffect, useState} from 'react';
import debounce from "debounce";
import CustomField from '../../../../components/CustomField';
import CountInput from "../../../../components/CountInput";

const ConcludeAndCreate = ({ t, createInstanceAction, instanceName, dispatch, state: {createInstance: {distribution = []} = {}} = {} }) => {
  const [instancesCount, setInstancesCount] = useState(1);
  const [autoFocus, setAutoFocus] = useState(-1);

  useEffect(() => {
    if (instanceName) {
      let changedDistribution = [...distribution, `${instanceName}${instancesCount > 9 ? instancesCount : `0${instancesCount}`}`]
      createInstanceAction({
        distribution: changedDistribution.slice(0, instancesCount)
      })(dispatch);
    }
  },[instancesCount]);

  useEffect(() => {
    const changedDistribution =
      new Array(instancesCount).fill().map((_, i) => {
        return `${instanceName}${i+1}`
      })
      createInstanceAction({
        distribution: changedDistribution
      })(dispatch);
  },[instanceName]);

  let handleChange = (value, i) => {
    let changedDistribution = [...distribution]
    changedDistribution[i] = value
    createInstanceAction({
      distribution: changedDistribution
    })(dispatch);
  }

  handleChange = debounce(handleChange, 200);

  return (
    <div className="ConcludeAndCreate m-t-40">
      <h3 className="font-size-20 text-grey-2 font-weight-regular m-b-5 ">{t('title')}</h3>
      <div className="flex row flex-mobile-column">
        <div className={"ConcludeAndCreate__count m-l-15 m-r-15 flex-grow-1 width-full-width"}>
          <h4 className={"title title--sm title--with-border"}>{t('howMany')}</h4>
          <p className="font-weight-light font-size-16 text-grey-1 m-b-20 m-t-10">
            {t('deployMultiple')}
          </p>
          <CountInput minValue={1} maxValue={10} value={instancesCount} onChange={setInstancesCount} />
        </div>
        <div className="m-l-15 m-r-15 flex-grow-1">
          <h4 className={"title title--sm title--with-border"}>{t('chooseName')}</h4>
          <p className="font-weight-light font-size-16 text-grey-1 m-b-20 m-t-10">
            {t('instanceNameing')}
          </p>
          {distribution.map((name, i) =>
            <CustomField key={name}>
            <input
              onChange={e => handleChange(e.target.value, i)}
              defaultValue={name}
              onFocus={() => setAutoFocus(i)}
              onBlur={() => setAutoFocus(-1)}
              autoFocus={autoFocus === i}
              onKeyPress={(event) => {
                if (!/^[a-zA-Z0-9- ]*$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              type="text"
              placeholder={t('placeholder')}
              className="m-b-25 text-grey-1"
            />
          </CustomField>)
          }
        </div>
      </div>
    </div>
  );
};

export default ConcludeAndCreate;
