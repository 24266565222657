import React, { useState } from 'react';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomField from '../CustomField';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getRegisterSshRequest } from '../../pages/Profile/actions';
import { useToasts } from 'react-toast-notifications';

const SshModal = ({ show, onHide, accountid, domains, onSubmit}) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { t } = useTranslation('sshKeysModal');
  const [name, setName] = useState(null);
  const [key, setKey] = useState(null);
  return (
    <div className="SshModal">
      <Modal show={show} onHide={onHide}>
        <div className="flex row m-t--30 flex-mobile-column">
          <div className="SshModal__add-key m-l-25 m-r-25 flex-grow-1">
            <h2 className="title m-t--10">{t('addKey.title')}</h2>
            <p className="m-b-20">{t('addKey.description')}</p>
            <CustomField className="m-b-30 keyContent">
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder={t('addKey.placeholders.content')}
                value={key}
                onChange={(e) => setKey(e.target.value)}
              />
            </CustomField>
            <CustomField className="m-b-30">
              <input
                name=""
                placeholder={t('addKey.placeholders.name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </CustomField>
            <button
              disabled={ !name || !key}
              className="button button--blue width-full-width"
              onClick={async () => {
                if (onSubmit) {
                  onSubmit({key, name})
                } else {
                  try {
                    await getRegisterSshRequest({
                      accountid,
                      name,
                      publickey: key,
                      domains,
                    })(dispatch);
                    onHide();
                    return addToast('register', {
                      appearance: 'success',
                      autoDismiss: true
                    });
                  } catch (error) {
                    return addToast(t(error.message), {
                      appearance: 'error',
                      autoDismiss: true
                    });
                  }
                }
              }}>
              {t('addKey.button')}
            </button>
          </div>
          <div className="SshModal__details m-l-25 m-r-25 flex-grow-1">
            <h2 className="title m-t--10">{t('details.title')}</h2>
            <p>
              {t('details.description')} <a href="">{t('details.link')} </a>
              <span>{t('details.instead')}</span>
            </p>
            <div className="keySet">
              <h3 className="title">{t('details.keySet.title')}</h3>
              <p>{t('details.keySet.openTerminal')}</p>
              <div className="grey-info flex flex-justify-content-space-between flex-align-items-center">
                {t('details.keySet.keygen')}
                <CopyToClipboard text={t('details.keySet.keygen')}>
                  <button className="button button--s button--white">{t('details.copy')}</button>
                </CopyToClipboard>
              </div>
              <p>{t('details.keySet.saveAndName')}</p>
              <div className="grey-info">{t('details.keySet.genereting')}</div>
              <p>{t('details.keySet.confirmPassphase')}</p>
              <div className="grey-info">{t('details.keySet.enterPassphase')}</div>
            </div>
            <div className={`publicKey`}>
              <p>
                {t('details.keySet.generated')}
              </p>
              <h3 className="title">{t('details.publicKey.title')}</h3>
              <p>{t('details.publicKey.description')}</p>
              <div className="grey-info flex flex-justify-content-space-between flex-align-items-center">
                {t('details.publicKey.placeholder')}{' '}
                <CopyToClipboard text={t('details.keySet.keygen')}>
                  <button className="button button--s button--white">{t('details.copy')}</button>
                </CopyToClipboard>
              </div>
              <p>
                {t('details.publicKey.forMoreDetails')}{' '}
                <a className="font-weight-regular" href="">
                  {t('details.publicKey.detailsLink')}
                </a>
              </p>
            </div>

          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SshModal;
