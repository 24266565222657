import React, { useState } from "react";
import { deleteLbGroupRequest } from "../../../../../Create/actions";
import { getLBGroupsRequest } from "../../../../../Dashboard/actions";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../../../../components/Loader/Loader";
import Modal from "../../../../../../components/Modal";

const DeleteBalancer = ({ show, onHide, loadBalancer, t }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteLbGroupRequest({ accountid: loadBalancer.accountid, groupid: loadBalancer.id })(dispatch);
      onHide();
      addToast(t("destroyModal.deleteSuccess"), {
        appearance: "success",
        autoDismiss: true
      });
      setLoading(false);
      await getLBGroupsRequest()(dispatch);
    } catch (error) {
      setLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  }

  return (
    <div>
      <div>
        <Modal
          Title={() => <span className="text-grey-2 font-weight-light m-t-0 m-b-0">{t("destroyModal.title")}</span>}
          onHide={onHide}
          show={show}
          size="small"
        >
          <p className="text-grey-1 font-size-16 line-height-1-6">{t("destroyModal.removeWarning1")}
            <span className="text-red-2">{" " + t("destroyModal.removeWarningRed")} </span>
            {t("destroyModal.removeWarning2")} </p>
          <p className="text-grey-1 font-size-16 p-b-40">{t("destroyModal.removeWarning3")} </p>
          {loading ? <div className="flex flex-justify-content-center width-full-width"><Loader /></div> :
            <div className="flex">
              <button className="button button--grey width-half flex-grow-1 m-r-15" onClick={onHide}>
                Cancel
              </button>
              <button
                className="button button--full-red width-half flex-grow-1 m-l-15"
                onClick={async () => { await handleDelete() }}
              >
                {t("destroyModal.destroy")}
              </button>
            </div>}
        </Modal>
      </div>
    </div>
  );
};

export default DeleteBalancer;
