import React from 'react';
import Checkbox from '../../../../components/Checkbox';

const BackupWork = ({ t, createInstanceAction, dispatch, disabled }) => {
    return (
        <div className={`BackupWork ${disabled && 'BackupWork--disabled'}`}>
            <h3 className='font-size-20 text-grey-2 font-weight-regular m-b-15 m-t-35 flex flex-align-items-center'>
                {t('title')}
                <span className={"badge m-l-10"}>COOMING SOON</span>
            </h3>
            <label className={`auth-box flex flex-grow-1 flex-justify-content-space-between flex-mobile-column`}>
                <div className='flex'>
                    <Checkbox
                        className='Checkbox--full-box m-r-5'
                        type='checkbox'
                        value='enable'
                        name='enableBackup'
                        onChange={(e) => createInstanceAction({ backup: e.target.checked })(dispatch)}
                    />
                    <div className='text-grey-2 font-size-15 m-r-10'>{t('enable')}</div>
                </div>
                <div className='text-grey-1 font-size-15'>
                    {t('systemLevel')} {t('occuresDescription')}
                </div>
                <div className='text-grey-1 font-size-15'>
                    <strong>$1.00</strong>/mo{' '}
                    {t('perInstance')}
                </div>
            </label>
        </div>
    );
};

export default BackupWork;
