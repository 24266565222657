import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LoadBalancersList from "./components/LoadBalancersList";
import NoLoadBalancers from "./components/NoLoadBalancers";
import BalancerDetails from "./components/BalancerDetails";
import Loader from "../../../Volumes/components/Loader/Loader";
import { getLoadBalancersRequest } from "../../../Dashboard/actions";

const LoadBalancers = ({ networkSubTab, id: loadBalancerId, accountid }) => {
  const dispatch = useDispatch();
  const { loadBalancers, loadBalancersLoading } = useSelector(({ instances }) => instances);
  const { t: networkT } = useTranslation("network");
  const t = (path) => networkT(`loadBalancer.${path}`);
  const [activeBalancer] = loadBalancers.filter(({ id }) => loadBalancerId === id);

  if (loadBalancerId && networkSubTab) {
    return (
      <div className="LoadBalancers">
        <BalancerDetails {...{ t, activeBalancer, loadBalancerId, networkSubTab }} />
      </div>
    );
  }
  useEffect(() => {
    if (accountid) {
      getLoadBalancersRequest({ accountid })(dispatch);
    } else {
      getLoadBalancersRequest()(dispatch);
    }
  }, [dispatch, accountid]);

  return (
    <div className="LoadBalancers">
      {loadBalancersLoading ? (
        <Loader />
      ) : loadBalancers.length ? (
        <LoadBalancersList
          networkSubTab={networkSubTab}
          loadBalancerId={loadBalancerId}
          loadBalancers={loadBalancers}
          accountid={accountid}
          t={t}
        />
      ) : (
        <NoLoadBalancers t={t} />
      )}
    </div>
  );
};

export default LoadBalancers;
