import React from 'react';
import logo from '../../../assets/images/logo.svg';

const AuthHeader = () => {
  return (
    <header>
      <div className="design-element" />
      <img src={logo} className="logo" alt="Logo" />
    </header>
  );
};

export default AuthHeader;
