import React, { useState } from "react";

import Card from "../../../../../components/Card";
import Checkbox from "../../../../../components/Checkbox";

const Recovery = ({ t: dashboardTrans }) => {
  const t = (path) => dashboardTrans(`instance.recovery.${path}`);
  const [boot, setBoot] = useState("iso");
  return (
    <div className="flex-grow-1">
      <Card className="m-b-40">
        <Card.Body className="width-full-width flex-direction-column p-l-40 p-r-40">
          <h3 className="font-size-27 text-grey-3 font-weight-regular m-b-10">
            {t("title")}
          </h3>
          <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-0">
            {t("description")}
          </p>
          <p>
            <a href="">{t("learnMore")}</a>
          </p>
          <div className="flex row flex-mobile-column">
            <div className="p-l-15 p-r-15 flex-grow-1 width-half">
              <label
                className={`auth-box flex flex-grow-1 flex-justify-content-space-between`}
              >
                <div className="flex">
                  <Checkbox
                    className="Checkbox--full-box m-r-10"
                    checked={boot === "iso"}
                    onChange={() => setBoot("iso")}
                    type="radio"
                    value="iso"
                    name="boot"
                  />
                  <div>
                    <div className="text-grey-6 font-size-16 font-weight-semibold m-b-5">
                      {t("isoLabel")}
                    </div>
                    <div className="text-grey-1 font-size-16">
                      {t("isoDescription")}
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <div className="p-l-15 p-r-15 flex-grow-1 width-half">
              <label
                className={`auth-box flex flex-grow-1 flex-justify-content-space-between`}
              >
                <div className="flex">
                  <Checkbox
                    className="Checkbox--full-box m-r-10"
                    checked={boot === "hardDrive"}
                    onChange={() => setBoot("hardDrive")}
                    value="hardDrive"
                    type="radio"
                    name="boot"
                  />
                  <div>
                    <div className="text-grey-6 font-size-16 font-weight-semibold m-b-5">
                      {t("hardDriveLabel")}
                    </div>
                    <div className="text-grey-1 font-size-16">
                      {t("hardDriveDescription")}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div>
            <p className="text-grey-1 font-size-16 m-t-20 line-height-1-6 m-b-10">
              {t(`${boot}.description`)}
            </p>
            <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-10">
              <strong className="text-grey-1">{t(`${boot}.shutDown`)}</strong>
              {t(`${boot}.shutDownDescription`)}
            </p>
            <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-10">
              <strong className="text-grey-1">{t(`${boot}.powerOn`)}</strong>
              {t(`${boot}.powerOnDescription`)}
            </p>
            {boot === "iso" && (
              <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-10">
                <strong className="text-grey-1">
                  {t(`${boot}.switchBack`)}
                </strong>
                {t(`${boot}.switchBackDescription`)}
              </p>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Recovery;
