import React from "react";

const CountInput = ({minValue, maxValue, onChange, value = 0}) => {
  return <div className={"CountInput"}>
    <span onClick={() => value !== minValue && (onChange && onChange(value - 1))} className={"CountInput__minus"}/>
    <div className={"CountInput__value"}>{value}</div>
    <span onClick={() => value !== maxValue && (onChange && onChange(value + 1))} className={"CountInput__plus"}/>
  </div>
}

export default CountInput;
