import React, { useState, useEffect } from "react";
import CreateVpc from "./CreateVpc";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getVpcOfferingsRequest,
  getVpcRequest,
  getZonesRequest,
} from "../../../Dashboard/actions";
import Loader from "../../../../pages/Volumes/components/Loader/Loader";
import DeleteVpc from "./DeleteVpc";
import EditVpc from "./EditVpc";
import RestartVpc from "./RestartVpc/RestartVpc";
import AclListModal from "./AclList/AclListModal";
import DeleteAclList from "./AclList/DeleteAclList";
import CreateAclList from "./AclList/CreateAclList";
import { useLoopFetching } from "../../../../components/hooks/useLoopFetching";
import checkJobStatus from "../../../../helpers/checkJobStatus";
import { useToasts } from "react-toast-notifications";
import { getClientDomainsRequest } from "../../../Profile/actions";

const VpcList = ({ t, accountid, underInstance }) => {
  const dispatch = useDispatch();
  const { vpcList, vpcLoading, accounts } = useSelector(
    ({ instances }) => instances
  );
  const [activeVpc, setActiveVpc] = useState(null);
  const [activeAclList, setActiveAclList] = useState(null);
  const [visibleKeyPop, setVisibleKeyPop] = useState(null);
  const [visibleModal, setVisibleModal] = useState(null);
  const { t: commonT } = useTranslation("common");

  useEffect(() => {
    if (accountid) {
      getVpcOfferingsRequest({ accountid })(dispatch);
      getZonesRequest({ accountid })(dispatch);
      getVpcRequest({ accountid })(dispatch);
      getClientDomainsRequest()(dispatch);
    } else {
      getVpcRequest()(dispatch);
      getClientDomainsRequest()(dispatch);
    }
  }, [accountid]);

  useEffect(() => {
    if (!accountid && !!accounts.length) {
      const [account] = accounts.filter(
        (account) => account.status === "Active"
      );
      getVpcOfferingsRequest({ accountid: account?.id })(dispatch);
      getZonesRequest({ accountid: account?.id })(dispatch);
    }
  }, [accounts]);

  const { addToast } = useToasts();
  const request = async (data, cb, accId) => {
    try {
      const result = await checkJobStatus(
        accId
          ? accId
          : accountid
          ? accountid
          : vpcList[visibleKeyPop]?.accountId,
        data
      );
      if (result.status === "success") {
        cancelFetching();
        cb();
        setVisibleModal(null);
        setVisibleKeyPop(null);
        setActiveAclList(null);
        setActiveVpc(null);
      }
      if (result.status === "error") {
        cancelFetching();
        addToast(t(result.error_message.errortext), {
          appearance: "error",
          autoDismiss: true,
        });
        getVpcRequest({
          accountid: accountid ? accountid : vpcList[activeVpc]?.accountId,
        })(dispatch);
      }
    } catch (error) {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
      cancelFetching();
    }
  };

  const { startFetching, cancelFetching } = useLoopFetching(request);

  return (
    <>
      <div className="flex">
        <div>
          <p className="text-grey-1 font-size-16">{t("description")} </p>
          <a href="">{t("whyUse")}</a>
        </div>
        <div className="flex flex-direction-column">
          <button
            className="m-t-15 min-width-250 button button--sm button--blue"
            onClick={() => {
              setVisibleModal("create");
            }}
          >
            {t("button")}
          </button>
          <button
            className="m-t-15 min-width-250 button button--sm button--blue"
            onClick={() => {
              setVisibleModal("createAclList");
            }}
          >
            {t("aclButton")}
          </button>
        </div>
      </div>
      {vpcLoading ? (
        <Loader />
      ) : vpcList.length > 0 ? (
        <>
          <div className="Vpc__list m-t-30 m-b-30">
            <div className="list-table">
              <div className="list-table__heading">
                <div className="text-blue-1 flex flex-align-items-center">
                  {t("list.name")}
                </div>
                <div className="text-align-left flex flex-align-items-center">
                  {t("list.state")}
                </div>
                <div className="text-align-left flex flex-align-items-center">
                  {t("list.cidr")}
                </div>
                <div className="text-align-left flex flex-align-items-center">
                  {t("list.zone")}
                </div>
                <div className="max-width-70" />
              </div>
              <div className="list-table__body">
                {vpcList.map(({ name, cidr, zonename, state, aclList }, i) => (
                  <div className="list-table__row-wrapper" key={name}>
                    <div className="list-table__row p-t-0 p-b-0">
                      <div className="flex flex-align-items-center position-relative">
                        <div
                          onClick={() => {
                            setActiveVpc(activeVpc === i ? null : i);
                          }}
                          className={`list-table__btn-toggler ${
                            !aclList
                              ? "list-table__btn-toggler--disabled"
                              : undefined
                          } ${
                            activeVpc === i
                              ? "list-table__btn-toggler--open"
                              : undefined
                          }`}
                        />
                        <div>
                          <div className="text-grey-3 font-size-15 font-weight-semibold">
                            {name}
                          </div>
                        </div>
                      </div>
                      <div className="text-grey-1 font-size-15 flex flex-align-items-center">
                        {state}
                      </div>
                      <div className="flex flex-align-items-center">
                        <div>
                          <div className="text-grey-3 font-size-15 font-weight-semibold">
                            {cidr}
                          </div>
                        </div>
                      </div>
                      <div className="text-grey-1 font-size-15 flex flex-align-items-center">
                        {zonename}
                      </div>
                      <div className="text-align-center text-blue-1 flex flex-align-items-center max-width-70 more position-relative">
                        <a
                          className="lighter font-weight-regular"
                          href="javascript:;"
                          onClick={() =>
                            visibleKeyPop !== i
                              ? setVisibleKeyPop(i)
                              : setVisibleKeyPop(null)
                          }
                        >
                          {commonT("more")}
                          <span
                            className={`arrow fomt-weight-semibold ${
                              visibleKeyPop === i ? "arrow--up" : "arrow--down"
                            } arrow--blue m-l-5`}
                          />
                        </a>
                        {visibleKeyPop !== null && visibleKeyPop === i && (
                          <div className="keyPop popup">
                            <div
                              className="text-grey-1"
                              onClick={() => {
                                setVisibleModal("restart");
                              }}
                            >
                              {t("restart")}
                            </div>
                            <div
                              className="text-grey-1"
                              onClick={() => {
                                setVisibleModal("edit");
                              }}
                            >
                              {t("edit")}
                            </div>
                            {state !== "Allocated" && (
                              <div
                                className="deleteKey"
                                onClick={() => {
                                  setVisibleModal("delete");
                                }}
                              >
                                {t("delete")}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {aclList && (
                      <div
                        className={`Vpc__acl-list ${
                          activeVpc === i ? "Vpc__acl-list--open" : undefined
                        }`}
                      >
                        <p className="Vpc__acl-list-title font-size-16">
                          {t("aclTitle")}
                        </p>
                        <div className="list-table">
                          <div className="list-table__heading">
                            <div className="text-grey-1 flex flex-align-items-center">
                              {t("aclName")}
                            </div>
                            <div className="text-grey-1 flex flex-align-items-center">
                              {t("aclDescription")}
                            </div>
                          </div>
                          <div className="list-table__body">
                            {aclList.map(({ description, name, id }) => (
                              <div className="list-table__row" key={name}>
                                <div
                                  className="text-blue-1 flex flex-align-items-center font-weight-semibold list-table__item cursor-pointer"
                                  onClick={() => {
                                    setVisibleModal("aclList");
                                    setActiveAclList({ id, name });
                                  }}
                                >
                                  {name}
                                </div>
                                <div className="flex flex-align-items-center font-weight-semibold list-table__item  ">
                                  {description}
                                </div>
                                <div
                                  onClick={() => {
                                    setVisibleModal("deleteAcl");
                                    setActiveAclList({ id, name });
                                  }}
                                  className="deleteKey"
                                >
                                  {t("aclDelete")}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="m-t-30 m-b-30">
          <p className="text-grey-1 font-size-16">{t("noVpc.description")} </p>
        </div>
      )}
      <CreateVpc
        t={t}
        show={visibleModal === "create"}
        accountid={accountid}
        onHide={() => {
          setVisibleModal(null);
          setVisibleKeyPop(null);
        }}
        startFetching={startFetching}
        underInstance={underInstance}
      />
      <CreateAclList
        t={t}
        show={visibleModal === "createAclList"}
        accountid={accountid}
        onHide={() => {
          setVisibleModal(null);
          setVisibleKeyPop(null);
        }}
        startFetching={startFetching}
        underInstance={underInstance}
      />
      <AclListModal
        t={t}
        show={visibleModal === "aclList"}
        onHide={() => {
          setVisibleModal(null);
          setVisibleKeyPop(null);
        }}
        aclList={activeAclList}
        accountid={accountid ? accountid : vpcList[activeVpc]?.accountId}
        startFetching={startFetching}
        underInstance={underInstance}
      />
      <DeleteAclList
        show={visibleModal === "deleteAcl"}
        onHide={() => {
          setVisibleModal(null);
        }}
        aclListId={activeAclList?.id}
        accountid={accountid ? accountid : vpcList[activeVpc]?.accountId}
        t={t}
        startFetching={startFetching}
        underInstance={underInstance}
      />
      <DeleteVpc
        t={t}
        show={visibleModal === "delete"}
        onHide={() => {
          setVisibleModal(null);
          setVisibleKeyPop(null);
        }}
        accountid={accountid ? accountid : vpcList[visibleKeyPop]?.accountId}
        vpcid={vpcList[visibleKeyPop]?.id}
        startFetching={startFetching}
      />
      <EditVpc
        t={t}
        show={visibleModal === "edit"}
        onHide={() => {
          setVisibleModal(null);
          setVisibleKeyPop(null);
        }}
        accountid={accountid ? accountid : vpcList[visibleKeyPop]?.accountId}
        vpcid={vpcList[visibleKeyPop]?.id}
        underInstance={underInstance}
      />
      <RestartVpc
        t={t}
        show={visibleModal === "restart"}
        onHide={() => {
          setVisibleModal(null);
          setVisibleKeyPop(null);
        }}
        accountid={accountid ? accountid : vpcList[visibleKeyPop]?.accountId}
        vpcid={vpcList[visibleKeyPop]?.id}
        startFetching={startFetching}
        underInstance={underInstance}
      />
    </>
  );
};

export default VpcList;
