import React from "react";
import Modal from "../../../../components/Modal";
import { deleteKeyRequest } from "../../actions";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

const DeleteModal = ({
  show,
  onHide,
  setVisibleModal,
  name,
  accountid,
  t,
  domains,
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  return (
    <div>
      <div>
        <Modal
          Title={() => (
            <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">
              {t("deleteKey.title")}
            </h2>
          )}
          onHide={onHide}
          show={show}
        >
          <p className="font-size-16 text-grey-1 line-height-1-6 m-t--10 m-b-30">
            {t("deleteKey.description")}
          </p>
          <div className="flex row">
            <button
              onClick={() => setVisibleModal(null)}
              className="button button--grey width-half flex-grow-1 m-l-15 m-r-15"
            >
              {t("deleteKey.cancel")}
            </button>
            <button
              onClick={async () => {
                try {
                  await deleteKeyRequest({ domains, name, accountid })(
                    dispatch
                  );
                  setVisibleModal(null);
                  onHide();
                  return addToast(t("deleteKey.success"), {
                    appearance: "success",
                    autoDismiss: true,
                  });
                } catch (error) {
                  onHide();
                  return addToast(error.message, {
                    appearance: "error",
                    autoDismiss: true,
                  });
                }
              }}
              className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15"
            >
              {t("deleteKey.button")}
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default DeleteModal;
