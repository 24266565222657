import React, { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, outsideClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        outsideClick(event)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside); //eslint-disable-line
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside); //eslint-disable-line
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
const OutsideAlerter = ({children, outsideClick}) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, outsideClick);

  return <div ref={wrapperRef}>{children}</div>;
}

export default OutsideAlerter
