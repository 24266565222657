import React, { useState, useEffect, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import prettyDate from "pretty-date";
import { useToasts } from "react-toast-notifications";

import noVolumesImg from "../../assets/images/no-volume.svg";
import noVolumesIco from "../../assets/images/dashboard/no_volumes_icon.svg";
import harddiskImg from "../../assets/images/volume-icon.svg";
import instancesIco from "../../assets/images/instance-icon.svg";
import DetachIco from "../../assets/images/detach-icon.svg";
import ResizeIco from "../../assets/images/resize-icon.svg";

import { getSnapshotsRequest, getVolumesRequest } from "./actions";
import { getInstancesRequest } from "../Dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../store";
import DetachModal from "./components/DetachModal";
import DeleteModal from "./components/DeleteModal";
import IncreaseModal from "./components/IncreaseModal";
import ResizeModal from "./components/ResizeModal";
import InfoModal from "./components/InfoModal";
import SnapshotModal from "./components/SnapshotModal";
import CreateModal from "./components/CreateModal";
import Loader from "./components/Loader/Loader";
import AttachModal from "./components/AttachModal";
import { useLoopFetching } from "../../components/hooks/useLoopFetching";
import Card from "../../components/Card";
import checkJobStatus from "../../helpers/checkJobStatus";
import pretty from "prettysize";
import { locationsNaming } from "../Dashboard/components/Resources";

const NoVolumes = ({ t, setVisibleModal, isDashboard }) => (
  isDashboard ?
    <div className="flex-grow-1 height-full-height">
      <Card className="height-full-height">
        <Card.Body className="flex flex-column height-full-height flex-align-items-center flex-justify-content-center">
          <img src={noVolumesIco} width={123} height={176} className="m-b-50" />
          <p className="font-size-16 text-grey-1 width-half text-align-center line-height-1-6">{t("noVolumesDashboard.description")}</p>
          <button
            onClick={() => {
              setVisibleModal("create");
            }} className="button button--blue m-t-20 p-l-40 p-r-40">
            {`+ ${t("noVolumes.button")}`}
          </button>
        </Card.Body>
      </Card>
    </div>
    : <div className="Volumes flex flex-column flex-align-items-center max-width-full-width m-b-30 content-center">
      <img src={noVolumesImg} width="190" height="160" alt="" />
      <div>
        <p className="text-grey-1 font-size-16 m-t-50 m-b-30 max-width-450 text-align-center">{t("noVolumes.description2")} </p>
        <div className="flex flex-justify-content-space-around">
          <button
            onClick={() => {
              setVisibleModal("create");
            }}
            className="button button--blue button--lg flex"
          >
            {`+ ${t("noVolumes.button")}`}
          </button>
        </div>
      </div>
    </div>
);

const VolumesList = ({
  t,
  volumes,
  instances,
  instanceId,
  visibleModal,
  setVisibleModal,
  setVisibleMenu,
  visibleMenu,
  visibleKeyPop,
  setVisibleKeyPop,
  startFetching,
  loading,
  isDashboard,
}) => {
  const { t: commonT } = useTranslation("common");

  const volumesFiltered = useMemo(() => instanceId
    ? [...volumes].filter(({ virtualmachineid }) => virtualmachineid === instanceId)
    : volumes, [volumes, instanceId]);

  const handleInstanceDesc = (id) => {
    const reqInstance = instances?.find((instance) => (instance?.id === id));

    if (reqInstance) {
      return `${reqInstance.cpunumber} vCPUs ${pretty(reqInstance.memory * 1024 * 1024)} RAM ${reqInstance.templatename}`;
    }
  }

  return (
    <>
      <div className="ssh m-b-30">
        <div className="table__head list-head flex font-size-5 text-grey-3 font-weight-semibold">
          <div className="m-l-0">{`${t("list.name")} (${volumesFiltered.length})`}</div>
          {!instanceId && <div className="text-align-left p-l-10">{t("list.instance")}</div>}
          <div className={`${!isDashboard ? '' : 'm-l-20'} text-align-left`}>{t("list.created")}</div>
          <div className="max-width-70" />
        </div>
        <div className="table__body list-body">
          {volumesFiltered.map(({ zonename, name, size, vmstate, vmdisplayname, created, deviceid, virtualmachineid }, i) => (
            <div key={name} className="table__row list-item p-t-0 p-b-0">
              <div className="flex flex-align-items-center">
                <img src={harddiskImg} className="m-r-15" alt="" />
                <div>
                  <div className="text-grey-3 font-size-15 font-weight-semibold m-b-5">{name}</div>
                  <div className="text-grey-8 font-size-12 font-weight-semibold">
                    {locationsNaming[zonename]} {(size / 1073741824).toFixed(2)}GB
                  </div>
                </div>
              </div>
              {!isDashboard ? <div className="flex flex-align-items-center">
                {virtualmachineid ? (
                  <div className="flex flex-align-items-center">
                    <div className="position-relative no-border p-l-0">
                      <img src={instancesIco} className="" alt="" />
                      <span
                        className={`status Instance__${vmstate === "Running" ? "active" : "inactive"}`}
                      />
                    </div>
                    <div className="m-l-12">
                      <div className="text-grey-3 font-size-15 font-weight-semibold m-b-5">{vmdisplayname}</div>
                      <div className="text-grey-8 font-size-12 font-weight-semibold">{handleInstanceDesc(virtualmachineid)}</div>
                    </div>
                  </div>
                ) :
                  <div onClick={() => {
                    setVisibleKeyPop(i);
                    setVisibleMenu(i);
                    setVisibleModal("attach")
                  }} className={`menu-item no-instance-state flex flex-align-items-center font-size-15 text-blue-1 font-weight-semibold cursor-pointer`}>
                    <div className="image m-r-10">
                      <img className={``} src={DetachIco} alt="" />
                    </div>
                    {t("attach")}
                  </div>
                }
              </div> : null}
              <div className="text-grey-1 font-size-16 font-weight-regular flex flex-align-items-center">
                {prettyDate.format(new Date(created))}
              </div>
              <div className="more_container text-align-center text-blue-1 flex flex-align-items-center max-width-70 more position-relative">
                <a
                  className="menu-item lighter font-weight-semibold font-size-16"
                  href="javascript:;"
                  onClick={() => {
                    if (visibleMenu !== i) {
                      setVisibleMenu(i)
                      return setVisibleKeyPop(i)
                    }
                    return setVisibleMenu(null)
                  }}
                >
                  {commonT("more")}{" "}
                  <span
                    className={`arrow font-weight-semibold ${visibleMenu === i ? "arrow--up" : "arrow--down"
                      } arrow--blue m-l-5`}
                  />
                </a>
                <div className={`keyPop popup ${visibleMenu !== null && visibleMenu === i ? '' : 'invisible'}`}>
                  {deviceid !== 0 && <div
                    className="text-grey-1 menu-item"
                    onClick={() => {
                      vmdisplayname ? setVisibleModal("detach") : setVisibleModal("attach");
                    }}
                  >
                    <img src={DetachIco} />
                    {t(vmdisplayname ? "detach" : "attach")}
                  </div>}
                  {/* WILL BE ADD IN FUTURE */}
                  {/* <div
                      className="text-grey-1"
                      onClick={() => {
                        setVisibleModal("increase");
                      }}
                    >
                      {t("increase")}
                    </div> */}
                  <div
                    className="text-grey-1 menu-item grey-out"
                    onClick={() => {
                      setVisibleModal("resize");
                    }}
                  >
                    <img src={ResizeIco} />
                    {t("resize")}
                  </div>

                  {deviceid !== 0 && (
                    <div
                      className="deleteKey menu-item"
                      onClick={() => {
                        setVisibleModal("delete");
                      }}
                    >
                      {t("deleteKey")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <DetachModal
          t={t}
          setVisibleModal={setVisibleModal}
          volumeDetails={volumesFiltered[visibleKeyPop]}
          loading={loading}
          onHide={() => {
            setVisibleModal(null);
            setVisibleKeyPop(null);
          }}
          show={visibleModal === "detach"}
          startFetching={startFetching}
        />
        <AttachModal
          t={t}
          setVisibleModal={setVisibleModal}
          volumeDetails={volumesFiltered[visibleKeyPop]}
          loading={loading}
          onHide={() => {
            setVisibleModal(null);
            setVisibleKeyPop(null);
          }}
          show={visibleModal === "attach"}
          startFetching={startFetching}
        />
        <DeleteModal
          t={t}
          setVisibleModal={setVisibleModal}
          volumeDetails={volumesFiltered[visibleKeyPop]}
          loading={loading}
          onHide={() => {
            setVisibleModal(null);
            setVisibleKeyPop(null);
          }}
          show={visibleModal === "delete"}
        />
        <IncreaseModal
          t={t}
          setVisibleModal={setVisibleModal}
          volumeDetails={volumesFiltered[visibleKeyPop]}
          onHide={() => {
            setVisibleModal(null);
            setVisibleKeyPop(null);
          }}
          show={visibleModal === "increase"}
        />
        <ResizeModal
          t={t}
          setVisibleModal={setVisibleModal}
          volumeDetails={volumesFiltered[visibleKeyPop]}
          onHide={() => {
            setVisibleModal(null);
            setVisibleKeyPop(null);
          }}
          loading={loading}
          show={visibleModal === "resize"}
          startFetching={startFetching}
        />
        <SnapshotModal
          t={t}
          setVisibleModal={setVisibleModal}
          volumeDetails={volumesFiltered[visibleKeyPop]}
          onHide={() => {
            setVisibleModal(null);
            setVisibleKeyPop(null);
          }}
          show={visibleModal === "snapshot"}
          startFetching={startFetching}
        />
        <InfoModal
          t={t}
          setVisibleModal={setVisibleModal}
          volumeDetails={volumesFiltered[visibleKeyPop]}
          onHide={() => {
            setVisibleModal(null);
            setVisibleKeyPop(null);
          }}
          show={visibleModal === "info"}
        />
      </div>
    </>
  );
};

const Volumes = ({ instanceId, isDashboard, zoneid, accountid }) => {
  const { t } = useTranslation("volumes");
  const { addToast } = useToasts();
  const globalState = useContext(store);
  const { state: { clientDetails: { id } = {} } = {} } = globalState;
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(null);
  const [visibleKeyPop, setVisibleKeyPop] = useState(null);
  const [visibleMenu, setVisibleMenu] = useState(null);
  const { volumes, loading } = useSelector(({ volumes }) => volumes);
  const { instances } = useSelector((state) => state.instances) || {};

  useEffect(() => {
      getInstancesRequest(id)(dispatch);
      getVolumesRequest()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const hideInstancesMenu = (e) => {
      if (
        e.target.className &&
        e.target.className.includes &&
        !e.target.className.includes('popup') &&
        !e.target.className.includes('menu-item')
      ) {
        setVisibleMenu(null);
      }
    };
    window.addEventListener('click', hideInstancesMenu); // eslint-disable-line
    return () => {
      window.removeEventListener('click', hideInstancesMenu); // eslint-disable-line
    };
  }, []);

  const request = async ( data, cb, accId) => {
    try {
      const result = await checkJobStatus(accId ? accId : volumes[visibleKeyPop].accountId, data);
      if (result.status === "success") {
        cancelFetching();
        cb();
      }
      if (result.status === "error") {
        cancelFetching();
        addToast(t(result.error_message.errortext), {
          appearance: "error",
          autoDismiss: true
        });
        setVisibleModal(null);
        setVisibleKeyPop(null);
        await getSnapshotsRequest()(dispatch);
        await getVolumesRequest()(dispatch);
      }
    } catch (error) {
      addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
      cancelFetching();
      setVisibleModal(null);
      setVisibleKeyPop(null);
    }
  };

  const { startFetching, cancelFetching } = useLoopFetching(request);

  const Header = () => {
    if (isDashboard && volumes?.length > 0) {
      return volumes.length !== 0 && <div className="flex flex-justify-content-space-between flex-align-items-center">
        <h2 className="font-size-27 font-weight-regular text-grey-3 m-t-0 m-b-0">{t("title")}</h2>
        <button
          onClick={() => {
            setVisibleModal("create");
          }}
          className="button button--blue button--lg flex font-weight-semibold font-size-15 p-t-0 p-b-0 grey-out"
        >
          {t("noVolumes.button")}
        </button>
      </div>
    }

    if (!isDashboard && volumes?.length !== 0) {
      return <div className="flex flex-justify-content-space-between flex-align-items-center m-b-20">
        <h2 className="title">{t("title")}</h2>
        <button
          onClick={() => {
            setVisibleModal("create");
          }}
          className="button button--blue button--lg flex grey-out"
        >
          {t("noVolumes.button")}
        </button>
      </div>
    }

    return null;
  }
  return (
    <div className="VolumesPage flex-grow-1">
      <div className={`container ${isDashboard ? 'height-full-height p-l-0 p-r-0' : ''}`}>
        <Header />
        {!volumes || loading && !visibleModal ? (
          <div className={`text-align-center p-t-30 p-b-30`}><Loader /></div>
        ) : volumes.length ? (
          <VolumesList
            t={t}
            instances={instances}
            instanceId={instanceId}
            isDashboard={isDashboard}
            startFetching={startFetching}
            volumes={volumes}
            {...{
              visibleModal,
              setVisibleModal,
              visibleKeyPop,
              setVisibleKeyPop,
              visibleMenu,
              setVisibleMenu,
              loading
            }}
          />
        ) : (
          <NoVolumes
            t={t}
            isDashboard={isDashboard}
            {...{
              visibleModal,
              setVisibleModal
            }}
          />
        )}
      </div>
      <CreateModal
        t={t}
        setVisibleModal={setVisibleModal}
        isDashboard={isDashboard}
        zoneid={zoneid}
        instanceId={instanceId}
        accountid={accountid}
        loading={loading}
        onHide={() => {
          setVisibleModal(null);
          setVisibleKeyPop(null);
        }}
        show={visibleModal === "create"}
        startFetching={startFetching}
      />
    </div>
  );
};

export default Volumes;
