import React from "react";
import { useTranslation } from "react-i18next";
import CustomField from "../../../../../../../components/CustomField";
import CustomSelect from "../../../../../../../components/CustomSelect";

const availableRules = ["http", "https", "tcp"].map((rule) => ({
  value: rule,
  text: rule,
}));

const SetHealthChecks = ({ createT, setActiveComponent, t }) => {
  return (
    <div className="p-t-30">
      <div className="flex healthChecks row flex-wrap">
        <div className="p-l-10 p-r-10 min-width-250">
          <h4 className="text-grey-1 fomt-weight-semibold font-size-12 m-b-10">
            {createT("changeAdvanced.protocol")}
          </h4>
          <CustomSelect className="Field--sm" options={[...availableRules]} />
        </div>
        <div className="p-l-10 p-r-10 min-width-250">
          <CustomField className="Field--sm">
            <label>
              <h4 className="text-grey-1 fomt-weight-semibold font-size-12 m-b-10">
                {createT("changeAdvanced.port")}
              </h4>
              <input />
            </label>
          </CustomField>
        </div>
        <div className="p-l-10 p-r-10 min-width-250">
          <CustomField className="Field--sm">
            <label>
              <h4 className="text-grey-1 fomt-weight-semibold font-size-12 m-b-10">
                {createT("changeAdvanced.path")}
              </h4>
              <input />
            </label>
          </CustomField>
        </div>
        <div className="p-l-10 p-r-10 min-width-250">
          <CustomField className="Field--sm">
            <label>
              <h4 className="text-grey-1 fomt-weight-semibold font-size-12 m-b-10">
                {createT("changeAdvanced.checkInterval")}
              </h4>
              <input />
            </label>
          </CustomField>
        </div>
        <div className="p-l-10 p-r-10 min-width-250">
          <CustomField className="Field--sm">
            <label>
              <h4 className="text-grey-1 fomt-weight-semibold font-size-12 m-b-10">
                {createT("changeAdvanced.responseTime")}
              </h4>
              <input />
            </label>
          </CustomField>
        </div>
        <div className="p-l-10 p-r-10 min-width-250">
          <CustomField className="Field--sm">
            <label>
              <h4 className="text-grey-1 fomt-weight-semibold font-size-12 m-b-10">
                {createT("changeAdvanced.unhealtyThreshold")}
              </h4>
              <input />
            </label>
          </CustomField>
        </div>
        <div className="p-l-10 p-r-10 min-width-250">
          <CustomField className="Field--sm">
            <label>
              <h4 className="text-grey-1 fomt-weight-semibold font-size-12 m-b-10">
                {createT("changeAdvanced.healtyThreshold")}
              </h4>
              <input />
            </label>
          </CustomField>
        </div>
      </div>
      <div className="flex m-t-30 m-b-30">
        <button
          onClick={() => setActiveComponent(false)}
          className="button button--blue button--sm min-width-100 m-r-20"
        >
          {t("settings.save")}
        </button>
        <button
          onClick={() => setActiveComponent(false)}
          className="button button--grey button--sm min-width-100"
        >
          {t("settings.cancel")}
        </button>
      </div>
    </div>
  );
};

const HealthChecks = ({
  settings = {},
  type,
  t,
  activeComponent,
  setActiveComponent,
}) => {
  const { value } = settings[type] || {};
  const { t: createLoadBalancerTrans } = useTranslation("createLoadBalancer");
  const translationsByComponent = (component) => (text) =>
    createLoadBalancerTrans(`${component}.${text}`);

  if (activeComponent === type) {
    return (
      <SetHealthChecks
        {...{
          activeComponent,
          setActiveComponent,
          createT: translationsByComponent("AdvancedSettings"),
          t,
        }}
      />
    );
  }
  return (
    <div className="flex flex-grow-1 flex-align-items-center flex-justify-content-space-between font-size-16 text-grey-1">
      <div>{value}</div>
      <button
        onClick={() => setActiveComponent(type)}
        className="button button--grey button--sm min-width-100"
      >
        {t("settings.edit")}
      </button>
    </div>
  );
};

export default HealthChecks;
