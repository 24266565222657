import { get, post, del, put } from '../../components/http';

import {createSlice} from '@reduxjs/toolkit';
import {RE_GET_INSTANCES_TIME} from "./index";

// Slice
const slice = createSlice({
  name: 'instances',
  initialState: {
    instances: null,
    loading: false,
    networksLoading: false,
    vpcLoading: false,
    firewallsLoading: false,
    rulesLoading: false,
    loadBalancersLoading: false,
    loadBalancerVMListLoading: false,
    lbGroupsLoading: false,
    ruleEditLoading: false,
    floatingIpsLoading: false,
    eventsLoading: false,
    backupOfferingsLoading: false,
    nicsDeleteLoading: false,
    aclRulesListLoading: false,
    lbGroups: [],
    backupListLoading: [],
    firewalls: [],
    inboundIpList: [],
    snapshots: [],
    networks: [],
    networksForOutbound: [],
    offerings: [],
    vpcList: [],
    vpcListOfferings: [],
    aclRulesList: [],
    aclList: [],
    zonesList: [],
    loadBalancers: [],
    loadBalancersRules: [],
    loadBalancerVMList: [],
    floatingIpList: [],
    accounts: [],
    events: [],
    backupOfferings: [],
    backupList: [],
    nicsList: [],
    metric: [],
    filteredMetric: [],
    averageUsage: [],
    isMetricDisabled: false
  },
  reducers: {
    getInstances: (state, action) => {
      state.instances = action.payload;
    },
    getNetworks: (state, action) => {
      state.networks = action.payload;
    },
    getNetworksOffering: (state, action) => {
      state.offerings = action.payload;
    },
    getSnapshots: (state, action) => {
      state.snapshots = action.payload;
    },
    getVpc: (state, action) => {
      state.vpcList = action.payload;
    },
    getVpcOfferings: (state, action) => {
      state.vpcListOfferings = action.payload;
    },
    getAclList: (state, action) => {
      state.aclList = action.payload;
    },
    getZones: (state, action) => {
      state.zonesList = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNetworksLoading: (state, action) => {
      state.networksLoading = action.payload;
    },
    setVpcLoading: (state, action) => {
      state.vpcLoading = action.payload;
    },
    setFirewallslLoading: (state, action) => {
      state.firewallsLoading = action.payload;
    },
    getFirewalls: (state, action) => {
      state.firewalls = action.payload;
    },
    getInboundIpList: (state, action) => {
      state.inboundIpList = action.payload;
    },
    setRulesLoading: (state, action) => {
      state.rulesLoading = action.payload;
    },
    getOutboundNetworks: (state, action) => {
      state.networksForOutbound = action.payload;
    },
    setLoadBalancersLoading: (state, action) => {
      state.loadBalancersLoading = action.payload;
    },
    getLoadBalancers: (state, action) => {
      state.loadBalancers = action.payload;
    },
    setLoadBalancerVMListLoading: (state, action) => {
      state.loadBalancerVMListLoading = action.payload;
    },
    getLoadBalancerVMList: (state, action) => {
      state.loadBalancerVMList = action.payload;
    },
    setEditRuleLoadng: (state, action) => {
      state.ruleEditLoading = action.payload;
    },
    getFloatingIps: (state, action) => {
      state.floatingIpList = action.payload;
    },
    setFloatingIpsLoading: (state, action) => {
      state.floatingIpsLoading = action.payload;
    },
    getAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    getEvents: (state, action) => {
      state.events = action.payload;
    },
    setEventsLoading: (state, action) => {
      state.eventsLoading = action.payload;
    },
    getBackupOfferings: (state, action) => {
      state.backupOfferings = action.payload;
    },
    setBackupOfferingLoading: (state, action) => {
      state.backupOfferingsLoading = action.payload;
    },
    getBackupList: (state, action) => {
      state.backupList = action.payload;
    },
    setBackupListLoading: (state, action) => {
      state.backupListLoading = action.payload;
    },
    getNicsList: (state, action) => {
      state.nicsList = action.payload;
    },
    setNicsLoading: (state, action) => {
      state.nicsListLoading = action.payload;
    },

    getAclRulesList: (state, action) => {
      state.aclRulesList = action.payload;
    },
    setAclRulesLoading: (state, action) => {
      state.aclRulesListLoading = action.payload;
    },
    getMetric: (state, action) => {
      state.metric = action.payload;
    },
    getFilteredMetric: (state, action) => {
      state.filteredMetric = action.payload;
    },
    getAverageUsage: (state, action) => {
      state.averageUsage = action.payload;
    },
    setMetricDisabled: (state, action) => {
      state.isMetricDisabled = action.payload;
    },
    getLoadBalancersRules: (state, action) => {
      state.loadBalancersRules = action.payload;
    },
    setLBGroupsLoading: (state, action) => {
      state.lbGroupsLoading = action.payload;
    },
    getLBGroups: (state, action) => {
      state.lbGroups = action.payload;
    }
  }
});

export default slice.reducer;

// Actions
export const {
  getInstances,
  setLoading,
  getSnapshots,
  getNetworks,
  getNetworksOffering,
  setNetworksLoading,
  getVpcOfferings,
  getVpc,
  setVpcLoading,
  getAclList,
  getZones,
  getFirewalls,
  setFirewallslLoading,
  getInboundIpList,
  setRulesLoading,
  getOutboundNetworks,
  getLoadBalancers,
  setLoadBalancersLoading,
  setLoadBalancerVMListLoading,
  getLoadBalancerVMList,
  setFloatingIpsLoading,
  getFloatingIps,
  getEvents,
  setEventsLoading,
  getBackupOfferings,
  setBackupOfferingLoading,
  getBackupList,
  setBackupListLoading,
  getNicsList,
  setNicsLoading,
  getAccounts,
  getAclRulesList,
  setAclRulesLoading,
  getMetric,
  getFilteredMetric,
  getAverageUsage,
  setMetricDisabled,
  getLoadBalancersRules,
  setLBGroupsLoading,
  getLBGroups,
} = slice.actions;

// Get all active instances
export const getInstancesRequest = () => async (dispatch) => {
  dispatch(setLoading(true));
  const { data: { data = {} } = {} } = await get(
    `/client/virtualmachines`
  );
  const { virtualmachines: instances } = data || {};

  dispatch(setLoading(false));
  dispatch(getInstances(instances));

  return instances;
};

// Get instances by network Id
export const getInstancesByNetworkRequest = (accountId, networkId, setInstancesbyNetwork) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data: { data } } = await get(
    `/accounts/${accountId}/vm?networkid=${networkId}`
  );
  setInstancesbyNetwork(prevInstances => ({...prevInstances, [networkId]: data}));
  dispatch(setLoading(false));
};

//get all accounts
export const getAccountsRequest = () => async (dispatch) => {
  const {
    data: {
      data: { accounts }
    }
  } = await get(`/accounts?status=active`);
  dispatch(getAccounts(accounts));
};

/// get VM snapshots
export const getSnapshotsRequest =
  ({ id, virtualmachineid }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const {
      data: { data }
    } = await get(`/accounts/${id}/snapshot/vm/${virtualmachineid}`);
    dispatch(getSnapshots(data));
  };

let timeout
const instancesStatus = {}
const continuousGetInstances = async (id, dispatch, currentStatus) => {
  instancesStatus[id] = currentStatus
  try {
    const instancesResponse = await getInstancesRequest(id)(dispatch);
    const instancesIsNotOnWait = instancesResponse?.filter(({state, id: instanceId}) => instanceId === id && (state === "Stopped" || state === "Running")) || []
    const instanceById = instancesResponse?.find(({id: instanceId}) => instanceId === id)

    if(!instancesIsNotOnWait?.length || instancesStatus[id] === instanceById?.state) {
      timeout = setTimeout(() => continuousGetInstances(id, dispatch, currentStatus), RE_GET_INSTANCES_TIME); //eslint-disable-line
    }
  } catch (error) {
    clearTimeout(timeout) //eslint-disable-line
  }
}

// Turn on/off instance by id
  export const updateInstanceStatus =
  ({ accountid, vmId, start = true, isForced }) =>
  async (dispatch) => {
    let updateResult;
    const currentStatus = start ? "Stopped" : "Running"
    try {
      if(isForced) {
        updateResult = await get(`/accounts/${accountid}/vm/${vmId}/${start ? 'start' : 'stop'}?forced=true`);
      } else {
        updateResult = await get(`/accounts/${accountid}/vm/${vmId}/${start ? 'start' : 'stop'}`);
      }
      await continuousGetInstances(vmId, dispatch, currentStatus)
    } catch (error) {
      throw new Error(error);
    }
    if (updateResult.data.error) {
      throw new Error(updateResult.data.error.message);
    }

    return updateResult;
  };
//destroy instance
export const destroyInstance =
  ({ accountid, vmid }) =>
  async (dispatch) => {
    let deleteResult;
    dispatch(setLoading(true));
    try {
      deleteResult = await get(`/accounts/${accountid}/vm/${vmid}/destroy`);
      getInstancesRequest()(dispatch);
    } catch (error) {
      dispatch(setLoading(false));
    }
    if (deleteResult.data.error) {
      throw new Error(deleteResult.data.error.message);
    }
    dispatch(getInstancesRequest());
    return deleteResult;
  };

// Terminate instance
export const terminateInstance =
  ({ accountid }) =>
    async (dispatch) => {
      let terminateResult;
      dispatch(setLoading(true));
      try {
        terminateResult = await get(`/accounts/${accountid}/terminate`);
      } catch (error) {
        dispatch(setLoading(false));
      }
      if (terminateResult.data.error) {
        throw new Error(terminateResult.data.error.message);
      }
      dispatch(getInstancesRequest());
      return terminateResult;
    };

export const createSnapshot =
  ({ accountid, virtualmachineid, name }) =>
  async (dispatch) => {
    let createSnapshotResponse;
    dispatch(setLoading(true));
    try {
      createSnapshotResponse = await post(`/accounts/${accountid}/snapshot/createforvm`, {
        virtualmachineid,
        name
      });
      getInstancesRequest(true)(dispatch);
    } catch (error) {
      createSnapshotResponse = error;
      dispatch(setLoading(false));
    }
    if (createSnapshotResponse.data.error) {
      dispatch(setLoading(false));
      throw new Error(createSnapshotResponse.data.error.message);
    }
    // getSnapshotsRequest({ id: accountid, virtualmachineid })(dispatch);
    return createSnapshotResponse;
  };

///delete VM snapshot
export const deleteVmSnapshot =
  ({ accountid, vmSnapshotId }) =>
  async (dispatch) => {
    let deleteResult;
    try {
      deleteResult = await del(`/accounts/${accountid}/snapshot/vmsnapshot/${vmSnapshotId}`, {});
      dispatch(setLoading(true));
    } catch (error) {
      deleteResult = { error };
      dispatch(setLoading(false));
    }
    if (deleteResult.data.error) {
      dispatch(setLoading(false));
      throw new Error(deleteResult.data.error.message);
    }
    return deleteResult;
  };

// Revert VM from a vmsnapshot
export const revertSnapshot =
  ({ accountid, snapshotId }) =>
  async (dispatch) => {
    let revertResult;
    try {
      revertResult = await put(`/accounts/${accountid}/snapshot/reverttovm/${snapshotId}`);
      dispatch(setLoading(true));
    } catch (error) {
      revertResult = { error };
      dispatch(setLoading(false));
    }
    if (revertResult.data.error) {
      dispatch(setLoading(false));
      throw new Error(revertResult.data.error.message);
    }
    return revertResult;
  };

// Get all newtworks for account
export const getNetworksRequest =
  ({ accountid } = {}) =>
  async (dispatch) => {
    dispatch(setNetworksLoading(true));
    try {
      if (accountid) {
        let {
          data: { data }
        } = await get(`/accounts/${accountid}/networks`);
        const filteredNetworksArray = data.filter((network) => network.type === 'Isolated');
        dispatch(getNetworks(filteredNetworksArray));
      } else {
        let {
          data: {
            data: { networks }
          }
        } = await get(`/client/networks`);
        const filteredNetworksArray = networks.filter((network) => network.type === 'Isolated');
        dispatch(getNetworks(filteredNetworksArray));
      }
      dispatch(setNetworksLoading(false));
    } catch (error) {
      dispatch(setNetworksLoading(false));
    }
  };

//delete network
export const deleteNetwork =
  ({ accountid, id }) =>
  async () => {
    let deleteResult;
    try {
      deleteResult = await del(`/accounts/${accountid}/networks`, {
        id
      });
    } catch (error) {
      deleteResult = { error };
    }
    if (deleteResult.data.error) {
      throw new Error(deleteResult.data.error.message);
    }
    // getNetworksRequest({ accountid })(dispatch);
    return deleteResult;
  };

//edit network
///we need fromMainPage parameter to understand where user change network, and make request to all network or for specify account
export const editNetwork =
  ({
    accountid,
    changecidr,
    name,
    displaytext,
    id
  }) =>
  async (dispatch) => {
    dispatch(setNetworksLoading(true));
    let changeResult;
    try {
      changeResult = await put(`/accounts/${accountid}/networks`, {
        id,
        changecidr,
        displaytext,
        name,
      });
    } catch (error) {
      changeResult = { error };
    }
    if (changeResult.data.error) {
      dispatch(setNetworksLoading(false));
      throw new Error(changeResult.data.error.message);
    }
    getNetworksRequest()(dispatch);
    return changeResult;
  };

//create network
export const createNetwork =
  ({ accountid, name, zoneid, description }) =>
  async (dispatch) => {
    dispatch(setNetworksLoading(true));
    let createResult;
    try {
      const {
        data: { data: networkOfferings }
      } = await get(`/accounts/${accountid}/networks/offerings`);
      const [networkOffering] = networkOfferings.filter(
        (offering) => offering.name === 'DefaultIsolatedNetworkOfferingWithSourceNatService-Allow'
      );
      createResult = await post(`/accounts/${accountid}/networks`, {
        displaytext: description,
        name,
        networkofferingid: networkOffering.id,
        zoneid
      });
    } catch (error) {
      createResult = { error };
    }
    if (createResult.data.error) {
      dispatch(setNetworksLoading(false));
      throw new Error(createResult.data.error.message);
    }
    getNetworksRequest()(dispatch);
  };

//get networks offering
export const getNetworksOfferingRequest =
  ({ accountid }) =>
  async (dispatch) => {
    try {
      let {
        data: { data }
      } = await get(`/accounts/${accountid}/networks/offerings`);
      const filteredOffers = data.filter((offer) => offer.forvpc);
      dispatch(setNetworksLoading(false));
      dispatch(getNetworksOffering(filteredOffers));
    } catch (error) {
      dispatch(setNetworksLoading(false));
    }
  };

// get VPC offerings
export const getVpcOfferingsRequest =
  ({ accountid }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      let {
        data: { data }
      } = await get(`/accounts/${accountid}/vpc/offerings`); //need to ask
      dispatch(setLoading(false));
      dispatch(getVpcOfferings(data));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

// get ACL list
export const getAclListRequest =
  ({ accountid }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      let {
        data: { data }
      } = await get(`/accounts/${accountid}/networks/acls/lists`);
      dispatch(setLoading(false));
      dispatch(getAclList(data));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

//get account zones
export const getZonesRequest =
  ({ accountid } = {}) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      if (accountid) {
        let {
          data: { data }
        } = await get(`/accounts/${accountid}/zones`);
        dispatch(getZones(data));
      } else {
        let {
          data: {
            data: { accounts }
          }
        } = await get(`/accounts`);
        const allZones = await Promise.all(
          accounts
            .filter((account) => account.status !== 'Terminated')
            .map(async ({ id }) => {
              const response = await get(`/accounts/${id}/zones`);
              return response.data.data;
            })
        );
        const uniqueZones = [...new Map(allZones.map((item) => [item['id'], item])).values()];
        dispatch(getZones(uniqueZones.flat()));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

//create VPC
export const createVpc =
  ({ accountid, name, networkdomain, zoneid, vpcofferingid, cidr }) =>
  async (dispatch) => {
    dispatch(setVpcLoading(true));
    let createResult;
    try {
      createResult = await post(`/accounts/${accountid}/vpc`, {
        displaytext: name,
        networkdomain,
        cidr,
        name,
        zoneid,
        vpcofferingid
      });
    } catch (error) {
      createResult = { error };
    }
    if (createResult.data.error) {
      dispatch(setVpcLoading(false));
      throw new Error(createResult.data.error.message);
    }
    return createResult;
  };

// get VPC list
export const getVpcRequest =
  ({ accountid } = {}) =>
  async (dispatch) => {
    dispatch(setVpcLoading(true));
    try {
      if (accountid) {
        let {
          data: { data }
        } = await get(`/accounts/${accountid}/vpc`);
        const aclList = await Promise.all(
          data.map(async ({ id }) => {
            const response = await get(`/accounts/${accountid}/networks/acls/lists`, {
              params: {
                vpcid: id
              }
            });
            return response.data.data;
          })
        );
        const vpcWithAclList = data.map((data, i) => ({
          ...data,
          aclList: aclList[i]
        }));
        dispatch(getVpc(vpcWithAclList));
      } else {
        let {
          data: {
            data: { vpcs }
          }
        } = await get(`/client/vpc`);
        const aclList = await Promise.all(
          vpcs.map(async ({ id, accountId }) => {
            const response = await get(`/accounts/${accountId}/networks/acls/lists`, {
              params: {
                vpcid: id
              }
            });
            return response.data.data;
          })
        );
        const vpcWithAclList = vpcs.map((data, i) => ({
          ...data,
          aclList: aclList[i]
        }));
        dispatch(getVpc(vpcWithAclList));
      }
      dispatch(setVpcLoading(false));
      dispatch(setAclRulesLoading(false));
    } catch (error) {
      dispatch(setAclRulesLoading(false));
      dispatch(setVpcLoading(false));
    }
  };

//delete VPC
export const deleteVpc =
  ({ accountid, vpcid }) =>
  async (dispatch) => {
    dispatch(setVpcLoading(true));
    let deleteResult;
    try {
      deleteResult = await del(`/accounts/${accountid}/vpc/${vpcid}`);
    } catch (error) {
      dispatch(setVpcLoading(false));
    }
    if (deleteResult.error) {
      dispatch(setVpcLoading(false));
      throw new Error(deleteResult.data.error.message);
    }
    return deleteResult;
  };

//edit VPC
export const editVpc =
  ({ accountid, name, id, underInstance }) =>
  async (dispatch) => {
    dispatch(setNetworksLoading(true));
    let changeResult;
    try {
      changeResult = await put(`/accounts/${accountid}/vpc`, {
        id,
        displaytext: name,
        name
      });
    } catch (error) {
      changeResult = { error };
    }
    if (changeResult.data.error) {
      dispatch(setNetworksLoading(false));
      throw new Error(changeResult.data.error.message);
    }
    underInstance ? getVpcRequest({ accountid })(dispatch) : getVpcRequest()(dispatch);
  };
//restart VPC
export const restartVPC =
  ({ accountid, id, cleanup, makeredundant }) =>
  async (dispatch) => {
    dispatch(setVpcLoading(true));
    let changeResult;
    try {
      changeResult = await post(`/accounts/${accountid}/vpc/restart`, {
        id,
        cleanup,
        makeredundant
      });
    } catch (error) {
      changeResult = { error };
    }
    if (changeResult.data.error) {
      dispatch(setVpcLoading(false));
      throw new Error(changeResult.data.error.message);
    }
    return changeResult;
  };
// get Firewalls rules for account
export const getFirewallsRequest =
  () =>
    async (dispatch) => {
      dispatch(setFirewallslLoading(true));
      try {
        const groupsResult = await get(`/client/firewall-groups`);

        if (groupsResult.data.error) {
          throw new Error(groupsResult.data.error.message);
        }
        const firewallGroups = groupsResult.data.data;

        for (const group of firewallGroups) {
          const ruleResult = await get(`/client/firewall-group-rules?firewall_group_id=${group.id}`);

          if (ruleResult.data.error) {
            throw new Error(ruleResult.data.error.message);
          }
          const groupRules = ruleResult.data.data.filter(rule => !rule.message); // Filter should be deleted when DB values will be deleted

          group.rules = groupRules;
        }

        dispatch(getFirewalls(firewallGroups));
        dispatch(setFirewallslLoading(false));
      } catch (error) {
        dispatch(setFirewallslLoading(false));
      }
    };

export const createFirewallGroupRequest =
  ({ name }) => //eslint-disable-line
    async () => {
      try {
        const response = await post(`client/firewall-groups`, {
          name: name,
          description: name
        })
        if (response.data.error) {
          throw new Error(response.data.error.message);
        }
        return response.data.data.groupId;
      } catch (error) {
        throw new Error(error.message);
      }
    }

export const createFirewallRuleRequest =
  ({ accountId, ipaddressid, protocol, cidrlist, startport, endport, icmpcode, icmptype }) =>
    async () => {
      try {
        const response = await post(`accounts/${accountId}/firewall`, {
          ipaddressid, protocol, cidrlist, startport, endport, icmpcode, icmptype
        })
        if (response.data.error) {
          throw new Error(response.data.error.message);
        }
        return response.data.data.id;
      } catch (error) {
        throw new Error(error.message);
      }
    }

export const deleteFirewallRuleRequest =
  ({ accountId, ruleId }) =>
    async () => {
      try {
        await del(`accounts/${accountId}/firewall/${ruleId}`);
      } catch (error) {
        throw new Error(error.message);
      }
    }

export const assignFirewallRuleToGroupRequest =
  ({ accountId, groupId, ruleId }) => //eslint-disable-line
    async () => {
      try {
        const response = await post(`accounts/${accountId}/firewall/rule`, {
          firewall_group_id: groupId,
          acs_rule_id: ruleId,
        })
        if (response.data.error) {
          throw new Error(response.data.error.message);
        }
        return response.data.data.groupId;
      } catch (error) {
        throw new Error(error.message);
      }
    }

export const deleteFirewallGroupRequest =
  ({ groupId, accountId }) => //eslint-disable-line
    async () => {
      try {
        await del(`client/firewall-groups/${groupId}`);
      } catch (error) {
        throw new Error(error.message);
      }
    }

export const editFirewallGroupRequest =
  ({ groupId, accountId, name }) => //eslint-disable-line
    async () => {
      try {
        await put(`client/firewall-groups/${groupId}`, {
          name: name,
          description: name,
        });
      } catch (error) {
        throw new Error(error.message);
      }
    }

///delete firewall rule
export const deleteFirewallRule =
  ({ accountid, ruleid, isInbound }) =>
  async (dispatch) => {
    dispatch(setFirewallslLoading(true));
    let deleteResult;
    try {
      if (isInbound) {
        deleteResult = await del(`/accounts/${accountid}/firewall/${ruleid}`);
      } else {
        deleteResult = await del(`/accounts/${accountid}/firewall/egress/${ruleid}`);
      }
    } catch (error) {
      deleteResult = { error };
    }
    if (deleteResult.data.error) {
      dispatch(setFirewallslLoading(false));
      throw new Error(deleteResult.data.error.message);
    }
    return deleteResult;
  };

///get IP's for inbound rules
export const getInboundIpRequest =
  ({ accountid }) =>
  async (dispatch) => {
    dispatch(setRulesLoading(true));
    try {
      let {
        data: {
          data: { addresses }
        }
      } = await get(`/client/address`);
      let filteredIpList = addresses.filter((obj) => obj.accountId === +accountid && !obj.vpcid);
      dispatch(getInboundIpList(filteredIpList));
      dispatch(setRulesLoading(false));
    } catch (error) {
      dispatch(setRulesLoading(false));
    }
  };

///create inbound rule
export const createInboundRule =
  ({ accountid, ipaddressid, protocol, endport, icmpcode, icmptype, startport }) =>
  async (dispatch) => {
    const dataToSend =
      protocol === 'icmp'
        ? {
            icmpcode,
            icmptype
          }
        : {
            startport,
            endport
          };
    let createResult;
    try {
      createResult = await post(`/accounts/${accountid}/firewall`, {
        ipaddressid,
        protocol,
        ...dataToSend
      });
    } catch (error) {
      dispatch(setRulesLoading(false));
    }
    if (createResult.data.error) {
      throw new Error(createResult.data.error.message);
    }

    return createResult;
  };
///get network ID's for Onbound rules
export const getOutboundIdRequest =
  ({ accountid }) =>
  async (dispatch) => {
    dispatch(setRulesLoading(true));
    try {
      let {
        data: { data }
      } = await get(`/accounts/${accountid}/firewall/egress`);
      const networkIds = [...new Set(data.map((network) => network.networkid))];
      const {
        data: { data: networksList }
      } = await get(`/accounts/${accountid}/networks`);
      const filteredNetworks = networksList.filter(({ id }) => networkIds.includes(id));
      dispatch(getOutboundNetworks(filteredNetworks));
      dispatch(setRulesLoading(false));
    } catch (error) {
      dispatch(setRulesLoading(false));
    }
  };

///create outbound rule
export const createOutboundRule =
  ({
    accountid,
    networkid,
    protocol,
    endport,
    icmpcode,
    icmptype,
    startport,
    cidrlist,
    destcidrlist
  }) =>
  async (dispatch) => {
    const dataToSend =
      protocol === 'icmp'
        ? {
            icmpcode,
            icmptype
          }
        : {
            startport,
            endport
          };
    let createResult;
    try {
      createResult = await post(`/accounts/${accountid}/firewall/egress`, {
        networkid,
        protocol,
        cidrlist,
        destcidrlist,
        ...dataToSend
      });
    } catch (error) {
      dispatch(setRulesLoading(false));
    }
    if (createResult.data.error) {
      throw new Error(createResult.data.error.message);
    }

    return createResult;
  };

///get list of LoadBalancer groups
export const getLBGroupsRequest =
  () =>
    async (dispatch) => {
      dispatch(setLBGroupsLoading(true));
      try {
        const {
          data: { data: groups }
        } = await get(`client/loadbalancers/group-rule`);

        const {
          data: {
            data: { loadbalancersRules }
          }
        } = await get(`/client/loadbalancers/rules`);

        if (groups.length) {
          for (const group of groups) {
            group.instances = [];
            group.protocols = [];
            group.algorithms = [];
            group.InstanceIds = [];
            const {
              data: { data: rules }
            } = await get(`client/loadbalancers/rule?groupid=${group.id}`);

            const ruleIds = rules.map(rule => rule.rule_id);

            const groupRules = loadbalancersRules.filter(rule => ruleIds.includes(rule.id));

            if (groupRules.length) {
              for (const rule of groupRules) {
                const {
                  data: { data: instances }
                } = await get(`/accounts/${rule.accountId}/loadbalancers/rules/${rule.id}/instances`);

                rule.instances = instances;
                rule.intermediateId = rules.find(intermediateRule => intermediateRule.rule_id === rule.id).id;
                group.instances = [... new Set([...group.instances, ...instances])];
                group.InstanceIds = [... new Set([...group.InstanceIds, ...instances.map(instance => instance.id)])];
                group.protocols = [... new Set([...group.protocols, rule.protocol])];
                group.algorithms = [... new Set([...group.algorithms, rule.algorithm])];
              }
            }

            group.rules = groupRules;
            group.zonename = groupRules[0]?.zonename;
            group.zoneid = groupRules[0]?.zoneid;
            group.protocol = groupRules[0]?.protocol;
            group.algorithm = groupRules[0]?.algorithm;
            group.publicip = groupRules[0]?.publicip;
            group.publicipid = groupRules[0]?.publicipid;
            group.instancesCount = group.InstanceIds.length; // unique instances count
            group.protocolsCount = group.protocols.length; // unique protocols count
            group.algorithmsCount = group.algorithms.length; // unique algorithms count
          }

        }

        dispatch(getLBGroups(groups));
        dispatch(setLBGroupsLoading(false));
      } catch (error) {
        dispatch(setLBGroupsLoading(false));
      }
    }

///get list of LoadBalancers with instances and ip list for ins
export const getLoadBalancersRequest =
  ({ accountid } = {}) =>
  async (dispatch) => {
    dispatch(setLoadBalancersLoading(true));
    if (accountid) {
      try {
        const {
          data: {
            data: { virtualMachines }
          }
        } = await get(`/accounts/${accountid}/vm`);

        const {
          data: { data }
        } = await get(`/accounts/${accountid}/loadbalancers/rules`);
        const dataWithAccount = data.map((data) => {
          return {
            ...data,
            accountId: accountid
          };
        });
        const response = await Promise.all(
          dataWithAccount.map(async ({ id }) => {
            const result = await get(`/accounts/${accountid}/loadbalancers/rules/${id}/instances`);

            return result.data.data.map((instance) => {
              const currentVm = virtualMachines.find((vm) => vm.id === instance.id);
              return {
                ...instance,
                ipList: [
                  {
                    id: currentVm.nic[0].id,
                    ipaddress: currentVm.nic[0].ipaddress,
                    isdefault: currentVm.nic[0].isdefault
                  },
                  ...currentVm.nic[0].secondaryip
                ]
              };
            });
          })
        );
        const loadBalancers = dataWithAccount.map((data, i) => {
          return {
            ...data,
            instances: response[i]
          };
        });

        dispatch(getLoadBalancers(loadBalancers));
        dispatch(setLoadBalancersLoading(false));
      } catch (error) {
        dispatch(setLoadBalancersLoading(false));
      }
    } else {
      try {
        const {
          data: {
            data: { loadbalancersRules }
          }
        } = await get(`/client/loadbalancers/rules`);
        const assignedInstances = await Promise.all(
          loadbalancersRules.map(async ({ id, accountId }) => {
            const response = await get(
              `/accounts/${accountId}/loadbalancers/rules/${id}/instances`
            );
            return response.data.data;
          })
        );
        const loadBalancersWithInstances = loadbalancersRules.map((data, i) => ({
          ...data,
          instances: assignedInstances[i]
        }));

        dispatch(getLoadBalancers(loadBalancersWithInstances));
        dispatch(setLoadBalancersLoading(false));
      } catch (error) {
        dispatch(setLoadBalancersLoading(false));
      }
    }
  };

///get Vm's for loadbalancers
export const getLoadBalancerVMListRequest =
  ({ accountid, networkid }) =>
  async (dispatch) => {
    dispatch(setLoadBalancerVMListLoading(true));
    try {
      const {
        data: {
          data: { virtualMachines }
        }
      } = await get(`/accounts/${accountid}/vm`);

      const loadBalancerVmList = virtualMachines.filter(
        ({ nic }) => nic[0].networkid === networkid
      );
      dispatch(getLoadBalancerVMList(loadBalancerVmList));
      dispatch(setLoadBalancerVMListLoading(false));
    } catch (error) {
      dispatch(setLoadBalancerVMListLoading(false));
    }
  };

// Assign VM to load balancer rule
//need to check API
export const getAssignVmToRuleRequest =
  ({ accountid, networkid, ruleid, vmIdForAssign }) =>
  async (dispatch) => {
    let assignResult;
    dispatch(setLoadBalancerVMListLoading(true));

    try {
      assignResult = await post(`/accounts/${accountid}/loadbalancers/rules/${ruleid}/assing-vm`, {
        virtualmachineids: `virtualMachineIds=${vmIdForAssign}`
      });
    } catch (error) {
      dispatch(setLoadBalancerVMListLoading(false));
    }
    if (assignResult.data.error) {
      dispatch(setLoadBalancerVMListLoading(false));
      throw new Error(assignResult.data.error.message);
    }
    getLoadBalancerVMListRequest({ accountid, networkid })(dispatch);
  };

///delete Vm from load balanacer rule
//need to check API
export const getDeleteVmFromRule =
  ({ accountid, ruleid, networkid, vmId }) =>
  async (dispatch) => {
    dispatch(setLoadBalancerVMListLoading(true));
    let deleteResult;
    try {
      deleteResult = await del(`/accounts/${accountid}/loadbalancers/rules/${ruleid}/remove-vm`, {
        virtualmachineids: `virtualMachineIds=${vmId}`
      });
    } catch (error) {
      dispatch(setLoadBalancerVMListLoading(false));
    }
    if (deleteResult.data.error) {
      dispatch(setLoadBalancerVMListLoading(false));
      throw new Error(deleteResult.data.error.message);
    }
    getLoadBalancerVMListRequest({ accountid, networkid })(dispatch);
  };

// edit Load Balancer rule
export const getEditBalancerRule =
  ({ accountid, data }) =>
  async (dispatch) => {
    let editResult;
    dispatch(setLoadBalancersLoading(true));

    try {
      editResult = await put(`/accounts/${accountid}/loadbalancers/rules`, {
        ...data
      });
    } catch (error) {
      dispatch(setLoadBalancersLoading(false));
    }
    if (editResult.data.error) {
      dispatch(setLoadBalancersLoading(false));
      throw new Error(editResult.data.error.message);
    }
    return editResult;
  };

// delete Load Balancer rule
export const deleteBalancerRule =
  ({ accountid, ruleId }) =>
  async (dispatch) => {
    dispatch(setLoadBalancersLoading(true));
    let deleteResult;
    try {
      deleteResult = await del(`/accounts/${accountid}/loadbalancers/rules/${ruleId}`);
    } catch (error) {
      dispatch(setLoadBalancersLoading(false));
    }
    if (deleteResult.data.error) {
      dispatch(setLoadBalancersLoading(false));
      throw new Error(deleteResult.data.error.message);
    }
    return deleteResult;
  };

// get load balancers rules
export const getLoadBalancersRulesRequest = () => async (dispatch) => {
  const {
    data: {
      data: { loadbalancersRules }
    }
  } = await get(`/client/loadbalancers/rules`);
  dispatch(getLoadBalancersRules(loadbalancersRules));
};

///get List Addresses belonging to the client
export const getIpAddressesRequest = () => async (dispatch) => {
  dispatch(setFloatingIpsLoading(true));
  try {
    const {
      data: {
        data: { addresses }
      }
    } = await get('/client/address');
    dispatch(getFloatingIps(addresses));
    dispatch(setFloatingIpsLoading(false));
  } catch (error) {
    dispatch(setFloatingIpsLoading(false));
  }
};

// assign Floating IP
export const getAssignFloatingIpRequest =
  ({ accountid, networkid, virtualmachineid, vmguestip }) =>
  async (dispatch) => {
    let assignResult;
    try {
      dispatch(setFloatingIpsLoading(true));
      assignResult = await post(`/accounts/${accountid}/address/associateIpAddress`, {
        networkid
      });
      if(assignResult.data.data.id) {
        const ipaddressid = assignResult.data.data.id;
        const data = await post(`/accounts/${accountid}/nat/enableStaticNat`, {
          ipaddressid,
          virtualmachineid,
          networkid,
          vmguestip
        });
        if (data.data.error) {
          throw new Error(data.data.error.message);
        }
        getIpAddressesRequest()(dispatch);
      }
    } catch (error) {
      dispatch(setFloatingIpsLoading(false));
    }
    if (assignResult.data.error) {
      dispatch(setFloatingIpsLoading(false));
      throw new Error(assignResult.data.error.message);
    }
    return assignResult;
  };

///get events
export const getEventsRequest =
  ({ accountid } = {}) =>
  async (dispatch) => {
    dispatch(setEventsLoading(true));
    try {
      const date = new Date();
      date.setDate(date.getDate()-7);
      const formattedDate = date.toISOString().split('T')[0]

      if (accountid) {
        const {
          data: { data }
        } = await get(`/accounts/${accountid}/events?startdate=${formattedDate}`);
        dispatch(getEvents(data?.filter(({type}) => type !== "CREATE_TAGS")));
        dispatch(setEventsLoading(false));
      } else {
        const {
          data: {
            data: { accounts }
          }
        } = await get(`/accounts`);

        const allEvents = await Promise.all(
          accounts
            .filter((account) => account.status === 'Active')
            .map(async ({ id }) => {
              const response = await get(`/accounts/${id}/events?startdate=${formattedDate}`);
              return response.data.data;
            })
        );
        const events = allEvents.flat().sort((a,b) =>{
          return new Date(b.created) - new Date(a.created);
        });
        dispatch(getEvents(events?.filter(({type}) => type !== "CREATE_TAGS")));
        dispatch(setEventsLoading(false));
      }
    } catch (error) {
      dispatch(setEventsLoading(false));
    }
  };

/// get backup offerings
export const getBackupOfferingsRequest =
  ({ accountid }) =>
  async (dispatch) => {
    dispatch(setBackupOfferingLoading(true));
    try {
      const {
        data: { data }
      } = await get(`/accounts/${accountid}/backup-offerings`);
      dispatch(getBackupOfferings(data));
      dispatch(setBackupOfferingLoading(false));
    } catch (error) {
      dispatch(setBackupOfferingLoading(false));
    }
  };

export const getAssignBackupRequest =
  ({ accountid, backupOfferingId, virtualmachineId }) =>
  async (dispatch) => {
    dispatch(getBackupList([]));
    let assignResult;
    try {
      dispatch(setBackupListLoading(true));
      assignResult = await post(
        `/accounts/${accountid}/backup/assign/${backupOfferingId}/${virtualmachineId}`,
        {
          backupofferingid: backupOfferingId,
          virtualmachineid: virtualmachineId
        }
      );
      dispatch(setBackupListLoading(false));
    } catch (error) {
      dispatch(setBackupListLoading(false));
    }
    if (assignResult.data.error) {
      dispatch(setBackupListLoading(false));
      throw new Error(assignResult.data.error.message);
    }
    return assignResult;
  };

///start backup
export const getStartBackupRequest =
  ({ accountid, vmId }) =>
  async () => {
    let startBackupResult;
    try {
      startBackupResult = await post(`/accounts/${accountid}/backup/create/${vmId}`);
    } catch (error) {
      return;
    }
    if (startBackupResult.data.error) {
      throw new Error(startBackupResult.data.error.message);
    }
    return startBackupResult;
  };

//get nics list
export const getNicsListRequest =
  ({ accountid, virtualmachineId }) =>
  async (dispatch) => {
    dispatch(setNicsLoading(true));
    const {
      data: {
        data: { nic }
      }
    } = await get(`/accounts/${accountid}/vm/${virtualmachineId}/nic`);
    const sortedNics = nic.sort((a, b) => b.isdefault - a.isdefault);
    dispatch(setNicsLoading(false));
    dispatch(getNicsList(sortedNics));
  };

// assign secondary ip to (NIC)
export const getAssignSecondaryIpRequest =
  ({ accountid, machineId, nicId }) =>
  async () => {
    let assignResult;
    try {
      assignResult = await post(`/accounts/${accountid}/vm/${machineId}/nic/${nicId}`);
    } catch (error) {
      throw new Error(error.message);
    }
    if (assignResult.data.error) {
      throw new Error(assignResult.data.error.message);
    }
    return assignResult;
  };

// Delete a secondary IP address from a NIC

export const deleteSecondaryIpRequest =
  ({ accountid, virtualmachineId, nicId, isSecondaryToDelete }) =>
  async () => {
    let deleteResult;
    try {
      if (isSecondaryToDelete) {
        deleteResult = await del(`/accounts/${accountid}/vm/${virtualmachineId}/nic/${nicId}`, {
          id: nicId
        });
      } else {
        deleteResult = await del(
          `/accounts/${accountid}/vm/${virtualmachineId}/remove-nic/${nicId}`
        );
      }
    } catch (error) {
      throw new Error(error.message);
    }
    if (deleteResult.data.error) {
      throw new Error(deleteResult.data.error.message);
    }
    return deleteResult;
  };

///Adds VM to specified network by creating a NIC

export const addNetworkToVmRequest =
  ({ accountid, virtualmachineId, networkId }) =>
  async () => {
    let addResult;
    try {
      addResult = await post(
        `/accounts/${accountid}/vm/${virtualmachineId}/add-to-network/${networkId}`
      );
    } catch (error) {
      throw new Error(error.message);
    }
    if (addResult.data.error) {
      throw new Error(addResult.data.error.message);
    }
    return addResult;
  };

///get acl rules list
export const getAclRulesListRequest =
  ({ accountid, aclid }) =>
  async (dispatch) => {
    dispatch(setAclRulesLoading(true));
    try {
      const {
        data: { data }
      } = await get(`/accounts/${accountid}/networks/acls`, {
        params: {
          aclid
        }
      });
      dispatch(setAclRulesLoading(false));
      dispatch(getAclRulesList(data));
    } catch (error) {
      dispatch(setAclRulesLoading(false));
      throw new Error(error.message);
    }
  };

/// add ACL rule
export const addAclRuleRequest =
  ({ aclid, accountid, values }) =>
  async (dispatch) => {
    let createResult;
    dispatch(setAclRulesLoading(true));
    try {
      createResult = await post(`/accounts/${accountid}/networks/acls`, {
        aclid,
        ...values
      });
    } catch (error) {
      throw new Error(error);
    }
    if (createResult.data.error) {
      throw new Error(createResult.data.error.message);
    }
    return createResult;
  };
/// delete acl list
export const deleteAclListRequest =
  ({ listid, accountid }) =>
  async (dispatch) => {
    let deleteResult;
    dispatch(setAclRulesLoading(true));
    try {
      deleteResult = await del(`/accounts/${accountid}/networks/acls/lists/${listid}`);
    } catch (error) {
      throw new Error(error);
    }
    if (deleteResult.data.error) {
      throw new Error(deleteResult.data.error.message);
    }
    return deleteResult;
  };

//create acl list
export const createAclListRequest =
  ({ description, name, vpcid, accountid }) =>
  async (dispatch) => {
    let createResult;
    dispatch(setAclRulesLoading(true));
    try {
      createResult = await post(`/accounts/${accountid}/networks/acls/lists`, {
        name,
        description,
        vpcid,
        fordisplay: name
      });
    } catch (error) {
      dispatch(setAclRulesLoading(false));
      throw new Error(error);
    }
    if (createResult.data.error) {
      dispatch(setAclRulesLoading(false));
      throw new Error(createResult.data.error.message);
    }
    return createResult;
  };

/// delete acl rule
export const deleteAclRuleRequest =
  ({ ruleid, accountid }) =>
  async (dispatch) => {
    let deleteResult;
    dispatch(setAclRulesLoading(true));
    try {
      deleteResult = await del(`/accounts/${accountid}/networks/acls/${ruleid}`);
    } catch (error) {
      dispatch(setAclRulesLoading(false));
      throw new Error(error);
    }
    if (deleteResult.data.error) {
      dispatch(setAclRulesLoading(false));
      throw new Error(deleteResult.data.error.message);
    }
    return deleteResult;
  };
//edit acl rule
export const editAclRuleRequest =
  ({ accountid, values, ruleid }) =>
  async (dispatch) => {
    let editResult;
    dispatch(setAclRulesLoading(true));
    try {
      editResult = await post(`/accounts/${accountid}/networks/acls/item`, {
        id: ruleid,
        ...values
      });
    } catch (error) {
      dispatch(setAclRulesLoading(false));
      throw new Error(error);
    }
    if (editResult.data.error) {
      dispatch(setAclRulesLoading(false));
      throw new Error(editResult.data.error.message);
    }
    return editResult;
  };

//metrics
export const getMetricRequest =
  ({ instanceId, accountid, startDate, endDate }) =>
  async (dispatch) => {
    try {
      const {
        data: { data }
      } = await get(`/accounts/${accountid}/vm/${instanceId}/list-vm-usage-history`, {params: {startDate, endDate}});
      if (!data.length) {
        return get(`/accounts/${accountid}/vm/${instanceId}/start-metric`);
      } else {
        dispatch(setMetricDisabled(false));
        dispatch(getMetric(data));
      }
    } catch (error) {
      throw new Error(error);
    }
  };


const planNaming = {
  S: "S-Packages",
  C: "C-Packages",
  M: "M-Packages"
}

//metrics
export const resizeRequest =
  ({ instanceId, accountId, product, boot, host, volumeId }) =>
    async () => {
      try {
        const serviceOffertingResponse = await get(`/shapehost-service-offerings`);

        const [serviceoffering] = serviceOffertingResponse?.data?.data?.filter(
          ({ name: offeringName, host: offeringHost }) => offeringName === planNaming[product?.name?.split("-")[0]] && offeringHost === host
        );

        const body = {
          account_id: accountId,
          virtualmachine_id: instanceId,
          new_product_id: product.id,
          new_billing_cycle: "m",
          payment_module_id: 58,
          serviceoffering_id: serviceoffering?.acs_id,
        };

        const detailsBody = {
          memory: product.memory,
          cpuNumber: product.cpu,
        };

        if (boot === "disk") {
          body.rootdisk_volume_size = product.storage;
          body.rootdisk_volume_id = volumeId;
        }

        const resizeBody = Object.fromEntries(Object.entries(
          {
            ...body,
            details: [detailsBody],
          }
        ).filter(([_, v]) => v != null)); //eslint-disable-line

        const scaleData = await post(`/client/orders/upgrade`, resizeBody);

        return scaleData

      } catch (error) {
        throw new Error(error);
      }
    };
