import React, {useState} from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {Link, useHistory, useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import mailIco from "../../assets/images/mail.svg";
import unlockIco from "../../assets/images/unlock.svg";
import registerIll1 from "../../assets/images/register-ill1.svg";
import registerIll2 from "../../assets/images/register-ill2.svg";
import registerIll3 from "../../assets/images/register-ill3.svg";

import CustomField from "../../components/CustomField";
import Checkbox from "../../components/Checkbox";
import { post } from "../../components/http";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";

const validations = {
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
  confirm: Yup.boolean().required("Required"),
}

const SiginSchema = Yup.object().shape(validations);

const register = async ({ values, addToast, history }) => {
  let signupResponse;
  try {
    signupResponse = await post("/signup", values);
  } catch (error) {
    return addToast(error.message, {
      appearance: "error",
      autoDismiss: true,
    });
  }
  const { data: { error } = {} } = signupResponse;
  if (error) {
    return addToast(error.message, {
      appearance: "error",
      autoDismiss: true,
    });
  }
  history.push("/login");
  return addToast(
    "Thank you for your registration! Your account is now ready to use.",
    {
      appearance: "success",
      autoDismiss: true,
    }
  );
};

function getCookie(name) {
  const value = "; " + document.cookie; // eslint-disable-line
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const Register = () => {
  const { t } = useTranslation("register");
  const history = useHistory();
  const [captchaToken, setCaptchaToken] = useState('');
  const { addToast } = useToasts();
  const location = useLocation();
  const query = new URLSearchParams(location.search); //eslint-disable-line
  const ref = query.get("ref")
  const affilate = query.get("affid")
  const { search } = location;
  let hbAffiliateValue = getCookie('HBAffiliate');

  if (hbAffiliateValue) {
    hbAffiliateValue = decodeURIComponent(hbAffiliateValue)
    hbAffiliateValue = hbAffiliateValue.split(':')[0];
  }

  return (
    <div className="RegisterPage">
      <div className="card">
        <div className="ill">
          <img className="ill__1" src={registerIll1} alt="" />
          <img className="ill__2" src={registerIll2} alt="" />
          <img className="ill__3" src={registerIll3} alt="" />
        </div>
        <h2>{t("title")}</h2>
        <p className="text-grey-1 text-align-center m-b-30 font-weight-light">
          {t("description")}
        </p>
        <Formik
          initialValues={{
            email: "",
            password: "",
            recaptcha: "",
            ref: ref || "",
            aff_id: hbAffiliateValue || affilate || "",
          }}
          validationSchema={SiginSchema}
          onSubmit={async (values) => {
            if (values.ref === "") {
              delete values.ref;
            }
            if (values.aff_id === "") {
              delete values.aff_id;
            }
            register({ history, values: {...values, recaptcha: captchaToken }, addToast });
          }}
        >
          {({ errors, touched, submitCount, handleChange }) => (
            <Form>
              <CustomField
                {...{
                  errors,
                  touched,
                  name: "email",
                  Ico: () => <img src={mailIco} className="ico" alt="" />,
                }}
              >
                <Field
                  name="email"
                  type="email"
                  placeholder={t("placeholders.email")}
                />
              </CustomField>

              <CustomField
                {...{
                  errors,
                  touched,
                  name: "password",
                  Ico: () => <img src={unlockIco} className="ico" alt="" />,
                }}
              >
                <Field
                  name="password"
                  type="password"
                  placeholder={t("placeholders.password")}
                />
              </CustomField>
              <div className="flex flex-align-items-center flex-justify-content-space-between m-b-10">
                <label className="flex flex-align-items-center">
                  <Checkbox
                    onChange={handleChange}
                    touched={touched}
                    submitCount={submitCount}
                    errors={errors}
                    name="confirm"
                  />
                  {t("labels.confirm")}
                  <a
                    href={`https://shape.host/privacy-policy-2`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="font-weight-semibold m-l-5 lighter"
                  >
                    {t("links.gdpr")}
                  </a>
                </label>
              </div>
              <button className="button width-full-width m-b-20" type="submit">
                {t("button")}
              </button>
            </Form>
          )}
        </Formik>
        {process.env.REACT_APP_ENV === "production" &&  // eslint-disable-line
          <GoogleReCaptcha
            onVerify={setCaptchaToken}/>
        }
      </div>
      <p className="text-align-center text-grey-1 font-size-16 m-t-20 font-weight-regular">
        {t("alreadyAMember")}{" "}
        <Link to={`/login${search}`} className="font-weight-semibold lighter">
          {t("links.logIn")}
        </Link>
      </p>
    </div>
  );
};

export default Register;
