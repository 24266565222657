import React from 'react';
import { Link, useParams } from 'react-router-dom';
import TabContent from './components';
import { useTranslation } from 'react-i18next';
import Tabs from '../../components/Tabs';

const Profile = () => {
  const { tab = 'profile' } = useParams();
  const TabComponent = TabContent[tab];
  const { t } = useTranslation('profile');
  return (
    <div className="ProfilePage">
      <div className="container">
        <h2 className="title">{t('title')}</h2>
        <Tabs>
          <Tabs.Header>
            <ul>
              <li>
                <Link className={tab === 'profile' && 'active'} to="/profile">
                  {t('tabs.profile')}
                </Link>
              </li>
              <li>
                <Link className={tab === 'security' && 'active'} to="/profile/security">
                  {t('tabs.security')}
                </Link>
              </li>
            </ul>
          </Tabs.Header>
          <Tabs.Content>
            <TabComponent />
          </Tabs.Content>
        </Tabs>
      </div>
    </div>
  );
};

export default Profile;
