import React, { useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../../../../components/Card";
import { get } from "../../../../../components/http";
import DestroyModal from "../../Instance/Tabs/DestroyModal";
import { useDispatch } from "react-redux";
import trash from "../../../../../assets/images/instanceActions/trash.svg";
import console from "../../../../../assets/images/instanceActions/console.svg";
import resize from "../../../../../assets/images/instanceActions/resize.svg";
import backup from "../../../../../assets/images/instanceActions/backups.svg";
// import tag from "../../../../../assets/images/instanceActions/tag.svg";
import line from "../../../../../assets/images/small-line.svg";

const items = [
  // {
  //   name: "addDomain",
  //   url: "/",
  // },
  {
    name: "accessConsole",
    url: "javascript:;",
    image: console
  },
  {
    name: "resizeInstance",
    url: "resize",
    image: resize
  },
  // {
  //   name: "viewUsage",
  //   url: "/",
  // },
  {
    name: "enableBackups",
    url: "backups",
    image: backup
  },
  // {
  //   name: "editTags",
  //   url: "tags",
  //   image: tag
  // },
];

const InstanceMenu = ({
  t: initialTransaltion,
  visibleMenu,
  id,
  displayname,
  accountid,
}) => {
  const dispatch = useDispatch();
  const t = (path) => initialTransaltion(`resources.instanceMenu.${path}`);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const getConsoleUrl = async () => {
    const {
      data: {
        data: { console_url },
      },
    } = await get(`/accounts/${accountid}/vm/${id}/console`);
    // eslint-disable-next-line no-undef
    window.open(
      console_url,
      "_blank",
      "noopener,noreferrer, width=300,resizable=yes"
    );
  };
  return (
    <>
      <div className={`InstanceMenu ${visibleMenu && "InstanceMenu--visible"}`}>
        <Card>
          <Card.Body>
            {items.map(({ name, url, image }) => (
              <Link
                key={name}
                to={name === "accessConsole" ? url : `/instance/${id}/${url}`}
              >
                <a
                  key={name}
                  onClick={name === "accessConsole" && getConsoleUrl}
                >
                  <img src={image} />
                  {t(name)}
                </a>
              </Link>
            ))}
            <img className="line" src={line}/>
            <a
              className="text-red-1 delete"
              href="javascript:;"
              onClick={() => setDeleteOpen(true)}
            >
              <img src={trash} />
              {t("delete")}
            </a>
          </Card.Body>
        </Card>
      </div>
      <DestroyModal
        show={isDeleteOpen}
        onHide={() => setDeleteOpen(false)}
        accountid={accountid}
        instanceId={id}
        name={displayname}
        dispatch={dispatch}
      />
    </>
  );
};

export default InstanceMenu;
