import React from 'react';
import instancesIco from '../../../../assets/images/create/instances2.svg';
import searchIco from '../../../../assets/images/network/loupe_icon.svg';
import pretty from "prettysize";

const SelectInstance = ({ instances, t, expanded, handleOptionsSelect, i, setSelectedInstance, setSearchText, SearchClassName, placeholder, triangleClassname }) => {

    const handleInstanceSelect = (instance) => {
        setSelectedInstance({...instance});
        handleOptionsSelect(null);
    }

    return (<div className='Instance_Select position-relative'>
        {(((expanded !== null && expanded === i) || (expanded !== null && expanded === true)) && instances) ? <div className="options_container">
            <div className={`${triangleClassname} options_content`}>
                {instances.length ? instances.map((instance, index) => {
                    return (
                        <div key={instance.id} >
                            <div className="flex item" onMouseDown={() => { handleInstanceSelect(instance) }}>
                                <div className="Instance position-relative m-r-14">
                                    <img src={instancesIco} />
                                    <span className={`status ${(instance?.state === 'Running' && 'Instance__active') ||
                                        (['Starting', 'Stopping'].includes(instance?.state)
                                            ? 'Instance__pending'
                                            : 'Instance__inactive')}`
                                    } />
                                </div>
                                <div className="Instance_info_container">
                                    <div className="font-size-15 font-weight-semibold text-grey-3">{instance?.displayname}</div>
                                    <div className="font-size-12 font-weight-semibold text-grey-8 Instance_info">
                                        <span>{instance?.cpunumber} {t('list.cpu')}</span>
                                        <span>{pretty(instance?.memory * 1024 * 1024)} {t('list.ram')}</span>
                                        <span>{instance?.zonename}</span>
                                        <span>{instance?.templatedisplaytext}</span>
                                    </div>
                                </div>
                            </div>
                            {index + 1 !== instances.length && <div className="line_cuted m-t-12 m-b-12"></div>}
                        </div>
                    )
                }) : <div className="List_item_empty font-size-15 font-weight-semibold text-grey-8">There is no any Instance ...</div>}
            </div>
        </div> : null}
        <div className={SearchClassName}>
            <img src={searchIco} className="ico" />
            <input
                className="Instance_search"
                onChange={(e) => { setSearchText(e.target.value) }}
                onFocus={() => { i || i === 0 ? handleOptionsSelect(i) : handleOptionsSelect(true) }}
                onBlur={() => { handleOptionsSelect(false); }}
                placeholder={placeholder} />
        </div>
    </div>)
}

export default SelectInstance;
