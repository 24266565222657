import React, { useState, useEffect } from "react";
import closeIco from "../../assets/images/close-ico.svg";

const Modal = ({ children, show, onHide, Title, size }) => {
  const [visible, setVisible] = useState(show);
  useEffect(() => {
    setVisible(show);
  }, [show]);
  
  if (visible) {
    return (
      <div className={`Modal ${size && `Modal__${size}`}`}>
        <div
          onClick={() => {
            setVisible(false);
            if (onHide) {
              onHide();
            }
          }}
          className="overley"
        />
        <div className="Modal__content">
          <div className="Modal__header">
            <div className="title">{Title && <Title />}</div>
            <span
              className="close"
              onClick={() => {
                setVisible(false);
                if (onHide) {
                  onHide();
                }
              }}
            >
              <img src={closeIco} alt="" />
            </span>
          </div>
          {children}
        </div>
      </div>
    );
  }
  return "";
};

export default Modal;
