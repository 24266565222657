import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Volumes/components/Loader/Loader";
import { useToasts } from "react-toast-notifications";
import userIco from "../../assets/images/header/user-ico.svg";
import InfoBox from '../../components/InfoBox';
import SubmitIcon from "../../assets/images/tickets/submit_icon.svg";
import CloseIcon from "../../assets/images/tickets/close_icon.svg";
import CustomerAgentIco from "../../assets/images/tickets/customer-agent.svg";
import {closeTicketRequest, replyToTicketRequest} from "./actions";
import { store } from "../../store";
import Button from "../../components/Button";

const Replay = ({ t: replayT, ticketNumber, status, setOpenMessage }) => {
  const globalState = useContext(store);
  const { state: { clientDetails } = {} } = globalState;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const t = (path) => replayT(`replay.${path}`);
  const [message, setMessage] = useState("");
  const [closeLoading, setClosedLoading] = useState(false);
  const { ticketDetails, ticketDetailsLoading } = useSelector(
    ({ support }) => support
  );

  const handleReply = async () => {
    try {
      await replyToTicketRequest({ ticketNumber, message })(dispatch);
      setMessage("");
      return addToast(t("success"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleClose = async () => {
    setClosedLoading(true)
    try {
      await closeTicketRequest({ ticketNumber })(dispatch);
      setClosedLoading(false)
      setOpenMessage(false)
      setMessage("");
      return addToast(t("successClosed"), {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleDate = (date) => {
    return date.replace("-", "/").replace("-", "/");
  }

  return (
    <div className="Replay">
        <div className="Replay__wrapper flex">
          <div className="Replay__content">
            <div className="replay">
              <h3 className="font-size-20 text-grey-1 font-weight-regular m-t-0">
                {t("title")}
              </h3>
              {status === 'Closed' ? '' :
                <textarea
                  className="m-b-30 text-area"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              }
              <div className="flex m-b-30 flex-justify-content-space-between">
                <div className="flex flex-align-items-center">
                </div>
                <div className="buttons-container">
                  {status === 'Closed' ? '' :
                    <>
                      <InfoBox
                        text={t('closeDescription')}
                      >
                        <div className="closeTicket">
                          <Button onClick={handleClose} loading={closeLoading} className="button button--full-red width-full-width font-size-16 button-content">
                            <img src={CloseIcon}/>
                            {t("closeTicket")}
                          </Button>
                        </div>
                      </InfoBox>
                      <Button
                        className="button button--blue font-size-16 button-content"
                        onClick={handleReply}
                        loading={ticketDetailsLoading}
                      >
                        <img src={SubmitIcon} />
                        {t("submit")}
                      </Button>
                    </>
                  }
                </div>
              </div>
            </div>
            {!ticketDetails?.replies ? (
                <div className={"flex flex-justify-content-center"}><Loader /></div>
              ) : (
                <div key={ticketDetails?.replies.length} className="messages">
              {ticketDetails?.replies?.slice().sort((a,b) => new Date(b.date) - new Date(a.date)).map(
                ({ name, date, body, fromSupport, email }) => (
                  <div
                    key={name}
                    className={`message ${fromSupport || !email && "message__support"}`}
                  >
                    <div className="message__title m-b-20 flex flex-align-items-center flex-justify-content-space-between">
                      <div className="flex">
                        <span className="flex flex-align-items-center">
                          <img src={email ? userIco : CustomerAgentIco} alt="" width="34" className="m-r-20" />
                        </span>
                        <div className="flex-column">
                          <div className="font-size-16 font-weight-semibold">{name} {email ? '' : '(Support Agent)'}</div>
                          <div className="font-size-14 font-weight-light">{handleDate(date)}</div>
                        </div>
                      </div>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: body.replaceAll(`\r\n`, '</br>')}} />
                  </div>
                )
              )}
              <div className="message">
                <div className="flex flex-align-items-flex-end m-b-20">
                  <span className="flex flex-align-items-center">
                    <img src={userIco} alt="" width="34" className="m-r-20" />
                  </span>
                  <div className="message__title flex-column flex-justify-content-space-between">
                    <div className="font-size-16 font-weight-semibold">{clientDetails.firstname + " " + clientDetails.lastname}</div>
                    <div className="flex font-size-14 font-weight-light">{handleDate(ticketDetails.ticket.date)}</div>
                  </div>
                </div>
                <div dangerouslySetInnerHTML={{__html: ticketDetails.ticket.body.replaceAll(`\r\n`, '</br>')}} />
              </div>
            </div>)
          }
          </div>
        </div>
    </div>
  );
};

export default Replay;
