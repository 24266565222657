import React from 'react';
import CustomField from '../../../../components/CustomField';

const ChooseName = ({ t }) => {
  return (
    <div>
      <h2 className="title m-t-20 m-b-20">{t('title')}</h2>
      <p className="text-grey-1 font-size-16 m-t-0 m-b-30">{t('description')}</p>
      <div className="flex row">
        <div className="p-l-15 p-r-15 flex-grow-1">
          <CustomField>
            <input placeholder={t('placeholder')} type="text" />
          </CustomField>
        </div>
        <div className="p-l-15 p-r-15 flex-grow-1">
          <CustomField>
            <input placeholder={t('descriptionPlaceholder')} type="text" />
          </CustomField>
        </div>
      </div>
    </div>
  );
};

export default ChooseName;
