import React from "react";
import IconLB from "../../../../../assets/images/loadBalancers/lb-listing-icon.svg";

const LoadBalancerNameAndState = ({
  name,
}) => (
  <div className="flex flex-align-items-center min-width-200">
    <div className="no-border p-l-0">
      <img className="m-r-10" src={IconLB} alt="" />
    </div>
    <div>
      <h4 className="font-weight-semibold m-b-0 m-t-0 text-grey-3 font-size-16">{name}</h4>
    </div>
  </div>
);

export default LoadBalancerNameAndState;
