import React from "react";
import EmptyExtraIP from "../../../../assets/images/network/empty_extraip.svg";

const NoFloating = ({ t, setAssignModalOpen }) => {
  return (
    <div className='Empty_container'>
      <img src={EmptyExtraIP} width={150} height={150} />
      <span className='text-grey-1 font-size-16 m-t-20 line-height-1-6'>
        {t('emptyExtraIP.description')}
        <span className="text-blue-1 cursor-pointer m-l-5">{t('emptyExtraIP.learnMore')}</span>
      </span>
      <button className="button button--blue button--md flex create_button" onClick={() => {setAssignModalOpen(true)}}>
        {t('emptyExtraIP.button')}
      </button>
    </div>
  );
};

export default NoFloating;
