import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import securityIco from "../../../../assets/images/security-ico.svg";
import InfoBox from "../../../../components/InfoBox";
import {CopyToClipboard} from "react-copy-to-clipboard";

import SshModal from "../../../../components/SshModal";
import {getClientDomainsRequest, getListKeysRequest, getSshKeys,} from "../../actions";
import CreateKeyModal from "../../../../components/CreateKeyModal/CreateKeyModal";
import DeleteModal from "./DeleteModal";

const Security = () => {
  const {t: profileTranslations} = useTranslation("profile");
  const {t: commonT} = useTranslation("common");
  const {domains, keys} = useSelector(({profile}) => profile);
  const dispatch = useDispatch();
  const t = (path) => profileTranslations(`security.${path}`);
  const [visibleKeyPop, setVisibleKeyPop] = useState(null);
  const [visibleModal, setVisibleModal] = useState(null);
  useEffect(() => {
    const onMount = async () => {
      await getSshKeys({id: 23})(dispatch);
      await getClientDomainsRequest()(dispatch);
    };
    onMount();
  }, [dispatch]);

  useEffect(() => {
    if (domains) {
      getListKeysRequest({domains})(dispatch);
    }
  }, [dispatch, domains]);
  return (
    <div className="SecurityPage">
      <SshModal
        show={visibleModal === "register"}
        onHide={() => setVisibleModal(null)}
      />
      <CreateKeyModal
        show={visibleModal === "createKey"}
        onHide={() => setVisibleModal(null)}
        t={t}
      />

      <div className="SecurityPage__info-box flex flex-justify-content-space-between">
        <div className="flex">
          <img src={securityIco} alt=""/>
          <div className="box-content">
            <div>
              <h3 className="name font-weight-regular-important">
                {t("secureAccount.title")}
              </h3>
              <p className="text-grey-1 font-weight-light line-height-1-4">
                {t("secureAccount.description")}
              </p>
            </div>
            <div className="flex-align-self-flex-end">
              <InfoBox
                className="InfoBox__size--small text-align-center"
                text={commonT("coomingSoon")}
              >
                <button disabled className="button button--blue button--sm ">
                  {t("secureAccount.button")}
                </button>
              </InfoBox>
            </div>
          </div>
        </div>
      </div>
      <div className="SecurityPage__row no-bottom-border SecurityPage__sshkey">
        <>
          <h3 className="m-b-10 font-weight-regular-important">
            {t("ssh.title")}
          </h3>
          <div className="flex flex-justify-content-space-between flex-align-items-center ">
            <p>
              {t("ssh.description")}{' '}
              <a href="">{t("ssh.link")}</a>
            </p>
            <div>
            </div>
          </div>
          <div className="line"/>
          <div>
            <div className="list-table ssh m-b-30">
              <div className="list-table__heading">
                <div>{t("history.tableHeads.keyname")}</div>
                <div className="text-align-left hidden-xs">
                  {t("history.tableHeads.fingerprint")}
                </div>
                <div className="max-width-70"/>
              </div>
              <div className="list-table__body">
                {keys.length ? keys.map(({name, fingerprint}, i) => (
                  <>
                    <div key={name} className="list-table__row">
                      <div>{name}</div>
                      <div
                        className="position-relative flex"
                        style={{wordBreak: "break-all"}}
                      >
                        {fingerprint}
                        <CopyToClipboard text={fingerprint}>
                            <span className="copy text-blue-1 m-l-5">
                              {commonT("copy")}
                            </span>
                        </CopyToClipboard>
                      </div>
                      <div className="text-align-center text-blue-1 flex flex-align-items-center max-width-70 more">
                        <a
                          className="lighter font-weight-regular"
                          href="javascript:;"
                          onClick={() =>
                            visibleKeyPop !== i
                              ? setVisibleKeyPop(i)
                              : setVisibleKeyPop(null)
                          }
                        >
                          {commonT("more")}{" "}
                          <span
                            className={`arrow ${
                              visibleKeyPop === i
                                ? "arrow--up"
                                : "arrow--down"
                            } arrow--blue m-l-5`}
                          />
                        </a>
                        {visibleKeyPop !== null && visibleKeyPop === i && (
                          <div className="keyPop popup">
                            <div className="visible-xs visible-xs-flex text-grey-1 font-weight-light fingerPrint">
                              {fingerprint}
                              <CopyToClipboard text={fingerprint}>
                                  <span className="copy text-blue-1">
                                    {commonT("copy")}
                                  </span>
                              </CopyToClipboard>
                            </div>
                            <div
                              className="deleteKey"
                              onClick={() => setVisibleModal("delete")}
                            >
                              {t("ssh.deleteKey")}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )) : <div className="no-ssh">No SSH key added yet.</div>}
                <DeleteModal
                  show={visibleModal === "delete"}
                  name={keys[visibleKeyPop]?.name}
                  t={t}
                  domains={domains}
                  accountid={keys[visibleKeyPop]?.accountid}
                  setVisibleModal={setVisibleModal}
                  onHide={() => {
                    setVisibleModal(null);
                    setVisibleKeyPop(null);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      </div>

    </div>
  );
};

export default Security;
