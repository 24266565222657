import React, { useState } from "react";

import Components from "./components";

const settingsTypes = [
  "size",
  "forwardRules",
  "algorithm",
  "healthChecks",
  "stickySession",
  "ssl",
  "proxyProtocol",
  "backendKeepalive",
  "destroy",
];

const Settings = ({ t }) => {
  const [settings, setSettings] = useState({
    size: { size: "Small", price: 10 },
    forwardRules: {
      from: { protocol: "HTTP", port: 80 },
      to: { protocol: "HTTP", port: 80 },
    },
    algorithm: { value: "Round Robin" },
    healthChecks: { value: "http://0.0.0.0:80/" },
    stickySession: { value: "Off" },
    ssl: { value: "No Redirect" },
    proxyProtocol: { value: "Disabled" },
    backendKeepalive: { value: "Disabled" },
  });

  const [activeComponent, setActiveComponent] = useState(false);

  return (
    <div className="Settings">
      {settingsTypes.map((setting) => {
        const Component = Components[setting];
        return (
          <div
            className="Settings__row flex flex-align-items-center"
            key={setting}
          >
            <div className="text-grey-1 font-size-16 font-weight-semibold min-width-150 m-r-20">
              {t(`settings.${setting}`)}
            </div>
            <Component
              {...{
                settings,
                t,
                type: setting,
                setSettings,
                activeComponent,
                setActiveComponent,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Settings;
