import React from 'react';
import { useTranslation } from 'react-i18next';

const AddNew = ({ className, ...props }) => {
  const { t } = useTranslation('createInstance');
  return (
    <div {...props} className={`AddNew ${className}`}>
      <span>+</span>
      {t('common.addNew')}
    </div>
  );
};

export default AddNew;
