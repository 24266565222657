import { get } from "../../components/http";

// Get client details
export const getClientDetailsReducer = (reducerState, action) => {
  return {
    ...reducerState,
    clientDetails: action.data && action.data.clientDetails,
  };
};

export const getClientDetailsState = { clientDetails: [] };

export const getClientDetailsType = "GET_CLIENT_DETAILS";

export const getClientDetails = (params = {}) => async (dispatch) => {
  let clientDetailResponse;
  try {
    clientDetailResponse = await get("/client/details", { params });
  } catch (error) {
    throw new error(error);
  }
  const { data: { data } = {} } = clientDetailResponse || {};
  dispatch({ type: getClientDetailsType, data: { clientDetails: data } });
};
