import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  getIpAddressesRequest,
  setFloatingIpsLoading
} from '../../../Dashboard/actions';
import ExtraIpList from './ExtraIpList';
import EmptyExtraIP from './EmptyExtraIP';
import AssignExtraIP from './AssignExtraIP';
import Loader from '../../../Volumes/components/Loader/Loader';
import { useLoopFetching } from '../../../../components/hooks/useLoopFetching';
import checkJobStatus from '../../../../helpers/checkJobStatus';
import { useToasts } from 'react-toast-notifications';

const ExtraIps = ({ domainid, underInstance }) => {
  const dispatch = useDispatch();
  const { floatingIpList, floatingIpsLoading } = useSelector(({ instances }) => instances);
  const [isAssignModalOpen, setAssignModalOpen] = useState(false);
  const [filteredIps, setFilteredIps] = useState([]);
  const { t: networkT } = useTranslation('network');
  const t = (path) => networkT(`extraIp.${path}`);

  useEffect(() => {
    getIpAddressesRequest()(dispatch);
  }, []);

  const filteredSourceNatIpsList = useMemo(() => {
    return filteredIps?.filter(ip => ip.issourcenat !== true)
  }, [filteredIps]);

  useEffect(() => {
    const filteredIp = domainid
      ? floatingIpList.filter((ip) => ip.domainid === domainid)
      : floatingIpList;
    setFilteredIps(filteredIp);
  }, [domainid, floatingIpList]);

  const { addToast } = useToasts();
  // TODO CHECK HOW TO WORK FROM MAIN WITH FIRST ACCOUNT ID, NOW HAVE LIMIT FOR FLOATING IPS
  const request = async (data, cb, accountid) => {
    try {
      const result = await checkJobStatus(
        accountid,
        data
      );
      if (result.status === 'success') {
        cancelFetching();
        cb();
      }
      if (result.status === 'error') {
        cancelFetching();
        addToast(t(result.error_message.errortext), {
          appearance: 'error',
          autoDismiss: true
        });
        setAssignModalOpen(false);
        dispatch(setFloatingIpsLoading(false));
      }
    } catch (error) {
      // CATCH FOR CHECK JOB STATUS
      // eslint-disable-next-line no-undef
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      });
      cancelFetching();
      setAssignModalOpen(false);
    }
  };

  const { startFetching, cancelFetching } = useLoopFetching(request);

  const handleExtraIpScreens = () => {
    if (floatingIpsLoading) return <div className='text-align-center p-t-30 p-b-30'><Loader /></div>;

    if (filteredSourceNatIpsList && filteredSourceNatIpsList.length) {
      return <ExtraIpList t={t} floatingIps={filteredIps} underInstance={underInstance} setAssignModalOpen={setAssignModalOpen} filteredSourceNatIpsList={filteredSourceNatIpsList} />
    } else {
      return <EmptyExtraIP t={t} setAssignModalOpen={setAssignModalOpen} />
    }
  }


  return (
    <div>
      {handleExtraIpScreens()}
      {isAssignModalOpen && (
        <AssignExtraIP
          t={t}
          show={isAssignModalOpen}
          onHide={() => {
            setAssignModalOpen(false);
          }}
          startFetching={startFetching}
        />
      )}
    </div>
  );
};

export default ExtraIps;
