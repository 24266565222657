import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../components/Pagination';
import { post } from '../../../../components/http';
import { useToasts } from 'react-toast-notifications';
import { getIpAddressesRequest, getInstancesRequest } from '../../../Dashboard/actions';
import { useLoopFetching } from '../../../../components/hooks/useLoopFetching';
import checkJobStatus from '../../../../helpers/checkJobStatus';
import ExtraIpListItem from './ExtraIpListItem';


const ExtraIpList = ({ t, floatingIps, setAssignModalOpen, filteredSourceNatIpsList }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [expanded, setExpanded] = useState(null);
  const { instances } = useSelector((state) => state.instances);
  const { t: commonT } = useTranslation('common');
  const [currentPage, setCurrentPage] = useState(1);
  const ipsPerPage = 10;

  const assignedInstanceIds = useMemo(() => {
    const ids = [];
    floatingIps?.forEach(({ virtualmachineid }) => {
      if (virtualmachineid) {
        ids?.push(virtualmachineid);
      }
    })
    return ids;
  }, [floatingIps])

  const paginationResult = useMemo(() => {
    const lastIpsIndex = currentPage * ipsPerPage;
    const firstIpsIndex = lastIpsIndex - ipsPerPage;
    return filteredSourceNatIpsList.slice(firstIpsIndex, lastIpsIndex);
  }, [filteredSourceNatIpsList, currentPage]);

  useEffect(() => {
    async function onMount() {
      await getInstancesRequest()(dispatch);
    }
    if (instances === null) {
      onMount();
    }
  }, []); //eslint-disable-line

  const request = async (data, cb, accId) => {
    try {
      const result = await checkJobStatus(accId, data);
      if (result.status === 'success') {
        cancelFetching();
        cb();
      }
      if (result.status === 'error') {
        cancelFetching();
        addToast(t(result.error_message.errortext), {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (error) {
      // CATCH FOR CHECK JOB STATUS
      // eslint-disable-next-line no-undef
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      });
      cancelFetching();
    }
  };

  const handleEnableStaticNat = async (accountId, ipaddressid, virtualmachineid, networkid, vmguestip, setLoading) => {
    try {
      setLoading(true)
      const data = await post(`/accounts/${accountId}/nat/enableStaticNat`, {
        ipaddressid,
        virtualmachineid,
        networkid,
        vmguestip
      });
      if (data.data.error) {
        throw new Error(data.data.error.message);
      }
      getIpAddressesRequest()(dispatch);
      addToast(commonT('success'), {
        appearance: 'success',
        autoDismiss: true
      });
      setLoading(false)
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true
      });
      setLoading(false);
    }
  };

  const handleDisableStaticNat = async (accountId, ipaddressid, setLoading) => {
    try {
      setLoading(true);
      const response = await post(`/accounts/${accountId}/nat/disableStaticNat`, {
        ipaddressid: ipaddressid
      });
      startFetching(
        response.data.data.jobid,
        () => {
          getIpAddressesRequest()(dispatch);
          addToast(commonT('success'), {
            appearance: 'success',
            autoDismiss: true
          });
          setLoading(false);
        },
        accountId
      );
    } catch (error) {
      addToast(t(error.message), {
        appearance: 'error',
        autoDismiss: true
      });
      setLoading(false);
    }
  };

  const handleExtraIpDelete = async (extraIp, setLoading) => {
    try {
      setLoading(true)
      const response = await post(
        `/accounts/${extraIp.accountId}/address/disassociateIpAddress`,
        {
          id: extraIp.id
        }
      );
      startFetching(
        response.data.data.jobid,
        () => {
          getIpAddressesRequest()(dispatch);
          addToast(commonT('success'), {
            appearance: 'success',
            autoDismiss: true
          });
          setLoading(false);
        },
        extraIp.accountId
      );
    } catch (error) {
      addToast(t(error.message), {
        appearance: 'error',
        autoDismiss: true
      });
      setLoading(false)
    }
  };

  const { startFetching, cancelFetching } = useLoopFetching(request);

  return (
    <div>
      <div className="Extra_Ips_header">
        <div className="Description_container">
          <div className="font-weight-regular text-grey-3 font-size-27 m-b-25">{t('title')}</div>
          <div className="text-grey-1 font-size-16">{t('descriptionPart1')}</div>
          <div className="text-grey-1 font-size-16">{t('descriptionPart2')} <span className="font-weight-semibold">{t('descriptionBold')}</span></div>
        </div>
        <button
          className="button button--blue"
          onClick={() => {
            setAssignModalOpen(true);
          }}>
          {t('assignModal.assign')}
        </button>
      </div>
      <h2 className="font-weight-regular text-grey-3 font-size-27">{t('list.title')}</h2>

      {!!floatingIps.length && (
        <>
          <div className="ExtraIP_listing">
            <div className="List_header">
              <div>{t('list.ip')} ({filteredSourceNatIpsList.length})</div>
              <div>{t('list.instance')}</div>
            </div>
            <div className="List_body">
              {paginationResult.map((currentIp, i) => {
                return <ExtraIpListItem
                  key={currentIp.id}
                  assignedInstanceIds={assignedInstanceIds}
                  extraIp={currentIp}
                  i={i}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  instances={instances}
                  t={t}
                  commonT={commonT}
                  handleEnableStaticNat={handleEnableStaticNat}
                  handleDisableStaticNat={handleDisableStaticNat}
                  handleExtraIpDelete={handleExtraIpDelete}
                />
              })}
            </div>
          </div>
          {filteredSourceNatIpsList.length > 10 && (
            <div className="m-t-30 m-b-20">
              <Pagination
                pages={Math.ceil(filteredSourceNatIpsList.length / ipsPerPage)}
                onChange={(page) => {
                  setCurrentPage(page);
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ExtraIpList;
