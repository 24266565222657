import React, { useEffect, useState } from "react";
import Modal from "../../../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getAclRulesListRequest } from "../../../../Dashboard/actions";
import Loader from "../../../../Volumes/components/Loader/Loader";
import DragIcon from "../../../../../assets/images/dragIcon.svg";
import CreateRuleModal from "./AclRules/CreateRuleModal";
import DeleteAclRule from "./AclRules/DeleteAclRule";
import EditAclRule from "./AclRules/EditAclRule";

const AclListModal = ({ show, onHide, aclList, accountid, t, startFetching }) => {
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(null);
  const [activeAclRule, setActiveAclRule] = useState(null);
  const { aclRulesList, aclRulesListLoading } = useSelector(({ instances }) => instances);
  useEffect(() => {
    show && getAclRulesListRequest({ accountid, aclid: aclList.id })(dispatch);
  }, [aclList, show]);
  const isDefaultAcl = aclList?.name === "default_deny" || aclList?.name === "default_allow";
  return (
    <Modal
      size="large"
      Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("aclList.title")}</h2>}
      onHide={onHide}
      show={show}
    >
      <button className="button button--md m-b-40" onClick={() => setVisibleModal("create")}>
        {t("aclList.addRule")}
      </button>
      {aclRulesListLoading ? (
        <Loader />
      ) : (
        <div className="Vpc__acl-rules-list font-size-16">
          {aclRulesList.map(
            ({ id, number, action, cidrlist, protocol, traffictype, startport, endport, icmpcode, icmptype }) => (
              <div className="Vpc__acl-rules-list-item" key={id}>
                <img className="Vpc__acl-rules-list-item-drag" src={DragIcon} />
                <div className="Vpc__acl-rules-list-item-info">
                  <div className="Vpc__acl-rules-list-item-title font-weight-semibold">{t("aclList.number")}</div>
                  <div className="Vpc__acl-rules-list-item-value m-t-10 m-t-10">{number}</div>
                </div>
                <div className="Vpc__acl-rules-list-item-info">
                  <div className="Vpc__acl-rules-list-item-title font-weight-semibold">{t("aclList.cidr")}</div>
                  <div className="Vpc__acl-rules-list-item-value m-t-10">{cidrlist}</div>
                </div>
                <div className="Vpc__acl-rules-list-item-info">
                  <div className="Vpc__acl-rules-list-item-title font-weight-semibold">{t("aclList.action")}</div>
                  <div className="Vpc__acl-rules-list-item-value m-t-10">{action}</div>
                </div>
                <div className="Vpc__acl-rules-list-item-info">
                  <div className="Vpc__acl-rules-list-item-title font-weight-semibold">{t("aclList.protocol")}</div>
                  <div className="Vpc__acl-rules-list-item-value m-t-10">{protocol}</div>
                </div>
                <div className="Vpc__acl-rules-list-item-info">
                  <div className="Vpc__acl-rules-list-item-title font-weight-semibold">{t("aclList.startPort")}</div>
                  <div className="Vpc__acl-rules-list-item-value m-t-10">{startport || icmptype}</div>
                </div>
                <div className="Vpc__acl-rules-list-item-info">
                  <div className="Vpc__acl-rules-list-item-title font-weight-semibold">{t("aclList.endPort")}</div>
                  <div className="Vpc__acl-rules-list-item-value m-t-10">{endport || icmpcode}</div>
                </div>
                <div className="Vpc__acl-rules-list-item-info">
                  <div className="Vpc__acl-rules-list-item-title font-weight-semibold">{t("aclList.trafficType")}</div>
                  <div className="Vpc__acl-rules-list-item-value m-t-10">{traffictype}</div>
                </div>
                <div className="Vpc__acl-rules-list-item-actions">
                  {!isDefaultAcl && (
                    <>
                      {/* <div className="text-grey-1">{t("aclList.tags")}</div> */}
                      {protocol !== "ICMP" && (
                        <div
                          className="text-grey-1 cursor-pointer"
                          onClick={() => {
                            setVisibleModal("editRule");
                            setActiveAclRule(id);
                          }}
                        >
                          {t("aclList.edit")}
                        </div>
                      )}

                      <div
                        className="delete cursor-pointer"
                        onClick={() => {
                          setVisibleModal("deleteRule");
                          setActiveAclRule(id);
                        }}
                      >
                        {t("aclList.delete")}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      )}
      <CreateRuleModal
        show={visibleModal === "create"}
        onHide={() => {
          setVisibleModal(null);
        }}
        aclid={aclList?.id}
        accountid={accountid}
        t={t}
        startFetching={startFetching}
      />
      <DeleteAclRule
        show={visibleModal === "deleteRule"}
        onHide={() => {
          setVisibleModal(null);
        }}
        ruleid={activeAclRule}
        aclid={aclList?.id}
        accountid={accountid}
        t={t}
        startFetching={startFetching}
      />
      <EditAclRule
        show={visibleModal === "editRule"}
        onHide={() => {
          setVisibleModal(null);
        }}
        ruleid={activeAclRule}
        aclid={aclList?.id}
        accountid={accountid}
        aclRulesList={aclRulesList}
        t={t}
        startFetching={startFetching}
      />
    </Modal>
  );
};

export default AclListModal;
