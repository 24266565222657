import React from "react";

import Card from "../../../../../components/Card";

const Kernel = ({ t: dashboardTrans }) => {
  const t = (path) => dashboardTrans(`instance.kernel.${path}`);
  return (
    <div className="flex-grow-1">
      <Card className="m-b-40">
        <Card.Body className="width-full-width flex-direction-column p-l-40 p-r-40">
          <h3 className="font-size-27 text-grey-3 font-weight-regular m-b-10">
            {t("title")}
          </h3>
          <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-10">
            {t("description")}
          </p>
          <a className="m-b-25" href="">
            {t("learnMore")}
          </a>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Kernel;
