import React, { useState, useMemo } from 'react';
import SelectInstance from '../ExtraIps/SelectInstance';
import instancesIco from '../../../../assets/images/create/instances2.svg';
import pretty from "prettysize";
import useComponentVisible from '../../../../helpers/useComponentVisible';
import { useSelector, useDispatch } from 'react-redux';
import { osicons } from '../../../Create/CreateInstance/components/ChooseImage/Tabs/Distributions';
import { icons as regionIcons } from '../../../Create/CreateInstance/components/ChooseLocations';
import { deleteFirewallRuleRequest, getFirewallsRequest } from '../../../Dashboard/actions'; //eslint-disable-line
import { useToasts } from "react-toast-notifications";
import UnassignIco from '../../../../assets/images/network/unassign_icon.svg';
import Loader from '../../../Volumes/components/Loader/Loader';
import { createRule } from './CreateOrEditFirewall';

const FirewallInstances = ({ rules, usedInstacesForRules, setUsedInstances, t, groupId }) => {
    const [expanded, setExpanded] = useState(false);
    const [unassigning, setUnassigning] = useState(null);
    const [assigning, setAssigning] = useState(false);
    const [searchText, setSearchText] = useState("");
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(null, 'more');
    const { floatingIpList, instances } = useSelector(({ instances }) => instances);

    const handleOptionsSelect = (value) => {
        setExpanded(value)
    }

    const instancesSearchList = useMemo(() => {
        if (floatingIpList && floatingIpList.length) {
            const assignedExtraIPs = [];

            floatingIpList.forEach(ip => {
                if (ip.virtualmachineid) {
                    assignedExtraIPs.push(ip.virtualmachineid);
                }
            });

            if ((assignedExtraIPs && assignedExtraIPs.length) && (instances && instances.length)) {
                return instances.filter(instace => assignedExtraIPs.includes(instace.id));
            }
        }

        return [];
    }, [instances, floatingIpList]);

    const filteredVMs = useMemo(() => {
        const usedInstancesIds = usedInstacesForRules.map(instance => { return instance.id });
        const filteredList = instancesSearchList.filter(instance => !usedInstancesIds.includes(instance.id));

        if (searchText) {
            return filteredList.filter(instance => instance.name.toLowerCase().includes(searchText.toLowerCase()));
        }

        return filteredList;
    }, [instancesSearchList, searchText, usedInstacesForRules]);

    const handleAssign = async (instance) => {
        setAssigning(true);
        try {
            const selectedInstancesCopy = [...usedInstacesForRules];
            setUsedInstances([...selectedInstancesCopy, instance]);
            setAssigning(usedInstacesForRules.length + 1);
            if (groupId) {
                const choosenIpId = rules[0].ipaddressid;
                const extraIp = floatingIpList.find(extraIp => extraIp.virtualmachineid === instance.id);
                for (const rule of rules) {
                    if (rule.ipaddressid === choosenIpId) {
                        const newRule = { ...rule, ipaddressid: choosenIpId }
                        await createRule(newRule, groupId, extraIp, dispatch);
                    }
                }
                await getFirewallsRequest()(dispatch);
                addToast(t('firewallInstances.assignSuccess'), {
                    appearance: "success",
                    autoDismiss: true
                });
            }
            setAssigning(false);
        } catch (error) {
            setAssigning(false);
            return addToast(error.message, {
                appearance: "error",
                autoDismiss: true
            });
        }
    }

    const handleUnassign = async (instanceId, index) => {
        setUnassigning(index);
        try {
            setIsComponentVisible(null);
            if (groupId) {
                const extraIp = floatingIpList.find(extraIp => extraIp.virtualmachineid === instanceId);
                for (const rule of rules) {
                    if (rule.ipaddressid === extraIp.id) {
                        await deleteFirewallRuleRequest({ accountId: extraIp.accountId, ruleId: rule.id })(dispatch);
                    }
                }
                await getFirewallsRequest()(dispatch);
                addToast(t('firewallInstances.unassignSuccess'), {
                    appearance: "success",
                    autoDismiss: true
                });
            }
            setUsedInstances((prev) => prev.filter((instance) => instance.id !== instanceId));
            setUnassigning(null);
        } catch (error) {
            setUnassigning(null);
            return addToast(error.message, {
                appearance: "error",
                autoDismiss: true
            });
        }
    }

    const getExtraIp = (instance) => {
        const extraIp = floatingIpList.find(extraIp => extraIp.virtualmachineid === instance.id);

        return extraIp?.ipaddress;
    }

    const getOsIcon = (instance) => {
        let osKey;
        Object.keys(osicons).forEach((key) => {
            if (instance.templatename.toLowerCase().includes(key.toLowerCase())) {
                osKey = key;
            }
        });

        return osicons[`${osKey}`];
    }

    const getRegionIcon = (instance) => {
        let regionKey;
        Object.keys(regionIcons).forEach((key) => {
            if (instance.zonename.toLowerCase().includes(key.toLowerCase())) {
                regionKey = key;
            }
        });

        return regionIcons[`${regionKey}`];
    }

    return (
        <div className='Instances_tab'>
            <SelectInstance
                instances={filteredVMs}
                expanded={expanded}
                setSelectedInstance={handleAssign}
                handleOptionsSelect={handleOptionsSelect}
                setSearchText={setSearchText}
                t={t}
                SearchClassName="Search_section"
                placeholder={t('firewallInstances.placeholder')}
            />
            {usedInstacesForRules && usedInstacesForRules.length ?
                <div className='Selected_VM_list'>
                    <div className='List_head'>
                        <div>{t('firewallInstances.instance')} ({usedInstacesForRules.length})</div>
                        <div>{t('firewallInstances.publicIp')}</div>
                        <div>{t('firewallInstances.os')}</div>
                        <div>{t('firewallInstances.region')}</div>
                    </div>
                    <div className='List_body'>
                        {usedInstacesForRules.map((instance, i) => {
                            return (
                                <div className='List_item' key={instance.id}>
                                    <div className="flex item">
                                        <div className="Instance position-relative m-r-14">
                                            <img src={instancesIco} />
                                            <span className={`status ${(instance?.state === 'Running' && 'Instance__active') ||
                                                (['Starting', 'Stopping'].includes(instance?.state)
                                                    ? 'Instance__pending'
                                                    : 'Instance__inactive')}`
                                            } />
                                        </div>
                                        <div className="Instance_info_container">
                                            <div className="font-size-15 font-weight-semibold text-grey-3">{instance?.displayname}</div>
                                            <div className="font-size-12 font-weight-semibold text-grey-8 Instance_info">
                                                <span>{instance?.cpunumber} {t('list.cpu')}</span>
                                                <span>{pretty(instance?.memory * 1024 * 1024)} {t('list.ram')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>{getExtraIp(instance)}</div>
                                    <div><img src={getOsIcon(instance)} width={25} height={25} /></div>
                                    <div><img src={getRegionIcon(instance)} width={38} height={28} /> {instance.zonename}</div>
                                    {(unassigning === null && assigning === false && usedInstacesForRules.length > 1) ? <div className="text-align-center text-blue-1 flex flex-align-items-center more position-relative">
                                        <a
                                            className="lighter font-weight-semibold font-size-16"
                                            href="javascript:;"
                                            id='more'
                                            onClick={() => {
                                                isComponentVisible !== i ? setIsComponentVisible(i) : setIsComponentVisible(null);
                                            }}>
                                            {t('firewallInstances.more')}
                                            <span
                                                className={`arrow fomt-weight-semibold ${isComponentVisible === i ? 'arrow--up' : 'arrow--down'
                                                    } arrow--blue m-l-5`}
                                            />
                                        </a>
                                        {isComponentVisible !== null && isComponentVisible === i && (
                                            <div ref={ref} className="keyPop popup font-size-14 font-weight-semibold text-grey-1 List_popup popup_menu">
                                                <div onClick={() => { handleUnassign(instance.id, i) }}>
                                                    <img src={UnassignIco} />
                                                    {t('firewallInstances.unassign')}
                                                </div>
                                            </div>
                                        )}
                                    </div> : <div></div>}
                                    {unassigning === i ? <div className="font-size-16 font-weight-semibold text-grey-8 flex flex-align-items-center">{t('list.unassign')} <Loader className="Loader_grey" /></div> : null}
                                    {(assigning && i === usedInstacesForRules.length - 1) ? <div className="font-size-16 font-weight-semibold text-grey-8 flex flex-align-items-center">{t('list.assign')} <Loader className="Loader_grey" /></div> : null}
                                </div>
                            )
                        })}
                    </div>
                </div> : null}
        </div>
    )
}

export default FirewallInstances;
