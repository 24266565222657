export default {
  title: "Create Firewall",
  button: "Create Firewall",
  Name: {
    title: "Firewall Name",
    placeholder: "Enter a firewall name",
  },
  InboundRules: {
    title: "Inbound Rules",
    description:
      "In this section you can set the Firewall rules for incoming traffic. Only the specified ports will accept inbound connections. All other traffic will be dropped.",
    noIps:'No Public NAT IP available'
  },
  OutboundRules: {
    title: "Outbound Rules",
    description:
      "Here you can set the Firewall rules for outbound traffic. Outbound traffic will only be allowed to the specified ports. All other traffic will be blocked.",
    noNetworks:'No network available'

  },
  AddInstances: {
    title: "Apply firewall to instances",
    description: "Select instances to apply your Firewall rules to.",
    placeholder: "Search for tags or instances",
  },
  type: "Type",
  protocol: "Protocol",
  portRange: "Port Range",
  sources: "Sources",
  destinations: "Destinations",
  createNewRule: "Create new rule",
  createRule: "Create rule",
  delete: "delete",
  add: "+ Add",
  done: "Done",
  instancePlaceholder: "Search for instances, Balancers, VPCs, et",
  startPort: "Start Port/ICMP Type",
  endPort: "End Port/ICMP Code",
  ipAddress: "IP Adress",
  ipAddressError: "IP is required!",
  portError: "Ivalid Port Value. Valid values is 1-65535",
  icmpError: "Invalid ICMP/Type or ICMP/Code value. Valid values is 1-14",
  cidrError: "CIDR must be valid to IPv4",
  networkError:"Network is required!",
  success: "Rule was added successfully",
  exitTitle: "Back to Firewall tab",
  exitText:
    "If a created rule was not added, you can cancel this and add the rule before you close this page.",
  exitBack: "Back to firewalls",
  exitClose: "Close",
  sourceCidr: "Source CIDR",
  destinationCidr: "Destination CIDR",
  networkId: "Network ID",
};
