import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../../../../../../components/Card';
import ubuntuIcon from '../../../../../../assets/images/chooseImage/ubuntu-icon.svg';
import freeBsdIcon from '../../../../../../assets/images/chooseImage/freebsd-icon.svg';
import fedoraIcon from '../../../../../../assets/images/chooseImage/fedora-icon.svg';
import debianIcon from '../../../../../../assets/images/chooseImage/debian-icon.svg';
import centosIcon from '../../../../../../assets/images/chooseImage/centos-icon.svg';
import windowsIcon from '../../../../../../assets/images/chooseImage/windows-icon.svg';
import rockyIcon from '../../../../../../assets/images/chooseImage/rocky-icon.svg';
import almaIcon from '../../../../../../assets/images/chooseImage/almalinux-icon.svg';
import ubuntuIconActive from '../../../../../../assets/images/chooseImage/ubuntu-icon-white.svg';
import freeBsdIconActive from '../../../../../../assets/images/chooseImage/freebsd-icon-white.svg';
import fedoraIconActive from '../../../../../../assets/images/chooseImage/fedora-icon-white.svg';
import debianIconActive from '../../../../../../assets/images/chooseImage/debian-icon-white.svg';
import centosIconActive from '../../../../../../assets/images/chooseImage/centos-icon-white.svg';
import windowsIconActive from '../../../../../../assets/images/chooseImage/windows-icon-white.svg';
import rockyIconActive from '../../../../../../assets/images/chooseImage/rocky-icon-white.svg';
import almaIconActive from '../../../../../../assets/images/chooseImage/almalinux-icon-white.svg';


import { getTemplatesRequest } from '../../../../actions';
import InfoBox from "../../../../../../components/InfoBox";

export const osicons = {
  CentOS: centosIcon,
  Ubuntu: ubuntuIcon,
  Windows: windowsIcon,
  Debian: debianIcon,
  Fedora: fedoraIcon,
  OpenBsd: freeBsdIcon,
  FreeBsd: freeBsdIcon,
  RockyLinux: rockyIcon,
  AlmaLinux: almaIcon,
};

export const osiconsActive = {
  CentOS: centosIconActive,
  Ubuntu: ubuntuIconActive,
  Windows: windowsIconActive,
  Debian: debianIconActive,
  Fedora: fedoraIconActive,
  OpenBsd: freeBsdIconActive,
  FreeBsd: freeBsdIconActive,
  RockyLinux: rockyIconActive,
  AlmaLinux: almaIconActive,
};

export const osMinMemory = {
  Ubuntu: {
    "22.04": 2048,
  },
  RockyLinux: {
    8: 2048,
    9: 2048,
  },
  AlmaLinux: {
    8: 2048,
  },
  Windows: 4096,
  "Windows Server": 4096,
}

export const osNames = ['Debian', 'CentOS', 'Fedora', 'Ubuntu', 'RockyLinux', 'Windows Server', 'Windows', 'FreeBSD', 'AlmaLinux' ];

const Distributions = ({ createInstanceAction, dispatch, state }) => {
  const [showVersions, setShowVersions] = useState(null);
  const { osdistributions, selectedRegion, pricingPlan } = useSelector((products) => products.products);

  const memory = Number(pricingPlan?.memory);

  const osDistributionsGroupedByVersion = osdistributions?.filter(({host, is_featured, is_public}) => selectedRegion?.host === host && is_featured && is_public).reduce((acc, el) => {
    osNames.forEach(name => {
      const [elName, version] = el?.name?.split('-')

      if (elName.toLowerCase() === name.toLowerCase()) {
        acc[elName] = [...(acc[elName] || []), {...el, version: version.trim()
      }]
      }
    })
    return acc
  }, {});

  const osdistributionsWithIcons = Object.keys(osDistributionsGroupedByVersion).map((key) => {
    const [iconKey] = Object.keys(osicons).filter(oskey => key.toLowerCase().includes(oskey.toLowerCase()))
    return {
      name: key,
      versions: osDistributionsGroupedByVersion[key],
      icon: osicons[iconKey],
      activeIcon: osiconsActive[iconKey]
    };
  });

  const reduxDispatch = useDispatch();
  useEffect(() => {
    const onMount = async () => {
      await getTemplatesRequest()(reduxDispatch);
    };
    onMount();
  }, []);

  const handleClick = (name, acs_id, osName) => () => {
    const isWindowsOS = name?.toLowerCase().includes('windows');
    const isWindows11 = name?.toLowerCase().includes('windows-11');
    createInstanceAction({ templateid: acs_id, isWindowsOS, isWindows11, osName })(dispatch);
  };

  // const selectedImage = osdistributionsWithIcons.filter(({versions}) => versions.map(({acs_id}) => acs_id).includes(state.createInstance.templateid));

  return (
    <div className="Distributions">
      <div className="flex row flex-mobile-column hidden-xs hidden-xs-flex">
        {osdistributionsWithIcons
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ name, versions, icon, activeIcon}) =>  (
            <div className="position-relative" key={name}>
              {(typeof osMinMemory[name] === "number" && osMinMemory[name] > memory) && <InfoBox sm classNameBox={'m-l-10 Distributions__info'}
                        text={'For newer Linux versions, please select a plan with a minimum of 2GB RAM. For Windows, a package with at least 4GB RAM is required.'}/>}
            <Card
              className={`${(typeof osMinMemory[name] === "number" && osMinMemory[name] > memory) && 'grey-out'}`}>
              <Card.Body
                onClick={() => setShowVersions(showVersions === name ? null : name)}
                className={`text-grey-2 flex-column font-weight-semibold ${
                  versions.map(({acs_id}) => acs_id).includes(state.createInstance.templateid) ? 'active' : ''
                }`}>
                <div className={"width-full-width"}>
                  <div className={"flex flex-align-items-center flex-grow-1 Distributions__box"}>
                    <img className="m-r-15" src={versions.map(({acs_id}) => acs_id).includes(state.createInstance.templateid) ? activeIcon : icon} alt="" />
                    <div>
                      {name}
                      <p>
                        {versions.map(({acs_id}) => acs_id).includes(state.createInstance.templateid) ? versions.filter(({acs_id}) => acs_id === state.createInstance.templateid)[0].version  : 'Select version'}
                        <span className={`arrow arrow--${showVersions === name ? 'up' : 'down'} m-l-5`}></span>
                      </p>
                    </div>
                  </div>
                  {showVersions === name ? <div className={"Distributions__version"}>
                    {versions.map(({name: versionName, acs_id, version}) => {
                      return <div key={acs_id} className={(osMinMemory[name] && typeof osMinMemory[name][version] === "number" && osMinMemory[name][version] > memory) && 'flex flex-align-items-center'}>
                        <div className={`${(osMinMemory[name] && typeof osMinMemory[name][version] === "number" && osMinMemory[name][version] > memory) && 'grey-out'}`} onClick={() => {
                        handleClick(versionName, acs_id, name)()
                        setShowVersions(null)
                      }}>
                        {version}
                      </div>
                      {(osMinMemory[name] && typeof osMinMemory[name][version] === "number" && osMinMemory[name][version] > memory) && 'grey-out' && <InfoBox sm classNameBox={'m-l-10'} text={'For newer Linux versions, please select a plan with a minimum of 2GB RAM. For Windows, a package with at least 4GB RAM is required.'} />}
                      </div>
                    })}
                  </div> : ""}
                </div>
              </Card.Body>
            </Card>
            </div>
          ))}
      </div>

      <div className="row visible-xs">
        <Slider
          {...{
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerPadding: 50,
                  centerMode: true,
                  arrows: false
                }
              }
            ]
          }}>
          {osdistributionsWithIcons
            .map(({ name, versions, icon, activeIcon}) => (
            <div className="position-relative" key={name}>
              {(typeof osMinMemory[name] === "number" && osMinMemory[name] > memory) &&
                <InfoBox sm classNameBox={'m-l-10 Distributions__info'}text={'For newer Linux versions, please select a plan with a minimum of 2GB RAM. For Windows, a package with at least 4GB RAM is required.'}/>}
              <Card
                key={name}
                className={`${(typeof osMinMemory[name] === "number" && osMinMemory[name] > memory) && 'grey-out'}`}>
                <Card.Body
                  className={`text-grey-2 flex-column font-weight-semibold ${
                    versions.map(({acs_id}) => acs_id).includes(state.createInstance.templateid) ? 'active' : ''
                  }`}>
                  <div className={"width-full-width"}>
                    <div className={"flex flex-align-items-center flex-grow-1 Distributions__box"}>
                      <img className="m-r-10" src={versions.map(({acs_id}) => acs_id).includes(state.createInstance.templateid) ? activeIcon : icon} alt="" />
                      <div>
                        {name}
                        <p onClick={() => setShowVersions(showVersions === name ? null : name)}>
                          {versions.map(({acs_id}) => acs_id).includes(state.createInstance.templateid) ? versions.filter(({acs_id}) => acs_id === state.createInstance.templateid)[0].version  : 'Select version'}
                          <span className={`arrow arrow--${showVersions === name ? 'up' : 'down'} m-l-5`}></span>
                        </p>
                      </div>
                    </div>
                    {showVersions === name ? <div className={"Distributions__version"}>
                      {versions.map(({name: versionName, acs_id, version}) => {
                        return <div onClick={() => {
                          handleClick(versionName, acs_id, name)()
                          setShowVersions(null)
                        }} key={acs_id}>{version}</div>
                      })}
                    </div> : ""}
                  </div>
                </Card.Body>
              </Card>
            </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default Distributions;
