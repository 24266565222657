import React from "react";

import creditIco from "../../../assets/images/credit-card-white.svg"

const AccountBalance = ({credit, action}) => {
  return (
      <div className={"AccountBalance"}>
        <div className={"flex flex-justify-content-space-between"}>
          <div>
            <h3>Account Balance</h3>
            <span className={"price"}>$ {credit}</span>
          </div>
          <button onClick={action} className={"button button--blue flex flex-align-items-center"}>
            <img className={`m-r-10`} src={creditIco} alt=""/>
            Recharge account
          </button>
        </div>
      </div>
    )

}

export default AccountBalance
