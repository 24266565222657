import React, { useState } from 'react';
import Checkbox from '../../../../components/Checkbox';
import CustomField from '../../../../components/CustomField';
import CustomSelect from '../../../../components/CustomSelect';

const GenerateIp = ({ t }) => (
  <div>
    <p className="text-grey-1 font-size-16 m-t-25 m-b-25">{t('llBeGenerated')}</p>
  </div>
);
const OwnIp = ({ t }) => (
  <div className="flex row m-t-10 m-b-10">
    <div className="p-l-15 p-r-15 flex-grow-1 max-width-350">
      <CustomField className="Field--sm">
        <label>
          <h4 className="text-grey-1 fomt-weight-semibold font-size-12 m-b-10">
            {t('addressRangeLabel')}
          </h4>
          <input placeholder={t('addressRangePlaceholder')} />
        </label>
      </CustomField>
    </div>
    <div className="p-l-15 p-r-15 flex-grow-1 max-width-350">
      <h4 className="text-grey-1 fomt-weight-semibold font-size-12 m-b-10 flex flex-align-items-center flex-justify-content-space-between">
        {t('networkSize')}
        <a href="">{t('moreAbout')}</a>
      </h4>
      <CustomSelect className="Field--sm" options={[]} />
    </div>
  </div>
);

const ConfigurePrivateIpRange = ({ t }) => {
  const [ipType, setIpType] = useState('generateIp');
  const components = { generateIp: GenerateIp, ownIp: OwnIp };
  const ActiveComponent = components[ipType];
  return (
    <div>
      <h3 className="font-size-27 text-grey-2 font-weight-regular m-b-20 m-t-40 ">{t('title')}</h3>
      <p className="text-grey-1 font-size-16 m-t-25 m-b-25">{t('description')}</p>
      <div className="flex row flex-mobile-column">
        <div className="p-l-15 p-r-15 flex-grow-1 width-half">
          <label
            className={`auth-box flex flex-grow-1 flex-justify-content-space-between ${
              ipType === 'generateIp' && 'active'
            }`}
          >
            <div className="flex">
              <Checkbox
                className="Checkbox--full-box m-r-10"
                checked={ipType === 'generateIp'}
                onChange={() => setIpType('generateIp')}
                type="radio"
                value="generateIp"
                name="ip"
              />
              <div>
                <div className="text-grey-6 font-size-16 font-weight-semibold m-b-5">
                  {t('generateIp')}
                  <span className="badge m-l-5">{t('recommended')}</span>
                </div>
                <div className="text-grey-1 font-size-16">{t('generateIpDescription')}</div>
              </div>
            </div>
          </label>
        </div>
        <div className="p-l-15 p-r-15 flex-grow-1 width-half">
          <label
            className={`auth-box flex flex-grow-1 flex-justify-content-space-between ${
              ipType === 'ownIp' && 'active'
            }`}
          >
            <div className="flex">
              <Checkbox
                className="Checkbox--full-box m-r-10"
                checked={ipType === 'ownIp'}
                onChange={() => setIpType('ownIp')}
                value="ownIp"
                type="radio"
                name="ip"
              />
              <div>
                <div className="text-grey-6 font-size-16 font-weight-semibold m-b-5">
                  {t('ownIp')}
                </div>
                <div className="text-grey-1 font-size-16">{t('ownIpDescription')}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <ActiveComponent t={t} />
    </div>
  );
};

export default ConfigurePrivateIpRange;
