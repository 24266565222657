import React, {useEffect, useMemo, useState} from "react";
import pretty from "prettysize";

import Card from "../../../../../components/Card";
import Checkbox from "../../../../../components/Checkbox";
import settingsIco from "../../../../../assets/images/choosePlan/settings.svg";
import Tabs from "../../../../../components/Tabs";
import {useDispatch, useSelector} from "react-redux";
import {getLocationsRequest, getProducts} from "../../../../Create/actions";
import {getVolumesRequest} from "../../../../Volumes/actions";
import {resizeRequest} from "../../../actions";
import Button from "../../../../../components/Button";
import reqWithJobId from "../../../../../helpers/reqWithJobId";

const tabs = [
  { name: "allTypes" },
  { name: "standard", cpuType: "shared", short: "s-" },
  { name: "memoryOptimized", cpuType: "dedicated", short: "m-" },
  { name: "cpuOptimized", cpuType: "dedicated", short: "c-", isNew: true },
];

const planNaming = {
  Standard: "S-",
  "Cpu-Optimized": "C-",
  "Memory-Optimized": "M-"
}


const cpuType = {
  "S-": "Shared",
  "C-": "Dedicated",
  "M-": "Dedicated",
}

const Resize = ({ t: dashboardTrans, instantState, instanceId, accountid: accountId, zoneid, tags = [] }) => {
  const t = (path) => dashboardTrans(`instance.resize.${path}`);
  const [boot, setBoot] = useState("ram");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("allTypes");
  const [selectedInstance, setSelectedInstance] = useState();
  const [refetchVolumes, setRefetchVolumes] = useState(0);
  const dispatch = useDispatch();

  const {value: activeProduct} = tags?.find(({key}) => key === "service_type")

  const { volumes = [] } = useSelector(({ volumes }) => volumes);
  const {products, locations} = useSelector(
    ({ products }) => products
  );

  useEffect(() => {
    const onMount = async () => {
      await getLocationsRequest()(dispatch)
    }
    onMount()
  }, [zoneid])

  useEffect(() => {
    const onMount = async () => {
      await getVolumesRequest()(dispatch);
    }
    onMount()
  }, [zoneid, refetchVolumes])

  const [zone] = locations.filter(({acs_id}) => acs_id === zoneid)

  useEffect(() => {
    const onMount = async () => {
      const {short: name} = tabs.find(({name}) => name === activeTab)
      await getProducts({
      params: {
        name,
        zoneid: zone?.id
      }
    })(dispatch)
    }
    if (zone?.id) {
      onMount()
    }
  }, [zone, activeTab])

  const [volume] = useMemo(() =>
    [...(volumes || [])].filter(({ virtualmachineid }) => virtualmachineid === instanceId), [instanceId, volumes]);


  const resizeInstance = async () => {
    const product = products.find(({id}) => id === selectedInstance)
    setLoading(true)
    try {
      await reqWithJobId(resizeRequest({instanceId, accountId, volumeId: volume?.id, product, boot, host: zone?.host})(dispatch), null, null, true)
    } catch (e) {
      console.log(e) //eslint-disable-line
    }
    setRefetchVolumes(refetchVolumes+1)
    setLoading(false)
  }

  return (
    <div className="Resize flex-grow-1">
      <Card className="m-b-40">
        <Card.Body className="width-full-width flex-direction-column p-l-40 p-r-40">
          <h3 className="font-size-27 text-grey-3 font-weight-regular m-b-10">
            {t("title")}
          </h3>
          <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-20">
            {t("description")}
          </p>
          <div className="flex row flex-mobile-column">
            <div className="p-l-15 p-r-15 flex-grow-1 width-half">
              <label
                className={`auth-box flex flex-grow-1 p-t-20 p-b-20 flex-justify-content-space-between`}
              >
                <div className="flex">
                  <Checkbox
                    className="Checkbox--full-box m-r-10"
                    checked={boot === "ram"}
                    onChange={() => setBoot("ram")}
                    type="radio"
                    value="ram"
                    name="boot"
                  />
                  <div>
                    <div className="text-grey-6 font-size-16 font-weight-semibold m-b-5">
                      {t("ramLabel")}
                    </div>
                    <div className="text-grey-1 font-size-16">
                      {t("ramDescription")}
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <div className="p-l-15 p-r-15 flex-grow-1 width-half">
              <label
                className={`auth-box flex flex-grow-1 p-t-20 p-b-20 flex-justify-content-space-between`}
              >
                <div className="flex">
                  <Checkbox
                    className="Checkbox--full-box m-r-10"
                    checked={boot === "disk"}
                    onChange={() => setBoot("disk")}
                    value="disk"
                    type="radio"
                    name="boot"
                  />
                  <div>
                    <div className="text-grey-6 font-size-16 font-weight-semibold m-b-5">
                      {t("diskLabel")}
                    </div>
                    <div className="text-grey-1 font-size-16">
                      {t("diskDescription")}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className="flex flex-align-items-center flex-justify-content-space-between m-t-20 m-b-20">
            <p className="m-t-0 m-b-0 text-grey-1">
              {t("currentUsing")} <span className="font-weight-semibold">Standard / 1 GB / 1 vCPU</span>
            </p>
            <span className="flex flex-align-items-center text-blue-1 ">
              {t("helpChoose")}
              <img className="m-l-10" src={settingsIco} />
            </span>
          </div>
          <Tabs>
            <Tabs.Header>
              <ul className="flex-align-items-flex-end">
                {tabs.map(({ name, isNew }) => (
                  <li key={name}>
                    <a
                      href="javascript:;"
                      onClick={() => setActiveTab(name)}
                      className={`flex flex-align-items-center ${activeTab === name && "active"
                        }`}
                    >
                      {t(`tabs.${name}`)}
                      {isNew && (
                        <span className="badge m-l-5 font-size-10">
                          {t(`tabs.new`)}
                        </span>
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </Tabs.Header>
            <Tabs.Content>
              <div className="list-table">
                <div className="list-table__heading font-size-12">
                  <div className="max-width-100"></div>
                  <div className="min-width-150">{t("table.type")}</div>
                  <div className="min-width-150">{t("table.cpuType")}</div>
                  <div className="max-width-100">{t("table.vcpus")}</div>
                  <div className="max-width-100">{t("table.memory")}</div>
                  <div className="max-width-100">{t("table.ssd")}</div>
                  <div className="max-width-100">{t("table.transfer")}</div>
                  <div className="">{t("table.price")}</div>
                </div>
                <div className="list-table__body font-size-15">
                  {products.map(
                    (product) => {
                      const {
                        id,
                        name,
                        cpu: vcpus,
                        memory,
                        storage: ssd,
                        bandwidth: transfer,
                        price_per_month:price,
                        price_per_hour: pricePerHour,
                      } = product;

                      const type = Object.keys(planNaming)[Object.values(planNaming)?.indexOf(`${name.split("-")[0]}-`)] || []
                      const [volumeSize] = pretty(volume?.size).split(" ")
                      console.log(activeProduct , name) // eslint-disable-line

                      return (
                        <label
                          key={id}
                          className={`
                            list-table__row ${(selectedInstance ? selectedInstance === id : activeProduct === name) && "active"}
                            ${boot === "disk" && Number(volumeSize) > Number(ssd) ? "grey-out" :  ""}
                          `}
                        >
                          <div className="max-width-70 m-l-30">
                            <Checkbox
                              key={id}
                              className="Checkbox--full-box"
                              checked={(selectedInstance ? selectedInstance === id : activeProduct === name)}
                              onChange={(e) =>
                                setSelectedInstance(Number(e.target.value))
                              }
                              type="radio"
                              value={id}
                              name="instance"
                            />
                          </div>
                          <div className="min-width-150">{type}</div>
                          <div className="min-width-150">{cpuType[`${name.split("-")[0]}-`]}</div>
                          <div className="max-width-100">{vcpus}</div>
                          <div className="max-width-100">{pretty(memory * 1024 * 1024)}</div>
                          <div className="max-width-100">{ssd}</div>
                          <div className="max-width-100">{transfer}</div>
                          <div>
                            <div className="font-weight-medium text-blue-1">${price}/mo</div>
                            <div className="">${pricePerHour}/hr</div>
                          </div>
                        </label>
                      );
                    }
                  )}
                </div>
              </div>
              <p className="flex flex-justify-content-flex-end m-t-40 m-b-20 text-grey-1">
                {t("eachInstance1")} <span className="text-blue-1 m-l-5 m-r-5">{t("eachInstanceBlue")}</span> {t("eachInstance2")}
              </p>
              <Button
                loading={loading}
                disabled={!selectedInstance || instantState !== "Stopped" || !volume}
                className="button button--blue button--sm width-full-width resize-button m-b-20"
                onClick={() => resizeInstance()}
              >
                {t("button")}
              </Button>
              {instantState !== "Stopped" && <p className={"m-b-30 text-grey-1"}>*{t("notStopped")}</p>}
            </Tabs.Content>
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Resize;
