import React from "react";
import Modal from "../../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { deleteVpc, getVpcRequest } from "../../../Dashboard/actions";
import Button from "../../../../components/Button";

const DeleteVpc = ({ onHide, accountid, vpcid, setVisibleModal, show, t, startFetching }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { vpcLoading } = useSelector(({ instances }) => instances);

  const handleDeleteVpc = async () => {
    try {
      const {
        data: {
          data: { jobid }
        }
      } = await deleteVpc({
        accountid,
        vpcid
      })(dispatch);
      startFetching(jobid, () => {
        onHide();
        addToast(t("deleteModal.success"), {
          appearance: "success",
          autoDismiss: true
        });
        getVpcRequest({ accountid })(dispatch);
      });
    } catch (error) {
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  return (
    <div>
      <div>
        <Modal
          Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("deleteModal.title")}</h2>}
          onHide={onHide}
          show={show}
        >
          <div className="flex row">
            <button
              onClick={() => setVisibleModal(null)}
              className="button button--grey width-half flex-grow-1 m-l-15 m-r-15"
            >
              {t("deleteModal.cancel")}
            </button>
            <Button
              loading={vpcLoading}
              onClick={handleDeleteVpc}
              className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15"
            >
              {t("deleteModal.confirm")}
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default DeleteVpc;
