export default {
  title: "Billing and Usage",
  tabs: {
    creditCard: "Credit/Debit Card",
    addCredit: "Add Credit",
    paypal: "Paypal",
    crypto: "Crypto",
    alipay: "Alipay",
    invoices: "Invoices",
    usage: "Usage",
  },
  creditCard: {
    makeCredit: {
      title: "Make a Credit/Debit Card Payment",
      placeholders: {
        fullName: "Your Full Name",
        billingAddress: "Billing Address",
        billingCity: "Billing city",
        billingPostalCode: "Billing Postal Code",
        billingCountry: "Billing Country/Region",
      },
    },
    cardDetails: {
      title: "Credit/Debit Card Details",
      placeholders: {
        cardNumber: "Credit/Debit Card Number",
        date: "MM/YY",
        cvv: "CVV",
      },
      brandsText: "Our payment system currently supports this brands:",
      chooseOne: "Choose one of your linked credit/debit cards from the list",
      addCredit: "Add new credit/debit card",
      existingCards: {
        expires: "Expires",
        default: "default",
        button: "Remove Card",
      },
    },
    chooseDeposit: {
      title: "Add credit",
      selectAmount:
        "1. Select Amount",
      selectMethod: "2. Select Payment Method",
      otherAmount: "or enter your own custom amount",
      fiatButton: "Credit Card / PayPal",
      cryptoButton: "Cryptocurrency",
      tooltip: "Your credit card information will never be sent to shape.host and will be safely stored in Paddle.",
      cryptoTooltip: "Securely charge your shape.host account\n" +
        "using cryptocurrencies.",
    },
    acceptTerms: "I agree to the",
    termsLink: "Terms of Service",
    pay: "Pay",
    withCard: "with card",
    button: "Link Credit/Debit Card",

  },
  billingProfile: {
    title: "Billing Information",
    billingAddress: "Billing Address 1",
    billingAddress2: "Billing Address",
    billingCity: "Billing city",
    billingCountry: "Billing Country/Region",
    billingRegion: "State / Province / Region",
    billingPostalCode: "ZIP / Postal Code",
    companyName: "Company Name",
    phoneNumber: "Phone Number",
    empty: {
      title: "Looks like you have no invoices here yet",
      description:
        "As soon as you will be charged, this area will become populated.",
    },
    tax: {
      title: "Tax ID",
      placeholder: "Add Tax Identification Number",
      button: "Enter Tax ID",
      description:
        "Already registered businesses can provide their tax identification number to further remove tax charges if eligible.",
    },
    button: "Save",
    history: {
      title: "Invoices list",
      description: "Your most recent billing events",
      tableHeads: {
        date: "Created Date",
        id: "Invoice #NO",
        dueDate: "Due Date",
        amount: "Amount",
        method: "Payment Method",
        status: "Status",
        paidDate: "Paid Date: ",
      },
      download: "Download Receipt - ",
      pdf: "PDF",
    },
  },
  paypal: {
    title: "Make a PayPal Payment",
    dollars: "Dollars",
    otherAmount: "Other Amount",
    placeholder: "Enter desired amount",
    acceptTerms: "I agree to the",
    termsLink: "Terms of Service",
    withPaypal: " With paypal",
    pay: "Pay",
  },
  crypto: {
    title: "Make a Crypto Payment",
    description: "You will be transferred to our partner's paying platform,",
    coinPayments: "CoinPayments",
    dollars: "Dollars",
    otherAmount: "Other Amount",
    placeholder: "Enter desired amount",
    acceptTerms: "I agree to the",
    termsLink: "Terms of Service",
    withCrypto: " With CoinPayments",
    pay: "Pay",
  },
  usage: {
    usage: "Used time",
    name: "Instance name",
    bandwidth: "Bandwidth",
    charge: "Price",
    empty: "We could not find any usage records for the selected period. You can choose another period from the date picker."
  },
};
