import React from "react";
import tooltipIcon from "../../../../../assets/images/network/tooltip.svg";

const Toggler = ({ isToggled, onChange, tooltip, text }) => {
  return (
    <div className="toggle flex flex-aling-items-flex-start flex-direction-column m-b-10">
      <div className="flex flex-align-items-flex-start">
        <p className="toggle-text m-t-0 m-b-10 text-grey-1 font-size-16">
          {text}
        </p>
        <div className="position-relative">
          <img src={tooltipIcon} className="m-l-5 toggle-tooltip-ico" />
          <span className="toggle-tooltip">{tooltip}</span>
        </div>
      </div>
      <label className="toggle-switch">
        <input type="checkbox" checked={isToggled} onChange={onChange} />
        <span className="switch" />
      </label>
    </div>
  );
};

export default Toggler;
