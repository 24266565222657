import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import profile from "./pages/Profile/actions";
import products from "./pages/Create/actions";
import instances from "./pages/Dashboard/actions";
import volumes from "./pages/Volumes/actions";
import billing from "./pages/Billing/billingActions";
import support from "./pages/Tickets/actions";

const reducer = combineReducers({
  profile,
  products,
  instances,
  volumes,
  billing,
  support,
});
const store = configureStore({
  reducer,
});
export default store;
