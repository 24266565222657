import React from 'react';
import { icons } from '../../../Create/CreateInstance/components/ChooseLocations';
import NetworkListItem from './NetworkListItem';

const NetworksWithLocation = ({ networks, location, t, setDetailsNetwork, instancesbyNetwork, setInstancesbyNetwork, startFetching }) => {
  const filteredNetworks = networks.filter(network => network.zoneid === location.acs_id);

  return <div className='Location_with_networks'>
    <div className='Location_content'>
      <img src={icons[location.country]} width={50} height={35} />
      <div>
        <div className='font-size-16 text-grey-3'>{location.city}, {t(`networks.countries.${location.country}`)}</div>
        <div className='font-size-14 text-grey-1'>{location.name}</div>
      </div>
    </div>
    <div className='Network_list'>
      <div className='List_header'>
        <div>{t('networks.list.name')}</div>
        <div>{t('networks.list.cidr')}</div>
        <div>{t('networks.list.instances')}</div>
        <div>{t('networks.list.state')}</div>
      </div>
      <div className='List_body'>
        {filteredNetworks && filteredNetworks.length && filteredNetworks.map((network, i) =>
          <NetworkListItem
            setInstancesbyNetwork={setInstancesbyNetwork}
            instancesbyNetwork={instancesbyNetwork}
            setDetailsNetwork={setDetailsNetwork}
            startFetching={startFetching}
            city={location.city}
            network={network}
            key={network.id}
            i={i}
            t={t}
          />
        )}
      </div>
    </div>
  </div>
}

export default NetworksWithLocation;
