import React from "react";
import { useTranslation } from "react-i18next";

const NoPtrRecords = ({ t }) => (
  <div className="PtrRecords">
    <div>
      <h2 className="font-weight-regular text-grey-3 font-size-27">
        {t("noPtrRecords.title")}
      </h2>
      <p className="text-grey-1 font-size-16 m-b-0">
        {t("noPtrRecords.description")}{" "}
      </p>
      <p className="text-grey-1 font-size-16 m-t-10">
        {t("noPtrRecords.createOnRename")}{" "}
      </p>
    </div>
  </div>
);

const PtrRecordsList = ({ t, ptrRecords }) => {
  return (
    <div className="Create">
      <h2 className="font-weight-regular text-grey-3 font-size-27">
        {t("title")}
      </h2>
      <p className="text-grey-1 font-size-16 m-b-30">{t("description")} </p>
      <div className="rules font-size-16">
        <div className="rules__head flex">
          <div>{t("ip")}</div>
          <div>{t("name")}</div>
        </div>
        <div className="rules__body ">
          {ptrRecords.map(({ name, ip }) => (
            <div
              key={name}
              className="existingRules flex flex-align-items-center width-full-width text-grey-1"
            >
              <div className="m-t-0 m-b-0">
                <div className="">{ip}</div>
              </div>
              <div className="m-t-0 m-b-0 text-blue-1">{name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const PtrRecords = ({
  ptrRecords = [{ ip: "64.778.223.73", name: "PTR_Record_Name_I" }],
}) => {
  const { t: networkT } = useTranslation("network");
  const t = (path) => networkT(`ptrRecords.${path}`);

  return (
    <div className="PtrRecords">
      {ptrRecords.length ? (
        <PtrRecordsList t={t} ptrRecords={ptrRecords} />
      ) : (
        <NoPtrRecords t={t} />
      )}
    </div>
  );
};

export default PtrRecords;
