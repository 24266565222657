import React, { useState } from "react";
import { v4 } from "uuid";
import { icons } from "../../pages/Create/CreateInstance/components/ChooseLocations";
import pretty from "prettysize";
// import { store } from "./store";

const CustomSelect = ({
  errors = {},
  touched = {},
  Ico,
  name,
  noCustomStyle,
  options = [],
  className,
  ListItem,
  isInstanceSelect,
  defaultValue,
  search = false,
  onChange,
  disabled,
  placeholder,
  ...props
}) => {
  const [selectValue, setValue] = useState(defaultValue ? defaultValue : "");
  const [filterText, setFilterText] = useState("");
  const [id] = useState(v4());
  const [showOptions, setShowOptions] = useState(false);

  const selected = (options || []).find(({ value }) => selectValue === value);

  return (
    <div
      className={`${!noCustomStyle ? "CustomSelect" : undefined} Select Field ${errors[name] && touched[name] && "Field--with-error"
        } ${Ico && "Field--with-ico"} ${className}`}
    >
      <div
        className="Field__content"
        onClick={() => {
          if (!disabled) setShowOptions(showOptions === id ? false : id);
        }}
      >
        {Ico && <Ico />}
        {search ? (
          <>
            {!showOptions && selectValue ? (
              <div className="Field__selected" >
                {isInstanceSelect ?
                  <div className="Instance__picked Field__content flex-justify-content-space-between-important">
                    <div className="Instance__title-ico no-border p-l-0">
                      <span
                        className={`Instance__status Instance__status--${selected.state === "Running" ? "active" : "inactive"
                          }`}
                      />
                      <div className="text-grey-8 font-size-17 font-weight-semibold">
                        {selected.text}
                      </div>
                    </div>
                    <div>
                      <div className="font-size-12 font-weight-semibold text-grey-8">
                        {selected.cpunumber} vCPUs / {pretty(selected.memory * 1024 * 1024)} RAM / {(selected.size / 1073741824).toFixed(2)} GB Disk
                      </div>
                    </div>
                    <div className="flex">
                      <img className="m-r-10" width="28" height="19" src={icons[selected.zonename.split("-")[0]]} />
                      <span className="font-weight-regular font-size-15 text-grey-1">{selected.zonename}</span>
                    </div>
                  </div>
                  : <div className="Field__content">{selected.text}</div>}
              </div>
            ) : (
              <input
                autoFocus
                className={`Field__content`}
                defaultValue={filterText}
                placeholder={placeholder}
                onChange={(e) => {
                  setFilterText(e.target.value);
                  if (!showOptions) {
                    setShowOptions(id);
                  }
                }}
                type="text"
                {...{ props }}
                name={name}
              />
            )}
          </>
        ) : (
          <select value={selectValue} {...props} name={name}>
            {options.map(({ value, text }) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
          </select>
        )}

        {showOptions === id && (
          <div className="Menu_content">
            <ul className="Select__options">
              {options
                .filter(({ text }) => !search || text.includes(filterText))
                .map(({ value, text, ...props }, index) => (
                  <>
                    {ListItem ? (
                      <ListItem
                        className={
                          value ===
                          (props.value || selectValue || options[0].value) &&
                          "active"
                        }
                        onClick={() => {
                          setValue(value);
                          onChange && onChange(value);
                        }}
                        index={index}
                        value={value}
                        text={text}
                        {...props}
                      />
                    ) : (
                      <li
                        className={
                          value ===
                          (props.value || selectValue || options[0].value) &&
                          "active"
                        }
                        onClick={() => {
                          setValue(value);
                          onChange && onChange(value);
                        }}
                        value={value}
                      >
                        {text}
                      </li>
                    )}
                  </>
                ))}
            </ul>
          </div>
        )}
        <div className="error">
          {errors[name] && touched[name] && errors[name]}
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;