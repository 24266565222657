import React from "react";
const leanAboutItems = ["overview", "api", "feedback"];

const LearnAbout = ({ t }) => (
  <div className="Vpc__LearnAbout card border-grey-1 max-width-full-width">
    <h2 className="font-weight-regular text-grey-3 font-size-27 m-b-0 m-t-0">
      {t("learn.title")}
    </h2>
    <div className="flex row">
      {leanAboutItems.map((item) => (
        <div key={item} className="m-r-40 m-l-40">
          <div className="flex flex-align-items-center m-t-20">
            <a className="font-size-16" href="">
              {t(`learn.${item}.link`)}
            </a>
          </div>
          <p className="text-grey-1 font-size-16">
            {t(`learn.${item}.description`)}{" "}
            {item === "overview" && (
              <a href="">{t(`learn.${item}.descriptionLink`)}</a>
            )}
          </p>
        </div>
      ))}
    </div>
  </div>
);

export default LearnAbout;
