import React from "react";
import Modal from "../../../../../../components/Modal";
import { deleteAclRuleRequest, getAclRulesListRequest } from "../../../../../Dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../../../Volumes/components/Loader/Loader";

const DeleteAclRule = ({ onHide, show, ruleid, aclid, accountid, t, startFetching }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { aclRulesListLoading } = useSelector(({ instances }) => instances);

  return (
    <Modal
      size="large"
      Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">{t("deleteAclRule.title")}</h2>}
      onHide={onHide}
      show={show}
    >
      {aclRulesListLoading ? (
        <Loader />
      ) : (
        <div className="flex row">
          <button className="button button--grey width-half flex-grow-1 m-l-15 m-r-15" onClick={() => onHide()}>
            {t("cancel")}
          </button>
          <button
            className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15"
            onClick={async () => {
              try {
                const {
                  data: {
                    data: { jobid }
                  }
                } = await deleteAclRuleRequest({
                  accountid,
                  ruleid,
                  aclid
                })(dispatch);
                startFetching(
                  jobid,
                  () => {
                    onHide();
                    addToast(t("deleteAclRule.success"), {
                      appearance: "success",
                      autoDismiss: true
                    });
                    getAclRulesListRequest({ accountid, aclid })(dispatch);
                  },
                  accountid
                );
              } catch (error) {
                return addToast(error.message, {
                  appearance: "error",
                  autoDismiss: true
                });
              }
            }}
          >
            {t("aclDelete")}
          </button>
        </div>
      )}
    </Modal>
  );
};

export default DeleteAclRule;
