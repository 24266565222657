import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';

import dockerIcon from '../../../../../../assets/images/chooseImage/docker-icon.svg';
import dockerIconWhite from '../../../../../../assets/images/chooseImage/apps/docker-ico-white.svg';
import jsIco from '../../../../../../assets/images/chooseImage/apps/js-ico.svg';
import jsIcoWhite from '../../../../../../assets/images/chooseImage/apps/js-icon-white.svg';
import lempIco from '../../../../../../assets/images/chooseImage/apps/lemp-ico.svg';
import lempIcoWhite from '../../../../../../assets/images/chooseImage/apps/lemp-ico-white.svg';
import lampIco from '../../../../../../assets/images/chooseImage/apps/lamp-ico.svg';
import lampIcoWhite from '../../../../../../assets/images/chooseImage/apps/lamp-ico-white.svg';
import steam from '../../../../../../assets/images/chooseImage/apps/steam.svg';
import steamWhite from '../../../../../../assets/images/chooseImage/apps/steam-white.svg';
import openliteJs from '../../../../../../assets/images/chooseImage/apps/openlite-js.svg';
import openliteJsWhite from '../../../../../../assets/images/chooseImage/apps/openlite-js-white.svg';
import openliteWordPress from '../../../../../../assets/images/chooseImage/apps/openlite-wordpress.svg';
import openliteWordPressWhite from '../../../../../../assets/images/chooseImage/apps/openlite-wordpress-white.svg';
import wordpressIco from '../../../../../../assets/images/chooseImage/apps/wordpress-ico.svg';
import wordpressIcoWhite from '../../../../../../assets/images/chooseImage/apps/wordpress-ico-white.svg';
import dokkuIco from '../../../../../../assets/images/chooseImage/apps/dokku.svg';
import dokkuIcoWhite from '../../../../../../assets/images/chooseImage/apps/dokku-white.svg';
import strapiIcon from '../../../../../../assets/images/chooseImage/apps/strapi.svg';
import strapiIconWhite from '../../../../../../assets/images/chooseImage/apps/strapi-white.svg';
import minecraft from '../../../../../../assets/images/chooseImage/apps/minecraft.svg';
import minecraftWhite from '../../../../../../assets/images/chooseImage/apps/minecraft-white.svg';
import controlPanel from '../../../../../../assets/images/chooseImage/apps/control-panel.svg';
import controlPanelWhite from '../../../../../../assets/images/chooseImage/apps/control-panel-white.svg';
import cloudPanel from '../../../../../../assets/images/chooseImage/apps/cloud-panel.svg';
import cloudPanelWhite from '../../../../../../assets/images/chooseImage/apps/cloud-panel-white.svg';
import wireguard from '../../../../../../assets/images/chooseImage/apps/wireguard.svg';
import wireguardWhite from '../../../../../../assets/images/chooseImage/apps/wireguard-white.svg';
import openvpn from '../../../../../../assets/images/chooseImage/apps/openvpn.svg';
import openvpnWhite from '../../../../../../assets/images/chooseImage/apps/openvpn-white.svg';

import Card from '../../../../../../components/Card';
import {get} from "../../../../../../components/http";
import {useSelector} from "react-redux";
import {osNames} from "./Distributions";

export const appsIcons = [
  {
    name: "node",
    icon: jsIco,
    iconActive: jsIcoWhite,
  },
  {
    name: "docker",
    icon: dockerIcon,
    iconActive: dockerIconWhite,
  },
  {
    name: "lemp",
    icon: lempIco,
    iconActive: lempIcoWhite,
  },
  {
    name: "lamp",
    icon: lampIco,
    iconActive: lampIcoWhite,
  },
  {
    name: "steam",
    icon: steam,
    iconActive: steamWhite,
  },
  {
    name: "OpenLiteSpeed - Node.js",
    icon: openliteJs,
    iconActive: openliteJsWhite,
  },
  {
    name: "wordpress",
    icon: wordpressIco,
    iconActive: wordpressIcoWhite,
  },
  {
    name: "WordPress - OpenLiteSpeed",
    icon: openliteWordPress,
    iconActive: openliteWordPressWhite,
  },
  {
    name: "dokku",
    icon: dokkuIco,
    iconActive: dokkuIcoWhite,
  },
  {
    name: "strapi",
    icon: strapiIcon,
    iconActive: strapiIconWhite,
  },
  {
    name: "cPanel & WHM",
    icon: controlPanel,
    iconActive: controlPanelWhite,
  },
  {
    name: "minecraft",
    icon: minecraft,
    iconActive: minecraftWhite,
  },
  {
    name: "cloudpanel",
    icon: cloudPanel,
    iconActive: cloudPanelWhite,
  },
  {
    name: "wireguard",
    icon: wireguard,
    iconActive: wireguardWhite,
  },
  {
    name: "openvpn",
    icon: openvpn,
    iconActive: openvpnWhite,
  },
];

const Apps = ({dispatch, createInstanceAction, state}) => {
  const {createInstance: {oneClick = {}} = {}} = state || {};
  const [showVersions, setShowVersions] = useState(null);
  const [apps, setApps] = useState([]);
  const { osdistributions, selectedRegion } = useSelector((products) => products.products);
  useEffect(() => {
    const onMount = async () => {
      const {data} = await get('/server-apps')
      const [appsResponse] = data?.data
      const parsedApps = appsResponse?.map(app => {
        let {icon, iconActive} = appsIcons.find(({name}) => {
          return app?.app_name?.toLowerCase() === name?.toLowerCase
        }) || {}
        if (!icon) {
          const foundIcon = appsIcons.find(({name}) => {
            return app?.app_name?.toLowerCase().includes(name?.toLowerCase())
          }) || {}
          icon = foundIcon?.icon;
          iconActive = foundIcon?.iconActive;
        }
        return {...app, icon, iconActive}
      })
      setApps(parsedApps)
    }
    onMount()
  }, []);

  useEffect(() => {
    const [distribution] = osdistributions.filter(({name, host, is_featured, is_public}) => is_featured && is_public && name === oneClick?.version && host === selectedRegion?.host);
    const osName = osNames.find(os => distribution?.name?.toLowerCase().includes(os?.toLowerCase()))
    createInstanceAction({ templateid: distribution?.acs_id, osName })(dispatch);
  }, [osdistributions, selectedRegion, oneClick?.version]);

  return (
    <div className='OneClick'>
      <div className='flex row flex-mobile-column hidden-xs hidden-xs-flex'>
        {apps
          .map(
            ({app_name: name, icon, iconActive, description, os: versions, data}) =>
              <Card
                key={name}
                className={`flex-grow-1 width-full-width`}
              >
                <Card.Body
                  onClick={() => setShowVersions(showVersions === name ? null : name)}
                  className={`m-l-15 m-r-15 m-b-20 flex flex-align-items-center flex-grow-1 text-grey-2 font-weight-semibold ${
                    name === oneClick.name && 'active'
                  }`}
                >
                  <div className={"flex flex-align-items-flex-start"}>
                    <img className='m-r-15 m-t-10' src={name === oneClick.name ? iconActive : icon} alt=''/>
                    <div>
                      <h3 className={"font-size-16 font-weight-semibold m-b-0 m-t-0"}>{name}</h3>
                      <p className={"m-t-0 m-b-10"}>
                        {description}
                      </p>
                      {name === oneClick?.name ?
                        <p className={"m-t-5 m-b-5 font-weight-bold"}>
                          {oneClick?.version}
                          <span className={`arrow arrow--${showVersions === name ? 'up' : 'down'} m-l-5`}></span>
                        </p> :
                        <p className={"m-t-5 m-b-5 font-weight-bold"}>
                          Select version
                          <span className={`arrow arrow--${showVersions === name ? 'up' : 'down'} m-l-5`}></span>
                        </p>}
                    </div>
                  </div>
                  {showVersions === name ? <div className={"OneClick__version"}>
                    {versions.map(({os: version}) => {
                      return <div onClick={() => {
                        createInstanceAction({oneClick: {name, version, appsUserData: data}})(dispatch)
                        setShowVersions(null)
                      }} key={version}>{version}</div>
                    })}
                  </div> : ""}
                </Card.Body>
              </Card>
          )}
      </div>
      <div className={`row visible-xs m-b-30`}>
        <Slider
          {...{
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  rows: 6,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerPadding: 50,
                  centerMode: true,
                  arrows: false
                }
              }
            ]
          }}
        >
          {apps
            .map(
              ({name, on, icon, iconActive}) =>
                <Card
                  key={name}
                  onClick={() => createInstanceAction({oneClick: {on, name}})(dispatch)}
                  className={`flex-grow-1 width-full-width`}
                >
                  <Card.Body
                    className={`m-l-15 m-r-15 m-b-20 flex flex-align-items-center flex-grow-1 text-grey-2 font-weight-semibold ${
                      name === oneClick.name && 'active'
                    }`}
                  >
                    <img className='m-r-10' src={name === oneClick.name ? iconActive : icon} alt=''/>
                    {name} <span className='font-weight-light text-grey-1 m-l-5'> on {on}</span>
                  </Card.Body>
                </Card>
            )}
        </Slider>
      </div>
    </div>
  );
};

export default Apps;
