import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST, //eslint-disable-line
});

// instance.defaults.withCredentials = true;

export const request = instance;

// Logout if the server returns 401.
const errorHandler = async (error) => {
  const { response: { status } = {} } = error;
  if (status === 401) {
    localStorage.removeItem("atkn"); //eslint-disable-line
    localStorage.removeItem("last_logged"); //eslint-disable-line
    document.location.href = "/login"; //eslint-disable-line
  }
  return Promise.reject(error);
};

const updateSession = async () => {
  const diff = Math.abs(
    new Date(localStorage.getItem("last_logged")) - new Date() //eslint-disable-line
  );
  const minutes = Math.floor(diff / 1000 / 60);

  if (minutes > 55) {
    localStorage.setItem("last_logged", new Date()); //eslint-disable-line
    const { data: { data: { token } = {} } = {} } = await get(
      "/refresh-token",
      {},
      false
    );
    localStorage.setItem("atkn", token); //eslint-disable-line
    return {};
  }
  return {};
};

/**
 * Generic GET request
 * @param url
 * @param responseDetails
 * @returns {Promise<AxiosResponse<T>>}
 */
export const get = async (url, responseDetails, handle = true) => {
  if (handle) {
    await updateSession();
  }
  const headers = { Authorization: `Bearer ${localStorage.getItem("atkn")}` }; //eslint-disable-line
  const getRequest = request.get(url, { headers, ...responseDetails });
  getRequest.then(null, errorHandler);
  return getRequest;
};

/**
 * Generic POST Request
 * @param url
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const post = async (url, data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("atkn")}` }; //eslint-disable-line
  return request({
    method: "POST",
    headers,
    url,
    data,
  });
};

/**
 * Generic PUT Request
 * @param url
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const put = async (url, data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("atkn")}` }; //eslint-disable-line
  return request({
    method: "PUT",
    headers,
    url,
    data,
  });
};

/**
 * Generic PUT Request
 * @param url
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const del = async (url, data) => {
  const headers = { Authorization: `Bearer ${localStorage.getItem("atkn")}` }; //eslint-disable-line
  return request({
    method: "DELETE",
    headers,
    url,
    data,
  });
};
