export default {
  infoBox: {
    title: 'Give $150, get back $50',
    description:
      "Everyone you refer gets $100 in credit over 60 days. Once they’ve spent $25 with us, you'll get $25. There is no limit to the amount of credit you can earn through referrals.",
  },
  referByEmailBox: {
    title: 'Refer by email',
    description:
      'Import your contacts from Gmail – or enter your contacts manually – and we’ll invite them for you.',
    button: 'Invite Contacts',
  },
  shareRefer: {
    title: 'Share your referral link',
    description: 'Copy your personal referral link and share it with your friends and followers.',
    button: 'Share Via',
    copy: 'Copy referral link',
  },
  stats: {
    title: 'Referral Stats',
    note:
      "*Please note: Bidding on branded keywords which include 'shapehost' and/or 'shape host' for ads is prohibited.",
  },
};
