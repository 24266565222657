import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { terminateInstance } from "../../../actions";
import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";

const DestroyModal = ({ show, onHide, accountid, dispatch, name }) => {
  const { t } = useTranslation("dashboard");
  const { addToast } = useToasts();
  const [loading, setLoading] = useState()
  const navigate = useHistory();

  const handleDestroy = async () => {
    setLoading(true);
    try {
      // const {
      //   data: {
      //     data: { jobid }
      //   }
      // } = await destroyInstance({ accountid, vmid: instanceId })(dispatch);
      // startFetching(jobid, () => {
      await terminateInstance({accountid})(dispatch);
      localStorage.removeItem("instancesOnWait") //eslint-disable-line
      setLoading(false);
      onHide();
      navigate.push('/');
      addToast(t("instance.destroy.modal.success", { name }), {
        appearance: "success",
        autoDismiss: true,
      });
      // });
    } catch (error) {
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }

  return (
    <Modal
      Title={() => (
        <span className="font-size-38 text-grey-3 m-t-0 m-b-0">
          {t("instance.destroy.modal.title")}
        </span>
      )}
      onHide={onHide}
      show={show}
    >
      <p className="flex font-size-16 text-grey-1">
        {t("instance.destroy.modal.description1")}
        <span className="text-red-2 display-block m-l-5">
          {t("instance.destroy.modal.descriptionRed")}
        </span>
      </p>
      <p className="font-size-16 text-grey-1 line-height-1-6 m-t--10 m-b-30">
        {t("instance.destroy.modal.description2")}
        <span className="text-red-2 display-block m-t-10">
          {t("instance.destroy.modal.areYouSure")}
        </span>
      </p>
        <div className="flex row m-t-10">
          <button
            onClick={onHide}
            className="button button--grey width-half flex-grow-1 m-l-15 m-r-15"
          >
            {t("instance.destroy.modal.cancel")}
          </button>
          <Button
            onClick={async () => { await handleDestroy() }}
            loading={loading}
            className="button button--full-red width-half flex-grow-1 m-l-15 m-r-15"
          >
            {t("instance.destroy.modal.confirm")}
          </Button>
        </div>
    </Modal>
  );
};

export default DestroyModal;
