export default {
  title: "Welcome back",
  placeholders: {
    email: "Email address",
    password: "Password"
  },
  labels: {
    remember: "Remember me"
  },
  links: {
    forgotPassword: "Forgot password?",
    signUp: "Sign Up Here"
  },
  button: "Sign in",
  notAMember: "Not yet a member? "
}