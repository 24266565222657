export default {
  resources: {
    title: "Resources",
    firstProject: {
      title: "This is your very first project",
      description:
        "Create new projects and organize your resources to suit your own workflow. ",
      link: "Learn more",
    },
    instances: "INSTANCES",
    nobackup:
      "BackUps are not enabled for this Instance. Click to activate it.",
    active: "Active",
    inactive: "Inactive",
    instanceMenu: {
      addDomain: "Add a domain",
      accessConsole: "Access console",
      resizeInstance: "Resize instance",
      viewUsage: "View usage",
      enableBackups: "Enable backups",
      editTags: "Edit tags",
      delete: "Destroy instance",
    },
  },
  instanceDetails: {
    image: "Image",
    size: "Size",
    cpus: "vCPUs",
    disk: "Disk",
    memory: "Memory",
    gb: "GB",
    monthly: "mo",
    resize: "Resize",
    region: "Region",
    privateIp: "Private IP",
    publicIp: "Public IP",
    floatingIp: "Floating IP",
    enableNow: "Enable Floating IP",
    console: "Console",
    turnOff: "Turn Off",
    turnOn: "Turn On",
    soon: "Coming soon",
    extraIPEnable: "Enable Now",
    extraIP: "Extra IP",

    menu: {
      graphs: "Graphs",
      rebuild: "Rebuild",
      access: "Access",
      power: "Power",
      volumes: "Volumes",
      resize: "Resize",
      networking: "Networking",
      backups: "Backups",
      snapshots: "Snapshots",
      kernel: "Kernel",
      history: "History",
      activity: "Activity",
      tags: "Tags",
      recovery: "Recovery",
      destroy: "Destroy",
    },
  },
  tabs: {
    activity: "Activity",
    resources: "Resources",
  },
  instance: {
    graph: {
      learnHow: "Learn how to update this Instance for new metrics.",
      cpuUsage: "CPU Usage",
      publicBandwidth: "Public Bandwidth",
      memoryUsage: "Memory",
      usageCpu: "Usage CPU",
      usageBandwidth: "Usage Bandwidth",
      usageDiskIO: "Usage Disk I/O",
      usageMemory: 'Usage Memory',
      outbound: "Outbound",
      inbound: "Inbound",
      usedMemory: "Used Memory",
      system: "System",
      hour: "hour",
      hours: "hour",
      days: "days",
      day: "day",
      month: "month",
    },
    rebuild: {
      title: "Restore Instance to Original Template",
      description: "This feature allows you to restore your instance to its original template, effectively resetting it to the state it was in when you first created it.",
      button: "Rebuild",
      confirm: "Confirm",
      cancel: "Cancel",
      success: "Successfully rebuild instance",
      notice: "Important Notice: Using the \"Restore Instance to Original Template\" function will permanently delete all current data on your instance. This includes files, configurations, applications, and any other data. Once the instance is restored to the original template, the deleted data cannot be recovered.",
      note: "Note: This process can take a few minutes. Please do not close or refresh the window until it's complete."
    },
    access: {
      console: {
        title: "Access Console",
        description:
          "This will open up a console VNC connection to your Instance and is the equivalent of plugging a monitor and keyboard directly to your virtual server.",
        button: "Launch Console",
      },
      resetPassword: {
        title: "Reset root password",
        description:
          "Clicking Reset Root Password below will immediately shut down your Instance and set a new root password. The new root password will be displayed here.",
        recovery: "recovery environment",
        proceed: "Do you wish to proceed?",
        button: "Reset Root Password",
        successCopy: "Copied",
        modal: {
          title: "Reset root password",
          cancel: "Cancel",
          confirm: "Confirm",
          password: "New password: ",
          success: "Continue",
          warning1: "Clicking ",
          warning2: "below will immediately shut down your Instance and set a new root password. The new root password will be displayed here within minutes.",
          warningQuestion: "Do you wish to proceed?",
          resetedDescription1: "You can see the new password set below. Make sure you copy and",
          resetedDescription2: "this password, as it will be",
          resetedDescription3: "the root of the server in the future.",
          resetedDescriptionBold1: " securely store ",
          resetedDescriptionBold2: " required to access ",
          copy: "copy",
        }
      },
    },
    destroy: {
      title: "Destroy Instance",
      descriptionBold: "This is irreversible.",
      description:
        "We will destroy your Instance and all associated backups. All associated Instance data will be scrubbed and irretrievable.",
      button: "Destroy this Instance",
      modal: {
        title: "Destroy Instance",
        description1: "Your selected server will be",
        descriptionRed: "permanently destroyed.",
        description2:
          "If you destroy this server, any data on it will also be permanently lost!",
        areYouSure: "Are you sure you want to continue?",
        confirm: "Destroy",
        cancel: "Cancel",
        success: "Instance {{name}} has been successfully destroyed"
      },
    },
    snapshots: {
      title: "Instance snapshots",
      noSnapshots: "You currently don't have any snapshots for this Instance.",
      delete: "Delete",
      revert: "Revert to Snapshot",
      type: "Type",
      name: "Name",
      created: "Created",
      comingSoon: "Coming soon...",
      comingSoonDescription: "Snapshots will allow you to create an image of your instance and use it later to restore it on a different instance.",
      create: {
        title: "Take snapshot",
        powerDown: "Power down",
        description:
          " your Instance before taking a snapshot to ensure data consistency. Snapshot's cost is based on space used and charged at a rate of $0.05/GB/mo. ",
        description2:
          "Snapshots can take up to 1 minute per GB of data used by your Instance. Snapshots cost $0.05/GB/mo based on the size of the snapshot (not the size of the filesystem). ",
        description3:
          "We recommend turning off your Instance before taking a snapshot to ensure data consistency.",
        learnMore: "Learn more",
        placeholder: "Enter snapshot name",
        button: "Take live snapshot",
        success: "Snapshot was successfully created",
      },
      deleteModal: {
        title: "Delete snapshot of Virtual machine",
        areYouSure: "Are you sure you want to proceed?",
        cancel: "Cancel",
        confirm: "Confirm",
        success: "Snapshot was successfully deleted",
      },
      revertModal: {
        title: "Revert to VM snapshot",
        success: "Snapshot was successfully reverted",
      },
    },
    network: {
      public: "Public Network",
      publicDesc: "Anybody can access the Instance via these public addresses.",
      private: "Private Network",
      privateDesc: "Only other members of the same VPC Network can access the Instance via its private address.",
      noPrivateDesc: "This instance is not part of a VPC yet. You can add it from",
      here: " here.",
      firewalls: "Firewalls",
      firewallsDesc: "Your Instance is protected by following firewalls.",
      noFirewallsDesc: "There is no firewall applied to this Instance.",
      IPv4Public: "Public IPv4 Address",
      IPv4Private: "Private IPv4 Address",
      publicGateway: "Public Gateway",
      subnetMask: "Subnet Mask",
      VPCNetwork: "VPC Network",
      IPRange: "VPC IP Range",
      extraIP: "Extra IP",
      enable: "Enable now",
      frameyStaging: "framey-staging",
      button: "Add Firewall",
      firewallProd: "Production Firewall",
      firewallStag: "Staging Firewall",
    },
    backups: {
      title: "Instance Backups",
      nobackups: "You currently don't have any backups for this Droplet.",
      startBackup: "Start backup",
      sure: "Are you sure you want create a VM backup?",
      cancel: "Cancel",
      ok: "OK",
      comingSoon: "Coming soon...",
      comingSoonDescription: "Enabling backups will automatically create a snapshot of your Instance once a week. This feature is not available yet.",
      change: {
        title: "Backups",
        disabled: {
          description:
            "Enabling backups will automatically create a snapshot of your Instance once a week. ",
          description2:
            "Enabling backups for this Instance will cost $0.00 per month.",
          button: "Enable Instance Backups",
        },
        enabled: {
          description:
            "Enabling backups will automatically create a snapshot of your Instance once a week. ",
          description2:
            "Enabling backups for this Instance will cost $0.00 per month.",
          button: "Disable Instance Backups",
        },
      },
      assignModal: {
        button: "Assign VM to backup offering",
        cancel: "Cancel",
        assign: "Assign",
        title: "Assign VM to backup offering",
        success: "Succefully assigned!",
      },
    },
    power: {
      title: "Turn off Instance",
      description:
        "When you turn off an Instance from the control panel, we first try a graceful shutdown. If that fails, we do a forced shutdown, which may corrupt data. To ensure data integrity, we recommend shutting down from the command line with power off",
      powerOff: "",
      instanceTurnedOff: {
        title: "When an Instance is turned off:",
        item1:
          "Its data and IP address are retained and its disk, CPU and RAM are reserved.",
        item2: "You continue to accrue its data transfer allowance.",
      },
      button: "Turn Off Instance",
      buttonOff: "Turn On Instance",
      loading: {
        on: "Turning on...",
        off: "Turning...",
      },
      detail1: "You will still be",
      detail1Bold: "billed",
      detail2: "for a turned off Instance, for that",
      detail2Bold: "destroy the Instance",
      detail3: "instead.",
      powerCycle: {
        title: "Power cycle",
        description:
          "A power cycle will immediately hard reset the server. You should only choose this option when you are unable to reboot the Instances from the command line.",
        proceed: "Do you wish to proceed?",
        button: "Power Cycle",
      },
    },
    recovery: {
      title: "Recovery",
      description:
        "Booting from a recovery ISO allows you to recover from kernel mismatches and perform repairs on corrupted file systems. ",
      learnMore: "Learn more",
      isoLabel: "Boot from Recovery ISO",
      isoDescription:
        "When this option is selected, your Instance will boot from the Recovery ISO at the next restart.",
      hardDriveLabel: "Boot from Hard Drive",
      hardDriveDescription:
        "When this option is selected, your Instance will boot from the Hard Drive at the next restart.",
      iso: {
        description:
          "This Instance is set to boot from the Recovery ISO the next time it is powered off and on again. To boot from the ISO:",
        shutDown: "Shut down completely:",
        shutDownDescription:
          " If the Instance is not already powered down, shut down from the command line or use the On/Off switch above. Note that a reboot is not sufficient to change the boot device.",
        powerOn: "Power on: ",
        powerOnDescription:
          "Use the On/Off switch above to power the Instance on and boot from the Recovery ISO.",
        switchBack: "Switch back to the hard drive: ",
        switchBackDescription:
          "When you have finished the recovery, return here to set your Instance to boot from its hard drive.",
      },
      hardDrive: {
        description:
          "This Instance is set to boot from the Recovery ISO the next time it is powered off and on again. To boot from the ISO:",
        shutDown: "Shut down completely:",
        shutDownDescription:
          " If the Instance is not already powered down, shut down from the command line or use the On/Off switch above. Note that a reboot is not sufficient to change the boot device.",
        powerOn: "Power on: ",
        powerOnDescription:
          "Use the On/Off switch above to power the Instance on and boot from the Recovery ISO.",
      },
    },
    kernel: {
      title: "Kernel",
      description:
        "The kernel for this Instance is not managed within the control panel. Instead, you can upgrade the kernel from within the Instance. ",
      learnMore: "Learn more.",
    },
    activity: {
      activity: "Activity",
      level: "Level",
      INFO: "Information",
      ERROR: "Error",
      type: "Type",
      state: "Status",
      description: "Event",
      domain: "Domain",
      created: "Date",
      noActivity: "There is no activity yet.",
    },
    resize: {
      title: "Resize Instance",
      description: "This Instance is currently on a Standard plan.",
      ramLabel: "CPU & RAM only",
      ramDescription:
        "This will only increase or decrease the CPU and RAM of your Instance, not disk size. This can be reversed.",
      diskLabel: "DISK, CPU & RAM",
      diskDescription:
        "This will increase Instance's disk size, CPU & RAM. This is a permanent change and cannot be reversed.",
      currentUsing: "You are currently using:",
      helpChoose: "Help me choose",
      eachInstance1: "Each Instance adds",
      eachInstanceBlue: "more data transfer",
      eachInstance2: "to your account.",
      notStopped: "Instance has to be stopped before any resize action.",
      tabs: {
        allTypes: "All Types",
        standard: "Standard",
        generalPurpose: "General Purpose",
        cpuOptimized: "CPU-Optimized",
        memoryOptimized: "Memory-Optimized",
        new: "NEW",
        shared: "SHARED CPU",
        dedicated: "DEDICATED CPU",
      },
      table: {
        type: "TYPE",
        cpuType: "CPU TYPE",
        vcpus: "vCPUs",
        memory: "MEMORY",
        ssd: "SSD",
        transfer: "TRANSFER",
        price: "PRICE",
      },
      button: "Resize Instance",
    },
    tags: {
      title: "Tag your droplet",
      description: "Tags are a great way to group droplets.",
      learnHow: "Learn how to get the most out of tags by reading our ",
      community: "community tutorial.",
      placeholder: "Type tags here, separated by space",
      button: "Save Tags",
    },
  },
  createInstance: {
    title: "Create an instance",
    version: "Version",
    os: "OS",
    details: "See more details",
    button: "Create Instance",
    newInstance: {
      title: "Add New Instance",
      description: "Start with one scalable virtual private server",
    },
  },
  create: {
    title: "Create something new",
    instance: {
      title: "Cloud Instances",
      description: "Create a cloud server",
    },
    volumes: {
      title: "Volumes",
      description: "Add storage to instances",
    },
    firewall: {
      title: "Cloud Firewalls",
      description: "Increase instance security",
    },
    extraIps: {
      title: "Extra IPs",
      description: "Reserve instance IP addresses",
    },
  },
  afterCreate: {
    title: "",
    loadBalancers: {
      title: "Load Balancers",
      description: "Balance your traffic properly",
    },
    vpcNetworks: {
      title: "VPC Networks",
      description: "Your virtual private cloud network",
    },
    advancedNetworks: {
      title: "Advanced Networks",
      description: "Customise your network",
    },
    ptrRecords: {
      title: "PTR Records",
      description: "Update your PTR records",
    },
  },
  learning: {
    title: "Useful links",
    knowledgeBase: {
      title: "Knowledge Base",
      description:
        "Learn more about our services",
    },
    liveChat: {
      title: "Live Chat",
      description:
        "Chat with one of our agents",
    },
    openTicket: {
      title: "Open Ticket",
      description: "Do you have a question or a problem?",
    },
    resources: {
      title: "Resources",
      description: "Check our easy-to-use tutorials",
    },
  },
  passwords: {
    title: "Your root password",
    description: "You can see the new password set below. Make sure you copy and securely store this password, as it will be required to access the root of the server in the future.",
    close: "Close",
    next: "Next instance"
  },
  claimBonus: {
    congrats: "🎉 Congratulations!",
    eligible: "You are eligible for a hosting service credit. Please enter your coupon code as seen Creative Tim.",
    placeholder: "Coupon Code",
    button: "Claim Credit",
    errorEligible: "You are eligible for a hosting service credit.",
    disableAdblocker: "⚠️ Please disable your AdBlocker and refresh this page in order to be able to use the coupon code from Creative Tim."
  }
};
