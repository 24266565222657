import { get } from "../../components/http";

// Get invoices action
export const getInvoicesType = "GET_INVOICES";

export const getInvoicesState = {
  invoices: {},
};

export const getInvoicesReducer = (reducerState, action) => {
  return {
    ...reducerState,
    invoices: { ...reducerState.invoices, ...action.data },
  };
};

export const getInvoicesRequest = (page) => async (dispatch) => {
  const { data: { data: { invoices, paginated } = {} } = {} } = await get(
    "/client/invoices",
    {
      params: { page },
    }
  );

  dispatch({ type: getInvoicesType, data: { invoices, paginated } });
};

// Get invoices action
export const getInvoicesPdfType = "GET_INVOICES_PDF";

export const getInvoicesPdfState = {
  invoicesPdf: {},
};

export const getInvoicesPdfReducer = (reducerState, action) => {
  return {
    ...reducerState,
    ...action.data,
  };
};

let tryCount = 0;

export const getInvoicesPdfRequest = (params) => async (dispatch) => {
  if (params) {
    try {
      const response = await get("/client/invoices/pdf", {
        params,
      });
      const { data: { data: { invoicesPdf } = {} } = {} } = response;
      dispatch({ type: getInvoicesPdfType, data: { invoicesPdf } });
    } catch (error) {
      const { response: { status } = {} } = error;
      if (status === 401 && tryCount < 4) {
        tryCount += 1;
        getInvoicesPdfRequest(params)(dispatch);
      }
    }
  }
};
