import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import Modal from "../../../components/Modal";
import {attachVolume, getVolumesRequest} from "../actions";
import Search from "../../../components/Layout/Header/Search";
import Button from "../../../components/Button";
import reqWithJobId from "../../../helpers/reqWithJobId";

const AttachModal = ({ t, show, onHide, setVisibleModal, volumeDetails, loading }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [selectedInstance, setSelectedInstance] = useState(null);
  return (
    <div>
      <Modal
        Title={() => <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0 ">{t("attachModal.title")}</h2>}
        onHide={onHide}
        show={show}
      >
        <div>
          <div>
            <label className=" text-grey-1 font-size-12 font-weight-semibold m-b-10 display-block">
              {t("createModal.instance.label")}
            </label>
            <Search onChange={setSelectedInstance} selectedInstance={selectedInstance} extraFilter={instance => volumeDetails?.zoneid === instance?.zoneid && volumeDetails?.accountId === instance?.accountid} hideOverley short fullWidth />
          </div>
          <div className="flex row m-t-30 ">
            <button
              onClick={() => setVisibleModal(null)}
              className="button button--grey width-half flex-grow-1 m-l-15 m-r-15"
            >
              {t("attachModal.cancel")}
            </button>
            <Button
              disabled={!selectedInstance}
              loading={loading}
              onClick={async () => {
                try {
                  await reqWithJobId(attachVolume({ ...volumeDetails, virtualmachineid: selectedInstance.id})(dispatch), volumeDetails?.accountId)
                  setVisibleModal(null);
                  onHide();
                  addToast(t("attachModal.success"), {
                    appearance: "success",
                    autoDismiss: true
                  });
                  getVolumesRequest()(dispatch);
                } catch (e) {
                  setVisibleModal(null);
                  onHide();
                  addToast(t("attachModal.error"), {
                    appearance: "error",
                    autoDismiss: true
                  });
                }
              }}
              className="button button--blue width-half flex-grow-1 m-l-15 m-r-15 "
            >
              {t("attachModal.confirm")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AttachModal;
