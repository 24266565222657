import React from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../../../../../components/Checkbox";

const SetSsl = ({ createT, t, setActiveComponent }) => {
  return (
    <div className="p-t-30">
      <div className="flex m-b-40">
        <label className={`flex m-r-20 text-grey-1`}>
          <Checkbox name="ssl" className="Checkbox--full-box  m-r-10" />
          {createT("changeAdvanced.sslLabel")}
        </label>
      </div>
      <div className="flex m-t-30 m-b-30">
        <button
          onClick={() => setActiveComponent(false)}
          className="button button--blue button--sm min-width-100 m-r-20"
        >
          {t("settings.save")}
        </button>
        <button
          onClick={() => setActiveComponent(false)}
          className="button button--grey button--sm min-width-100"
        >
          {t("settings.cancel")}
        </button>
      </div>
    </div>
  );
};

const Ssl = ({
  settings = {},
  type,
  t,
  activeComponent,
  setActiveComponent,
}) => {
  const { value } = settings[type] || {};
  const { t: createLoadBalancerTrans } = useTranslation("createLoadBalancer");
  const translationsByComponent = (component) => (text) =>
    createLoadBalancerTrans(`${component}.${text}`);
  if (activeComponent === type) {
    return (
      <SetSsl
        {...{
          activeComponent,
          setActiveComponent,
          createT: translationsByComponent("AdvancedSettings"),
          t,
        }}
      />
    );
  }
  return (
    <div className="flex flex-grow-1 flex-align-items-center flex-justify-content-space-between font-size-16 text-grey-1">
      <div>{value}</div>
      <button
        onClick={() => setActiveComponent(type)}
        className="button button--grey button--sm min-width-100"
      >
        {t("settings.edit")}
      </button>
    </div>
  );
};

export default Ssl;
