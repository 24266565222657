import { get, post, put, del } from '../../components/http'; //eslint-disable-line

import { createSlice } from '@reduxjs/toolkit';

// Get hosting products
// export const getProductsReducer = (reducerState, action) => {
//   return {
//     ...reducerState,
//     products: (action.data && action.data.products) || reducerState.products,
//     productsByType:
//       (action.data && action.data.productsByType) || action.data.products,
//     locations: ((action.data && action.data.products) || reducerState.products)
//       .map(({ locations }) => locations)
//       .flat()
//       .filter(
//         ({ name }, index, self) =>
//           self.map((value) => value.name).indexOf(name) === index
//       ),
//     osdistributions: (
//       (action.data && action.data.products) ||
//       reducerState.products
//     )
//       .map(({ osdistributions }) => osdistributions)
//       .flat()
//       .filter(
//         ({ name }, index, self) =>
//           self.map((value) => value.name).indexOf(name) === index
//       ),
//   };
// };

// Slice
const slice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    productsByType: [],
    productsByCity: [],
    locations: [],
    fetchLocationsLoading: false,
    regions: [],
    osdistributions: [],
    createLbLoading: false,
    selectedPlan: 'standard',
    pricingPlan: null,
    selectedRegion: null,
    createInstanceLoading: false,
    shapehostNetworks: [],
    primaryNetworks: [],
    needToValidateNetwork: false,
    networkId: null,
    selectedProject: {
      accountId: '',
      nameToShow: '',
      nameToSend: ''
    },
    networkType: 'basic',
    tags: []
  },
  reducers: {
    getProducts: (state, action) => {
      state.products = action.payload && action.payload.products;
      state.productsByType = action.payload && action.payload.productsByType;
    },
    setCreateLbLoading: (state, action) => {
      state.createLbLoading = action.payload;
    },
    setLocationsLoading: (state, action) => {
      state.fetchLocationsLoading = action.payload;
    },
    getLocations: (state, action) => {
      state.locations = action.payload;
    },
    getProductsByType: (state, action) => {
      state.productsByType = action.payload;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    setPricingPlan: (state, action) => {
      state.pricingPlan = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selectedRegion = action.payload;
    },
    getProductsByCity: (state, action) => {
      state.productsByCity = action.payload;
    },
    getTemplates: (state, action) => {
      state.osdistributions = action.payload;
    },
    setCreateInstanceLoading: (state, action) => {
      state.createInstanceLoading = action.payload;
    },
    getShapehostNetworks: (state, action) => {
      state.shapehostNetworks = action.payload;
    },
    getRegions: (state, action) => {
      state.regions = action.payload;
    },
    setPrimaryNetworks: (state, action) => {
      state.primaryNetworks = action.payload;
    },
    setNeedToValidateNetwork: (state, action) => {
      state.needToValidateNetwork = action.payload;
    },
    setProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    setNetworkType: (state, action) => {
      state.networkType = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    }
  }
});
export default slice.reducer;

// Actions
export const {
  getProducts: getProductsAction,
  setCreateLbLoading,
  getLocations,
  setLocationsLoading,
  getProductsByType: getProductsByTypeAction,
  getProductsByCity: getProductsByCityAction,
  setSelectedPlan: setSelectedPlanAction,
  setPricingPlan: setPricingPlanAction,
  setSelectedLocation: setSelectedLocationAction,
  getTemplates,
  setCreateInstanceLoading: setCreateInstanceLoadingAction,
  getShapehostNetworks,
  getRegions,
  setPrimaryNetworks,
  setNeedToValidateNetwork,
  setProject,
  setNetworkType,
  setTags
} = slice.actions;

export const getProducts =
  (params = {}) =>
    async (dispatch) => {
      let productsResponse;
      try {
        productsResponse = await get('/products', params);
      } catch (error) {
        throw new Error(error);
      }
      const {
        data: { data }
      } = productsResponse;

      dispatch(
        getProductsAction({
          products: data
        })
      );
    };

export const planType = {
  S : 'standard',
  GP: 'generalPurpose',
  CPU: 'cpuOptimized',
  MEM: 'memoryOptimized'
};

/// set selected type
export const setSelectedPlan = (data) => (dispatch) => {
    dispatch(setSelectedPlanAction(data));
};

// get products by city
export const getProductsByCity = (city, products) => (dispatch) => {
  const productsByCity = (products.zones ? [products] : products).reduce((out, item) => {
    if (item.zones.map((loc) => loc.city).includes(city)) {
      out.push(item);
    }
    return out;
  }, []);
  dispatch(getProductsByCityAction(productsByCity));
};

// set pricing plan
export const setPricingPlan = (pricingPlan) => (dispatch) => {
  dispatch(setPricingPlanAction(pricingPlan));
};

//set selected region
export const setSelectedLocation = (region, productsByType) => (dispatch) => {
  dispatch(setSelectedLocationAction(region));
  getProductsByCity(region.city, productsByType)(dispatch);
};

// Create Load Balancer Group
export const createLbGroupRequest =
  ({ accountid, name, description, networkid }) =>
    async () => {
      try {
        const {
          data: { data: { groupId } }
        } = await post(`client/loadbalancers/group-rule`, {
          accountid, name, description, networkid
        })
        return groupId;
      } catch (error) {
        throw new Error(error);
      }
    }

// Update Load Balancer Group
export const updateLbGroupRequest =
  ({ id, accountid, name, networkid }) =>
    async () => {
      try {
        const result = await put(`client/loadbalancers/group-rule`, {
          id: id,
          accountid: accountid,
          name: name,
          description: name,
          networkid: networkid,
        })
        if (result.data.error) {
          throw new Error(result.data.error.message);
        }
        return result;
      } catch (error) {
        throw new Error(error);
      }
    }

// Delete Load Balancer Group
export const deleteLbGroupRequest =
  ({ accountid, groupid }) =>
    async () => {
      try {
        const result = await del(`client/loadbalancers/group-rule`, {
          groupid: groupid,
          accountid: accountid,
        })
        if (result.data.error) {
          throw new Error(result.data.error.message);
        }
        return result;
      } catch (error) {
        throw new Error(error);
      }
    }

// Create Load Balancer Rule
export const createLbRuleRequest =
  ({ algorithm, protocol, name, privateport, publicport, description, zoneid, publicipid, networkid, accountId }) =>
    async () => {
      try {
        const result = await post(`accounts/${accountId}/loadbalancers/rules`, {
          algorithm, protocol, name, privateport, publicport, description, zoneid, publicipid, networkid
        })
        if (result.data.error) {
          throw new Error(result.data.error.message);
        }
        return result;
      } catch (error) {
        throw new Error(error);
      }
    }

// Update Load Balancer Rule
export const updateLbRuleRequest =
  ({ accountId, id, algorithm, protocol, name }) =>
    async () => {
      try {
        const result = await put(`accounts/${accountId}/loadbalancers/rules`, {
          id: id,
          algorithm: algorithm,
          protocol: protocol,
          name: name,
          description: name,
        })
        if (result.data.error) {
          throw new Error(result.data.error.message);
        }
        return result;
      } catch (error) {
        throw new Error(error);
      }
    }

// Delete Load Balancer Rule
export const deleteLbRuleRequest =
  ({ accountId, id }) =>
    async () => {
      try {
        const result = await del(`client/loadbalancers/rule`, {
          accountid: accountId,
          id: id,
        })
        if (result.data.error) {
          throw new Error(result.data.error.message);
        }
        return result;
      } catch (error) {
        throw new Error(error);
      }
    }

// Assign Load Balancer Rule to Group
export const assignRuleToGroupRequest =
  ({ rule_id, accountid, description, groupid }) =>
    async () => {
      try {
        const result = await post(`client/loadbalancers/rule`, {
          rule_id, accountid, description, groupid
        })
        if (result.data.error) {
          throw new Error(result.data.error.message);
        }
        return result;
      } catch (error) {
        throw new Error(error);
      }
    }

// Assign Instance to Load Balancer Rule
export const assignInstancetoLbRuleRequest =
  ({ accountId, ruleId, instanceIds }) =>
    async () => {
      try {
        const instanceIdsString = instanceIds.join();
        const result = await post(`accounts/${accountId}/loadbalancers/rules/${ruleId}/assign-vm`, {
          virtualmachineids: instanceIdsString,
          vmidipmap: "",
        });
        if (result.data.error) {
          throw new Error(result.data.error.message);
        }
        return result;
      } catch (error) {
        throw new Error(error);
      }
    }

// REmove Instance from Load Balancer Rule
export const unassignInstanceFromLbRuleRequest =
  ({ accountId, ruleId, instanceIds }) =>
    async () => {
      try {
        const instanceIdsString = instanceIds.join();
        const result = await del(`accounts/${accountId}/loadbalancers/rules/${ruleId}/remove-vm`, {
          virtualmachineids: instanceIdsString,
          vmidipmap: "",
        });
        if (result.data.error) {
          throw new Error(result.data.error.message);
        }
        return result;
      } catch (error) {
        throw new Error(error);
      }
    }

// Create Load balancer
/// add zone id and publicid when assing VM wil be fixed
export const getCreateLbRequest =
  ({ publicipid, rules, name, accountid, zoneid }) =>
    async (dispatch) => {
      dispatch(setCreateLbLoading(true));
      let createResult;
      try {
        createResult = await Promise.all(
          rules.map(async (rule) => {
            const result = await post(`/accounts/${accountid}/loadbalancers/rules`, {
              zoneid,
              publicipid,
              name,
              protocol: rule.protocol,
              privateport: rule.privPort,
              publicport: rule.pubPort,
              algorithm: rule.algo
            });
            if (result.data.error) {
              dispatch(setCreateLbLoading(false));
              throw new Error(result.data.error.message);
            }

            if (rule.instances.length > 0) {
              const { data: { data: { id } } } = result;
              for (const instance of rule.instances) {
                try {
                  await post(`/accounts/${accountid}/loadbalancers/rules/${id}/assign-vm`, {
                    "virtualmachineids": `${instance}`
                  });
                } catch (error) {
                  throw new Error(error)
                }
              }
            }
            return result;
          })
        );

        dispatch(setCreateLbLoading(false));
      } catch (error) {
        dispatch(setCreateLbLoading(false));
        throw new Error(error);
      }
      return createResult.map((data) => data?.data?.data?.jobid);
    };

export const getLocationsRequest = () => async (dispatch) => {
  dispatch(setLocationsLoading(true))
  const {
    data: { data }
  } = await get('/shapehost-zones');
  const zones = data.flat();
  const regions = [...new Set(zones.map(({ region }) => region))];
  dispatch(getRegions(regions));
  dispatch(getLocations(zones));
  dispatch(setSelectedLocationAction(zones.filter(({active}) => !!active)[0]));
  dispatch(setLocationsLoading(false))
};

export const getTemplatesRequest = () => async (dispatch) => {
  try {
    const {
      data: { data }
    } = await get('/shapehost-templates');

    dispatch(getTemplates(data.flat()));
  } catch (error) {
    throw new Error(error);
  }
};

export const setCreateInstanceLoading = (bool) => (dispatch) => {
  dispatch(setCreateInstanceLoadingAction(bool));
};

export const createInstanceRequest =
  ({ product_id, cloud_name, has_vpc, payment_module_id }) =>
    async (dispatch) => {
      dispatch(setCreateInstanceLoadingAction(true));
      let clientOrderResult;
      try {
        clientOrderResult = await post('/client/orders', {
          product_id: product_id,
          cloud_name,
          has_vpc,
          payment_module_id: payment_module_id
        });

        if (clientOrderResult.data.data.payNowLink) {
          dispatch(setCreateInstanceLoading(false));
        }
      } catch (error) {
        dispatch(setCreateInstanceLoading(false));
        throw new Error(error);
      }
      return clientOrderResult;
    };

export const getOrderStatus = async (id) => {
  try {
    const orderStatusResult = await get(`/client/orders/${id}/status`);
    return orderStatusResult;
  } catch (err) {
    throw new Error(err);
  }
}

export const getOrderStatusRequest =
  ({ id }) =>
    async (dispatch) => {
      try {
        const orderStatusResult = await get(`/client/orders/${id}/status`);
        return orderStatusResult;
      } catch (error) {
        dispatch(setCreateInstanceLoadingAction(false));
        throw new Error(error);
      }
    };

export const planNaming = {
  standard: "S-Packages",
  cpuOptimized: "C-Packages",
  memoryOptimized: "M-Packages"
}

export const deployInstanceRequest =
  ({
    templateid,
    zoneid,
    pricingPlan,
    host,
    name,
    keypairs,
    userdata,
    appUserData,
    defaultNetwork,
    networkType,
    advancedNetworkId,
    selectedPlan,
    product_id,
    cloud_name,
    has_vpc,
    payment_module_id,
    sshkeyname,
    sshpublickey,
    bootmode,
    boottype
  }) =>
    async (dispatch) => {
      let deployResponse;
      let networkids = [defaultNetwork];
      let advancednetwork;

      if (networkType === 'advanced') {
        if (advancedNetworkId) {
          networkids = [...networkids, advancedNetworkId];
        } else {
          const {
            data: {data}
          } = await get(`/network-offerings`, {
            zoneid: zoneid
          });
          const networkOfferings = data
            .filter(
              (offering) => offering.name === 'DefaultIsolatedNetworkOfferingWithSourceNatService-Allow' && offering.host === host
            )
            .map(({id}) => id);

          advancednetwork = {
            networkofferingid: networkOfferings.join(','),
          }
        }
      }
      const serviceOffertingResponse = await get(`/shapehost-service-offerings`);
      const [serviceoffering] = serviceOffertingResponse.data.data.filter(
        ({ name: offeringName, host: offeringHost }) => offeringName === planNaming[selectedPlan] && offeringHost === host
      );

      const deployBody = Object.fromEntries(Object.entries(
        {
          serviceofferingid: serviceoffering.acs_id,
          templateid: templateid,
          zoneid: zoneid,
          displayname: name,
          networkids: networkids.join(','),
          keypairs: keypairs || null,
          sshkeyname,
          sshpublickey,
          bootmode,
          boottype,
          details: [{
            memory: pricingPlan.memory,
            cpuNumber: pricingPlan.cpus,
            rootdisksize: pricingPlan.disk
          }],
          // eslint-disable-next-line
          userdata: appUserData ? appUserData : (userdata ? btoa(userdata) : null),
          advancednetwork: advancednetwork ? advancednetwork : null,
          startvm: true
        }
      ).filter(([_, v]) => v != null)); //eslint-disable-line

      try {
        deployResponse = await post(`/client/orders/fullorder`, {
          product_id,
          cloud_name,
          has_vpc,
          payment_module_id,
          ...deployBody
        });
        if (deployResponse.data.error) {
          throw new Error(deployResponse.data.error.message);
        }
      } catch (error) {
        dispatch(setCreateInstanceLoadingAction(false));
        throw new Error(error);
      }
      return deployResponse;
    };

export const getShapehostNetworksRequest = () => async (dispatch) => {
  try {
    const {
      data: { data }
    } = await get('/shapehost-networks');
    const primaryNetworks = data.flat().filter(({ primary }) => primary === 1);
    dispatch(setPrimaryNetworks(primaryNetworks));
    dispatch(getShapehostNetworks(data.flat()));
  } catch (error) {
    throw new Error(error);
  }
};
