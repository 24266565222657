import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getClientDomainsRequest } from "../../../../Profile/actions";
import { useToasts } from 'react-toast-notifications';
import { createNetwork } from '../../../../Dashboard/actions';
import Modal from "../../../../../components/Modal";
import CustomField from "../../../../../components/CustomField";
import ChooseRegion from "./ChooseRegion";
import Loader from "../../../../Volumes/components/Loader/Loader";

const CreateModal = ({ onHide, show, t, locations }) => {
	const { domains } = useSelector(({ profile }) => profile);

	const [name, setName] = useState(null);
	const [description, setDescription] = useState(null);
	const [region, setRegion] = useState(null);
	const [accountId, setAccountId] = useState(null);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { addToast } = useToasts();

	const filteredLocations = useMemo(() => {
		return locations.filter(location => location.advanced_networking === 1 && location.active === 1)
	}, [locations]);

	useEffect(() => {
		const onMount = async () => {
			await getClientDomainsRequest()(dispatch);
		};
		onMount();
	}, [dispatch]);

	useEffect(() => {
		if (domains && region) {
			const reqDomain = domains.find(domain => {
				return domain.url === region.host;
			})

			if (reqDomain)
				setAccountId(reqDomain.id)
		}
	}, [domains, region]);

	const handleCreateNetwork = async () => {
		setLoading(true);
		try {
			await createNetwork({
				accountid: accountId,
				name,
				zoneid: region.acs_id,
				description,
			})(dispatch);
			setLoading(false);
			addToast(t('networks.createModal.success'), {
				appearance: 'success',
				autoDismiss: true
			}),
				onHide(),
				handleClearNetworkFields();
		} catch (error) {
			setLoading(false);
			return addToast(error.message, {
				appearance: 'error',
				autoDismiss: true
			});
		}
	};

	const handleClearNetworkFields = () => {
		setName(null);
		setDescription(null);
		setRegion(null);
	}

	return (
		<Modal
			size="small"
			Title={() => (
				<span className="font-size-38 text-grey-3 font-weight-light">
					{t("networks.createModal.title")}
				</span>
			)}
			onHide={onHide}
			show={show}
		>
			<div className="Create_network_modal">
				<div className="modal_section">
					<span className="font-size-13 font-weight-semibold text-grey-3">{t("networks.createModal.name")}</span>
					<CustomField>
						<input
							name="name"
							type="text"
							onChange={(e) => { setName(e.target.value) }}
							placeholder={t("networks.createModal.placeholderName")}
						/>
					</CustomField>
				</div>
				<div className="modal_section">
					<span className="font-size-13 font-weight-semibold text-grey-3">{t("networks.createModal.description")}</span>
					<CustomField>
						<input
							name="description"
							type="text"
							onChange={(e) => { setDescription(e.target.value) }}
							placeholder={t("networks.createModal.placeholderDescription")}
						/>
					</CustomField>
				</div>
				<div className="modal_section">
					<span className="font-size-13 font-weight-semibold text-grey-3">{t("networks.createModal.region")}</span>
					<ChooseRegion locations={filteredLocations} onChange={setRegion} t={t} />
				</div>
				{loading ? (
					<Loader />
				) : (<div className="flex flex-justify-content-space-between m-t-30">
					<button className="modal_button cancel_button" onClick={() => onHide()}>{t("networks.createModal.cancelButton")}</button>
					<button
						disabled={!(name && description && region && accountId)}
						className={`modal_button confirm_button ${!(name && description && region && accountId) && "button--blueDisabled"}`}
						onClick={handleCreateNetwork}>
						{t("networks.createModal.confirmButton")}
					</button>
				</div>)}
			</div>
		</Modal>
	)
}

export default CreateModal;
