import React, {useCallback, useState} from "react";
import Card from "../../../../../components/Card";
import {get} from "../../../../../components/http";
import Modal from "../../../../../components/Modal";
import reqWithJobId from "../../../../../helpers/reqWithJobId";
import Button from "../../../../../components/Button";
import {useToasts} from "react-toast-notifications";

const Rebuild = ({ t: dashboardTrans, accountid, instanceId, setInstancesPassword }) => {
  const t = (path) => dashboardTrans(`instance.rebuild.${path}`);
  const [notice, setNotice] = useState(false);
  const {addToast} = useToasts();
  const [loading, setLoading] = useState(false)
  const rebuildInstance = useCallback(async () => {
    try {
      setLoading(true)
      const {result: {virtualmachine = {}} = {}} = await reqWithJobId(get(`/accounts/${accountid}/vm/${instanceId}/restore-vm`, {}), accountid)
      setLoading(false)
      setNotice(false)
      setInstancesPassword([{name: virtualmachine?.displayname, password: virtualmachine?.password}])
      return addToast(t("success"), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (e) {
      setLoading(false)
      return addToast(e.message, {
        appearance: 'error',
        autoDismiss: true
      });
    }
  }, [])
  return (
    <div className="Access_tab flex flex-column flex-justify-content-space-between">
      <Modal Title={() => t("title")} show={notice} onHide={() => setNotice(false)}>
        <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-25">{t("notice")}</p>
        <p>{t("note")}</p>
        <div className={"flex"}>
          <button disabled={loading} onClick={() => {setNotice(true)}} className="button button--md button--grey width-full-width max-width-250 m-b-20 m-r-10" >
            {t("cancel")}
          </button>
          <Button loading={loading} onClick={rebuildInstance} className="button button--md button--blue width-full-width max-width-250 m-b-20" >
            {t("confirm")}
          </Button>
        </div>
      </Modal>
      <Card>
        <Card.Body className="width-full-width flex-direction-column p-l-40 p-r-40 p-t-20 p-b-20">
          <h3 className="font-size-27 text-grey-3 font-weight-regular m-b-10">{t("title")}</h3>
          <p className="text-grey-1 font-size-16 m-t-0 line-height-1-6 m-b-25">{t("description")}</p>
          <button onClick={() => {
            setNotice(true)
          }} className="button button--md button--blue max-width-250 m-b-20" >
            {t("button")}
          </button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Rebuild;
