import { get, put, del, post } from "../../components/http";

import { createSlice } from "@reduxjs/toolkit";

// Slice
const slice = createSlice({
  name: "volumes",
  initialState: {
    volumes: null,
    snapshots: [],
    snapshotsLoading: false,
    loading: false
  },
  reducers: {
    getVolumes: (state, action) => {
      state.volumes = action.payload;
    },
    getSnapshot: (state, action) => {
      state.snapshots = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export default slice.reducer;

// Actions
const { getVolumes, setLoading, getSnapshot } = slice.actions;

// Get all active volumes
export const getVolumesRequest = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const {
      data: {
        data: { volumes }
      }
    } = await get('/client/volumes');
    dispatch(getVolumes(volumes));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

//Get all active snapshots

export const getSnapshotsRequest = () => async (dispatch) => {
  const { data: { data: { accounts } } = {} } = await get(`/accounts`);
  let response = await Promise.all(
    accounts
      .filter((account) => account.status === "Active" || account.status === "Suspended")
      .map(({ id }) => {
        return get(`/accounts/${id}/snapshot`);
      })
  );
  let snapshots = response
    .map((data) => data.data.data)
    .flat()
    .filter((snapshot) => snapshot);
  dispatch(setLoading(false));
  dispatch(getSnapshot(snapshots));
};

// Detach a volume
export const detachVolume =
  ({ accountId, id }) =>
  async (dispatch) => {
    let detachResult;
    dispatch(setLoading(true));
    try {
      detachResult = await put(`/accounts/${accountId}/volumes/${id}/detach`);
    } catch (error) {
      detachResult = { error };
      dispatch(setLoading(false));
    }

    if (detachResult.data.error) {
      dispatch(setLoading(false));
      throw new Error(detachResult.data.error.message);
    }
    return detachResult;
  };

// Attach volume
export const attachVolume =
  ({ accountId, id, virtualmachineid }) =>
  async (dispatch) => {
    let attachVolume;
    dispatch(setLoading(true));
    try {
      attachVolume = await post(`/accounts/${accountId}/volumes/${id}/attach`, {
        virtualmachineid
      });
    } catch (error) {
      attachVolume = { error };
      dispatch(setLoading(false));
    }

    if (attachVolume.data.error) {
      dispatch(setLoading(false));
      throw new Error(attachVolume.data.error.message);
    }
    return attachVolume;
  };

// Delete a volume
export const deleteVolume =
  ({ accountId, id }) =>
  async (dispatch) => {
    let deleteResult;
    dispatch(setLoading(true));
    try {
      deleteResult = await del(`/accounts/${accountId}/volumes/${id}`, {});
    } catch (error) {
      deleteResult = { error };
      dispatch(setLoading(false));
    }

    if (deleteResult.data.error) {
      dispatch(setLoading(false));
      throw new Error(deleteResult.data.error.message);
    }
    getVolumesRequest()(dispatch);
  };

// Volume snapshot
export const createVolumeSnapshot =
  ({ accountId, id: volumeid, name }) =>
  async (dispatch) => {
    let snapshotResult;
    dispatch(setLoading(true));
    try {
      snapshotResult = await post(`/accounts/${accountId}/snapshot`, {
        volumeid,
        name
      });
    } catch (error) {
      snapshotResult = { error };
      dispatch(setLoading(false));
    }

    if (snapshotResult.data.error) {
      dispatch(setLoading(false));

      throw new Error(snapshotResult.data.error.message);
    }

    return snapshotResult;
  };

// Volume increase size
export const increaseVolumeSize =
  ({ accountId, id: volumeid, newSize }) =>
  async (dispatch) => {
    let increaseResult;
    dispatch(setLoading(true));
    try {
      increaseResult = await put(`/accounts/${accountId}/volumes/${volumeid}/resize`, {
        size: newSize
      });
    } catch (error) {
      increaseResult = { error };
      dispatch(setLoading(false));
    }

    if (increaseResult.error) {
      throw new Error(increaseResult.error);
    }
    return increaseResult;
  };

// Create volume
export const createVolume =
  ({
    accountId,
    size,
    virtualmachineid,
    name,
    diskofferingid,
    snapshotid,
    zoneid,
    projectid,
    domainid,
    displayvolume
  }) =>
  async (dispatch) => {
    let createResult;
    let attachVolume;

    try {
      dispatch(setLoading(true));
      createResult = await post(`/accounts/${accountId}/volumes`, {
        size,
        name,
        virtualmachineid,
        diskofferingid,
        snapshotid,
        zoneid,
        domainid,
        projectid,
        displayvolume
      });
      if (createResult.data.error) {
        throw new Error(createResult.data.error.message);
      }

      attachVolume = await post(`/accounts/${accountId}/volumes/${createResult.data.data.id}/attach`, {
        virtualmachineid
      });
    } catch (error) {
      attachVolume = { error };
      createResult = { error };
      dispatch(setLoading(false));
    }
    if (attachVolume.error) {
      throw new Error(attachVolume.error);
    }

    return attachVolume;
  };

//delete Volume Snapshot
export const deleteVolumeSnapshot =
  ({ accountId, snapshotId }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let deleteResult;
    try {
      deleteResult = await del(`/accounts/${accountId}/snapshot/${snapshotId}`, {});
    } catch (error) {
      deleteResult = { error };
      dispatch(setLoading(false));
    }

    if (deleteResult.data.error) {
      dispatch(setLoading(false));
      throw new Error(deleteResult.data.error.message);
    }
    return deleteResult;
  };
