import React, { useState, useReducer } from "react";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import ReactSlider from 'react-slider';
import Modal from "../../../../components/Modal";
import CustomField from "../../../../components/CustomField";
import SelectDomain from "../../../../components/SelectDomain";
import SelectVpc from "../../../../components/SelectVpc";
import SelectZone from "../../../../components/SelectZone";
import Loader from "../../../Volumes/components/Loader/Loader";
import { createVpc, getVpcRequest } from "../../../Dashboard/actions";

const CreateVpc = ({
  show,
  onHide,
  t,
  accountid,
  startFetching,
  underInstance,
}) => {
  const initialValues = {
    name: null,
    cidr: null,
    networkdomain: null,
  };
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { vpcListOfferings, zonesList, vpcLoading } = useSelector(
    ({ instances }) => instances
  );
  const domains = useSelector((state) => state.profile.domains);
  const [selectedVpc, setSelectedVpc] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [, setChoosenVolume] = useState(null);
  const availableDomains = domains.map(({ domain, id }) => ({
    value: domain,
    text: domain,
    id,
  }));

  const values = [10, 25, 50, 100, 200, 500];

  const [vpcFields, setVpcFields] = useReducer(
    (prevValues, newValues) => ({ ...prevValues, ...newValues }),
    initialValues
  );
  const { name, cidr, networkdomain } = vpcFields;

  const handleVpcFieldsChange = (event) => {
    const { name, value } = event.target;
    setVpcFields({ [name]: value });
  };
  const handleClearVpcFields = () => {
    setVpcFields({
      name: null,
      cidr: null,
      networkdomain: null,
    });
  };
  const handleCreateVpc = async () => {
    try {
      const {
        data: {
          data: { jobid },
        },
      } = await createVpc({
        accountid: accountid ? accountid : selectedDomain.id,
        name,
        networkdomain,
        zoneid: selectedZone.id,
        vpcofferingid: selectedVpc.id,
        cidr: cidr,
      })(dispatch);
      startFetching(
        jobid,
        () => {
          addToast(t("createModal.success"), {
            appearance: "success",
            autoDismiss: true,
          });
          onHide();
          handleClearVpcFields();
          getVpcRequest({ accountid })(dispatch);
        },
        accountid ? accountid : selectedDomain.id
      );
    } catch (error) {
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <Modal
      size="large"
      Title={() => (
        <h2 className="font-size-27 text-grey-3 m-t-0 m-b-0">
          {t("createModal.title")}
        </h2>
      )}
      onHide={onHide}
      show={show}
    >
      <div className="modal-inner">
        {!underInstance && (
          <>
            <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
              {t("createModal.domain")}
            </label>
            <SelectDomain
              domainList={availableDomains}
              onChange={(value) => setSelectedDomain(value)}
            />
          </>
        )}
        <div className={"slider"}>
          <ReactSlider
            className="horizontal-slider"
            marks
            markClassName="example-mark"
            min={0}
            max={values.length - 1}
            thumbClassName="example-thumb"
            trackClassName="example-track"
            onChange={value => setChoosenVolume(values[value])}
            renderThumb={(props, state) => <div {...props}>{values[state.valueNow]}</div>}
            renderMark={(props) => <span {...{ ...props, className: `${props.className} mark-${props?.key}` }}>${values[props?.key]}</span>}
          />
        </div>
        <CustomField className="Field--sm m-b-10">
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createModal.name")}
          </label>
          <input
            name="name"
            type="text"
            onChange={handleVpcFieldsChange}
            value={name}
          />
        </CustomField>
        <CustomField className="Field--sm m-b-10">
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createModal.cidr")}
          </label>
          <input
            name="cidr"
            type="text"
            onChange={handleVpcFieldsChange}
            placeholder={t("createModal.cidrPlaceholder")}
            value={cidr}
          />
        </CustomField>
        <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
          {t("createModal.vpcOffering")}
        </label>
        <SelectVpc onChange={setSelectedVpc} vpcList={vpcListOfferings} />
        <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
          {t("createModal.region")}
        </label>
        <SelectZone onChange={setSelectedZone} zonesList={zonesList} />
        <CustomField className="Field--sm m-b-10">
          <label className="text-grey-1 text-align-left font-size-14 font-weight-semibold display-block">
            {t("createModal.networkDomain")}
          </label>
          <input
            name="networkdomain"
            type="text"
            onChange={handleVpcFieldsChange}
            value={networkdomain}
          />
        </CustomField>
      </div>
      {vpcLoading ? (
        <Loader />
      ) : (
        <div className="flex row m-t-10">
          <button
            onClick={() => onHide()}
            className="button button--grey width-half flex-grow-1 m-l-15 m-r-15"
          >
            {t("createModal.cancel")}
          </button>
          <button
            disabled={
              !selectedZone ||
              !selectedVpc ||
              !name ||
              !cidr ||
              !networkdomain ||
              (!underInstance && !selectedDomain)
            }
            onClick={handleCreateVpc}
            className="button width-half flex-grow-1 m-l-15 m-r-15"
          >
            {t("createModal.confirm")}
          </button>
        </div>
      )}
    </Modal>
  );
};

export default CreateVpc;
