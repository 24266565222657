import React from "react";
import CustomSelect from "../../components/CustomSelect";

const ListItem = ({ text, ...props }) => (
  <li {...props} className="flex flex-align-items-center p-l-5 p-t-10 p-b-10">
    <div>
      <div className="text-grey-3 font-size-15 font-weight-semibold">
        {text}
      </div>
    </div>
  </li>
);

const SelectVpc = ({ vpcList, onChange }) => {
  return (
    <div>
      <CustomSelect
        className="Field--sm Select__offering m-b-10"
        search
        noCustomStyle
        ListItem={ListItem}
        onChange={(vpcId) => {
          const [selectedVpc] = vpcList.filter(({ id }) => id === vpcId);
          onChange && onChange(selectedVpc);
        }}
        options={
          vpcList &&
          vpcList.map(({ id, name, ...props }) => ({
            value: id,
            text: name,
            ...props,
          }))
        }
      />
    </div>
  );
};

export default SelectVpc;
