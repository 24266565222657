import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import login from './login';
import register from './register';
import profile from './profile';
import footer from './footer';
import header from './header';
import sidebar from './sidebar';
import referrals from './referrals';
import createInstance from './createInstance';
import billing from './billing';
import sshKeysModal from './sshKeysModal';
import tickets from './tickets';
import dashboard from './dashboard';
import network from './network';
import createLoadBalancer from './createLoadBalancer';
import createVpcNetwork from './createVpcNetwork';
import createFirewall from './createFirewall';
import volumes from './volumes';
import common from './common';
import loadBalancers from './loadBalancers';

export default {
  forgotPassword,
  resetPassword,
  login,
  register,
  volumes,
  footer,
  header,
  profile,
  referrals,
  sidebar,
  createInstance,
  createLoadBalancer,
  createVpcNetwork,
  createFirewall,
  billing,
  sshKeysModal,
  tickets,
  dashboard,
  network,
  common,
  loadBalancers,
};
