import React, {useEffect, useState} from "react";
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useTranslation, Trans} from "react-i18next";
import {useToasts} from "react-toast-notifications";

import {post} from "../../components/http";

import mailIco from "../../assets/images/mail.svg";
import unlockIco from "../../assets/images/unlock.svg";
import loginIll1 from "../../assets/images/login-ill1.svg";
import loginIll2 from "../../assets/images/login-ill2.svg";
import loginIll3 from "../../assets/images/login-ill3.svg";

import CustomField from "../../components/CustomField";
import Checkbox from "../../components/Checkbox";

const validations = {
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
}

const SiginSchema = Yup.object().shape(validations);

const useRedirectToRef = () => {
  const pastUrl = ["https://shape.host/windows-vps-hosting", "https://shape.host/windows-cloud-vps", "https://shape.host/double-credit-top-up-offer"];
  const history = useHistory();
  const { search, pathname } = useLocation();
  useEffect(() => {
    if (pastUrl.includes(document.referrer) && document.referrer !== document.URL) { // eslint-disable-line
      history.push(`${pathname}${search}${search ? '&' : '?'}ref=${document.referrer?.split("shape.host").pop().replace("/", "")}`) // eslint-disable-line
    }
  }, [])
}

const login = async ({values, history, addToast, setNotVerified}) => {
  let loginResponse;
  try {
    loginResponse = await post("/login", values);
    const {data: {error, data} = {}} = loginResponse;
    const {token, email_verified} = data || {};
    if (!email_verified) {
      setNotVerified(true);
      // return
    }
    if (error) {
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    //eslint-disable-next-line
    localStorage.setItem("atkn", token); //TODO token should be as httponly
    //eslint-disable-next-line
    localStorage.setItem("last_logged", new Date()); //TODO token should be as httponly
    return history.push("/");
  } catch (error) {
    return addToast(error.message, {
      appearance: "error",
      autoDismiss: true,
    });
  }
};

const Login = () => {
  const [captchaToken, setCaptchaToken] = useState('');
  const [notVerified, setNotVerified] = useState(false);
  const {t} = useTranslation("login");
  const history = useHistory();
  const { search } = useLocation();
  const {addToast} = useToasts();
  useRedirectToRef()
  return (
    <div className="LoginPage">
      <div className="card">
        <div className="ill">
          <img className="ill__1" src={loginIll1} alt=""/>
          <img className="ill__2" src={loginIll2} alt=""/>
          <img className="ill__3" src={loginIll3} alt=""/>
        </div>
        <h2 className="m-b-30">{t("title")}</h2>
        <Formik
          initialValues={{
            email: "",
            password: "",
            recaptcha: "",
          }}
          validationSchema={SiginSchema}
          onSubmit={async (values) => {
            login({addToast, history, values: {...values, token: captchaToken}, setNotVerified});
          }}
        >
          {({values, errors, touched, handleChange}) => (
            <Form>
              <CustomField
                {...{
                  errors,
                  touched,
                  name: "email",
                  Ico: () => <img src={mailIco} className="ico" alt=""/>,
                }}
              >
                <Field
                  name="email"
                  type="email"
                  placeholder={t("placeholders.email")}
                />
              </CustomField>

              <CustomField
                {...{
                  errors,
                  touched,
                  name: "password",
                  Ico: () => <img src={unlockIco} className="ico" alt=""/>,
                }}
              >
                <Field
                  name="password"
                  type="password"
                  placeholder={t("placeholders.password")}
                />
              </CustomField>
              <div className="flex flex-align-items-center flex-justify-content-space-between m-b-10">
                <label className="flex flex-align-items-center">
                  <Checkbox name="remember" onChange={handleChange}/>
                  {t("labels.remember")}
                </label>
                <Link
                  to={`/forgot-password${search}`}
                  className="font-weight-semibold lighter"
                >
                  {t("links.forgotPassword")}
                </Link>
              </div>
              <button className="button width-full-width m-b-20" type="submit">
                {t("button")}
              </button>
              {notVerified ? <p>
                <Trans i18nKey="notVerified">
                  To ensure the security and authenticity of our user accounts, we require email validation. It seems you haven't confirmed your email address yet. {/*eslint-disable-line*/}
                  If you didn't receive the confirmation email or need it to be resent, please click <Link to={`/resend-confirmation-email/${values?.email}`} className="font-weight-semibold lighter">here</Link>. {/*eslint-disable-line*/}
                </Trans>
              </p> : ''}
            </Form>
          )}
        </Formik>
        {process.env.REACT_APP_ENV === "production" && <GoogleReCaptcha // eslint-disable-line
          onVerify={setCaptchaToken}/>
        }
      </div>
      <p className="text-align-center text-grey-1 font-size-16 m-t-20 font-weight-regular">
        {t("notAMember")}{" "}
        <Link to={`/register${search}`} className="font-weight-semibold lighter">
          {t("links.signUp")}
        </Link>
      </p>
    </div>
  );
};

export default Login;
