import React, {useEffect} from 'react';
import {getClientDomainsRequest} from '../../../Profile/actions';
import Checkbox from '../../../../components/Checkbox';
import {useDispatch} from 'react-redux';

import SshKey from './SshKey';

const Authentication = ({
  t,
  createInstanceAction,
  dispatch,
  state
}) => {
  const {createInstance: {authentication} = {}} = state || {};

  const reduxDispatch = useDispatch();
  useEffect(() => {
    const onMount = async () => {
      await getClientDomainsRequest()(reduxDispatch);
      createInstanceAction({authentication: "password"})(dispatch);
    };
    onMount();
  }, []);

  const handleCheck = (e) => {
    createInstanceAction({authentication: e.target.value})(dispatch);
  };
  return (
    <div className='Authentication'>
      <h3 className='font-size-20 text-grey-2 font-weight-regular m-b-15 '>{t('title')}</h3>
      <div className='flex row flex-mobile-column'>
        <div className='p-l-15 p-r-15 flex-grow-1 width-half'>
          <label
            className={`auth-box flex flex-grow-1 flex-justify-content-space-between ${
              authentication === 'sshKey' && 'active'
            }`}
          >
            <div className='flex'>
              <Checkbox
                checked={authentication === 'sshKey'}
                onChange={handleCheck}
                className='Checkbox--full-box m-r-10'
                type='radio'
                value='sshKey'
                name='authentication'
              />
              <div>
                <div className='text-grey-6 font-size-16 font-weight-semibold'>{t('sshKey')}</div>
                <div className='text-grey-1 font-size-16'>{t('sshDescription')}</div>
              </div>
            </div>
          </label>
        </div>

        <div className='p-l-15 p-r-15 flex-grow-1 width-half'>
          <label
            className={`auth-box flex flex-grow-1 flex-justify-content-space-between ${
              authentication === 'password' && 'active'
            }`}
          >
            <div className='flex'>
              <Checkbox
                checked={authentication === 'password'}
                onChange={handleCheck}
                className='Checkbox--full-box m-r-10'
                type='radio'
                value='password'
                name='authentication'
              />
              <div>
                <div className='text-grey-6 font-size-16 font-weight-semibold'>{t('passwordAuthentication')}</div>
                <div className='text-grey-1 font-size-16'>{t('passwordAuthenticationDescription')}</div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <SshKey state={state} isVisible={authentication === 'sshKey'} t={t}/>
    </div>
  );
};

export default Authentication;
