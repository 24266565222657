import React, { useState, useEffect, useContext } from "react";

import { useTranslation } from "react-i18next";
import Slider from "react-slick";

import Card from "../../components/Card";
import Tabs from "../../components/Tabs";

import { store } from "../../store";

import Instance from "./components/Instance";

import Components from "./components";

import firstProjectIco from "../../assets/images/dashboard/firstProject.svg";
import firstProjectIll from "../../assets/images/dashboard/first-project-ill.svg";
import newInstanceIco from "../../assets/images/dashboard/newInstance.svg";
import whiteNewInstanceIco from "../../assets/images/dashboard/newInstanceWhite.svg";
import blueStar from "../../assets/images/blue-star.svg";
import whiteStar from "../../assets/images/white-star.svg";
import { Link, useParams } from "react-router-dom";

import instance from "../../assets/images/create/instances.svg";
import loadBalancers from "../../assets/images/create/database.svg";
import volumes from "../../assets/images/create/hard-disk.svg";
import floatingIps from "../../assets/images/create/ip.svg";
import firewall from "../../assets/images/create/cloudFirewalls.svg";
import vpcNetworks from "../../assets/images/create/volumes.svg";
import advancedNetworks from "../../assets/images/create/advancedNetworks.svg";
import ptrRecords from "../../assets/images/create/ptrRecords.svg";
import knowledgeBase from "../../assets/images/create/knowledgeBase.svg";
import liveChat from "../../assets/images/create/liveChat.svg";
import openTicket from "../../assets/images/create/openTicket.svg";
import blog from "../../assets/images/create/blog.svg";

import { getAccountsRequest, getInstancesRequest, getIpAddressesRequest } from "./actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import {get, post} from "../../components/http";
import {appsIcons} from "../Create/CreateInstance/components/ChooseImage/Tabs/Apps";
import reqWithJobId from "../../helpers/reqWithJobId";
import Modal from "../../components/Modal";
import InstancesPassword from "./components/InstancesPassword";
import ClaimBonus from "./components/ClaimBonus/ClaimBonus";
import {useToasts} from "react-toast-notifications";

export const images = {
  instance,
  volumes,
  firewall,
  extraIps: floatingIps,
  loadBalancers,
  vpcNetworks,
  advancedNetworks,
  ptrRecords,
  knowledgeBase,
  liveChat,
  openTicket,
  resources: blog,
};

const createDetails = [
  "instance",
  "volumes",
  "firewall",
  "extraIps",
];

const afterCreateDetails = [
  "loadBalancers",
  "vpcNetworks",
  "advancedNetworks",
  "ptrRecords",
];

const learningLinks = [
  "knowledgeBase",
  "liveChat",
  "openTicket",
  "resources",
];

export const links = {
  instance: "/create-instance",
  volumes: "/volumes",
  firewall: "/vpc-network/firewalls",
  extraIps: "/vpc-network/extra-ips",
  loadBalancers: "/create-load-balancer",
  vpcNetworks: "/vpc-network",
  advancedNetworks: "/vpc-network",
  ptrRecords: "/tickets/new?message=PTR Record Request",
  openTicket: "/tickets/new"
}

const tabs = ["resources", "activity"];
export const RE_GET_INSTANCES_TIME = 10000;

const fpPromise = import('../../fp')
  .then(FingerprintJS => FingerprintJS.load());

const Dashboard = ({location: {pathname} = {}}) => {
  const { t } = useTranslation("dashboard");
  const [activeTab, setActiveTab] = useState("resources");
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [visitorId, setVisitorId] = useState(null);
  const [instancesPassword, setInstancesPassword] = useState([]);
  const TabComponent = Components[activeTab];
  const { id: instanceId, tab } = useParams();
  const globalState = useContext(store);
  const {addToast} = useToasts();
  const { state: { clientDetails: { id, firstname, user: { bonus_claimed, referrer_id } = {} } = {} } = {} } = globalState;

  const dispatch = useDispatch();
  const { instances, loading } = useSelector((state) => state.instances) || {};

  const [activeInstance] =
    (instanceId &&
      instances &&
      instances.filter(({ id }) => id === instanceId))  ||
    [];

  const [apps, setApps] = useState([])

  useEffect(() => {
    const onMount = async () => {
      const {data} = await get('/server-apps')
      const [appsResponse] = data?.data
      const parsedApps = appsResponse?.map(app => {
        let {icon, iconActive} = appsIcons.find(({name}) => {
          return app?.app_name?.toLowerCase() === name?.toLowerCase
        }) || {}
        if (!icon) {
          const foundIcon = appsIcons.find(({name}) => {
            return app?.app_name?.toLowerCase().includes(name?.toLowerCase())
          }) || {}
          icon = foundIcon?.icon;
          iconActive = foundIcon?.iconActive;
        }
        return {...app, icon, iconActive}
      })
      setApps(parsedApps)
    }
    onMount()
  }, []);

  useEffect(() => {
    async function onMount() {
      await getAccountsRequest()(dispatch);
      await getIpAddressesRequest()(dispatch);
    }
    if (instances === null) {
      onMount();
    }

  }, []); //eslint-disable-line

  const storageInstances = localStorage.getItem("instancesOnWait") //eslint-disable-line

  useEffect(() => {
    const onMount = async () => {
      let instancesOnWait = JSON.parse(storageInstances) || [] //eslint-disable-line
      if(instancesOnWait?.length) {
        const instancesResponse = instancesOnWait.map(({orderId}) => {
          return reqWithJobId(null, null, orderId, true)
        });
        const responses = await Promise.all(instancesResponse);

        const instancesWithPassword = responses.filter(result => !!result?.data?.data[0]?.deploy_vm_result?.virtualmachine?.password).map(result => ({name: result?.data?.data[0]?.deploy_vm_result?.virtualmachine?.displayname, password: result?.data?.data[0]?.deploy_vm_result?.virtualmachine?.password}))
        setInstancesPassword(instancesWithPassword)
      }
    }
    onMount()
  }, [storageInstances]); //eslint-disable-line

  useEffect(() => {
    let timeout
    const continuousGetInstances = async () => {
      try {
        const instancesResponse = await getInstancesRequest(id)(dispatch);
        const instancesIsNotOnWait = instancesResponse?.filter(({state}) => state === "Stopped" || state === "Running") || []
        if(!instancesIsNotOnWait?.length) {
          timeout = setTimeout(() => continuousGetInstances(), RE_GET_INSTANCES_TIME); //eslint-disable-line
        }
      } catch (error) {
        clearTimeout(timeout) //eslint-disable-line
      }
    }
    async function onMount() {
      await continuousGetInstances()
    }

    onMount();

    return () => {
      clearTimeout(timeout) //eslint-disable-line
    }
  }, [pathname])

  const closePasswords = () => {
    setInstancesPassword([])
    localStorage.removeItem("instancesOnWait") //eslint-disable-line
  }

  const accountId = activeInstance?.tags?.filter(({key}) => key === "service_id")[0]?.value

  const [claimSuccess, setClaimSuccess] = useState(false)

  const onClaim = async (coupon) => {
    let response
    try {
      response = await post("/redeem", {id: visitorId, coupon})
    } catch (error) {
      response = {error}
    }

    if (response?.data?.error || response?.error) {
      addToast(t("Something went wrong"), {
        appearance: 'error',
        autoDismiss: true
      });
      return response?.data?.error
    }
    return setClaimSuccess(response)
  }

  useEffect(() => {
    const onMount = async () => {
      const closedClaim = localStorage.getItem("closeClaimBonus"); //eslint-disable-line
      if (bonus_claimed === 0 && referrer_id !== null && closedClaim !== "true") { //eslint-disable-line
        try {
          let result = await(await fpPromise).get() //eslint-disable-line
          console.log(result) //eslint-disable-line
          setVisitorId(result?.visitorId)
          setShowClaimModal(true)
        } catch (error) {
          setShowClaimModal("error")
        }
      }
    }
    onMount()
  }, [bonus_claimed, localStorage.getItem("closeClaimBonus")]) //eslint-disable-line

  return (
    <div className="Dashboard">

      <Modal show={instancesPassword?.length} onHide={() => closePasswords}>
        <InstancesPassword onClose={closePasswords} instancesPasswords={instancesPassword} />
      </Modal>
      <div className="container">
        <h2 className="title">{t("resources.title")}</h2>
        {instanceId ? (
          <Instance
            {...{ ...activeInstance, accountid: accountId , t, instanceId, setInstancesPassword, tab, dispatch, loading }}
          />
        ) : (
          <div>
            {instances === null ?
              <div className={"flex flex-justify-content-center p-t-30 p-b-30"}>
                <Loader />
              </div> :
              (instances && instances.length ? (
              <div className="Resources m-b-50">
                <Tabs>
                  <Tabs.Header>
                    <ul>
                      {tabs.map((name) => (
                        <li key={name}>
                          <a
                            href="javascript:;"
                            onClick={() => setActiveTab(name)}
                            className={`${activeTab === name && "active"}`}
                          >
                            {t(`tabs.${name}`)}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Tabs.Header>
                  <Tabs.Content>
                    <TabComponent t={t} instances={instances} />
                  </Tabs.Content>
                </Tabs>
              </div>
            ) : (
              <Card className="firstProject">
                <Card.Body>
                  <img src={firstProjectIco} alt="" />
                  <img className="ill hidden-xs" src={firstProjectIll} alt="" />
                  <div>
                    <h3 className="font-size-20">
                      {t("resources.firstProject.title")}
                    </h3>
                    <p className="text-grey-1">
                      {t("resources.firstProject.description")}{" "}
                      <a href="">{t("resources.firstProject.link")}</a>
                    </p>
                  </div>
                  <span className="close" />
                </Card.Body>
              </Card>
            ))}
          </div>
        )}
        <div className="createInstance">
          <h2 className="title">{t("createInstance.title")}</h2>
          <div className="row flex boxes hidden-xs hidden-xs-flex">
            <Card className="flex-grow-1 p-l-15 p-r-15 width-full-width createInstance__card">
              <Link to={firstname ? "/create-instance" : "/profile"}>
                <Card.Body className="flex-column">
                  <div className="flex flex-justify-content-center flex-align-items-center">
                    <img src={newInstanceIco} alt="" className="ico" />
                    <img src={whiteNewInstanceIco} className="ico ico--white" alt="" />
                    <img src={blueStar} className="star" alt="" />
                    <img src={whiteStar} className="star star--white" alt="" />
                  </div>
                  <h3>{t("createInstance.newInstance.title")}</h3>
                  <p className="m-t-0">
                    {t("createInstance.newInstance.description")}
                  </p>
                </Card.Body>
              </Link>
            </Card>
            {apps?.map(({ app_name: name, version, os, description, icon }) => (
                <Card
                  key={name}
                  className="flex-grow-1 p-l-15 p-r-15 width-full-width"
                >
                  <Card.Body className="flex-column intancesBox">
                    <div className="flex intancesBox__image flex-align-items-center flex-justify-content-center">
                      <img className={"logo"} src={icon} alt="" />
                    </div>
                    <h3>
                      <img className="logo--sm" src={icon} alt="" />
                      {name}
                    </h3>
                    <div>
                      <span className="font-weight-semibold text-grey-5 m-r-5">
                        {t("createInstance.version")}
                      </span>
                      <span className="text-grey-5">{version}</span>
                    </div>
                    <div>
                      <span className="font-weight-semibold text-grey-5 m-r-5">
                        {t("createInstance.os")}
                      </span>
                      <span className="text-grey-5">{os[0]?.os}</span>
                    </div>
                    <p>{description}</p>
                    <div className="buttons">
                      <Link to={firstname ? "/create-instance?imageTab=apps" : "/profile"} className="button button--sm button--blue width-full-width">
                        {t("createInstance.button")}
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              )
            )}
          </div>
          <div className="row boxes visible-xs">
            <Slider
              {...{
                responsive: [
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                      dots: true,
                      centerPadding: 50,
                      centerMode: true,
                      arrows: false,
                    },
                  },
                ],
              }}
            >
              <Card className="flex-grow-1 p-l-15 p-r-15 width-full-width createInstance__card">
                <Card.Body className="flex-column">
                  <div className="flex flex-justify-content-center flex-align-items-center">
                    <img src={newInstanceIco} alt="" />
                    <Link to={firstname ? "create-instance" : "/profile"} className="plus" />
                  </div>
                  <h3>
                    {t("createInstance.newInstance.title")}
                  </h3>
                  <p className="m-t-0">
                    {t("createInstance.newInstance.description")}
                  </p>
                </Card.Body>
              </Card>
              {apps?.map(({ app_name: name, version, os, description, icon }) => (
                  <Card
                    key={name}
                    className="flex-grow-1 p-l-15 p-r-15 width-full-width"
                  >
                    <Card.Body className="flex-column intancesBox">
                      <div className="flex intancesBox__image flex-align-items-center flex-justify-content-center">
                        <img src={icon} alt="" />
                      </div>
                      <h3>
                        <img className="logo--sm" src={icon} alt="" />
                        {name}
                      </h3>
                      <div>
                        <span className="font-weight-semibold text-grey-5 m-r-5">
                          {t("createInstance.version")}
                        </span>
                        <span className="text-grey-5">{version}</span>
                      </div>
                      <div>
                        <span className="font-weight-semibold text-grey-5 m-r-5">
                          {t("createInstance.os")}
                        </span>
                        <span className="text-grey-5">{os[0]?.os}</span>
                      </div>
                      <p>{description}</p>
                      <div className="buttons">
                        <Link to={firstname ? "/create-instance?imageTab=apps" : "/profile"} className="button button--sm button--blue width-full-width">
                          {t("createInstance.button")}
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                )
              )}
            </Slider>
          </div>
          <div className="Dashboard__links m-t-30">
            <div className="flex row flex-mobile-column">
              <div className="flex-grow-1 width-full-width p-l-15 p-r-15">
                <h3>{t("create.title")}</h3>
                <div>
                  {createDetails.map((detail) => (
                    <Link to={links[detail]} key={detail}>
                      <div className="flex">
                        <div className="image">
                          <img src={images[detail]} alt="" />
                        </div>
                        <div>
                          <h4 className="text-grey-1 font-size-18 font-weight-regular m-t-0 m-b-5">
                            {t(`create.${detail}.title`)}
                          </h4>
                          <p className="font-size-15 m-t-0 text-blue-1">
                            {t(`create.${detail}.description`)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex-grow-1 width-full-width p-t-45  p-l-15 p-r-15">
                <h3></h3>
                <div>
                  {afterCreateDetails.map((detail) => (
                    <Link to={links[detail]} key={detail}>
                      <div key={detail} className="flex">
                        <div className="image">
                          <img src={images[detail]} alt="" />
                        </div>
                        <div>
                          <h4 className="text-grey-1 font-size-18 font-weight-regular m-t-0 m-b-5">
                            {t(`afterCreate.${detail}.title`)}
                          </h4>
                          <p className="font-size-15 m-t-0 text-blue-1">
                            {t(`afterCreate.${detail}.description`)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex-grow-1 width-full-width p-l-15 p-r-15">
                <h3>{t("learning.title")}</h3>
                <div>
                  {learningLinks.map((detail) => (
                    <Link to={links[detail]} key={detail}>
                      <div key={detail} className="flex">
                        <div className="image">
                          <img src={images[detail]} alt="" />
                        </div>
                        <div>
                          <h4 className="text-grey-1 font-size-18 font-weight-regular m-t-0 m-b-5">
                            {t(`learning.${detail}.title`)}
                          </h4>
                          <p className="font-size-15 m-t-0 text-blue-1">
                            {t(`learning.${detail}.description`)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        onHide={() => {
          localStorage.setItem("closeClaimBonus", "true"); //eslint-disable-line
          setShowClaimModal(false)
        }}
        show={showClaimModal}
        Title={() => showClaimModal === "error" ?
          <>
            <p className={'ClaimBonus__title m-t-0 m-b-20'}>Please disable your <br/>
              <strong>AdBlocker</strong>
            </p>
          </> : (
          <>{!claimSuccess ?
            (<p className={'ClaimBonus__title m-t-0 m-b-20'}>Claim your credit from <br/>
              <strong>Creative Tim</strong>
            </p>) : <p className={'ClaimBonus__title m-t-0 m-b-20'}>Coupon successfully <br/> claimed</p>
          }
          </>
        )}
        size="small">
        <ClaimBonus error={showClaimModal === "error"} success={claimSuccess} onSubmit={onClaim} />
      </Modal>
    </div>
  );
};

export default Dashboard;
