import React from "react";
import Slider from "react-slick";

import AddNew from "../../common/AddNew";

import backupsIcon from "../../../../../../assets/images/chooseImage/backups-icon.svg";

const backups = [
  {
    name: "Backupt_nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_3nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_4nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_4nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_5nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_6nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_3nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_4nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_4nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_5nam",
    created: "4 days ago",
    size: 19.8,
  },
  {
    name: "Backupt_6nam",
    created: "4 days ago",
    size: 19.8,
  },
];

const Backups = () => {
  return (
    <div className="Backups flex row image-box-container">
      <AddNew className="m-l-20 m-r-20" />
      {backups.map(({ name, created, size }) => (
        <div
          key={name}
          className="flex flex-align-items-center flex-justify-content-space-between hidden-xs hidden-xs-flex image-box m-l-20 m-r-20"
        >
          <div className="flex flex-align-items-center">
            <img className="m-r-10" src={backupsIcon} alt="" />
            <div className="text-grey-1 font-weight-regular">
              <div className="text-grey-2 font-weight-16 font-weight-semibold">
                {name}
              </div>
              {created}
            </div>
          </div>
          <div className="text-grey-1 text-align-right m-l-10">
            <div className="text-blue-1 font-weight-16 font-weight-semibold">
              {size}
            </div>
            GB
          </div>
        </div>
      ))}
      <div className={`visible-xs`}>
        <Slider
          {...{
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  rows: 5,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  centerPadding: 50,
                  centerMode: true,
                  arrows: false,
                },
              },
            ],
          }}
        >
          {backups.map(({ name, created, size }) => (
            <div key={name} className="p-l-20 p-r-20 m-b-15">
              <div className="flex flex-align-items-center flex-justify-content-space-between image-box">
                <div className="flex flex-align-items-center">
                  <img className="m-r-10" src={backupsIcon} alt="" />
                  <div className="text-grey-1 font-weight-regular">
                    <div className="text-grey-2 font-weight-16 font-weight-semibold">
                      {name}
                    </div>
                    {created}
                  </div>
                </div>
                <div className="text-grey-1 text-align-right m-l-10">
                  <div className="text-blue-1 font-weight-16 font-weight-semibold">
                    {size}
                  </div>
                  GB
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Backups;
