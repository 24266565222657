import React, {useEffect, useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import pretty from "prettysize";
import power from "../../../../../assets/images/dashboard/power.svg";
import powerBlue from "../../../../../assets/images/dashboard/power-blue.svg";
import apps from "../../../../../assets/images/dashboard/apps.svg";
import refresh from "../../../../../assets/images/dashboard/refresh.svg";
import resize from "../../../../../assets/images/dashboard/resize.svg";
import settings from "../../../../../assets/images/dashboard/settings.svg";
import trash from "../../../../../assets/images/dashboard/trash.svg";
import {getIpAddressesRequest, getNetworksRequest, updateInstanceStatus} from "../../../actions";
import {useDispatch, useSelector} from "react-redux";
import Card from "../../../../../components/Card";
import line from "../../../../../assets/images/small-line.svg";
import switchIco from "../../../../../assets/images/dashboard/switch.svg";
import hardDisk from "../../../../../assets/images/create/volumes.svg";
import addIco from "../../../../../assets/images/add.svg";
import {Link} from "react-router-dom";
import DestroyModal from "../../Instance/Tabs/DestroyModal";
import Loader from "../../../../../components/Loader/Loader";
import {post} from "../../../../../components/http";
import reqWithJobId from "../../../../../helpers/reqWithJobId";

const ExtraIps = ({ips}) => {
  return (
    <div className="ExtraIps">
      <Card>
        <Card.Body>
          {ips.map(({ipaddress: ip}, i) =>
            i !== 0 && <span key={ip}><img src={switchIco} alt=""/>{ip}</span>
          )}
          {ips.length > 1 ? <img className="line" src={line}/> : ''}
          <Link to="vpc-network/extra-ips">
            <span className="text-blue-1 cursor-pointer"><img src={settings} alt=""/>Add / Remove IPs</span>
          </Link>
        </Card.Body>
      </Card>
    </div>
  )
}

export const VpcModal = ({networks, instanceId}) => {
  const [loading, setLoading] = useState(false)
  const addVpcToInstance = async ({accountId, networkId}) => {
    setLoading(true)
    await reqWithJobId(post(`/accounts/${accountId}/vm/${instanceId}/add-to-network/${networkId}`), accountId)
    setLoading(false)
  }
  return (
    <div className="VpcModal">
      <Card>
        <Card.Body>
          <Link
            to={`/vpc-network`}
          >
            <span className="text-blue-1 cursor-pointer"><img src={addIco} alt=""/>Create new VPC</span>
          </Link>
          {networks.length ? <img className="line" src={line}/> : ''}
          {loading ? <div className={'flex flex-justify-content-center'}><Loader size={"small"} /></div> : networks.map(({name, id: networkId, accountId}) =>
            <span className={'cursor-pointer'} onClick={() => addVpcToInstance({networkId, accountId})} key={name}><img src={hardDisk} alt=""/>{name}</span>
          )}
        </Card.Body>
      </Card>
    </div>
  )
}

const updateStatus = async ({ dispatch, accountid, id: vmId, start, setIsLoading }) => {
  try {
    await updateInstanceStatus({ accountid, vmId, start })(dispatch);
  } catch (error) {
    setIsLoading(false)
    throw new Error(error);
  }
};

const InstanceDetails = ({
     id,
     server,
     ip,
     cpu,
     ipv6,
     location,
     memory,
     price,
     accountid,
     zoneid,
     state,
     storage,
     t: initialTranslate,
     commonT,
     nic,
     osIco,
     iscompatible
    }) => {
  const dispatch = useDispatch();
  const [extraIpsModal, setExtraIpsModal] = useState(null)
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [vpcModal, setVpcModal] = useState(null)
  const { floatingIpList, networks } = useSelector(({ instances }) => instances);
  const t = (path) => initialTranslate(`instanceDetails.${path}`);
  const [advancedNetwork] = nic.filter(net => net.type === "Isolated");
  const [basicNetwork] = nic.filter(net => net.type === "Shared");
  const floatingIps = floatingIpList.filter(({virtualmachineid}) => virtualmachineid === id);
  console.log(iscompatible) // eslint-disable-line

  useEffect(() => {
    getIpAddressesRequest()(dispatch);
    getNetworksRequest()(dispatch);
  }, []);
  return (
    <div className="InstanceDetails">
      <div className="flex">
        <div>
          <div className="InstanceDetails__image">
            <span>{t("image")}</span>
            <img src={osIco}/>
            {server}
          </div>
          <div>
            <span>{t("size")}</span>
            <div>
              <span>{cpu} {t("cpus")}</span>
              {storage && <span>{pretty(storage * 1024 * 1024)} {t("disk")}</span>}
            </div>
          </div>
          <div>
            <span/>
            <div>
              <span>{pretty(memory * 1024 * 1024)} RAM</span>
              {price && <a href=""><span>{price}/{t("monthly")}</span></a>}
            </div>
          </div>
        </div>
        <div>
          <div className="copy-wrapper">
            <span>IPv4</span>
            {basicNetwork?.ipaddress}
          </div>
          <div className="copy-wrapper">
            <span>IPv6</span> {ipv6}
            <span className="text-blue-1">{t("soon")}</span>
          </div>
          <div className="copy-wrapper">
            <span>Extra IPs</span> {ipv6}
            {floatingIps.length
              ? <div className="position-relative min-width-auto">
                  <span onClick={() => setExtraIpsModal(!extraIpsModal)} className="text-blue-1 cursor-pointer">{floatingIps[0].ipaddress} {floatingIps.length-1 ? <span className="font-weight-regular">{`+${floatingIps.length-1} IPs`}</span> : ''}</span>
                  {extraIpsModal ? <ExtraIps ips={floatingIps}/> : ''}
                </div>
              : <Link className={iscompatible === 0 && "grey-out"} to="vpc-network/extra-ips"><span className="text-blue-1">Assign IPs</span></Link>
            }
          </div>
        </div>
        <div>
          {advancedNetwork ? (
            <div className="copy-wrapper">
              <span>{t("privateIp")}</span>
              {advancedNetwork?.ipaddress}
              <CopyToClipboard text={ip}>
                <span className="copy text-blue-1 m-l-10">
                  {commonT("copy")}
                </span>
              </CopyToClipboard>
            </div>
          ) : (
            <div className="position-relative">
              <span>{t("privateIp")}</span>
              <div className="position-relative vpc-container">
                <span onClick={() => setVpcModal(!vpcModal)} className={`text-blue-1 cursor-pointer position-relative ${iscompatible === 0 && "grey-out"}`}>
                  Add to VPC
                </span>
                {vpcModal && <VpcModal instanceId={id} networks={networks.filter(({zoneid: netZoneId}) => {
                  return netZoneId === zoneid
                })}/>}
              </div>
            </div>
          )}
          <div>
            <span>VPC</span> {advancedNetwork?.networkname}
          </div>
          <div>
            <span>{t("region")}</span> {location}
          </div>
        </div>
      </div>
      <div className="actions">
        <div>
            <span className={`actions__item ${!(isLoading === false || isLoading !== state && (state !== 'Stopping' && state !== 'Starting')) && 'pointer-events-none' }`} onClick={async () => {
              setIsLoading(state)
              updateStatus({start: state !== 'Running', dispatch, accountid, id, setIsLoading})
            }
            }>
              {isLoading === false || isLoading !== state && (state !== 'Stopping' && state !== 'Starting') ?  <img src={state === 'Running' ? power : powerBlue}/> : <Loader size={"small"} className={"m-r-5"} />}
              Turn {state === 'Running' ? 'off' : 'on'}
            </span>
          <Link
            to={`/instance/${id}/access`}
          >
            <span className={"actions__item"}>
              <img src={apps}/>
              Console
            </span>
          </Link>
          <Link
            className={iscompatible === 0 && "grey-out"}
            to={`/instance/${id}/resize`}
          >
            <span className="text-grey-1 actions__item">
              <img src={resize}/>
              Resize
            </span>
          </Link>
        </div>
        <div>
          <Link
            to={`/instance/${id}/graphs`}
          >
            <span className="text-grey-1 actions__item">
              <img src={settings}/>
              Manage
            </span>
          </Link>
          <span className={`text-red-2 actions__item ${(isLoading || state === 'Stopping' || state === 'Starting') && 'grey-out' }`} onClick={() => setDeleteOpen(true)}>
              <img src={trash}/>
              Destroy
          </span>
          <Link
            className={"invisible"}
            to={`/instance/${id}/backups`}
          >
            <span className="text-grey-1 actions__item">
              <img src={refresh}/>
              Backup
            </span>
          </Link>
        </div>
      </div>
      <DestroyModal
        show={isDeleteOpen}
        onHide={() => setDeleteOpen(false)}
        accountid={accountid}
        instanceId={id}
        dispatch={dispatch}
      />
    </div>
  );
};

export default InstanceDetails;
