import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import CustomField from "../../../components/CustomField";
import Checkbox from "../../../components/Checkbox";
import Card from "../../../components/Card";
import { post } from "../../../components/http";
import Loader from "../../Volumes/components/Loader/Loader";
import { useToasts } from "react-toast-notifications";

const CreditCardSchema = Yup.object().shape({
  terms: Yup.boolean().oneOf([true]).required("Required"),
  amount: Yup.string().required("Required"),
});

const amounts = [10, 25, 50, 100, 250];

const CreditCard = ({ t: creditCardTrans }) => {
  const { addToast } = useToasts();
  const t = (path) => creditCardTrans(`paypal.${path}`);
  const { payModules } = useSelector(({ billing }) => billing);
  const [payModule, setPayModule] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (payModules.length > 0) {
      const [payPalModule] = payModules.filter(
        ({ module }) => module === "PayPal"
      );
      setPayModule(payPalModule);
    }
  }, [payModules]);

  return (
    <div>
      <Formik
        initialValues={{ amount: "50" }}
        validationSchema={CreditCardSchema}
        onSubmit={async (value) => {
          setLoading(true);
          try {
            const { data } = await post(`/client/payment/add-credit`, {
              amount: value.amount,
              paymentmodule: payModule.id,
            });
            setLoading(false);
            if (data.error) {
              setLoading(false);
              return addToast(data.error.message, {
                appearance: "error",
                autoDismiss: true,
              });
            }
            // eslint-disable-next-line no-undef
            window.open(data.data.payNowLink, "_self", "noopener,noreferrer");
          } catch (error) {
            setLoading(false);
            return addToast(error.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          handleChange,
          values,
          submitCount,
        }) => (
          <Form>
            <h3 className="font-size-27 text-grey-2 font-weight-regular m-t-20 m-b-30">
              {t("title")}
            </h3>
            <div
              className={`amounts flex-wrap flex row m-b-20 ${
                errors.amount && submitCount && "amounts--error"
              }`}
            >
              {amounts.map((amount) => {
                return (
                  <Card
                    key={amount}
                    onClick={() => {
                      setFieldValue("otherAmount", false);
                      setFieldValue("amount", amount.toString());
                    }}
                    className="flex-grow-1 m-b-10"
                  >
                    <Card.Body
                      className={`m-l-15 m-r-15 flex-justify-content-center font-weight-semibold text-grey-2 ${
                        !values.otherAmount &&
                        values.amount === amount.toString() &&
                        "active"
                      }`}
                    >
                      {amount} {t("dollars")}
                    </Card.Body>
                  </Card>
                );
              })}
              <Card
                onClick={() => {
                  setFieldValue("otherAmount", true);
                  setFieldValue("amount", "");
                }}
                className="flex-grow-1 amounts__other"
              >
                <Card.Body
                  className={`m-l-15 m-r-15 flex-justify-content-center font-weight-semibold text-grey-2 ${
                    values.otherAmount && "active"
                  }`}
                >
                  {t("otherAmount")}
                </Card.Body>
              </Card>
            </div>

            {values.otherAmount && (
              <CustomField
                {...{
                  errors,
                  touched,
                  submitCount,
                  className: `flex-grow-1 m-t-15 m-b-10 ${
                    !values.amount && "otherAmount"
                  }`,
                  name: "amount",
                }}
              >
                <Field
                  name="amount"
                  type="text"
                  placeholder={t("placeholder")}
                />
              </CustomField>
            )}

            <label className="flex flex-align-items-center text-grey-1 font-weight-regular m-t-20 m-b-30">
              <Checkbox
                {...{ errors, touched, onChange: handleChange }}
                name="terms"
                className="m-r-10"
                touched={touched}
                submitCount={submitCount}
              />
              {t("acceptTerms")}
              <a className="m-l-5 font-weight-regular" href="">
                {t("termsLink")}
              </a>
            </label>

            <button
              className="button button--blue width-full-width m-b-20 flex"
              type="submit"
            >
              {loading ? (
                <Loader />
              ) : (
                <>
                  {t("pay")} ${values.amount}
                  {t("withPaypal")}
                </>
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreditCard;
