import React, { useMemo, useState } from 'react';
import useComponentVisible from '../../../../helpers/useComponentVisible';
import ProtocolIco from '../../../../assets/images/network/protocol-icon.svg';
import SelectWithIcons from '../../../../components/SelectWithIcons';
import VmIco from '../../../../assets/images/network/vm_icon.svg';
import IpIco from '../../../../assets/images/network/ip_icon.svg';
import LbIco from '../../../../assets/images/network/load_balancer_icon_small.svg';
import LoadbalancerIco from '../../../../assets/images/network/load_balancer_icon.svg';
import InstanceIco from '../../../../assets/images/create/instances2.svg';
import LocationIco from '../../../../assets/images/ip-location.svg';
import IconX from '../../../../assets/images/network/delete_x_icon.svg';
import TrashIco from '../../../../assets/images/tickets/trash_icon.svg';
import ConfigIco from '../../../../assets/images/config-icon.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import pretty from "prettysize";
import DeleteModal from './DeleteModal';

const protocols = [
    { value: 'tcp', text: 'TCP', icon: ProtocolIco },
    { value: 'icmp', text: 'ICMP', icon: ProtocolIco },
    { value: 'udp', text: 'UDP', icon: ProtocolIco }
];

const handleIcon = (type, item) => {
    switch (type) {
        case "vm":
            return item === 'source' ? VmIco : InstanceIco;
        case "lb":
            return item === 'source' ? LbIco : LoadbalancerIco;
        case "ip":
            return item === 'source' ? IpIco : LocationIco;
    }
}

const handleInitialSource = (cidrlist, extraIPs, lbGroups) => {
    const cidrs = cidrlist?.split(',');
    const cidrItems = [];

    if (cidrlist && cidrs.length) {
        cidrLoop: for (const cidr of cidrs) {
            if (cidr === '0.0.0.0/0') {
                cidrItems.push({ text: 'All IPv4', value: cidr });
            } else {
                const [cidrIp] = cidr.split('/');
                for (const extraIp of extraIPs) {
                    if (extraIp.virtualmachinedisplayname && extraIp.ipaddress === cidrIp) {
                        cidrItems.push({ text: extraIp.virtualmachinedisplayname, value: cidr, icon: VmIco });
                        continue cidrLoop;
                    }
                }

                for (const lbGroup of lbGroups) {
                    if (lbGroup.publicip && lbGroup.publicip === cidrIp) {
                        cidrItems.push({ text: lbGroup.name, value: cidr, icon: LbIco });
                        continue cidrLoop;
                    }
                }

                cidrItems.push({ text: cidrIp, value: cidr, icon: IpIco });
            }
        }

        return cidrItems;
    } else {
        return [];
    }
}

const generateServerName = (displayname, search) => {
    const index = displayname?.toLowerCase().indexOf(search?.toLowerCase());
    const value = displayname.slice(index, search?.length + index)
    const nameArray = displayname.split(value)

    const html = nameArray.join(`<span class="text-blue-1">${value}</span>`)
    return html
}

const FirewallRule = ({ rule, handleRuleDelete, handleRuleEdit, deletable, i, t }) => {
    const { floatingIpList, lbGroups, instances } = useSelector(({ instances }) => instances);
    const [isModalVisible, setModalVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [source, setSource] = useState(handleInitialSource(rule?.cidrlist, floatingIpList, lbGroups));
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(null, 'more');
    const { isComponentVisible: expanded, setIsComponentVisible: setExpanded } = useComponentVisible(false, 'search');
    const { t: commonT } = useTranslation('common');

    const protocol = useMemo(() => {
        return protocols.find(protocol => protocol.value === rule?.protocol);
    }, [rule]);

    const handleProtocolChange = (choosenProtocol) => {
        if (choosenProtocol.value === 'icmp') {
            handleRuleEdit({ ...rule, protocol: choosenProtocol.value, icmptype: 0, icmpcode: 0, startport: null, endport: null });
        } else {
            handleRuleEdit({ ...rule, protocol: choosenProtocol.value, icmptype: null, icmpcode: null, startport: 1, endport: 65535 });
        }
    }

    const handleSourceDelete = (sourceItem) => {
        source.splice(source.findIndex(item => item.text === sourceItem.text), 1);

        setSource([...source]);
        const sourceString = source.map(item => { return item.value }).join(',');
        handleRuleEdit({ ...rule, cidrlist: sourceString });
    }

    const deleteBehaviour = (rule) => {
        setIsComponentVisible(null);
        handleRuleDelete(rule);
    }

    const editBehaviour = (firewallRule) => {
        setIsComponentVisible(null);
        handleRuleEdit({ ...firewallRule, type: 'edit' })
    }

    const addIPv4 = () => {
        const IPv4 = { text: 'All IPv4', value: '0.0.0.0/0' };
        const sourceCopy = [...source];
        sourceCopy.unshift(IPv4);

        setSource(sourceCopy);
        const editedRule = { ...rule, cidrlist: rule.cidrlist ? '0.0.0.0/0,' + rule.cidrlist : '0.0.0.0/0' };
        handleRuleEdit(editedRule);
    }

    const addSource = (item) => {
        const newSource = { text: item.name, value: `${item.value}/32`, icon: handleIcon(item.type, "source") };
        const sourceCopy = [...source];
        sourceCopy.push(newSource);

        setSource(sourceCopy);
        const sourceString = source.map(item => { return item.value }).join(',');
        const sourceStringFull = sourceString ? sourceString + `,${item.value}/32` : `${item.value}/32`;
        const editedRule = { ...rule, cidrlist: sourceStringFull };
        handleRuleEdit(editedRule);
        setSearchText('');
    }

    const handleIconPortChange = (value, type) => {
        if (type === 'portRange') {
            const [startport, endport] = value.split('-');
            if (startport && endport) {
                handleRuleEdit({ ...rule, startport: startport, endport: endport });
            } else {
                handleRuleEdit({ ...rule, startport: 0, endport: 0 });
            }
        }

        if (type === 'icmpType') {
            handleRuleEdit({ ...rule, icmptype: value });
        }

        if (type === 'icmpCode') {
            handleRuleEdit({ ...rule, icmpcode: value });
        }
    }

    const isIPv4Exist = useMemo(() => {
        if (source && source.length) {
            let isExist = false;
            source.forEach(item => {
                if (item.value === '0.0.0.0/0') {
                    isExist = true;
                }
            })
            return isExist;
        } else {
            return false;
        }
    }, [source]);


    const searchList = useMemo(() => {
        const list = [];
        let searchResult = [];
        const usedIps = source.map(item => item.value.split('/')[0]);

        if (searchText) {
            lbGroups.forEach(group => {
                if (group.publicip && !usedIps.includes(group.publicip)) {
                    list.push({ name: group.name, value: group.publicip, type: 'lb' });
                }
            })

            floatingIpList.forEach(extraIp => {
                if (!usedIps.includes(extraIp.ipaddress)) {
                    if (extraIp.virtualmachinename) {
                        const choosenInstance = instances?.find(instance => instance.id === extraIp.virtualmachineid);
                        list.push({
                            name: extraIp.virtualmachinename,
                            value: extraIp.ipaddress,
                            type: 'vm',
                            state: choosenInstance.state,
                            cpunumber: choosenInstance.cpunumber,
                            memory: choosenInstance.memory,
                            zonename: choosenInstance.zonename,
                        });
                    } else {
                        const ipExist = list.find(item => item.value === extraIp.ipaddress);

                        if (!ipExist) {
                            list.push({ name: extraIp.ipaddress, value: extraIp.ipaddress, type: 'ip' })
                        }
                    }
                }
            })

            if (list.length) {
                searchResult = list.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()));
            }
        }


        return searchResult;
    }, [lbGroups, searchText, source]);

    const handleAddExternalSource = (e) => {
        if (e.key === 'Enter') {
            const regex = /^([0-9]{1,3}\.){3}[0-9]{1,3}($|\/)([1-2]?[0-9]$|[3][0-2]$)/
            if (regex.test(searchText)) {
                const [ip, prefix] = searchText.split('/')
                const newSource = { text: searchText, value: `${ip}/${prefix ? prefix : '32'}`, icon: IpIco };
                const sourceCopy = [...source];
                sourceCopy.push(newSource);

                setSource(sourceCopy);
                const sourceString = source.map(item => { return item.value }).join(',');
                const sourceStringFull = sourceString ? sourceString + `,${newSource.value}` : `${newSource.value}`;
                const editedRule = { ...rule, cidrlist: sourceStringFull };
                handleRuleEdit(editedRule);
                setSearchText('');
            }
        }
    }

    return (
        <>{rule.type === 'edit' || rule.type === 'new' ?
            <div className='List_item_edit'>
                <div><SelectWithIcons options={protocols} defaultOption={protocol} onChange={handleProtocolChange} /></div>
                {rule?.protocol !== 'icmp' ? <div>
                    <input
                        defaultValue={rule?.startport && rule?.endport ? `${rule?.startport}-${rule?.endport}` : ''}
                        onChange={(e) => { handleIconPortChange(e.target.value, 'portRange') }}
                    />
                </div> : null}
                {rule?.protocol === 'icmp' ? <div>
                    <input
                        onKeyPress={(event) => {
                            if (!/^[\d./]+$/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        defaultValue={rule?.icmptype}
                        onChange={(e) => { handleIconPortChange(e.target.value, 'icmpType') }}
                    />
                </div> : null}
                {rule?.protocol === 'icmp' ? <div>
                    <input
                        onKeyPress={(event) => {
                            if (!/^[\d./]+$/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        defaultValue={rule?.icmpcode}
                        onChange={(e) => { handleIconPortChange(e.target.value, 'icmpCode') }}
                    />
                </div> : null}
                <div className='position-relative'>
                    <div id='search' className='Source_input'>
                        {source && source.length ? source.map(sourceItem => {
                            return <div className='Source_item' key={sourceItem.text}>
                                {sourceItem.icon ? <img src={sourceItem.icon} /> : null}
                                <span>{sourceItem.text}</span>
                                <img className='x-icon' src={IconX} onClick={() => handleSourceDelete(sourceItem)} />
                            </div>
                        }) : null}
                        <input
                            value={searchText}
                            onKeyPress={(e) => {
                                handleAddExternalSource(e);
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onFocus={() => setExpanded(true)}
                            onBlur={() => setExpanded(false)}
                        />
                        {expanded &&
                            <div className='Select_menu'>
                                {isIPv4Exist ? null : <div className='font-size-16 font-weight-semibold text-grey-1 cursor-pointer' onMouseDown={() => { addIPv4() }}>All IPv4</div>}
                                {searchList.length ? <div className='Search_list'>
                                    {searchList.map(item => {
                                        return (
                                            <div className='Search_list_item' key={item.name} onMouseDown={() => { addSource(item) }}>
                                                <div className='Icon_container Instance position-relative m-r-14'>
                                                    <img src={handleIcon(item.type)} />
                                                    {item.type === 'vm' ? <span className={`status ${(item?.state === 'Running' && 'Instance__active') ||
                                                        (['Starting', 'Stopping'].includes(item?.state)
                                                            ? 'Instance__pending'
                                                            : 'Instance__inactive')}`
                                                    } /> : null}
                                                </div>
                                                <div className='flex flex-column'>
                                                    <span className='text-grey-3 font-size-16 font-weight-semibold' dangerouslySetInnerHTML={{ __html: generateServerName(item.name, searchText) }} />
                                                    {item.type === 'vm' ? <div className="font-size-12 font-weight-semibold text-grey-8 Instance_info">
                                                        <span className='m-r-5'>{item?.cpunumber} {t('list.cpu')}</span>
                                                        <span className='m-r-5'>{pretty(item?.memory * 1024 * 1024)} {t('list.ram')}</span>
                                                        <span>{item?.zonename}</span>
                                                    </div> : null}
                                                </div>

                                            </div>)
                                    })}
                                </div> :
                                    <div className='font-size-16 font-weight-light text-grey-1'>{t('firewallRules.selectPlaceholder')}</div>}
                            </div>}
                    </div>
                </div>
                {deletable && <div className="text-align-center text-blue-1 flex flex-align-items-center more position-relative">
                    <a
                        className="lighter font-weight-semibold font-size-16"
                        href="javascript:;"
                        id='more'
                        onClick={() => {
                            isComponentVisible !== i ? setIsComponentVisible(i) : setIsComponentVisible(null);
                        }}>
                        {commonT('more')}
                        <span
                            className={`arrow fomt-weight-semibold ${isComponentVisible === i ? 'arrow--up' : 'arrow--down'
                                } arrow--blue m-l-5`}
                        />
                    </a>
                    {isComponentVisible !== null && isComponentVisible === i && (
                        <div ref={ref} className="keyPop popup font-size-14 font-weight-semibold text-grey-1 List_popup popup_menu">
                            <div onClick={() => { setModalVisible(true) }}>
                                <img src={TrashIco} />
                                {t('firewallRules.deleteKey')}
                            </div>
                        </div>
                    )}
                </div>}
            </div>
            : <div className='List_item_display'>
                <div>{protocol.text}</div>
                {rule?.startport || rule?.endport ? <div>{rule?.startport}-{rule?.endport}</div> : null}
                {rule?.icmptype ? <div>{rule?.icmptype}</div> : null}
                {rule?.icmpcode ? <div>{rule?.icmpcode}</div> : null}
                <div>
                    {source && source.length ? source.map(sourceItem => {
                        return <div className='Source_item' key={sourceItem.text}>
                            {sourceItem.icon ? <img src={sourceItem.icon} /> : null}
                            {sourceItem.text}
                        </div>
                    }) : null}
                </div>
                <div className="text-align-center text-blue-1 flex flex-align-items-center more position-relative">
                    <a
                        className="lighter font-weight-semibold font-size-16"
                        href="javascript:;"
                        id='more'
                        onClick={() => {
                            isComponentVisible !== i ? setIsComponentVisible(i) : setIsComponentVisible(null);
                        }}>
                        {commonT('more')}
                        <span
                            className={`arrow fomt-weight-semibold ${isComponentVisible === i ? 'arrow--up' : 'arrow--down'
                                } arrow--blue m-l-5`}
                        />
                    </a>
                    {isComponentVisible !== null && isComponentVisible === i && (
                        <div ref={ref} className="keyPop popup font-size-14 font-weight-semibold text-grey-1 List_popup popup_menu">
                            <div onClick={() => { editBehaviour(rule, rule.type) }}>
                                <img src={ConfigIco} />
                                {t('firewallRules.editKey')}
                            </div>
                            {deletable ? <> <div className="line_cuted m-t-15 m-b-15"></div>
                                <div onClick={() => { setModalVisible(true) }}>
                                    <img src={TrashIco} />
                                    {t('firewallRules.deleteKey')}
                                </div></> : null}
                        </div>
                    )}
                </div>
            </div>}
            {isModalVisible && <DeleteModal
                t={t}
                onHide={() => setModalVisible(false)}
                show={isModalVisible}
                handleDelete={() => deleteBehaviour(rule)}
                deleteItem='rule'
            />}
        </>
    )
}

export default FirewallRule;
